import { ACTIONS } from '../../enums/actions';
import { IDispatchAction } from '../../interfaces/store/root.interface';
import { ICommunity } from '../../shared/interfaces/models/community.interface';
import { IUserCommunity } from '../../shared/interfaces/models/user-community.interface';
import { SHARED_ACTIONS } from '../../shared/constants/actions.const';
import { ILevel } from '../../shared/interfaces/models/level.interface';

export const getCommunities = (
  onSuccess?: (result: ICommunity[]) => void,
  onFail?: (result: ICommunity[]) => void
): IDispatchAction => ({
  type: ACTIONS.GET_COMMUNITIES,
  onSuccess,
  onFail
});

export const getCommunityById = (
  payload: any,
  onSuccess?: (result: ICommunity) => void,
  onFail?: (result: ICommunity) => void
): IDispatchAction => ({
  type: SHARED_ACTIONS.GET_COMMUNITY_BY_ID,
  payload,
  onSuccess,
  onFail
});

export const getUserCommunitiesByUserId = (
  payload: any,
  onSuccess?: (result: IUserCommunity[]) => void,
  onFail?: (result: IUserCommunity[]) => void
): IDispatchAction => ({
  type: ACTIONS.GET_USER_COMMUNITIES_BY_USER_ID,
  payload,
  onSuccess,
  onFail
});

export const getLowestLevel = (
  onSuccess?: (result: ILevel) => void,
  onFail?: (result: any) => void
): IDispatchAction => ({
  type: SHARED_ACTIONS.GET_LOWEST_LEVEL,
  onSuccess,
  onFail
});

export const getCommunitiesByArtist = (
  payload: any,
  onSuccess?: (result: ICommunity[]) => void,
  onFail?: (result: ICommunity[]) => void
): IDispatchAction => ({
  type: SHARED_ACTIONS.GET_COMMUNITIES_BY_ARTIST,
  payload,
  onSuccess,
  onFail
});

export const joinToCommunityEvents = (
  payload: any,
  onSuccess?: (result: any) => void,
  onFail?: (result: any) => void
): IDispatchAction => ({
  type: SHARED_ACTIONS.JOIN_TO_COMMUNITY_EVENTS,
  payload,
  onSuccess,
  onFail
});

export const leaveCommunityEvents = (
  payload: any,
  onSuccess?: (result: any) => void,
  onFail?: (result: any) => void
): IDispatchAction => ({
  type: SHARED_ACTIONS.LEAVE_COMMUNITY_EVENTS,
  payload,
  onSuccess,
  onFail
});

export const getTopUsersFromCommunity = (
  payload: any,
  onSuccess?: (result: IUserCommunity[]) => void,
  onFail?: (result: any) => void
): IDispatchAction => ({
  type: SHARED_ACTIONS.GET_TOP_USERS_FROM_COMMUNITY,
  payload,
  onSuccess,
  onFail
});
