import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { IUploadSong } from '../../shared/interfaces/custom/upload-song.interface';
import { setGeneralValue } from '../../store/actions/generalActions';
import DropZoneComponent from './DropZoneComponent';
import SongFormManager from './SongFormManager';
const style = {
  borderRadius: 2,
  p: 4
};

interface IProps {
  onFinish: () => void;
}

const AddNewSongsComponent: React.FC<IProps> = ({ onFinish }) => {
  const dispatch = useDispatch();
  const [selectedUploadSongs, setSelectedUploadSongs] = useState<IUploadSong[] | undefined>();

  const handleAudioFilesSelected = (audioFiles: File[]) => {
    const selectedUploadSongsTmp = audioFiles.map(
      (audioFile, index) =>
        ({
          index,
          audioFile
        } as IUploadSong)
    );
    if (selectedUploadSongsTmp && selectedUploadSongsTmp.length > 0) {
      setSelectedUploadSongs(selectedUploadSongsTmp);
    }
  };

  const handleFormsFilled = (filledUploadSongs: IUploadSong[]) => {
    dispatch(setGeneralValue('uploadSongs', filledUploadSongs));
    dispatch(setGeneralValue('uploadModalState', true));
    onFinish();
  };

  return (
    <>
      <Box sx={style}>
        {selectedUploadSongs ? (
          <SongFormManager uploadSongs={selectedUploadSongs} onCancel={onFinish} onFormsFilled={handleFormsFilled} />
        ) : (
          <DropZoneComponent onAudioFilesSelected={handleAudioFilesSelected} onCancel={onFinish} />
        )}
      </Box>
    </>
  );
};

export default AddNewSongsComponent;
