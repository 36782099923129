import { createTheme } from '@mui/material/styles';
import chroma from 'chroma-js';
import colors from './colors.json';
// import { amber } from '@mui/material/colors';
// import createPalette from '@mui/material/styles/createPalette';

const colorNames = ['primary', 'secondary', 'warning', 'error', 'success', 'info'];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (mode) =>
  createTheme({
    palette: {
      // type: mode,
      type: 'light',
      ...colors
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 1024,
        xl: 1200
      }
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '8px'
          }
        },
        variants: [
          ...colorNames.map((colorName) => ({
            props: { variant: 'outlined', color: colorName },
            style: {
              border: `2px solid ${colors[colorName]['200']}`,
              '&:hover': {
                border: `2px solid ${colors[colorName]['500']}`
              }
            }
          }))
        ]
      },
      MuiChip: {
        variants: [
          ...colorNames.map((colorName) => ({
            props: { color: colorName },
            style: {
              backgroundColor: chroma(colors[colorName]['main']).alpha(0.1).hex(),
              color: chroma(colors[colorName]['main']).darken(2.5).saturate(2).hex()
            }
          })),
          {
            props: { variant: 'text' },
            style: {
              height: 0,
              '& .MuiChip-label': {
                padding: 0,
                fontWeight: 400,
                fontSize: '12px',
                lineHeight: '16px'
              }
            }
          },
          {
            props: { variant: 'tag' },
            style: {
              borderRadius: '6px',
              height: 'auto',
              '& .MuiChip-label': {
                padding: '2px 4px',
                fontWeight: 400,
                fontSize: '12px',
                lineHeight: '16px'
              }
            }
          },
          {
            props: { variant: 'badge' },
            style: {
              borderRadius: '16px 0px',
              height: 'auto',
              '& .MuiChip-label': {
                padding: '2px 12px',
                fontWeight: 600,
                fontSize: '10px',
                lineHeight: '12px'
              }
            }
          },
          {
            props: { variant: 'screen' },
            style: {
              borderRadius: '16px 0px',
              height: 'auto',
              '& .MuiChip-label': {
                padding: '10px',
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '16px'
              },
              '& .MuiChip-icon': {
                marginLeft: '10px'
              }
            }
          }
        ]
      }
    },
    typography: {
      hero: {
        fontFamily: 'Nunito',
        fontSize: 44,
        fontWeight: 600,
        lineHeight: '56px',
        display: 'block'
      },
      corben: {
        fontFamily: 'Corben',
        fontSize: 44,
        fontWeight: 600,
        lineHeight: '56px',
        display: 'block'
      },
      h1: {
        fontFamily: 'Nunito',
        fontSize: 32,
        fontWeight: 600,
        lineHeight: '36px'
      },
      h2: {
        fontFamily: 'Nunito',
        fontSize: 24,
        fontWeight: 600,
        lineHeight: '28px'
      },
      'h2-medium': {
        fontFamily: 'Nunito',
        fontSize: 24,
        fontWeight: 500,
        lineHeight: '28px',
        display: 'block'
      },
      h3: {
        fontFamily: 'Nunito',
        fontSize: 20,
        fontWeight: 600
      },
      h4: {
        fontFamily: 'Nunito',
        fontSize: 20,
        fontWeight: 300
      },
      h5: {
        fontFamily: 'Nunito',
        fontSize: 16,
        fontWeight: 600
      },
      h6: {
        fontFamily: 'Nunito',
        fontSize: 16,
        fontWeight: 300
      },
      subtitle1: {
        fontFamily: 'Nunito',
        fontSize: 20,
        fontWeight: 400,
        lineHeight: '28px'
      },
      subtitle2: {
        fontFamily: 'Nunito'
      },
      body1: {
        fontFamily: 'Nunito',
        fontSize: 16,
        lineHeight: '20px',
        fontWeight: 500
      },
      body2: {
        fontFamily: 'Nunito',
        fontSize: 14,
        lineHeight: '16px',
        fontWeight: 500
      },
      button: {
        fontFamily: 'Nunito',
        fontSize: 16,
        lineHeight: '20px',
        fontWeight: 500,
        textTransform: 'capitalize'
      },
      caption: {
        fontFamily: 'Nunito',
        fontSize: 12,
        lineHeight: '16px',
        fontWeight: 400
      },
      overline: {
        fontFamily: 'Nunito',
        fontSize: 16,
        lineHeight: '20px',
        fontWeight: 500
      },
      display1: {
        fontFamily: 'Nunito',
        fontSize: 40,
        lineHeight: '44px',
        fontWeight: 600,
        display: 'block'
      },
      display2: {
        fontFamily: 'Nunito',
        fontSize: 32,
        lineHeight: '36px',
        fontWeight: 600,
        display: 'block'
      },
      userid: {
        fontFamily: 'Nunito',
        fontSize: 20,
        lineHeight: '24px',
        fontWeight: 400,
        display: 'block'
      },
      badge: {
        fontFamily: 'Nunito',
        fontSize: 10,
        lineHeight: '12px',
        fontWeight: 600,
        display: 'block'
      },
      bottomnav: {
        fontFamily: 'Nunito',
        fontSize: 9,
        lineHeight: '12px',
        fontWeight: 600,
        letterSpacing: '0.3px',
        color: '#50007F',
        textTransform: 'uppercase',
        display: 'block'
      }
    }
  });
