/* eslint-disable no-console */
import { Action } from 'redux';
import { EventChannel, eventChannel } from 'redux-saga';
import { call, delay, fork, put, race, take } from 'redux-saga/effects';
import { ACTIONS } from '../../enums/actions';
import { IDispatchAction } from '../../interfaces/store/root.interface';
import artistForks from '../forks/artistForks';
import awsForks from '../forks/awsForks';
import communitiesForks from '../forks/communitiesForks';
import resourceForks from '../forks/resourceForks';
import songForks from '../forks/songForks';
import userForks from '../forks/userForks';
import voteForks from '../forks/voteForks';
import { artistSubscribe } from './artistSaga';
import { authSubscribe } from './authSaga';
import { awsSubscribe } from './awsSaga';
import { communitiesSubscribe } from './communitiesSaga';
import { resourceSubscribe } from './resourceSaga';
import { songSubscribe } from './songSaga';
import { userSubscribe } from './userSaga';
import { SHARED_ACTIONS } from '../../shared/constants/actions.const';

let globalSocket: any;
// eslint-disable-next-line @typescript-eslint/no-var-requires
const socketIOClient = require('socket.io-client');

function connect(): Promise<any> {
  globalSocket = socketIOClient(process.env.REACT_APP_SOCKET_HOST ?? 'http://localhost:3001');
  return new Promise((resolve) => {
    globalSocket.on('connect', () => {
      console.log('Connect Socket::::::');
      securedSocket(globalSocket, 'user/join-personal-room', {});
      resolve(globalSocket);
    });
    globalSocket.on('connect_error', (err: any) => {
      console.log(`connect_error due to ${err.message}`);
    });
  });
}

function socketSubscribe(socket: any) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return eventChannel((emit) => {
    socket.on('exception', (err: any) => {
      emit({ type: ACTIONS.SOCKET_MESSAGE_ERROR, payload: err });
      console.log('an exception happened', err);
    });
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  });
}

function securedSocket(socket: any, message: string, data: Record<string, unknown>) {
  const jwtToken = localStorage.getItem('jwt');
  let dataWithJwt = { jwt: jwtToken };
  if (data) {
    dataWithJwt = { jwt: jwtToken, ...data };
  }
  socket.emit(message, dataWithJwt);
}

function disconnect(): void {
  globalSocket.disconnect();
}

export function* read(socket: any, subscription: any): Generator {
  const channel = (yield call(subscription, socket)) as EventChannel<unknown>;
  while (true) {
    const action = (yield take(channel)) as Action;
    yield put(action);
  }
}

export function* disconnectSocket(action: IDispatchAction): Generator {
  yield call(disconnect);

  if (action.onSuccess) {
    action.onSuccess();
  }
}

export function* socketInitFlow(action: IDispatchAction): Generator {
  const socket = yield call(connect);

  // Socket Reads: fork passes in the socket instance and each subscriber
  // to handle messages coming from the server through websockets.
  yield fork(read, socket, socketSubscribe);
  yield fork(read, socket, userSubscribe);
  yield fork(read, socket, authSubscribe);
  yield fork(read, socket, songSubscribe);
  yield fork(read, socket, communitiesSubscribe);
  yield fork(read, socket, awsSubscribe);
  yield fork(read, socket, resourceSubscribe);
  yield fork(read, socket, artistSubscribe);

  // Socket Requests: fork passes in socket and stand by until an action is dispatched
  // that sends a message to the server.
  yield fork(awsForks, (message: string, data: Record<string, unknown>) => securedSocket(socket, message, data));
  yield fork(resourceForks, (message: string, data: Record<string, unknown>) => securedSocket(socket, message, data));
  yield fork(userForks, (message: string, data: Record<string, unknown>) => securedSocket(socket, message, data));
  yield fork(songForks, (message: string, data: Record<string, unknown>) => securedSocket(socket, message, data));
  yield fork(artistForks, (message: string, data: Record<string, unknown>) => securedSocket(socket, message, data));
  yield fork(communitiesForks, (message: string, data: Record<string, unknown>) =>
    securedSocket(socket, message, data)
  );
  yield fork(voteForks, (message: string, data: Record<string, unknown>) => securedSocket(socket, message, data));

  if (action.onSuccess) {
    action.onSuccess();
  }
}

export function* responseHandler<ResponsePayload>(
  action: ACTIONS | SHARED_ACTIONS | SHARED_ACTIONS[] | ACTIONS[],
  onSuccess?: <T = unknown>(data: T) => void,
  onFail?: (error: unknown) => void
): Generator {
  const { timeout, error, response } = (yield race({
    error: take(ACTIONS.SOCKET_MESSAGE_ERROR),
    response: take(action),
    timeout: delay(5000)
  })) as {
    response: IDispatchAction<ResponsePayload>;
    timeout: boolean;
    error: IDispatchAction<unknown>;
  };

  if (timeout) {
    if (onFail) {
      onFail('timeout');
    }
  } else if (error) {
    if (onFail) {
      onFail({ error, response });
    }
    yield put({ type: ACTIONS.ERROR, data: error });
  } else {
    if (onSuccess) {
      // console.log('response: ', response);
      onSuccess(response.payload);
    }
  }
}
