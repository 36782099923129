import { ACTIONS } from '../../enums/actions';
import {
  ILoginPayload,
  IPasswordResetPayload,
  IRegisterWithInvitationPayload,
  IRequestPasswordResetPayload
} from '../../interfaces/requests/auth.interface';
import { IDispatchAction } from '../../interfaces/store/root.interface';
import { SHARED_ACTIONS } from '../../shared/constants/actions.const';
import { ICheckIfUserExistsPayload } from '../../shared/interfaces/custom/check-if-user-exists-payload.interface';
import { IRegisterPayload } from '../../shared/interfaces/custom/register-payload.interface';
import { IInvitation } from '../../shared/interfaces/models/invitation.interface';
import { IAppSettings } from '../../shared/interfaces/models/app-settings.interface';

export const login = (
  payload: ILoginPayload,
  onSuccess?: () => void,
  onFail?: (error: any) => void
): IDispatchAction<ILoginPayload> => ({
  type: ACTIONS.LOGIN,
  payload,
  onSuccess,
  onFail
});

export const register = (
  payload: IRegisterPayload,
  onSuccess?: () => void,
  onFail?: () => void
): IDispatchAction<IRegisterPayload> => ({
  type: SHARED_ACTIONS.REGISTER,
  payload,
  onSuccess,
  onFail
});

export const checkIfUserExists = (
  payload: ICheckIfUserExistsPayload,
  onSuccess?: (userExists: boolean) => void,
  onFail?: () => void
): IDispatchAction<ICheckIfUserExistsPayload> => ({
  type: SHARED_ACTIONS.CHECK_IF_USER_EXISTS,
  payload,
  onSuccess,
  onFail
});

export const logout = (onSuccess?: () => void, onFail?: () => void): IDispatchAction => ({
  type: ACTIONS.LOGOUT,
  onSuccess,
  onFail
});

export const registerWithInvitation = (
  payload: IRegisterWithInvitationPayload,
  onSuccess?: () => void,
  onFail?: () => void
): IDispatchAction<IRegisterWithInvitationPayload> => ({
  type: ACTIONS.REGISTER_WITH_INVITATION,
  payload,
  onSuccess,
  onFail
});

export const requestPasswordReset = (
  payload: IRequestPasswordResetPayload,
  onSuccess?: () => void,
  onFail?: () => void
): IDispatchAction<IRequestPasswordResetPayload> => ({
  type: ACTIONS.REQUEST_PASSWORD_RESET,
  payload,
  onSuccess,
  onFail
});

export const resetPassword = (
  payload: IPasswordResetPayload,
  onSuccess?: () => void,
  onFail?: () => void
): IDispatchAction<IPasswordResetPayload> => ({
  type: ACTIONS.RESET_PASSWORD,
  payload,
  onSuccess,
  onFail
});

export const checkAuth = (onSuccess?: () => void, onFail?: () => void) => ({
  type: ACTIONS.CHECK_AUTH,
  onSuccess,
  onFail
});

export const getInvitationByToken = (
  payload: any,
  onSuccess?: (data: { invitation: IInvitation; validToken: boolean }) => void,
  onFail?: (error: any) => void
): IDispatchAction => ({
  type: SHARED_ACTIONS.GET_INVITATION_BY_TOKEN,
  payload,
  onSuccess,
  onFail
});

export const getAppSettings = (
  onSuccess?: (data: { appSettings: IAppSettings[] }) => void,
  onFail?: (error: any) => void
): IDispatchAction => ({
  type: SHARED_ACTIONS.GET_APP_SETTINGS,
  onSuccess,
  onFail
});
