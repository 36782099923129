/* eslint-disable @typescript-eslint/no-empty-function  */
/* eslint-disable @typescript-eslint/no-unused-vars*/

import IcecastMetadataPlayer, { IcyMetadata } from 'icecast-metadata-player';
import IcecastMetadataStats from 'icecast-metadata-stats';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PLAYER_CONFIG, RADIO_STATUS } from '../../../enums/player';
import { IRadioControls } from '../../../interfaces/echo-player/echo-player-controls.interface';
import { IPlayerInfo } from '../../../interfaces/echo-player/echo-player-info.interface';
import { IIcecastStats } from '../../../interfaces/responses/icecast-stats.interface';
import { getSongsByIdList } from '../../../store/actions/songActions';
import useAudioHelper from '../helpers/useAudioHelper';

interface IProps {
  icecast?: IcecastMetadataPlayer;
  setIcecast: (icecast: IcecastMetadataPlayer | undefined) => void;
  audioElement: HTMLAudioElement;
  setAudioElement: (audioElement: HTMLAudioElement) => void;
  playerInfo: IPlayerInfo;
}

export default function useRadioControls({
  icecast,
  setIcecast,
  audioElement,
  setAudioElement,
  playerInfo
}: IProps): IRadioControls {
  const {
    data: playerData,
    triggers: {
      radio: { afterPlay, afterStop, beforePlay, beforeStop },
      song: songTriggers
    }
  } = playerInfo;
  const dispatch = useDispatch();
  const { getUrlFromSong } = useAudioHelper();
  const [statsListener, setStatsListener] = useState<any>();
  const [stopAfterPlayStartsEnabled, setStopAfterPlayStartsEnabled] = useState(false);

  useEffect(() => {
    return () => {
      statsListener?.stop();
      icecast?.stop().then(() => {
        icecast?.detachAudioElement();
      });
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (stopAfterPlayStartsEnabled && icecast?.state === 'playing') {
        beforeStop();
        await icecast.stop();

        const song = playerData.song.song;
        const sourceUrl = song ? getUrlFromSong(song) : playerData.song.sourceUrl;
        const songStartedAt = new Date();
        song && sourceUrl && songTriggers.afterPlay(song, sourceUrl, songStartedAt);

        setStopAfterPlayStartsEnabled(false);
      }
    })().then();
  }, [icecast?.state, stopAfterPlayStartsEnabled]);

  // HELPERS ==================================================

  const updateStatus = (status: RADIO_STATUS) => {
    // console.log('status', status);
    playerInfo.triggers.radio.onStatus(status);
    playerInfo.data.radio.onStatusChange && playerInfo.data.radio.onStatusChange(status);
  };

  const setOnStatusChange = (onStatusChange: (status: RADIO_STATUS) => void) => {
    playerInfo.triggers.radio.setOnStatusChange(onStatusChange);
  };

  const getElapsed = (songStartedAt: Date) => {
    const currentMillis = new Date().getTime();
    let elapsed: number | undefined = currentMillis - (songStartedAt?.getTime() ?? currentMillis);
    elapsed = Math.floor(elapsed / 1000);
    return elapsed;
  };

  const extractMetadata = (metadata: IcyMetadata) => {
    if (metadata.StreamTitle && playerData.radio.metadata?.StreamTitle === metadata.StreamTitle) {
      return;
    }
    const dataArray = metadata.StreamTitle?.split('-').map((stringId) => parseInt(stringId));
    if (!dataArray || dataArray.length < 3) {
      // eslint-disable-next-line no-console
      console.error('Couldn`t update metadata. metadata.StreamTitle without data');
      return;
    }
    const idList = [dataArray[0], dataArray[1]];
    const songStartedAt = new Date(dataArray[2] + 4000);
    const songDuration = dataArray[3];

    playerInfo.triggers.radio.onMetadata({ metadata, songStartedAt, songDuration });

    if (idList && idList.length > 0) {
      dispatch(
        getSongsByIdList({ idList }, (songs) => {
          const song = songs.find((s) => s.id === idList[0]);
          const nextSong = songs.find((s) => s.id === idList[1]);
          updateStatus(RADIO_STATUS.PLAYING);
          playerInfo.triggers.radio.onMetadata({ song, nextSong });
          // console.log(`song id ${song?.id} elapsed:`, getElapsed(songStartedAt));
        })
      );
    }
  };

  const listenStats = async (communityId: number, sourceUrl: string) => {
    if (!sourceUrl) {
      return;
    }
    if (statsListener) {
      await statsListener?.stop();
    }
    const stats = new IcecastMetadataStats(sourceUrl, {
      onStats: (stats: IIcecastStats) => {
        const icySource = stats?.icestats?.source?.find(
          (s) => s.server_name === `/community-${communityId}.ogg`
        ) as any;
        playerInfo.triggers.radio.onStats({ stats, listenersCount: icySource?.listeners });
        // if (icySource?.title !== undefined) {
        //   extractMetadata({ StreamTitle: icySource?.title } as IcyMetadata);
        // }
      },
      interval: 30,
      sources: ['icestats']
    });
    stats.start();
    setStatsListener(stats);
  };

  // const stop = async () => {
  //   beforeStop();
  //   if (icecast) {
  //     await icecast.stop();
  //     await icecast.detachAudioElement();
  //     setIcecast(undefined);
  //   }
  // };

  const stop = useCallback(async () => {
    // console.log('radioControls stop()');
    beforeStop();
    if (icecast) {
      await icecast.stop();
    }
  }, [icecast]);

  const stopWhileLoading = () => {
    setStopAfterPlayStartsEnabled(true);
  };

  const play = useCallback(
    async (inputCommunityId?: number) => {
      // console.log('radioControls play()');
      const communityId = inputCommunityId !== undefined ? inputCommunityId : playerData.radio.communityId;
      if (communityId === undefined || communityId === null) {
        // eslint-disable-next-line no-console
        console.error('no communityId provided for start listening radio');
        return;
      }
      const sourceUrl = `${PLAYER_CONFIG.RADIO_STREAMING_BASE_URL}-${communityId}.ogg`;

      if (icecast) {
        // console.log('icecast.state: ', icecast.state);
        if (
          icecast.state !== 'stopped' &&
          ((playerData.radio.nextCommunityId !== undefined && communityId === playerData.radio.nextCommunityId) ||
            (playerData.radio.nextCommunityId === undefined && playerData.radio.sourceUrl === sourceUrl))
          // (playerData.radio.status === RADIO_STATUS.PLAYING || playerData.radio.status === RADIO_STATUS.UNAVAILABLE) &&
        ) {
          // console.log('[R1] Avoiding start playing radio. Already playing radio');
          return;
        } else if (
          (playerData.radio.nextCommunityId !== undefined && communityId === playerData.radio.nextCommunityId) ||
          (playerData.radio.nextCommunityId === undefined && communityId === playerData.radio.communityId)
        ) {
          // console.log('[R2] Restarting same radio...');
          beforePlay(communityId);
          await icecast.play();
          return;
        } else {
          // console.log('[R3] Stopping previous radio');
          await statsListener?.stop();
          await icecast.stop();
          await icecast.detachAudioElement();
        }
      } else {
        // console.log('[R4] icecast not defined');
      }
      beforePlay(communityId);

      // console.log('[R5] initializing new Icecast instance');
      const newIcecastPlayer = new IcecastMetadataPlayer(sourceUrl, {
        onMetadata: (meta: IcyMetadata) => {
          updateStatus(RADIO_STATUS.METADATA_RECEIVED);
          extractMetadata(meta);
        },
        onPlay: () => {
          updateStatus(RADIO_STATUS.PLAYING_WITHOUT_METADATA);
          afterPlay();
        },
        onStop: () => {
          afterStop();
          updateStatus(RADIO_STATUS.EMPTY);
        },
        onLoad: () => {
          updateStatus(RADIO_STATUS.CONNECTING);
        },
        onError: (error) => {
          afterStop();
          updateStatus(RADIO_STATUS.UNAVAILABLE);
          // eslint-disable-next-line no-console
          console.error(error);
        },
        onRetry: () => {
          // updateStatus(RADIO_STATUS.RECONNECTING);
        },
        onStreamStart: () => {
          updateStatus(RADIO_STATUS.LOADING);
        },
        onSwitch: () => {
          updateStatus(RADIO_STATUS.SWITCHING);
        },
        icyDetectionTimeout: 5000,
        enableLogging: false,
        audioElement: audioElement,
        retryTimeout: 120
      });
      setIcecast(newIcecastPlayer);
      await newIcecastPlayer.play();
      listenStats(communityId, sourceUrl);
    },
    [icecast, audioElement, playerData]
  );

  const togglePlay = useCallback(
    async (communityId?: number) => {
      // console.log('radioControls togglePlay()');
      if (playerData.radio.playing) {
        await stop();
      } else {
        await play(communityId);
      }
    },
    [playerData.radio.playing, stop, play]
  );

  // const togglePlay = async (communityId?: number) => {
  //   if (playerData.radio.playing) {
  //     await stop();
  //   } else {
  //     await play(communityId);
  //   }
  // };

  return {
    icecast,
    audioElement,
    play,
    stop,
    stopWhileLoading,
    togglePlay,
    setOnStatusChange
  };
}
