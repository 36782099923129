import { ACTIONS } from '../../enums/actions';
import { ICreateUploadResourcePayload, IResourceFileUploadPayload } from '../../interfaces/requests/resource.interface';
import { IDispatchAction } from '../../interfaces/store/root.interface';

export const createUploadResource = (
  payload: ICreateUploadResourcePayload,
  onSuccess?: () => void,
  onFail?: () => void
): IDispatchAction => ({
  payload,
  type: ACTIONS.CREATE_UPLOAD_RESOURCE,
  onSuccess,
  onFail
});

export const getUserResources = (onSuccess?: () => void, onFail?: () => void): IDispatchAction => ({
  type: ACTIONS.GET_USER_RESOURCES,
  onSuccess,
  onFail
});

export const uploadResourceFile = (
  payload: IResourceFileUploadPayload,
  onSuccess?: () => void,
  onFail?: () => void,
  onProgress?: (percent: number) => void
): IDispatchAction => ({
  payload,
  type: ACTIONS.UPLOAD_RESOURCE_FILE,
  onSuccess,
  onFail,
  onProgress
});
