import React, { useEffect, useState } from 'react';
import SongForm from './SongForm';
import { SxProps } from '@mui/system';
import { Box } from '@mui/material';
import { IUploadSong } from '../../shared/interfaces/custom/upload-song.interface';
import { getCommunities } from '../../store/actions/communitiesActions';
import { useDispatch } from 'react-redux';
import { useSelector } from '../../store';
import useAnalytics from '../../lib/hooks/useAnalytics';

interface IProps {
  uploadSongs: IUploadSong[];
  onFormsFilled: (uploadSongs: IUploadSong[]) => void;
  onCancel: () => void;
  sx?: SxProps;
}

const SongFormManager: React.FC<IProps> = ({ uploadSongs, onFormsFilled, onCancel, sx }) => {
  const { track } = useAnalytics();
  const normalizedCommunities = useSelector((state) => state.main.communities);
  const communities = Object.values(normalizedCommunities);
  const dispatch = useDispatch();
  const [currentFormIndex, setCurrentFormIndex] = useState(0);

  useEffect(() => {
    dispatch(getCommunities());
    track('song_upload_form');
  }, []);

  const handleFilledForm = (songFile: IUploadSong) => {
    uploadSongs[currentFormIndex] = songFile;
    if (isLastForm()) {
      onFormsFilled(uploadSongs);
    } else {
      setCurrentFormIndex(currentFormIndex + 1);
    }
  };

  const handleFormBack = () => {
    if (!isFirstForm()) {
      setCurrentFormIndex(currentFormIndex - 1);
    }
  };

  const isLastForm = () => {
    return currentFormIndex + 1 >= uploadSongs.length;
  };

  const isFirstForm = () => {
    return currentFormIndex === 0;
  };

  return (
    <Box sx={sx}>
      {uploadSongs[currentFormIndex] && communities && (
        <SongForm
          communities={communities}
          uploadSong={uploadSongs[currentFormIndex]}
          onCancel={onCancel}
          onBack={handleFormBack}
          onFormFilled={handleFilledForm}
          isLastForm={isLastForm()}
          isFirstForm={isFirstForm()}
        />
      )}
    </Box>
  );
};

export default SongFormManager;
