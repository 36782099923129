export enum UPLOAD_TYPE {
  AWS = "aws", // Will be uploaded to AWS.
  REGULAR = "regular", // Will be uploaded to the server.
}

export enum APP_SECTION {
  ARTIST_DASHBOARD_COVER = "artist_dashboard_cover",
  ARTIST_SONG = "artist_song",
  COMMUNITY_PROFILE = "community_profile",
  SONG_COVER = "song_cover",
  USER_PROFILE = "user_profile",
  VIDEO_PROFILE = "video_profile",
  SONG_VIDEO = "song_video",
}

export type APP_SECTION_IMAGE = Extract<
  APP_SECTION,
  | APP_SECTION.ARTIST_DASHBOARD_COVER
  | APP_SECTION.SONG_COVER
  | APP_SECTION.COMMUNITY_PROFILE
  | APP_SECTION.USER_PROFILE
>;

export const APP_SECTION_IMAGE: Readonly<
  Record<APP_SECTION_IMAGE, APP_SECTION_IMAGE>
> = {
  [APP_SECTION.ARTIST_DASHBOARD_COVER]: APP_SECTION.ARTIST_DASHBOARD_COVER,
  [APP_SECTION.SONG_COVER]: APP_SECTION.SONG_COVER,
  [APP_SECTION.COMMUNITY_PROFILE]: APP_SECTION.COMMUNITY_PROFILE,
  [APP_SECTION.USER_PROFILE]: APP_SECTION.USER_PROFILE,
};

export type APP_SECTION_MUSIC = Extract<APP_SECTION, APP_SECTION.ARTIST_SONG>;

export const APP_SECTION_MUSIC: Readonly<
  Record<APP_SECTION_MUSIC, APP_SECTION_MUSIC>
> = {
  [APP_SECTION.ARTIST_SONG]: APP_SECTION.ARTIST_SONG,
};

export const APP_SECTION_PUBLIC: Readonly<Record<APP_SECTION, boolean>> = {
  [APP_SECTION.ARTIST_SONG]: false,
  [APP_SECTION.ARTIST_DASHBOARD_COVER]: false,
  [APP_SECTION.SONG_COVER]: false,
  [APP_SECTION.USER_PROFILE]: true,
  [APP_SECTION.VIDEO_PROFILE]: false,
  [APP_SECTION.SONG_VIDEO]: false,
  [APP_SECTION.COMMUNITY_PROFILE]: false,
};

export enum UPLOAD_STATUS {
  ADDED = "added",
  UPLOADING = "uploading",
  DONE = "done",
}
