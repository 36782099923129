export enum SHARED_ACTIONS {
  // App state actions.
  INIT_SOCKET = "INIT_SOCKET",
  DISCONNECT_SOCKET = "DISCONNECT_SOCKET",
  ERROR = "ERROR",
  LOADING = "LOADING",
  CLEAR_ERROR = "CLEAR_ERROR",
  SOCKET_MESSAGE_ERROR = "SOCKET_MESSAGE_ERROR",

  NORMALIZED = "NORMALIZED",

  // Regular actions
  LOGIN = "LOGIN",
  REQUEST_PASSWORD_RESET = "REQUEST_PASSWORD_RESET",
  CHECK_AUTH = "CHECK_AUTH",

  // General purpose actions.
  SET_GENERAL_VALUE = "SET_GENERAL_VALUE",
  GET_INITIAL_DATA = "GET_INITIAL_DATA",

  // Socket related actions.
  UPDATE_ME = "UPDATE_ME",
  GET_ME = "GET_ME",

  // Votes
  CAST_VOTE = "CAST_VOTE",
  INIT_UPLOAD = "INIT_UPLOAD",

  // Songs
  ADD_SONG_TO_UPLOAD_QUEUE = "ADD_SONG_TO_UPLOAD_QUEUE",
  CREATE_SONG = "CREATE_SONG",
  SET_UPLOAD_SONGS = "SET_UPLOAD_SONGS",
  SET_UPLOAD_SONGS_SUCCESS = "SET_UPLOAD_SONGS_SUCCESS",
  CREATE_SONG_SUCCESS = "CREATE_SONG_SUCCESS",
  CREATE_SONGS_AND_GENERATE_PRESIGNED_UPLOAD_URLS = "CREATE_SONGS_AND_GENERATE_PRESIGNED_UPLOAD_URLS",
  UPDATE_SONG = "UPDATE_SONG",
  UPLOAD_FILE_SONG = "UPLOAD_FILE_SONG",
  GET_SONGS_BY_ARTIST = "song/get-by-artist",
  GET_SONGS_BY_ARTIST_SUCCESS = "song/get-by-artist/success",

  // AWS
  GET_PRESIGNED_UPLOAD_URLS = "GET_PRESIGNED_UPLOAD_URLS",
  GET_PRESIGNED_UPLOAD_URLS_SUCCESS = "GET_PRESIGNED_UPLOAD_URLS_SUCCESS",
  INIT_PRESIGNED_URL_MULTIPART_UPLOAD = "INIT_PRESIGNED_URL_MULTIPART_UPLOAD",
  INIT_PRESIGNED_URL_MULTIPART_UPLOAD_SUCCESS = "INIT_PRESIGNED_URL_MULTIPART_UPLOAD_SUCCESS",
  INIT_PRESIGNED_URL_SIMPLE_UPLOAD_SUCCESS = "INIT_PRESIGNED_URL_SIMPLE_UPLOAD_SUCCESS",
  INIT_PRESIGNED_URL_SIMPLE_UPLOAD = "INIT_PRESIGNED_URL_SIMPLE_UPLOAD",
  COMPLETE_MULTIPART_UPLOAD = "COMPLETE_MULTIPART_UPLOAD",

  // Resources
  CREATE_UPLOAD_RESOURCE = "CREATE_UPLOAD_RESOURCE",
  CREATE_UPLOAD_RESOURCE_SUCCESS = "CREATE_UPLOAD_RESOURCE_SUCCESS",
  UPLOAD_RESOURCE_FILE = "UPLOAD_RESOURCE_FILE",
  UPLOAD_RESOURCE_FILE_SUCCESS = "UPLOAD_RESOURCE_FILE_SUCCESS",
  FILE_UPLOADED_SUCCESS = "FILE_UPLOADED_SUCCESS",
  FILE_UPLOADED_ERROR = "FILE_UPLOADED_ERROR",
  GET_USER_RESOURCES = "GET_USER_RESOURCES",

  // Community
  GET_COMMUNITIES = "GET_COMMUNITIES",
  GET_USER_COMMUNITIES_BY_USER_ID = "GET_USER_COMMUNITIES_BY_USER_ID",
  GET_USER_COMMUNITIES_BY_USER_ID_SUCCESS = "GET_USER_COMMUNITIES_BY_USER_ID_SUCCESS",
  GET_COMMUNITIES_SUCCESS = "GET_COMMUNITIES_SUCCESS",
  UPDATE_COMMUNITY = "UPDATE_COMMUNITY",
  UPDATE_COMMUNITY_SUCCESS = "UPDATE_COMMUNITY_SUCCESS",
  // CREATE_COMMUNITY = 'CREATE_COMMUNITY',
  // CREATE_COMMUNITY_SUCCESS = 'CREATE_COMMUNITY_SUCCESS',
  // UPDATE_COMMUNITY = 'UPDATE_COMMUNITY',
  // UPDATE_COMMUNITY_SUCCESS = 'UPDATE_COMMUNITY_SUCCESS',
  // GET_COMMUNITY_BY_ID = 'GET_COMMUNITY_BY_ID',
  // GET_COMMUNITY_BY_ID_SUCCESS = 'GET_COMMUNITY_BY_ID_SUCCESS',

  // Users
  GET_USERS = "GET_USERS",
  GET_USERS_SUCCESS = "GET_USERS_SUCCESS",
  INVITE_ARTIST = "INVITE_ARTIST",
  INVITE_ARTIST_SUCCESS = "INVITE_ARTIST_SUCCESS",
  INVITE_CURATOR = "INVITE_CURATOR",
  INVITE_CURATOR_SUCCESS = "INVITE_CURATOR_SUCCESS",
  UPDATE_USER = "UPDATE_USER",
  UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS",
  REMOVE_USER = "REMOVE_USER",
  REMOVE_USER_SUCCESS = "REMOVE_USER_SUCCESS",

  // Artist
  GET_ARTIST = "GET_ARTIST",
  GET_ARTIST_SUCCESS = "GET_ARTIST_SUCCESS",
  GET_ARTIST_BY_ID = "GET_ARTIST_BY_ID",
  GET_ARTIST_BY_ID_SUCCESS = "GET_ARTIST_BY_ID_SUCCESS",
  UPDATE_ARTIST = "UPDATE_ARTIST",
  UPDATE_ARTIST_SUCCESS = "UPDATE_ARTIST_SUCCESS",
  DELETE_SONG = "DELETE_SONG",
  DELETE_SONG_SUCCESS = "DELETE_SONG_SUCCESS",

  // Modal
  HANDLE_MODAL = "HANDLE_MODAL",

  GET_USER_COMMUNITY_BY_USER = "communities/get-by-user",
  GET_USER_COMMUNITY_BY_USER_SUCCESS = "communities/get-by-user-success",

  TEST = "test",
  TEST_SUCCESS = "test-success",

  GET_COMMUNITIES_BY_ARTIST = "communities/get-by-artist",
  GET_COMMUNITIES_BY_ARTIST_SUCCESS = "communities/get-by-artist/success",

  GET_SONGS_BY_COMMUNITY = "song/get-by-community",
  GET_SONGS_BY_COMMUNITY_SUCCESS = "song/get-by-community/success",

  UPDATE_UPVOTE_STATUS = "song/update-upvote-status",
  UPDATE_UPVOTE_STATUS_SUCCESS = "song/update-upvote-status/success",

  START_TO_UPLOAD_SONGS = "START_TO_UPLOAD_SONGS",
  START_TO_UPLOAD_SONGS_SUCCESS = "START_TO_UPLOAD_SONGS_SUCCESS",

  GET_COMMUNITY_BY_ID = "communities/get-by-id",
  GET_COMMUNITY_BY_ID_SUCCESS = "communities/get-by-id/success",

  CREATE_MANY_SONGS = "song/create-many-songs",
  CREATE_MANY_SONGS_SUCCESS = "communities/get-by-id/success",

  START_SONG_STREAMING_EVENT = "radio/streaming-started",
  GET_RADIO_STREAMING_INFO = "radio/get-streaming-started",
  GET_RADIO_STREAMING_INFO_SUCCESS = "radio/get-streaming-started/success",
  JOIN_TO_COMMUNITY_EVENTS = "community/join-to-events",
  JOIN_TO_COMMUNITY_EVENTS_SUCCESS = "community/join-to-events/success",
  LEAVE_COMMUNITY_EVENTS = "community/leave-to-events",
  LEAVE_COMMUNITY_EVENTS_SUCCESS = "community/leave-to-events/success",

  GET_CHATBOT_USER_ID_LIST = "user/get-chatbot-user-id-list",
  GET_CHATBOT_USER_ID_LIST_SUCCESS = "user/get-chatbot-user-id-list/success",

  GET_SONGS_BY_ID_LIST = "songs/get-by-id-list",
  GET_SONGS_BY_ID_LIST_SUCCESS = "songs/get-by-id-list/success",

  GET_SONG_STARTED_AT_BY_RADIO = "radio/get-song-started-at",
  GET_SONG_STARTED_AT_BY_RADIO_SUCCESS = "radio/get-song-started-at/success",

  GET_SONG_COMMENTS = "songs/get-comments",
  GET_SONG_COMMENTS_SUCCESS = "songs/get-comments/success",
  SAVE_SONG_COMMENT = "songs/save-comment",
  SAVE_SONG_COMMENT_SUCCESS = "songs/save-comment/success",
  NEW_SONG_COMMENT_EVENT = "songs/new-song-comment-event",

  GET_SONG_REACTIONS = "songs/get-reactions",
  GET_SONG_REACTIONS_SUCCESS = "songs/get-reactions/success",
  SAVE_SONG_REACTION = "songs/save-reaction",
  SAVE_SONG_REACTION_SUCCESS = "songs/save-reaction/success",
  NEW_SONG_REACTION_EVENT = "songs/new-song-reaction-event",

  REGISTER = "REGISTER",
  REGISTER_SUCCESS = "REGISTER_SUCCESS",

  CHECK_IF_USER_EXISTS = "CHECK_IF_USER_EXISTS",
  CHECK_IF_USER_EXISTS_SUCCESS = "CHECK_IF_USER_EXISTS_SUCCESS",

  GET_GENERIC_VIDEOS = "songs/get-generic-videos",
  GET_GENERIC_VIDEOS_SUCCESS = "songs/get-generic-videos/success",
  GET_GENERIC_VIDEOS_FAIL = "songs/get-generic-videos/fail",

  CREATE_ARTIST_REQUEST = "artist/create-request",
  CREATE_ARTIST_REQUEST_SUCCESS = "artist/create-request/success",

  COMPLETE_SINGLEPART_UPLOAD = "aws/complete-singlepart-upload",

  GET_SONG_COMMUNITY = "song/get-song-community",
  GET_SONG_COMMUNITY_SUCCESS = "song/get-song-community/success",
  ARTIST_UPDATED_SUCCESS = "artist/updated-success",
  ARTIST_UPDATED_ERROR = "artist/updated-error",

  SET_UPDATE_ME_DATA = "SET_UPDATE_ME_DATA",
  NEW_USER_POINTS = "user/new-points",
  NEW_USER_LEVEL = "user/new-level",

  GET_LOWEST_LEVEL = "level/get-lowest",
  GET_LOWEST_LEVEL_SUCCESS = "level/get-lowest/success",

  SONG_UPLOAD_SUCCESS = "song/upload/success",
  SONG_UPLOAD_ERROR = "song/upload/error",
  SONG_UPLOAD_FINISHED = "song/upload-finished",

  INVITE_USER = "user/invite",
  INVITE_USER_SUCCESS = "user/invite/success",
  INVITE_USER_ERROR = "user/invite/error",

  GET_INVITATION_BY_TOKEN = "invitation/get-by-token",
  GET_INVITATION_BY_TOKEN_SUCCESS = "invitation/get-by-token/success",

  GET_APP_SETTINGS = "app/get-settings",
  GET_APP_SETTINGS_SUCCESS = "app/get-settings/success",

  GET_TOP_USERS_FROM_COMMUNITY = "user/get-top-users-from-community",
  GET_TOP_USERS_FROM_COMMUNITY_SUCCESS = "user/get-top-users-from-community/success",

  GET_TOP_SUPPORTERS = "user/get-top-supporters",
  GET_TOP_SUPPORTERS_SUCCESS = "user/get-top-supporters/success",

  GET_TOP_SUPPORTERS_COUNT = "user/get-top-supporters-count",
  GET_TOP_SUPPORTERS_COUNT_SUCCESS = "user/get-top-supporters-count/success",
}
