/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Button, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from '../../store';
import { getCommunityById } from '../../store/actions/communitiesActions';
import { useDispatch } from 'react-redux';
import { Z_INDEXES } from '../../enums/zIndexes';
import { IPlayerData } from '../../interfaces/echo-player/echo-player-info.interface';
import CommunityInfoDialog from './CommunityInfoDialog';
import InfoIcon from '@mui/icons-material/Info';

interface RouteParams {
  communityId: string;
}
interface PropsType {
  playerData: IPlayerData;
}

const CommunityBar: React.FC<PropsType> = ({ playerData }) => {
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up('md'));
  const dispatch = useDispatch();
  const normalizedCommunities = useSelector((state) => state.main.communities);
  const history = useHistory();
  const { communityId } = useParams<RouteParams>();
  const [community, setCommunity] = useState(normalizedCommunities[parseInt(communityId)]);
  const [communityInfoDialogOpen, setCommunityInfoDialogOpen] = useState(false);

  useEffect(() => {
    if (community) {
      console.log('community', community);
      document.title = `24/7 Streaming Playlist - ${community.name} | Echodrop`;
    } else {
      document.title = `24/7 Streaming Playlist | Echodrop`;
    }
  }, [community]);

  useEffect(() => {
    if (!normalizedCommunities[parseInt(communityId)]) {
      dispatch(getCommunityById({ id: parseInt(communityId) }, (communityResponse) => setCommunity(communityResponse)));
    }
  }, [normalizedCommunities]);
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ p: 1, bgcolor: 'white', zIndex: Z_INDEXES.RADIO_PLAYER__COMMUNITY_BAR, height: 52 }}
    >
      <Button
        onClick={() => history.goBack()}
        color="inherit"
        startIcon={<ArrowBackIosNewIcon />}
        sx={{ pl: upMd ? undefined : '10px' }}
        size={upMd ? 'medium' : 'small'}
      >
        {upMd ? 'Back' : ''}
      </Button>
      <Stack alignItems="center" justifyContent="center">
        <Typography variant="h3" sx={{ fontWeight: 800, fontSize: 14 }}>
          {community?.name}
        </Typography>
        {/*<Typography variant="caption">*/}
        {/*  /!*@ts-ignore*!/*/}
        {/*  {playerData.radio.listenersCount !== undefined ? (*/}
        {/*    // @ts-ignore*/}
        {/*    `${playerData.radio.listenersCount} people listening`*/}
        {/*  ) : (*/}
        {/*    <Skeleton width={100} />*/}
        {/*  )}*/}
        {/*</Typography>*/}
      </Stack>

      <Button
        color="inherit"
        onClick={() => setCommunityInfoDialogOpen(true)}
        startIcon={<InfoIcon />}
        sx={{ pl: upMd ? undefined : '10px' }}
        size={upMd ? 'medium' : 'small'}
      >
        {upMd ? 'Info' : ''}
      </Button>

      {community && (
        <CommunityInfoDialog
          community={community}
          open={communityInfoDialogOpen}
          setOpen={setCommunityInfoDialogOpen}
        />
      )}
    </Stack>
  );
};

export default CommunityBar;
