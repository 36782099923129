/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import routePaths from '../../lib/routePaths';
import { generatePath, useRouteMatch } from 'react-router-dom';
import { Button, Icon, Stack, useMediaQuery, useTheme } from '@mui/material';
import CellTowerIcon from '@mui/icons-material/CellTower';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import useAnalytics from '../../lib/hooks/useAnalytics';
// import { track } from '../../utils/general';

interface PropTypes {
  communityId: number;
  selectedPage: number;
  onPageChange: (pageId: number) => void;
  height: number;
}

const TopBar: React.FC<PropTypes> = ({ communityId, onPageChange, selectedPage, height }) => {
  const { track } = useAnalytics();
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up('md'));
  const curationTokens = '3/5';
  const rankLevel = 1;

  const rankColors = ['warning.300', 'warning.900', 'error.300', 'error.900'];
  // const muiNewIcon = (
  //   <Icon>
  //     <img src="/assets/newIcon.svg" />
  //   </Icon>
  // );

  const buttons = [
    {
      title: 'Home',
      route: routePaths.private.chat,
      path: generatePath(routePaths.private.chat, { communityId }),
      match: useRouteMatch(routePaths.private.chat),
      icon: CellTowerIcon
    },
    {
      title: 'Chart',
      route: routePaths.private.curation.top,
      path: generatePath(routePaths.private.curation.top, { communityId }),
      match: useRouteMatch(routePaths.private.curation.top),
      icon: TrendingUpIcon
    },
    {
      title: 'Recently Added',
      route: routePaths.private.curation.new,
      path: generatePath(routePaths.private.curation.new, { communityId }),
      match: useRouteMatch(routePaths.private.curation.new),
      icon: AutoAwesomeIcon,
      width: upMd ? 145 : undefined
    }
  ];
  const handlePageChange = (pageIndex: number) => {
    track(`radio_tab_${buttons[pageIndex].title.toLowerCase()}`);
    onPageChange(pageIndex);
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="flex-start"
      spacing={upMd ? 2 : 1}
      sx={{ height: height, px: upMd ? 2 : 1 }}
    >
      {buttons.map((button, i) => (
        <Button
          // @ts-ignore
          startIcon={<button.icon />}
          color="secondary"
          key={button.route}
          variant={i === selectedPage ? 'contained' : 'outlined'}
          onClick={() => handlePageChange(i)}
          size="small"
          sx={{ width: button.width, minWidth: button.width, pl: upMd ? undefined : '20px' }}
        >
          {upMd ? button.title : ''}
        </Button>
      ))}
    </Stack>
  );
};

export default TopBar;
