import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material';

interface ReturnType {
  width?: number;
  height?: number;
  containerHeight: number;
  containerWidth: number;
  containerXPadding: number;
}

export default function useWindowSize(): ReturnType {
  const theme = useTheme();
  // const downLg = useMediaQuery(theme.breakpoints.down('lg'));
  // const downSm = useMediaQuery(theme.breakpoints.down('sm'));

  const [windowSize, setWindowSize] = useState<ReturnType>({
    width: undefined,
    height: undefined,
    containerHeight: 843,
    containerWidth: 843,
    containerXPadding: 12
  });

  // useEffect(() => {
  //   setWindowSize((oldState) => ({
  //     ...oldState,
  //     containerXPadding: downSm ? 0 : oldState.width ? oldState.width * 0.1 : 12
  //   }));
  // }, [downSm]);

  useEffect(() => {
    function handleResize() {
      const maxWidth = 1440;
      const width = window.innerWidth;
      const downSm = width < theme.breakpoints.values.sm;
      let padding = downSm ? 5 : width * 0.05;
      const containerWidth = width - padding * 2;
      if (containerWidth > maxWidth) {
        padding = (width - maxWidth) / 2;
      }

      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        containerHeight: window.innerHeight - 48 - 8,
        containerWidth: window.innerWidth - padding * 2,
        containerXPadding: padding
      });
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
}
