import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useEffect } from 'react';
import { Typography } from '@mui/material';

interface PropTypes {
  showDialog?: boolean;
  setShowDialog?: (show: boolean) => void;
  title?: string;
  text?: string;
  onAccept?: () => void;
  onCancel?: () => void;
  acceptText?: string;
  cancelText?: string;
  cancelIsActiveButton?: boolean;
}

const ConfirmationDialogComponent: React.FC<PropTypes> = ({
  showDialog,
  setShowDialog,
  title = 'Confirmation',
  text = 'Are you sure?',
  onAccept,
  onCancel,
  acceptText = 'Ok',
  cancelText = 'Cancel',
  cancelIsActiveButton = false
}) => {
  const [open, setOpen] = React.useState(showDialog !== undefined ? showDialog : true);

  useEffect(() => {
    if (showDialog !== undefined) {
      setOpen(showDialog);
    }
  }, [showDialog]);

  const handleAccept = () => {
    onAccept && onAccept();
    setOpen(false);
    setShowDialog && setShowDialog(false);
  };
  const handleCancel = () => {
    onCancel && onCancel();
    setOpen(false);
    setShowDialog && setShowDialog(false);
  };

  return (
    <Dialog sx={{ p: 4 }} fullWidth open={open} onClose={handleCancel}>
      <Typography sx={{ ml: 3, mt: 2 }} variant="h3">
        {title}
      </Typography>

      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button variant={cancelIsActiveButton ? 'contained' : 'text'} onClick={handleCancel}>
          {cancelText}
        </Button>
        <Button variant={cancelIsActiveButton ? 'text' : 'contained'} onClick={handleAccept}>
          {acceptText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialogComponent;
