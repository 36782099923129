import {
  Box,
  Button,
  Grid,
  LinearProgress,
  linearProgressClasses,
  Modal,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { APP_SECTION } from '../../shared/constants/upload.const';
import { IArtist } from '../../shared/interfaces/models/artist.interface';
import { queue } from '../../store/actions/queueActions';
import { uploadResourceFile } from '../../store/actions/resourceActions';
import useIsOwner from '../../lib/hooks/useIsOwner';
import ShowIfOwner from '../../components/ShowIfOwner/ShowIfOwner';
import VideocamIcon from '@mui/icons-material/Videocam';
import { VIDEO_EXTENSIONS } from '../../shared/constants/file.const';
import { LIMITS } from '../../enums/uploads';
import { mbToBytes } from '../../utils/general';
import { getFileExtension } from '../../shared/utils/file';

interface PropsType {
  artist: IArtist;
}

const ArtistVideoSection: React.FC<PropsType> = ({ artist }) => {
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up('md'));
  const isOwner = useIsOwner();
  const dispatch = useDispatch();
  const videoRef = useRef<any>();
  const [videoUrl, setVideoUrl] = useState<string>('');
  const inputFileVideo = useRef<any>(null);
  const [uploadPercent, setUploadPercent] = useState(0);
  const [openLoadingModal, setOpenLoadingModal] = useState(false);
  const [loadingCompleted, setLoadingCompleted] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showVideoUploadLimits, setShowVideoUploadLimits] = useState(false);

  useEffect(() => {
    if (loadingCompleted) {
      getVideoUrl();
      setTimeout(() => {
        getVideoUrl();
      }, 1000);
    } else {
      getVideoUrl();
    }
  }, [artist?.resources, loadingCompleted]);

  const getVideoUrl = () => {
    if (artist?.resources) {
      const videoResource = artist?.resources?.find((resource) => resource.appSection === APP_SECTION.VIDEO_PROFILE);
      if (videoResource) {
        setVideoUrl(videoResource.url as string);
      }
    }
  };

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 16,
    borderRadius: 18,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 18,
      backgroundColor: theme.palette.primary.main
    }
  }));

  const uploadVideoFileAsResource = (videoFile: any) => {
    if (videoFile) {
      setOpenLoadingModal(true);
      dispatch(
        queue(
          uploadResourceFile(
            {
              file: videoFile,
              appSection: APP_SECTION.VIDEO_PROFILE,
              name: videoFile.name,
              associatedId: artist?.id
            },
            () => {
              setLoadingCompleted(true);
            },
            () => {
              console.log('file failed uploading');
            },
            (percent) => {
              setUploadPercent(percent);
            }
          )
        )
      );
    }
  };

  const handleFileVideo = async (fileSelect: any) => {
    const file = fileSelect.target.files[0];

    if (file.size > mbToBytes(LIMITS.VIDEOS.MAX_SIZE_MB)) {
      alert(`Video size is too large. Please select a video under ${LIMITS.VIDEOS.MAX_SIZE_MB} MB.`);
      return;
    }

    const extension = getFileExtension(file.name);
    if (!VIDEO_EXTENSIONS.includes(extension)) {
      alert(
        `File type "${extension}" is not supported. Format must be one of the following: ${VIDEO_EXTENSIONS.join(', ')}`
      );
      return;
    }

    const videoEl = document.createElement('video');
    videoEl.src = URL.createObjectURL(file);
    videoEl.onloadedmetadata = () => {
      window.URL.revokeObjectURL(videoEl.src);
      const { videoWidth, videoHeight } = videoEl;

      if (videoWidth > 1080 || videoHeight > 1920) {
        alert(
          `Max resolution allowed is: ${LIMITS.VIDEOS.MAX_RESOLUTION.WIDTH}x${LIMITS.VIDEOS.MAX_RESOLUTION.HEIGHT} (vertical video)`
        );
        return;
      }

      uploadVideoFileAsResource(file);
    };
  };

  const videoStyle = useMemo(() => {
    const style = {
      borderRadius: '4px',
      height: upMd ? '640px' : '320px',
      width: upMd ? '360px' : '180px'
    };
    return style;
  }, [upMd]);

  // const videoStyle = {
  //   borderRadius: '4px',
  //   height: '640px',
  //   width: '360px'
  // };

  {
    return (
      <>
        <div onMouseOver={() => setShowVideoUploadLimits(true)} onMouseOut={() => setShowVideoUploadLimits(false)}>
          {videoUrl ? (
            <div>
              <Stack style={videoStyle} component={Button} onClick={() => isOwner && inputFileVideo.current.click()}>
                <video
                  ref={videoRef}
                  className="videoTag"
                  autoPlay
                  loop
                  muted
                  style={{ objectFit: 'cover', ...videoStyle }}
                >
                  <source src={videoUrl} type="video/mp4" />
                </video>
              </Stack>
              <ShowIfOwner>
                <input
                  id="videoInput"
                  type="file"
                  accept={VIDEO_EXTENSIONS.map((e) => `.${e}`).join(',')}
                  ref={inputFileVideo}
                  className="hidden"
                  onChange={(e) => handleFileVideo(e)}
                />
              </ShowIfOwner>
            </div>
          ) : (
            <ShowIfOwner>
              <div>
                <Stack
                  component={Button}
                  onClick={() => isOwner && inputFileVideo.current.click()}
                  sx={{
                    backgroundColor: '#D9D9D9',
                    '&:hover': {
                      backgroundColor: '#E5E5EA'
                    },
                    borderRadius: '4px',
                    minHeight: videoStyle.height, //'640px',
                    minWidth: videoStyle.width //'360px'
                  }}
                  alignItems="center"
                  justifyContent="center"
                >
                  {loadingCompleted ? <Typography>Processing..</Typography> : <VideocamIcon />}
                </Stack>
                <input
                  id="videoInput"
                  type="file"
                  accept={VIDEO_EXTENSIONS.map((e) => `.${e}`).join(',')}
                  ref={inputFileVideo}
                  className="hidden"
                  onChange={(e) => handleFileVideo(e)}
                />
              </div>
            </ShowIfOwner>
          )}
          <div style={{ marginBottom: '-32px' }}>
            {showVideoUploadLimits ? (
              <ShowIfOwner>
                <Stack alignItems="center" justifyContent="center">
                  <Typography variant="caption">
                    {LIMITS.VIDEOS.MAX_RESOLUTION.WIDTH}x{LIMITS.VIDEOS.MAX_RESOLUTION.HEIGHT} max resolution
                  </Typography>
                  <Typography variant="caption">
                    {LIMITS.VIDEOS.MAX_SIZE_MB} MB max - Formats: {VIDEO_EXTENSIONS.join(', ').toUpperCase()}
                  </Typography>
                </Stack>
              </ShowIfOwner>
            ) : (
              <ShowIfOwner>
                <div style={{ height: 32 }} />
              </ShowIfOwner>
            )}
          </div>
        </div>
        <Modal
          open={openLoadingModal}
          onClose={() => setOpenLoadingModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{
            display: 'flex',
            justifyContent: 'center',
            position: 'fixed',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.3)'
          }}
        >
          <Grid
            container
            style={{
              position: 'absolute',
              display: 'flex',
              justifyContent: 'center',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'white',
              width: '80%',
              maxWidth: '400px',
              padding: '20px',
              boxSizing: 'border-box',
              borderRadius: '4px'
            }}
          >
            <Grid display="flex">
              <Typography variant="h6">Uploading artist video profile</Typography>
            </Grid>
            <Stack direction="row" justifyContent="space-between"></Stack>
            <Box sx={{ width: '100%', mr: 1 }} marginTop="5px" marginBottom="5px">
              <BorderLinearProgress variant="determinate" value={uploadPercent} />
            </Box>
            {loadingCompleted && (
              <Button
                onClick={() => {
                  setOpenLoadingModal(false);
                  setLoadingCompleted(false);
                  setUploadPercent(0);
                }}
              >
                Done
              </Button>
            )}
          </Grid>
        </Modal>
      </>
    );
  }
};

export default ArtistVideoSection;
