import React from 'react';
import { TableHead, TableRow, TableCell } from '@mui/material';

interface PropsType {
  colMapping: any;
  colsToShow: string[];
  coloredHeaderBg?: boolean;
}

const CurationTableHeader: React.FC<PropsType> = ({ colMapping, colsToShow, coloredHeaderBg = false }) => {
  const styles = {
    headerCell: {
      fontSize: '14px',
      fontWeight: 600,
      color: 'primary.secondary',
      bgcolor: coloredHeaderBg ? '#E5E5EA' : undefined,
      fontFamily: 'Open Sans'
    }
  };

  return (
    <TableHead>
      <TableRow sx={styles.headerCell}>
        {colsToShow.map((col: string) => (
          <TableCell key={col} sx={styles.headerCell} align={col === 'upvote' ? 'center' : 'left'}>
            {/*@ts-ignore*/}
            {colMapping[col].headerText}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default CurationTableHeader;
