/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Stack, TextField, Typography, IconButton } from '@mui/material';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { useDispatch } from 'react-redux';
import { updateArtistBio } from '../../store/actions/artistActions';
import { IArtist } from '../../shared/interfaces/models/artist.interface';
import ShowIfOwner from '../../components/ShowIfOwner/ShowIfOwner';
import useAnalytics from '../../lib/hooks/useAnalytics';

interface PropsType {
  artist: IArtist;
}

const AboutSection: React.FC<PropsType> = ({ artist }) => {
  const { track } = useAnalytics();
  const dispatch = useDispatch();
  const [textFieldVisible, setTextFieldVisible] = useState(false);
  const [aboutText, setTextAbout] = useState(artist.bio);

  useEffect(() => {
    // console.log('artist.bio:', artist.bio);
    if (artist.bio) {
      setTextAbout(aboutText);
    }
  }, [artist.bio]);

  useEffect(() => {
    // console.log('!!!!!!!!!! aboutText', aboutText);
  }, [aboutText]);

  const handleUpdateAbout = () => {
    artist.bio = aboutText;
    if (artist.id) {
      dispatch(
        updateArtistBio(
          {
            id: artist.id,
            artist
          },
          (a) => {
            artist = a[0];
            track('about_edited');
          }
        )
      );
      setTextFieldVisible(false);
    }
  };

  const handleCancel = () => {
    setTextAbout(artist.bio);
    setTextFieldVisible((state) => !state);
  };

  return (
    <div>
      <Stack direction="row" spacing={3} alignItems="center" sx={{ mb: 2 }}>
        <Typography variant="h2">About</Typography>

        {textFieldVisible ? (
          <Stack direction="row" spacing={1} alignItems="flex-start">
            <Button onClick={handleUpdateAbout} variant="contained" size="small">
              Save
            </Button>
            <Button onClick={handleCancel} size="small">
              Cancel
            </Button>
          </Stack>
        ) : (
          <ShowIfOwner>
            <IconButton color="primary" onClick={() => setTextFieldVisible((state) => !state)}>
              <DriveFileRenameOutlineIcon />
            </IconButton>
          </ShowIfOwner>
        )}
      </Stack>
      {textFieldVisible ? (
        <>
          <TextField
            fullWidth
            multiline={true}
            value={aboutText}
            onChange={(event) => setTextAbout(event.target.value)}
          />
        </>
      ) : (
        <Typography>{aboutText}</Typography>
      )}
    </div>
  );
};

export default AboutSection;
