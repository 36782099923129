/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Stack, useMediaQuery, useTheme } from '@mui/material';
// import { EmojiData, Picker } from 'emoji-mart';
import emojiData from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ISongReaction } from '../../shared/interfaces/models/song-reaction.interface';
import { saveSongReaction } from '../../store/actions/songActions';
import FloatingTemplate from '../../templates/Desktop/FloatingTemplate';
import AddReactionIcon from '@mui/icons-material/AddReaction';
import { Z_INDEXES } from '../../enums/zIndexes';
import { useEchoPlayer } from '../EchoPlayer/EchoPlayerProvider';
import OutsideAlerter from '../ClickOutsideHider/ClickOutsideHider';
// @ts-ignore
import { EmojiData } from 'emoji-mart';
import useAnalytics from '../../lib/hooks/useAnalytics';
// import emojiData from "@emoji-mart/data";

interface PropType {
  bgcolor?: string;
  color?: string;
  communityId?: number;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ReactionButtonComponent: React.FC<PropType> = ({ bgcolor = 'primary.main', color = 'white', communityId }) => {
  const { track } = useAnalytics();
  const theme = useTheme();
  const downLg = useMediaQuery(theme.breakpoints.down('lg'));
  const player = useEchoPlayer();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const buttonRef = useRef<any>(null);
  const buttonWidth = 200;

  const handleOnSelect = (emojiData: EmojiData) => {
    if (player.data.current.playing && emojiData && emojiData.shortcodes) {
      console.log('emojiData: ', emojiData);
      const songReaction = {
        emojiColons: emojiData.shortcodes,
        songId: player.data.current.song?.id,
        communityId,
        songTime: player.controls.current.getCurrentTime()
      } as ISongReaction;
      dispatch(saveSongReaction(songReaction));
      track('song_reaction', songReaction);
    }
    setShow(false);
    // // @ts-ignore
    // setReactionList([{ showed: false, emojiText: emojiData.native }]);
  };

  return (
    <div style={{ zIndex: Z_INDEXES.RADIO_PLAYER__REACTION_BUTTON_COMPONENT }}>
      <Button
        startIcon={<AddReactionIcon />}
        sx={{
          zIndex: Z_INDEXES.RADIO_PLAYER__REACTION_BUTTON_COMPONENT,
          color: show ? 'black' : 'white',
          width: buttonWidth
        }}
        color="cwhite"
        variant={show ? 'contained' : 'outlined'}
        ref={buttonRef}
        onClick={() => setShow((oldState) => !oldState)}
      >
        Add a reaction
      </Button>

      {show && (
        <FloatingTemplate
          style={{ zIndex: Z_INDEXES.RADIO_PLAYER__REACTION_BUTTON_COMPONENT_CONTAINER }}
          anchorRef={buttonRef}
          xOffset={(downLg ? 104 : 0) - 250 - 12}
          yOffset={-430 - 16}
          useAnchorAsContainer
        >
          <Stack direction="row" justifyContent="center" sx={{ width: '516px', cursor: 'default' }}>
            <OutsideAlerter
              onClickOutside={() => {
                setTimeout(() => setShow(false), 100);
              }}
            >
              <Picker data={emojiData} showPreview={false} showSkinTones={false} onEmojiSelect={handleOnSelect} />
              {/*<Picker onSelect={handleOnSelect} showPreview={false} showSkinTones={false} />*/}
            </OutsideAlerter>
          </Stack>
        </FloatingTemplate>
      )}
    </div>
  );
};

export default ReactionButtonComponent;
