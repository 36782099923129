import { fork, takeEvery, takeLatest } from 'redux-saga/effects';
import { ACTIONS } from '../../enums/actions';
import { SHARED_ACTIONS } from '../../shared/constants/actions.const';
import authSaga from './authSaga';
import awsSaga from './awsSaga';
import generalSaga from './generalSaga';
import queueSaga from './queueSaga';
import resourceSaga from './resourceSaga';
import { disconnectSocket, socketInitFlow } from './socketSaga';
import songSaga from './songSaga';
import userSaga from './userSaga';

export default function* rootSaga() {
  yield takeLatest(ACTIONS.INIT_SOCKET, socketInitFlow);
  yield takeLatest(ACTIONS.DISCONNECT_SOCKET, disconnectSocket);
  yield takeLatest(ACTIONS.LOGIN, authSaga.login);
  yield takeLatest(SHARED_ACTIONS.REGISTER, authSaga.register);
  yield takeLatest(SHARED_ACTIONS.CHECK_IF_USER_EXISTS, authSaga.checkIfUserExists);
  yield takeLatest(ACTIONS.LOGOUT, authSaga.logout);
  yield takeLatest(ACTIONS.REGISTER_WITH_INVITATION, authSaga.registerWithInvitation);
  yield takeLatest(ACTIONS.CHECK_AUTH, authSaga.checkAuth);
  yield takeLatest(ACTIONS.REQUEST_PASSWORD_RESET, authSaga.requestPasswordReset);
  yield takeLatest(SHARED_ACTIONS.GET_INVITATION_BY_TOKEN, authSaga.getInvitationByToken);
  yield takeLatest(ACTIONS.RESET_PASSWORD, authSaga.resetPassword);
  yield takeLatest(SHARED_ACTIONS.GET_APP_SETTINGS, authSaga.getAppSettings);
  yield takeLatest(SHARED_ACTIONS.GET_TOP_SUPPORTERS, userSaga.getTopSupportersForArtist);
  yield takeLatest(SHARED_ACTIONS.GET_TOP_SUPPORTERS_COUNT, userSaga.getTopSupportersCountForArtist);
  yield takeEvery(ACTIONS.UPLOAD_RESOURCE_FILE, resourceSaga.uploadResourceFile);
  yield takeEvery(ACTIONS.COMPLETE_MULTIPART_UPLOAD, awsSaga.completeMultiPartUpload);
  yield takeEvery(ACTIONS.INIT_PRESIGNED_URL_MULTIPART_UPLOAD, awsSaga.initPresignedUrlMultipartUpload);
  yield takeEvery(ACTIONS.INIT_PRESIGNED_URL_SIMPLE_UPLOAD, awsSaga.initPresignedUrlSimpleUpload);
  yield takeEvery(ACTIONS.SET_UPLOAD_SONGS, songSaga.setUploadSong);
  yield takeEvery(ACTIONS.GET_INITIAL_DATA, generalSaga.getInitialData);
  yield takeEvery(ACTIONS.QUEUE, queueSaga.queue);

  // Queue Fork
  yield fork(queueSaga.worker);
}
