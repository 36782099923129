import { Stack, useMediaQuery, useTheme } from '@mui/material';
import React, { useRef } from 'react';
import { WAVE_SIZE_PRESETS } from '../../../../enums/player';
import useWindowSize from '../../../../lib/hooks/useWindowSize';
import AudioVisualizer from '../../../AudioVisualizer/AudioVisualizer';
import CoverSection from './CoverSection/CoverSection';
import { useEchoPlayer } from '../../../EchoPlayer/EchoPlayerProvider';
import { ISong } from '../../../../shared/interfaces/models/song.interface';
import FloatingTemplate from '../../../../templates/Desktop/FloatingTemplate';
import ReactionSection from '../../../RadioPlayerComponent/RadioBodySection/ReactionsSection/ReactionSection';
import CommentsSection from '../../../RadioPlayerComponent/RadioBodySection/CommentsSection/CommentsSection';
import { ElapsedTimeProvider } from '../../../EchoPlayer/ElapsedTimeProvider';
import WriteCommentButtonComponent from '../../../SharedPlayerSections/WriteCommentButtonComponent';
import ReactionButtonComponent from '../../../SharedPlayerSections/ReactionButtonComponent';
// import { secondsToTimeFormat } from '../../../../utils/date-utils';
// import TimeSection from '../../../SharedPlayerSections/TimeSection';

interface IProps {
  song?: ISong;
}
//
// const TimeSection: React.FC = () => {
//   const elapsed = useElapsedTime();
//   if (!elapsed) {
//     return null;
//   }
//   return (
//     <Typography sx={{ ml: 1, textShadow: '2px 2px 2px black, 0 0 10px black' }} variant="body1" color="white">
//       {secondsToTimeFormat(elapsed)}
//     </Typography>
//   );
// };

const SongDetailBodySection: React.FC<IProps> = ({ song }) => {
  const theme = useTheme();
  const downLg = useMediaQuery(theme.breakpoints.down('lg'));
  const upMd = useMediaQuery(theme.breakpoints.up('md'));
  const player = useEchoPlayer();
  const size = useWindowSize();
  const audioVisualizerRef = useRef<any>();
  const containerRef = useRef<any>();

  const waveSizePreset = {
    ...WAVE_SIZE_PRESETS.MEDIUM,
    width: size.containerWidth / 2,
    height: size.containerHeight >= 650 ? WAVE_SIZE_PRESETS.MEDIUM.height : WAVE_SIZE_PRESETS.SMALL.height
  };

  return (
    <>
      <div ref={containerRef} style={{ color: '#fafafa', height: '100%' }}>
        <CoverSection song={song} />
        <div ref={audioVisualizerRef} style={{ position: 'absolute', bottom: 0, right: 0 }}>
          {player.data.current.playing && (
            <Stack direction="row" justifyContent="center" sx={{ mt: 5 }}>
              <AudioVisualizer showRadioStatusLabel centerContent sizePreset={waveSizePreset} />
            </Stack>
          )}

          {player.data.song.playing && upMd && (
            <Stack direction={downLg ? 'column' : 'row'} justifyContent="center" alignItems="center" spacing={1}>
              <WriteCommentButtonComponent />
              <ReactionButtonComponent />
            </Stack>
          )}
        </div>
      </div>
      {upMd && (
        <ElapsedTimeProvider>
          <FloatingTemplate useAnchorAsContainer inheritHeight inheritWidth yInherit anchorRef={audioVisualizerRef}>
            <ReactionSection />
          </FloatingTemplate>

          <FloatingTemplate useAnchorAsContainer inheritWidth inheritHeight anchorRef={audioVisualizerRef} yInherit>
            <CommentsSection />
          </FloatingTemplate>
        </ElapsedTimeProvider>
      )}
    </>
  );
};

export default SongDetailBodySection;
