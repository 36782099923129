/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable indent */
import { Avatar, Skeleton, Stack, Typography } from '@mui/material';
import React, { useMemo, useRef } from 'react';
import { RESOURCE_STATUS } from '../../../../shared/constants/resource.const';
import { APP_SECTION } from '../../../../shared/constants/upload.const';
import { IMAGE_SIZES } from '../../../../shared/interfaces/custom/image-preset-settings.interface';
import { Z_INDEXES } from '../../../../enums/zIndexes';
import { useEchoPlayer } from '../../../EchoPlayer/EchoPlayerProvider';
import { RADIO_STATUS } from '../../../../enums/player';
import UpvoteButtonComponent from '../../../UpvoteButtonComponent/UpvoteButtonComponent';
import VolumeComponent from '../../../SharedPlayerSections/VolumeComponent';
import { ElapsedTimeProvider } from '../../../EchoPlayer/ElapsedTimeProvider';
import TimeSection from '../../../SharedPlayerSections/TimeSection';
import { generatePath, useHistory } from 'react-router-dom';
import routePaths from '../../../../lib/routePaths';

interface PropType {
  communityId: number;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CoverSection: React.FC<PropType> = ({ communityId }) => {
  const history = useHistory();
  const { data: playerData } = useEchoPlayer();
  const reactionAnchorRef = useRef<any>();

  const coverImageResource = playerData.current.song?.resources?.find(
    (r) =>
      r.appSection === APP_SECTION.SONG_COVER && r.status === RESOURCE_STATUS.AVAILABLE && r.data.size === IMAGE_SIZES.L
  );

  const artistProfileLink = useMemo(() => {
    if (!playerData.radio.song) return undefined;
    return generatePath(routePaths.private.artistDashboard.main, { artistId: playerData.radio.song.artistId });
  }, [playerData.radio.song]);

  const songLinkWithAutoPlay = useMemo(() => {
    if (!playerData.radio.song) return undefined;
    return generatePath(routePaths.private.songDetail, { songId: playerData.radio.song?.id, autoPlay: 'autoPlay' });
  }, [playerData.radio.song]);

  const handleSongTitleClick = () => {
    songLinkWithAutoPlay && history.push(songLinkWithAutoPlay);
  };

  const handleSongArtistClick = () => {
    artistProfileLink && history.push(artistProfileLink);
  };

  return (
    <Stack direction="column" justifyContent="flex-start" alignItems="center" spacing={3} sx={{ py: 3 }}>
      <Stack
        sx={{ zIndex: Z_INDEXES.RADIO_PLAYER__COVER_SECTION }}
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <VolumeComponent buttonColor="white" iconColor="black" openDirection="bottom" />

        <div>
          {playerData ? (
            <Avatar
              ref={reactionAnchorRef}
              alt={playerData.current.song?.name}
              src={coverImageResource?.url}
              sx={{ width: 85, height: 85 }}
            />
          ) : (
            <Skeleton variant="circular" width={85} height={85} />
          )}
        </div>

        <UpvoteButtonComponent
          useWhiteCircleBg
          showBadge={false}
          loading={playerData.current.loading && playerData.radio.status !== RADIO_STATUS.PLAYING}
          hideVotes
          songId={playerData.radio.song?.id}
          communityId={playerData.radio.communityId}
        />
      </Stack>

      {/* eslint-disable-next-line no-inline-styles/no-inline-styles */}
      <div style={{ textAlign: 'center' }}>
        {playerData.radio.status === RADIO_STATUS.UNAVAILABLE && (
          <>
            <Typography variant="h5" sx={{ fontWeight: 700, fontSize: 20 }}>
              This radio is unavailable for the moment :(
            </Typography>
            <Typography variant="caption">We'll be back soon</Typography>
          </>
        )}
        <Typography
          onClick={handleSongTitleClick}
          variant="h5"
          sx={{ fontWeight: 700, fontSize: 20, cursor: songLinkWithAutoPlay ? 'pointer' : 'default' }}
        >
          {!playerData.radio.loading && playerData.radio.song && playerData.radio.status === RADIO_STATUS.PLAYING ? (
            playerData.radio.song?.name
          ) : (
            <Skeleton width={200} />
          )}
        </Typography>
        <Typography
          onClick={handleSongArtistClick}
          variant="caption"
          sx={{ fontSize: 16, cursor: artistProfileLink ? 'pointer' : 'default' }}
        >
          {!playerData.radio.loading &&
          playerData.radio.song?.user &&
          playerData.radio.status === RADIO_STATUS.PLAYING ? (
            playerData.radio.song?.user?.username
          ) : (
            <Skeleton width={200} />
          )}
        </Typography>{' '}
        <br />
        <ElapsedTimeProvider>
          <TimeSection />
        </ElapsedTimeProvider>
      </div>
    </Stack>
  );
};

export default CoverSection;
