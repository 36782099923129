import { ISong } from '../../shared/interfaces/models/song.interface';
import { IMAGE_SIZES } from '../../shared/interfaces/custom/image-preset-settings.interface';
import { IArtist } from '../../shared/interfaces/models/artist.interface';
import { ISongCommunity } from '../../shared/interfaces/models/song-community.interface';
import { timeSince } from '../../utils/date-utils';

export interface ISongComponent {
  id?: number;
  createdAt?: string;
  addedToCommunityAt?: string;
  index?: number;
  image?: string;
  name?: string;
  artist?: string;
  artistId?: number;
  streams?: number;
  listeningHours?: string;
  rank?: string;
  exclusive?: string;
  totalNfts?: number;
  soldNfts?: number;
  votes?: number;
  isNew?: boolean;
  currentUserBoostCount?: number;
  communityId?: number;
  song?: ISong;
  availableBoosts?: number;
  deleting?: boolean;
}

interface createFromArgs {
  song: ISong;
  artist?: IArtist;
  rank?: string;
  exclusive?: string;
  votes?: number;
  totalNfts?: number;
  soldNfts?: number;
  communityId?: number;
}

export const createFromSongCommunity = (sc: ISongCommunity): ISongComponent => {
  const imageResource = sc.song?.resources?.find((r) => r.data.size === IMAGE_SIZES.S);
  const image = imageResource ? imageResource.url : undefined;
  return {
    id: sc.song?.id,
    createdAt: timeSince(sc.song?.createdAt),
    addedToCommunityAt: timeSince(sc.createdAt),
    image,
    name: sc.song?.name,
    artist: sc.song?.user?.username,
    artistId: sc.song?.user?.artist?.id,
    // TODO: implement stream count in backend
    streams: sc.song?.playCount,
    listeningHours: sc.song?.listeningHours?.toString(),
    rank: undefined,
    exclusive: undefined,
    votes: sc.votes,
    currentUserBoostCount: sc.currentUserBoostCount,
    totalNfts: undefined,
    availableBoosts: sc.availableBoosts,
    soldNfts: undefined,
    communityId: sc.communityId,
    song: sc.song
  };
};

export const createFromISong = ({
  song,
  artist,
  rank,
  exclusive,
  votes,
  totalNfts,
  soldNfts,
  communityId
}: createFromArgs): ISongComponent => {
  const imageResource = song.resources?.find((r) => r.data.size === IMAGE_SIZES.S);
  const image = imageResource ? imageResource.url : undefined;
  return {
    id: song.id,
    createdAt: timeSince(song.createdAt),
    image,
    name: song.name,
    artist: artist?.name,
    artistId: artist?.id,
    streams: song.playCount,
    listeningHours: song.listeningHours?.toString(),
    rank: rank,
    exclusive: exclusive,
    votes: votes,
    currentUserBoostCount: undefined,
    totalNfts,
    soldNfts,
    communityId: communityId,
    song
  };
};
