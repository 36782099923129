import { useTheme, Stack, Typography, IconButton } from '@mui/material';
import * as React from 'react';
import { PauseCircle, PlayCircle } from '@mui/icons-material';

interface PropsType {
  songList: any[];
  onPlaySong?: (songId: number) => void;
}

const SongHorizontalList: React.FC<PropsType> = ({ songList, onPlaySong }) => {
  const theme = useTheme();
  return (
    <Stack direction="row" spacing={4} sx={{ overflowX: 'auto' }}>
      {songList.map((song) => (
        <Stack
          key={song.id}
          direction="column"
          justifyContent="flex-end"
          sx={{
            minWidth: 240,
            width: 240,
            height: 240,
            backgroundImage: `url(${song.imageUrl})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '240px 240px',
            borderRadius: 2
          }}
        >
          <Stack sx={{ p: 2 }} direction="row" justifyContent="space-between" alignItems="center">
            <Stack
              direction="column"
              spacing={1}
              sx={{ color: 'white', p: 1, background: `${theme.palette.grey[900]}33` }}
            >
              <Typography variant="body1" sx={{ fontWeight: 800 }}>
                {song.name}
              </Typography>
              <Typography variant="body1">{song.artist}</Typography>
              <Typography variant="body1">{`${song.soldNfts}/${song.totalNfts}`}</Typography>
            </Stack>
            {onPlaySong && (
              <IconButton sx={{ color: 'white' }} size="small" onClick={() => onPlaySong(song.id)}>
                {song.isPlaying ? <PauseCircle sx={{ fontSize: 32 }} /> : <PlayCircle sx={{ fontSize: 32 }} />}
              </IconButton>
            )}
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};

export default SongHorizontalList;
