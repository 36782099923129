import jwtDecode, { JwtPayload } from 'jwt-decode';
import get from 'lodash/get';
import { eventChannel } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { ACTIONS } from '../../enums/actions';
import { HttpResponse, RequestMethod, RouteMethod } from '../../interfaces/api/http.interface';
import {
  ILoginPayload,
  IPasswordResetPayload,
  IRequestPasswordResetPayload
} from '../../interfaces/requests/auth.interface';
import {
  ILoginResponse,
  IPasswordResetResponse,
  IRegisterWithInvitationResponse
} from '../../interfaces/responses/auth.interface';
import { IDispatchAction } from '../../interfaces/store/root.interface';
import HttpClient, { HttpError } from '../../lib/HttpClient';
import { SHARED_ACTIONS } from '../../shared/constants/actions.const';
import { ICheckIfUserExistsPayload } from '../../shared/interfaces/custom/check-if-user-exists-payload.interface';
import { IRegisterPayload } from '../../shared/interfaces/custom/register-payload.interface';
import { IUser } from '../../shared/interfaces/models/user.interface';
import { GetFriendlyError, GetFriendlyStatusError } from '../../utils/general';
import { setGeneralValue } from '../actions/generalActions';
import { IRegisterWithInvitationPayload } from '../../interfaces/requests/auth.interface';
import { IInvitation } from '../../shared/interfaces/models/invitation.interface';
import { IAppSettings } from '../../shared/interfaces/models/app-settings.interface';
import { generateAppSettingsFieldObject } from '../../shared/utils/general';

const client = new HttpClient('auth');
export const publicRoutes: RouteMethod[] = [
  { method: RequestMethod.PUT, route: 'login' },
  { method: RequestMethod.PUT, route: 'request-password-reset' },
  { method: RequestMethod.POST, route: 'refresh-token' }
];
client.setPublicRoutes(publicRoutes);

async function requestPasswordResetAPI(
  data: IRequestPasswordResetPayload
): Promise<HttpResponse<IPasswordResetResponse>> {
  return client.post('request-password-reset', data);
}

async function resetPasswordAPI(data: IPasswordResetPayload): Promise<HttpResponse<IPasswordResetResponse>> {
  return client.post('reset-password', data);
}
async function getInvitationByTokenAPI(data: {
  tokenKey: string;
}): Promise<HttpResponse<{ invitation: IInvitation; isValid: boolean }>> {
  const invitationClient = new HttpClient('invitation');
  return invitationClient.post('get-by-token', data);
}
async function getAppSettingsAPI(): Promise<HttpResponse<IAppSettings[]>> {
  const invitationClient = new HttpClient('app');
  return invitationClient.get('get-settings');
}

async function loginAPI(data: ILoginPayload): Promise<HttpResponse<ILoginResponse>> {
  return client.put('login', data);
}

async function registerAPI(data: IRegisterPayload): Promise<HttpResponse<IUser>> {
  return client.post('register', data);
}

async function checkIfUserExistsAPI(data: ICheckIfUserExistsPayload): Promise<HttpResponse<boolean>> {
  return client.post('checkIfUserExists', data);
}

async function refreshTokenAPI(data: { refreshToken: string }): Promise<HttpResponse<string>> {
  return client.put('refresh-jwt', data, {}, false);
}

async function registerWithInvitationAPI(data: IRegisterWithInvitationPayload): Promise<HttpResponse<string>> {
  return client.post('register-with-invitation', data, {}, false);
}

export function authSubscribe(socket: any) {
  return eventChannel((emit) => {
    socket.on('exception', () => {
      emit(setGeneralValue('shouldSocketReconnect', true));
    });
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  });
}

const authSaga = {
  *checkAuth(action: IDispatchAction): Generator {
    const jwtToken = localStorage.getItem('jwt');
    const refreshToken = localStorage.getItem('refresh_token');
    let isValid = false;

    if (jwtToken) {
      const payloadJwt = jwtDecode(jwtToken as string) as JwtPayload;
      isValid = get(payloadJwt, 'exp', 0) >= Math.round(new Date().getTime() / 1000) + 10800;
    }

    if (!isValid) {
      if (refreshToken !== null) {
        try {
          const response = (yield call(refreshTokenAPI, { refreshToken })) as HttpResponse<string>;
          localStorage.setItem('jwt', response.data);
          if (action.onSuccess) {
            action.onSuccess();
          }
        } catch (e) {
          if (action.onFail) {
            localStorage.removeItem('jwt');
            localStorage.removeItem('refresh_token');
            action.onFail();
          }
        }
      } else {
        if (action.onFail) {
          action.onFail();
        }
      }
    } else {
      if (action.onSuccess) {
        action.onSuccess();
      }
    }
  },

  *login(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as ILoginPayload;
      const response = (yield call(loginAPI, payload)) as HttpResponse<ILoginResponse>;
      if (response.status === 200 || response.status === 201) {
        if (action.onSuccess) {
          // Saving the JWT token to LocalStorage
          const { jwtToken, refreshToken, user } = response.data;
          localStorage.setItem('jwt', jwtToken);
          localStorage.setItem('refresh_token', refreshToken);
          action.onSuccess();
          yield put({ type: 'LOGIN_SUCCESS', data: user });
        }
      }
    } catch (e) {
      let friendlyError;
      if (e instanceof HttpError) {
        const error = e as HttpError;
        const parsedErrorMessage = JSON.parse(error.message);
        if (action.onFail) {
          const userErrorMessage =
            parsedErrorMessage.code === 401 ? 'Incorrect Credentials' : 'Sorry, something was wrong. Try again.';
          action.onFail(new Error(userErrorMessage));
        }
        if (parsedErrorMessage.code === 400) {
          friendlyError = GetFriendlyError(parsedErrorMessage.message);
        } else {
          friendlyError = GetFriendlyStatusError(parsedErrorMessage.code);
        }
        yield put({ type: ACTIONS.ERROR, data: friendlyError });
      } else {
        const error = e as Error;
        friendlyError = GetFriendlyError(error.message);
      }
      yield put({ type: ACTIONS.ERROR, data: friendlyError });
    }
  },

  *register(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as IRegisterPayload;
      const response = (yield call(registerAPI, payload)) as HttpResponse<IUser>;
      if (response.status === 200 || response.status === 201) {
        if (action.onSuccess) {
          const user = response.data;
          action.onSuccess();
          yield put({ type: SHARED_ACTIONS.REGISTER_SUCCESS, data: user });
        }
      }
    } catch (e) {
      if (action.onFail) {
        action.onFail(e);
      }
      let friendlyError;
      if (e instanceof HttpError) {
        const error = e as HttpError;
        const parsedErrorMessage = JSON.parse(error.message);
        if (parsedErrorMessage.code === 400) {
          friendlyError = GetFriendlyError(parsedErrorMessage.message);
        } else {
          friendlyError = GetFriendlyStatusError(parsedErrorMessage.code);
        }
        yield put({ type: ACTIONS.ERROR, data: friendlyError });
      } else {
        const error = e as Error;
        friendlyError = GetFriendlyError(error.message);
      }
      yield put({ type: ACTIONS.ERROR, data: friendlyError });
    }
  },

  *checkIfUserExists(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as ICheckIfUserExistsPayload;

      const response = (yield call(checkIfUserExistsAPI, payload)) as HttpResponse<boolean>;
      if (response.status === 200 || response.status === 201) {
        if (action.onSuccess) {
          const userExists = response.data;
          action.onSuccess(userExists);
          yield put({ type: SHARED_ACTIONS.CHECK_IF_USER_EXISTS_SUCCESS, data: userExists });
        }
      }
    } catch (e) {
      if (action.onFail) {
        action.onFail(e);
      }
      let friendlyError;
      if (e instanceof HttpError) {
        const error = e as HttpError;
        const parsedErrorMessage = JSON.parse(error.message);
        if (parsedErrorMessage.code === 400) {
          friendlyError = GetFriendlyError(parsedErrorMessage.message);
        } else {
          friendlyError = GetFriendlyStatusError(parsedErrorMessage.code);
        }
        yield put({ type: ACTIONS.ERROR, data: friendlyError });
      } else {
        const error = e as Error;
        friendlyError = GetFriendlyError(error.message);
      }
      yield put({ type: ACTIONS.ERROR, data: friendlyError });
    }
  },

  *logout(action: IDispatchAction): Generator {
    try {
      localStorage.removeItem('jwt');
      localStorage.removeItem('refresh_token');
      yield put({ type: ACTIONS.LOGOUT_SUCCESS });
      action.onSuccess && action.onSuccess();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  },

  *registerWithInvitation(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as IRegisterWithInvitationPayload;
      const response = (yield call(
        registerWithInvitationAPI,
        payload
      )) as HttpResponse<IRegisterWithInvitationResponse>;
      if (response.status === 200 || response.status === 201) {
        if (action.onSuccess) {
          // Saving the JWT token to LocalStorage
          action.onSuccess();
          yield put({ type: 'REGISTER_WITH_INVITATION_SUCCESS ', data: response.data });
        }
      }
    } catch (e) {
      let friendlyError;
      if (e instanceof HttpError) {
        const error = e as HttpError;
        const parsedErrorMessage = JSON.parse(error.message);
        if (parsedErrorMessage.code === 400) {
          friendlyError = GetFriendlyError(parsedErrorMessage.message);
        } else {
          friendlyError = GetFriendlyStatusError(parsedErrorMessage.code);
        }
        yield put({ type: ACTIONS.ERROR, data: friendlyError });
      } else {
        const error = e as Error;
        friendlyError = GetFriendlyError(error.message);
      }
      yield put({ type: ACTIONS.ERROR, data: friendlyError });
    }
  },

  *requestPasswordReset(action: IDispatchAction): Generator {
    const payload = action.payload as IRequestPasswordResetPayload;
    try {
      const response = (yield call(requestPasswordResetAPI, payload)) as HttpResponse<unknown>;
      if (response.status === 200 || response.status === 201) {
        if (action.onSuccess) {
          action.onSuccess();
        }
        yield put({ type: 'REQUEST_PASSWORD_RESET_SUCCESS' });
      } else {
        action.onFail && action.onFail();
        yield put({ type: 'REQUEST_PASSWORD_RESET_ERROR' });
      }
    } catch (e) {
      action.onFail && action.onFail();
      yield put({ type: 'REQUEST_PASSWORD_RESET_ERROR' });
      let friendlyError;
      if (e instanceof HttpError) {
        const error = e as HttpError;
        const parsedErrorMessage = JSON.parse(error.message);
        if (parsedErrorMessage.code === 400) {
          friendlyError = GetFriendlyError(parsedErrorMessage.message);
        } else {
          friendlyError = GetFriendlyStatusError(parsedErrorMessage.code);
        }
        yield put({ type: ACTIONS.ERROR, data: friendlyError });
      } else {
        const error = e as Error;
        friendlyError = GetFriendlyError(error.message);
      }
      yield put({ type: ACTIONS.ERROR, data: friendlyError });
    }
  },

  *getInvitationByToken(action: IDispatchAction): Generator {
    const payload = action.payload as { tokenKey: string };
    try {
      const response = (yield call(getInvitationByTokenAPI, payload)) as HttpResponse<unknown>;
      if (response.status === 200 || response.status === 201) {
        if (action.onSuccess) {
          action.onSuccess(response.data as { invitation: IInvitation; validToken: boolean });
        }
        yield put({ type: SHARED_ACTIONS.GET_INVITATION_BY_TOKEN_SUCCESS, data: response.data });
      } else {
        action.onFail && action.onFail(response);
        yield put({ type: SHARED_ACTIONS.GET_INVITATION_BY_TOKEN_SUCCESS, data: response.data });
      }
    } catch (e) {
      action.onFail && action.onFail(e);
      yield put({ type: SHARED_ACTIONS.GET_INVITATION_BY_TOKEN_SUCCESS, data: { error: e } });
      let friendlyError;
      if (e instanceof HttpError) {
        const error = e as HttpError;
        const parsedErrorMessage = JSON.parse(error.message);
        if (parsedErrorMessage.code === 400) {
          friendlyError = GetFriendlyError(parsedErrorMessage.message);
        } else {
          friendlyError = GetFriendlyStatusError(parsedErrorMessage.code);
        }
        yield put({ type: ACTIONS.ERROR, data: friendlyError });
      } else {
        const error = e as Error;
        friendlyError = GetFriendlyError(error.message);
      }
      yield put({ type: ACTIONS.ERROR, data: friendlyError });
    }
  },

  *getAppSettings(action: IDispatchAction): Generator {
    try {
      const response = (yield call(getAppSettingsAPI)) as HttpResponse<unknown>;
      if (response.status === 200 || response.status === 201) {
        const settingFields = generateAppSettingsFieldObject(response.data as IAppSettings[]);
        if (action.onSuccess) {
          action.onSuccess(settingFields);
        }
        yield put(setGeneralValue('appSettings', settingFields));
        yield put({ type: SHARED_ACTIONS.GET_APP_SETTINGS_SUCCESS, data: settingFields });
      } else {
        action.onFail && action.onFail(response);
        yield put({ type: SHARED_ACTIONS.GET_APP_SETTINGS_SUCCESS, data: response.data });
      }
    } catch (e) {
      action.onFail && action.onFail(e);
      yield put({ type: SHARED_ACTIONS.GET_APP_SETTINGS_SUCCESS, data: { error: e } });
      let friendlyError;
      if (e instanceof HttpError) {
        const error = e as HttpError;
        const parsedErrorMessage = JSON.parse(error.message);
        if (parsedErrorMessage.code === 400) {
          friendlyError = GetFriendlyError(parsedErrorMessage.message);
        } else {
          friendlyError = GetFriendlyStatusError(parsedErrorMessage.code);
        }
        yield put({ type: ACTIONS.ERROR, data: friendlyError });
      } else {
        const error = e as Error;
        friendlyError = GetFriendlyError(error.message);
      }
      yield put({ type: ACTIONS.ERROR, data: friendlyError });
    }
  },

  *resetPassword(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as IPasswordResetPayload;
      const response = (yield call(resetPasswordAPI, payload)) as HttpResponse<unknown>;
      if (response.status === 200 || response.status === 201) {
        if (action.onSuccess) {
          action.onSuccess();
        }
        yield put({ type: 'RESET_PASSWORD_SUCCESS' });
      }
    } catch (e) {
      let friendlyError;
      if (e instanceof HttpError) {
        const error = e as HttpError;
        const parsedErrorMessage = JSON.parse(error.message);
        if (parsedErrorMessage.code === 400) {
          friendlyError = parsedErrorMessage.message;
        } else {
          friendlyError = GetFriendlyStatusError(parsedErrorMessage.code);
        }
        yield put({ type: ACTIONS.ERROR, data: friendlyError });
      } else {
        const error = e as Error;
        friendlyError = GetFriendlyError(error.message);
      }

      if (action.onFail) {
        action.onFail(friendlyError);
      }

      yield put({ type: ACTIONS.ERROR, data: friendlyError });
    }
  }
};

export default authSaga;
