/* eslint-disable @typescript-eslint/no-empty-function  */
/* eslint-disable @typescript-eslint/no-unused-vars*/

import { PLAYER_CONFIG, PLAYER_SOURCE, RADIO_STATUS } from '../../../enums/player';
import {
  IOnMetadataProps,
  IPlayerData,
  IPlayerDataSetters,
  IRadioInfoTriggers
} from '../../../interfaces/echo-player/echo-player-info.interface';
import { joinToCommunityEvents, leaveCommunityEvents } from '../../../store/actions/communitiesActions';
import { useDispatch } from 'react-redux';
import useAudioHelper from '../helpers/useAudioHelper';
import { IIcecastStats } from '../../../interfaces/responses/icecast-stats.interface';

export default function useRadioInfoTriggers(data: IPlayerData, setters: IPlayerDataSetters): IRadioInfoTriggers {
  const dispatch = useDispatch();
  const { getUrlFromSong } = useAudioHelper();
  const listeners = {
    onStatusChange: (status: RADIO_STATUS) => {
      // // console.log('empty status change listener');
    }
  };

  const beforeStop = () => {
    // console.log('RADIO beforeStop()');
    // setters.setRadioData({ loading: true });
  };
  const beforePlay = (communityId: number) => {
    // console.log('RADIO beforePlay() communityId: ', communityId);
    setters.setRadioData({ nextCommunityId: communityId, loading: true, song: undefined, nextSong: undefined });
  };

  const afterStop = () => {
    // console.log('RADIO afterStop()');
    setters.setData((prevData) => {
      if (prevData.radio.communityId) {
        dispatch(leaveCommunityEvents({ id: prevData.radio.communityId }));
      }
      return {
        ...prevData,
        radio: {
          ...prevData.radio,
          playing: false,
          loading: false
        },
        current: {
          ...prevData.current,
          playing: prevData.current.sourceType === PLAYER_SOURCE.RADIO_STREAMING ? false : prevData.current.playing
        }
      };
    });
  };

  const afterPlay = (communityId?: number) => {
    // const { song } = data.radioStreaming;
    setters.setData((prevData) => {
      communityId = communityId ?? prevData.radio.nextCommunityId;
      // console.log('RADIO afterPlay() communityId: ', communityId);
      dispatch(joinToCommunityEvents({ id: communityId }));
      return {
        ...prevData,
        song: {
          ...prevData.song,
          playing: false
        },
        radio: {
          ...prevData.radio,
          communityId,
          sourceUrl: `${PLAYER_CONFIG.RADIO_STREAMING_BASE_URL}-${communityId}.ogg`,
          playing: true,
          loading: false,
          nextCommunityId: undefined
        },
        current: {
          ...prevData.current,
          communityId,
          sourceType: PLAYER_SOURCE.RADIO_STREAMING,
          playing: true,
          song: prevData.radio.song,
          nextSong: prevData.radio.nextSong ? prevData.radio.nextSong : undefined,
          songResourceUrl: prevData.radio.song && getUrlFromSong(prevData.radio.song),
          songDuration: prevData.radio.songDuration
        }
      };
    });
  };

  const onStats = ({ stats, listenersCount }: { stats: IIcecastStats; listenersCount: number }) => {
    setters.setRadioData({ stats, listenersCount });
  };

  const onStatus = (status: RADIO_STATUS) => {
    setters.setRadioData({ status });
    listeners.onStatusChange(status);
  };

  const setOnStatusChange = (onStatusChangeListener: (status: RADIO_STATUS) => void) => {
    listeners.onStatusChange = onStatusChangeListener;
  };

  const onMetadata = ({ metadata, songStartedAt, songDuration, song, nextSong }: IOnMetadataProps) => {
    if (metadata && songStartedAt && songDuration) {
      setters.setRadioData({ metadata, songStartedAt, songDuration });
      setters.setData((prevData) => {
        if (prevData.radio.playing) {
          return {
            ...prevData,
            current: {
              ...prevData.current,
              songStartedAt,
              songDuration
            }
          };
        }
        return prevData;
      });
    }

    if (song && nextSong) {
      setters.setRadioData({ song, nextSong });
      setters.setData((prevData) => {
        if (prevData.radio.playing) {
          return {
            ...prevData,
            current: {
              ...prevData.current,
              nextSong,
              song
            }
          };
        }
        return prevData;
      });
    }
  };

  return { afterStop, afterPlay, onMetadata, onStats, onStatus, beforePlay, beforeStop, setOnStatusChange };
}
