import { eventChannel } from 'redux-saga';
import { take } from 'redux-saga/effects';
import { ACTIONS } from '../../enums/actions';

export function voteSubscribe(socket: any) {
  return eventChannel((emit) => {
    socket.on('vote/cast-vote-success', (data: unknown) => {
      // Reduce returned data.
      emit(data); // This should be replaced with the real action.
    });
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  });
}

const voteSaga = {
  *castVote(emitSocketMessage: any): Generator {
    while (true) {
      yield take(ACTIONS.CAST_VOTE);
      emitSocketMessage('vote/cast-vote');
    }
  }
};

export default voteSaga;
