import React, { useEffect, useRef, useState } from 'react';
import { TableContainer, Table, Box } from '@mui/material';
import BaseTableHeader from './SongBaseTableHeader';
import SongBaseTableBody from './SongBaseTableBody';
import { ISongComponent } from '../../interfaces/ui-components/song.interface';
import Loading from '../Loading/Loading';
import useWindowSize from '../../lib/hooks/useWindowSize';
import { useSelector } from '../../store';

interface PropsType {
  songList: ISongComponent[];
  colsToShow?: string[];
  colMapping?: any;
  onScrollReachBottom?: () => void;
  loadMore?: () => void;
  skeletonRows?: number;
  separateTop?: number;
  hasMore?: boolean;
  coloredHeaderBg?: boolean;
  setSongDeleted?: (val: boolean) => void;
  setSongList: (songList: ISongComponent[]) => void;
}

const SongBaseTable: React.FC<PropsType> = ({
  setSongList,
  songList,
  colsToShow,
  colMapping,
  onScrollReachBottom,
  loadMore,
  skeletonRows,
  separateTop,
  hasMore = false,
  coloredHeaderBg = false
}) => {
  const size = useWindowSize();
  const songDeleted = useSelector((state) => state.general.songDeleted);
  const tableRef = useRef<HTMLDivElement | null>(null);
  const [distanceThreshold, setDistanceThreshold] = useState(0);

  useEffect(() => {
    if (songList && songDeleted.length > 0) {
      const tmpList = songList.filter((song) => song.id !== songDeleted[0]);
      setSongList(tmpList);
    }
  }, [songDeleted]);

  if (!colMapping) {
    colMapping = {
      createdAt: { headerText: 'Created at', songPropertyName: 'createdAt' },
      addedToCommunityAt: { headerText: 'Added', songPropertyName: 'addedToCommunityAt' },
      index: { headerText: '#', songPropertyName: 'index' },
      id: { headerText: 'id', songPropertyName: 'id' },
      image: { headerText: '', songPropertyName: 'image' },
      song: { headerText: 'Song', songPropertyName: 'name' },
      artist: { headerText: 'Artist', songPropertyName: 'artist' },
      streams: { headerText: 'Streams', songPropertyName: 'streams' },
      rank: { headerText: 'Rank', songPropertyName: 'rank' },
      exclusive: { headerText: 'Exclusive', songPropertyName: 'exclusive' },
      upvote: { headerText: 'Boost', songPropertyName: 'votes' },
      listen: { headerText: 'Listen', songPropertyName: null },
      edit: { headerText: 'Edit', songPropertyName: null },
      delete: { headerText: 'Delete', songPropertyName: null }
    };
  }
  if (!colsToShow) {
    colsToShow = ['image', 'song', 'artist', 'streams', 'rank', 'exclusive', 'upvote', 'listen', 'edit', 'delete'];
  }

  const scrollListener = () => {
    if (!tableRef.current || !onScrollReachBottom) {
      return;
    }
    const bottom = tableRef.current.scrollHeight - tableRef.current.clientHeight;
    if (tableRef.current.scrollTop > bottom - distanceThreshold && onScrollReachBottom) {
      onScrollReachBottom();
    }
  };

  useEffect(() => {
    if (tableRef && tableRef.current && onScrollReachBottom) {
      const bottom = tableRef.current.scrollHeight - tableRef.current.clientHeight;
      if (distanceThreshold === 0) {
        setDistanceThreshold(bottom * 0.25);
      }
      tableRef.current.addEventListener('scroll', scrollListener);
      return () => {
        tableRef.current?.removeEventListener('scroll', scrollListener);
      };
    }
  }, [tableRef, tableRef.current, onScrollReachBottom]);

  return (
    <>
      {skeletonRows && skeletonRows > 0 && (
        <Box
          sx={{
            height: 'inherit',
            width: size.containerWidth / 2,
            backgroundColor: 'white',
            opacity: 0.7,
            position: 'absolute'
          }}
        >
          <Loading />
        </Box>
      )}
      <TableContainer sx={{ maxHeight: 'inherit' }} ref={tableRef}>
        <Table>
          <BaseTableHeader colMapping={colMapping} colsToShow={colsToShow} coloredHeaderBg={coloredHeaderBg} />
          <SongBaseTableBody
            songList={songList}
            colMapping={colMapping}
            colsToShow={colsToShow}
            skeletonRows={skeletonRows}
            separateTop={separateTop}
            hasMore={hasMore}
            loadMore={loadMore}
          />
        </Table>
      </TableContainer>
    </>
  );
};

export default SongBaseTable;
