/* eslint-disable */
import { Avatar, Skeleton, Stack, Typography } from '@mui/material';
import React from 'react';
import ControlsSection from './ControlsSection';

import { SxProps } from '@mui/system';
import { PLAYER_SOURCE, RADIO_STATUS, WAVE_SIZE_PRESETS } from '../../enums/player';
import useWindowSize from '../../lib/hooks/useWindowSize';
import { RESOURCE_STATUS } from '../../shared/constants/resource.const';
import { APP_SECTION } from '../../shared/constants/upload.const';
import { IMAGE_SIZES } from '../../shared/interfaces/custom/image-preset-settings.interface';
import AudioVisualizer from '../AudioVisualizer/AudioVisualizer';
import { Z_INDEXES } from '../../enums/zIndexes';
import { useEchoPlayer } from '../EchoPlayer/EchoPlayerProvider';
import { generatePath, useHistory } from 'react-router-dom';
import routePaths from '../../lib/routePaths';

interface PropsType {
  sx?: SxProps;
}

const BottomBarPlayerComponent: React.FC<PropsType> = ({ sx }) => {
  const { controls: playerControls } = useEchoPlayer();
  const player = useEchoPlayer();
  const size = useWindowSize();
  const history = useHistory();

  const handleMuteChange = (mute: boolean) => {
    playerControls.current.setMute(mute);
  };

  const handleVolumeChange = (volume: number) => {
    playerControls.current.setVolume(volume);
  };

  const coverImageResource = player.data.current.song?.resources?.find(
    (r: any) =>
      r.appSection === APP_SECTION.SONG_COVER && r.status === RESOURCE_STATUS.AVAILABLE && r.data.size === IMAGE_SIZES.L
  );

  const showCurrentPlayingInfo =
    !player.data.radio.loading &&
    player.data.current.song &&
    (!player.data.radio.playing || (player.data.radio.playing && player.data.radio.status === RADIO_STATUS.PLAYING));

  const handleClickOnSongTitle = () => {
    if (player.data.current.sourceType === PLAYER_SOURCE.RADIO_STREAMING) {
      history.push(generatePath(routePaths.private.curation.top, { communityId: player.data.radio.communityId }));
    } else if (player.data.current.sourceType === PLAYER_SOURCE.SPECIFIC_SONG && player.data.song.song) {
      history.push(
        generatePath(routePaths.private.songDetail, { songId: player.data.song.song.id, autoPlay: 'noAutoPlay' })
      );
    }
  };

  return (
    <Stack
      sx={{
        zIndex: Z_INDEXES.BOTTOM_BAR_PLAYER,
        position: 'fixed',
        bottom: 0,
        width: size.containerWidth,
        bgcolor: 'secondary.500',
        py: 1,
        height: 50,
        ...sx
      }}
      direction="row"
      alignItems="center"
      justifyContent="flex-start"
    >
      {player.data.current.sourceType && (
        <ControlsSection
          loadingPlay={player.data.current.loading}
          playing={player.data.current.playing}
          togglePlaying={playerControls.current.togglePlay}
          mute={player.data.current.mute}
          setMute={handleMuteChange}
          volume={player.data.current.volume}
          setVolume={handleVolumeChange}
        />
      )}

      <AudioVisualizer style={{ marginTop: -36 }} sizePreset={WAVE_SIZE_PRESETS.SMALL} />

      {player.data.current.song && (
        <Stack
          sx={{ width: '100%', pr: 3, color: 'white' }}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Stack direction="row" alignItems="center" onClick={handleClickOnSongTitle} sx={{ cursor: 'pointer' }}>
            {showCurrentPlayingInfo && <Avatar sx={{ ml: 3, width: 24, height: 24 }} src={coverImageResource?.url} />}
            <Typography sx={{ ml: 1 }} variant="body2">
              {showCurrentPlayingInfo ? (
                <>
                  <span style={{ fontWeight: 700 }}>{player.data.current.song?.name}</span>
                  {' by '}
                  {player.data.current.song?.user?.username}
                </>
              ) : (
                <>{<Skeleton width={50} />}</>
              )}
            </Typography>
          </Stack>
          {showCurrentPlayingInfo && player.data.current.nextSong && (
            <Typography sx={{ ml: 3 }} variant="body2">
              {'Next: '}
              <span style={{ fontWeight: 700 }}>{player.data.current.nextSong?.name}</span>
              {' by '}
              {player.data.current.nextSong?.user?.username}
            </Typography>
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default BottomBarPlayerComponent;
