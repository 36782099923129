import { APP_SECTION_IMAGE } from "../../constants/upload.const";

export enum IMAGE_SIZES {
  S = "S",
  M = "M",
  L = "L",
  XL = "XL",
  ORIGINAL = "original",
}

export interface IImagePresetSettings {
  size: IMAGE_SIZES;
  width: number;
  height: number;
  autoCrop: boolean;
  scaleToFit: boolean;
}

export type IImagePresets = {
  [key in APP_SECTION_IMAGE]: IImagePresetSettings[];
};
