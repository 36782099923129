export enum USER_ROLE {
  LISTENER = "listener",
  ARTIST = "artist",
  CURATOR = "curator",
  ADMIN = "admin",
  SYSADMIN = "sysadmin",
}

export enum USER_STATUS {
  INACTIVE = "inactive",
  ACTIVE = "active",
  BLOCKED = "blocked",
  DELETED = "deleted",
}

export enum ARTIST_REQUEST_STATUS {
  REQUESTED = "requested",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
  BLOCKED = "blocked",
}
