import { ACTIONS } from '../../enums/actions';
import { IDispatchAction } from '../../interfaces/store/root.interface';
import { SHARED_ACTIONS } from '../../shared/constants/actions.const';
import { IPexelsVideo } from '../../shared/interfaces/custom/pexels-video.interface';
import { ISongComment } from '../../shared/interfaces/models/song-comment.interface';
import { ISongCommunity } from '../../shared/interfaces/models/song-community.interface';
import { ISongReaction } from '../../shared/interfaces/models/song-reaction.interface';
import { ISongVote } from '../../shared/interfaces/models/song-vote.interface';
import { ISong } from '../../shared/interfaces/models/song.interface';
import { Paginated } from '../../shared/interfaces/responses/paginated.interface';

export const createSong = (
  payload: any,
  onSuccess?: (result: ISong) => void,
  onFail?: () => void
): IDispatchAction => ({
  type: ACTIONS.CREATE_SONG,
  payload,
  onSuccess,
  onFail
});

export const uploadSong = (payload: ISong[], onSuccess?: () => void, onFail?: () => void): IDispatchAction => ({
  type: ACTIONS.UPLOAD_FILE_SONG,
  payload,
  onSuccess,
  onFail
});

export const setUploadSong = (payload: any, onSuccess?: () => void, onFail?: () => void): IDispatchAction => ({
  type: ACTIONS.SET_UPLOAD_SONGS,
  payload,
  onSuccess,
  onFail
});

export const getSongsByArtist = (
  payload: any,
  onSuccess?: (result: ISong[]) => void,
  onFail?: () => void
): IDispatchAction => ({
  type: SHARED_ACTIONS.GET_SONGS_BY_ARTIST,
  payload,
  onSuccess,
  onFail
});

export const getSongCommunitiesByCommunity = (
  payload: any,
  onSuccess?: (result: Paginated<ISongCommunity>) => void,
  onFail?: () => void
): IDispatchAction => ({
  type: SHARED_ACTIONS.GET_SONGS_BY_COMMUNITY,
  payload,
  onSuccess,
  onFail
});

export const getSongCommunity = (
  payload: any,
  onSuccess?: (result: ISongCommunity) => void,
  onFail?: () => void
): IDispatchAction => ({
  type: SHARED_ACTIONS.GET_SONG_COMMUNITY,
  payload,
  onSuccess,
  onFail
});

export const updateUpvoteStatus = (
  payload: any,
  onSuccess?: (result: ISongVote) => void,
  onFail?: () => void
): IDispatchAction => ({
  type: SHARED_ACTIONS.UPDATE_UPVOTE_STATUS,
  payload,
  onSuccess,
  onFail
});

export const getSongsByIdList = (
  payload: any,
  onSuccess?: (result: ISong[]) => void,
  onFail?: () => void
): IDispatchAction => ({
  type: SHARED_ACTIONS.GET_SONGS_BY_ID_LIST,
  payload,
  onSuccess,
  onFail
});

export const getSongComments = (
  payload: any,
  onSuccess?: (result: ISongComment[]) => void,
  onFail?: () => void
): IDispatchAction => ({
  type: SHARED_ACTIONS.GET_SONG_COMMENTS,
  payload,
  onSuccess,
  onFail
});

export const saveSongComment = (
  payload: any,
  onSuccess?: (result: ISongComment) => void,
  onFail?: () => void
): IDispatchAction => ({
  type: SHARED_ACTIONS.SAVE_SONG_COMMENT,
  payload,
  onSuccess,
  onFail
});

export const getSongReactions = (
  payload: any,
  onSuccess?: (result: Paginated<ISongReaction>) => void,
  onFail?: () => void
): IDispatchAction => ({
  type: SHARED_ACTIONS.GET_SONG_REACTIONS,
  payload,
  onSuccess,
  onFail
});

export const saveSongReaction = (
  payload: any,
  onSuccess?: (result: ISongReaction) => void,
  onFail?: () => void
): IDispatchAction => ({
  type: SHARED_ACTIONS.SAVE_SONG_REACTION,
  payload,
  onSuccess,
  onFail
});

export const getGenericVideos = (
  payload: any,
  onSuccess?: (result: Paginated<IPexelsVideo>) => void,
  onFail?: () => void
): IDispatchAction => ({
  type: SHARED_ACTIONS.GET_GENERIC_VIDEOS,
  payload,
  onSuccess,
  onFail
});

export const getSongDetail = (
  payload: any,
  onSuccess?: (result: ISong[]) => void,
  onFail?: () => void
): IDispatchAction => ({
  type: SHARED_ACTIONS.GET_SONGS_BY_ARTIST,
  payload,
  onSuccess,
  onFail
});
