/* eslint-disable @typescript-eslint/no-empty-function  */
/* eslint-disable @typescript-eslint/no-unused-vars*/

import { PLAYER_SOURCE } from '../../../enums/player';
import {
  ICurrentControls,
  IRadioControls,
  ISongControls
} from '../../../interfaces/echo-player/echo-player-controls.interface';
import { IPlayerInfo } from '../../../interfaces/echo-player/echo-player-info.interface';

interface IProps {
  songControls: ISongControls;
  radioControls: IRadioControls;
  playerInfo: IPlayerInfo;
}

export default function useCurrentControls({ radioControls, songControls, playerInfo }: IProps): ICurrentControls {
  const {
    data: playerData,
    triggers: {
      current: { onVolumeChange, onMuteChange }
    }
  } = playerInfo;
  // const dispatch = useDispatch();
  // const [clockTick, setClockTick] = useState(false);
  //
  // useEffect(() => {
  // const intervalId = setInterval(() => {
  //   setClockTick((state) => !state);
  // }, 1000);
  //
  // return () => {
  //   clearInterval(intervalId);
  // };
  // }, []);
  //
  // useEffect(() => {
  //   if (playerData.radio.playing) {
  //     const currentMillis = new Date().getTime();
  //     let elapsed: number | undefined = currentMillis - (playerData.current.songStartedAt?.getTime() ?? currentMillis);
  //     elapsed = Math.floor(elapsed / 1000);
  //     elapsed = playerData.current.songDuration && elapsed < playerData.current.songDuration ? elapsed : undefined;
  //     setElapsed(elapsed);
  //   } else if (playerData.song.playing) {
  //     setElapsed(songControls.audioElement?.currentTime);
  //   } else if (elapsed !== undefined) {
  //     setElapsed(elapsed);
  //   }
  // }, [clockTick]);

  // TODO: Instead have a elapsed state updating every second, use this function for specific cases and use a timer (startedSong + duration) for radio station end of song
  const getCurrentTime = () => {
    if (playerData.radio.playing) {
      const currentMillis = new Date().getTime();
      let elapsed: number | undefined = currentMillis - (playerData.radio.songStartedAt?.getTime() ?? currentMillis);
      elapsed = Math.floor(elapsed / 1000) - 36;
      elapsed = playerData.radio.songDuration && elapsed < playerData.radio.songDuration ? elapsed : undefined;
      return elapsed;
    } else if (playerData.song.playing) {
      const time = songControls.audioElement?.currentTime;
      return time ? Math.floor(time) : undefined;
    } else {
      return undefined;
    }
  };

  const togglePlay = async () => {
    if (playerData.current.sourceType === PLAYER_SOURCE.RADIO_STREAMING) {
      await radioControls.togglePlay();
    } else if (playerData.current.sourceType === PLAYER_SOURCE.SPECIFIC_SONG) {
      await songControls.togglePlay();
    }
  };

  const setVolume = (volume: number) => {
    radioControls.audioElement && (radioControls.audioElement.volume = volume);
    songControls.audioElement && (songControls.audioElement.volume = volume);
    radioControls.audioElement && (radioControls.audioElement.muted = volume === 0);
    songControls.audioElement && (songControls.audioElement.muted = volume === 0);
    onVolumeChange(volume);
  };

  const setMute = (mute: boolean) => {
    radioControls.audioElement && (radioControls.audioElement.muted = mute);
    songControls.audioElement && (songControls.audioElement.muted = mute);
    onMuteChange(mute);
  };

  return {
    togglePlay,
    setVolume,
    setMute,
    getCurrentTime
  };
}
