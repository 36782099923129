/* eslint-disable @typescript-eslint/no-unused-vars */
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import { Box, Paper, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import * as React from 'react';
import { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Loading from '../../components/Loading/Loading';
import ArtistInfo from '../../components/UserInfoComponent/ArtistInfo';
import { INormalized } from '../../interfaces/store/main.interface';
import { IRootState } from '../../interfaces/store/root.interface';
import { ARTIST_STATUS } from '../../shared/constants/artist.const';
import { USER_ROLE } from '../../shared/constants/user.const';
import { IArtist } from '../../shared/interfaces/models/artist.interface';
import { IUser } from '../../shared/interfaces/models/user.interface';
import { useSelector } from '../../store';
import { getArtist } from '../../store/actions/artistActions';
import { getUserById } from '../../store/actions/userActions';
import AboutSection from './AboutSection';
import './ArtistDashboard.scss';
import ArtistVideoSection from './ArtistVideoSection';
import CommunitiesFeaturingMusic from './CommunitiesFeaturingMusic';
import UploadedSongsSection from './UploadedSongsSection';

interface PropsType {
  artists: INormalized<IArtist>;
}

const ArtistDashboard: React.FC<PropsType> = ({ artists }) => {
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up('md'));
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.general.currentUser);
  const [profileData, setProfileData] = React.useState<{
    artist: IArtist | undefined;
    user: IUser | undefined;
  }>({
    artist: undefined,
    user: undefined
  });

  const params = useParams<{ artistId: string | undefined }>();

  const fetchArtist = (): Promise<IArtist> =>
    new Promise((resolve, reject) => {
      dispatch(
        getArtist(
          { id: Number(params.artistId) },
          (artist) => {
            resolve(artist);
          },
          () => {
            reject();
          }
        )
      );
    });

  const fetchUser = (): Promise<IUser> =>
    new Promise((resolve, reject) => {
      dispatch(
        getUserById(
          { id: Number(params.artistId) },
          (user) => {
            resolve(user as IUser);
          },
          () => {
            reject();
          }
        )
      );
    });

  const getProfileData = async () => {
    if (currentUser && params.artistId) {
      setProfileData({ artist: undefined, user: undefined });
      const artist = await fetchArtist();
      const user = Number(currentUser.id) === Number(params.artistId) ? currentUser : await fetchUser();
      setProfileData({ artist, user });
    }
  };

  useEffect(() => {
    if (currentUser) {
      document.title = `User Profile - ${currentUser?.username} | Echodrop`;
    } else {
      document.title = `User Profile | Echodrop`;
    }
  }, [currentUser]);

  useEffect(() => {
    getProfileData().then();
  }, [currentUser, params.artistId]);

  useEffect(() => {
    if (params.artistId) {
      const artist = artists[parseInt(params.artistId)];
      setProfileData((oldState) => ({ ...oldState, artist }));
    }
  }, [artists[Number(params.artistId)]]);

  if (!profileData.artist || !profileData.user) {
    return (
      <Box sx={{ height: 400 }}>
        <Loading />
      </Box>
    );
  }

  return (
    <Stack spacing={5} sx={{ pb: 7 }}>
      <Stack sx={{ pt: 3 }} direction="row" spacing={4}>
        <ArtistVideoSection artist={profileData.artist} />
        <Stack spacing={3} sx={{ width: '100%' }}>
          <ArtistInfo artist={profileData.artist} user={profileData.user} />
          <AboutSection artist={profileData.artist} />
          {upMd && <CommunitiesFeaturingMusic />}
        </Stack>
      </Stack>
      {!upMd && <CommunitiesFeaturingMusic />}

      {profileData.artist.status === ARTIST_STATUS.APPROVED ? (
        <UploadedSongsSection sx={{ mb: 15 }} title="Uploaded music" />
      ) : (
        <Paper sx={{ p: 3 }}>
          <Stack direction="row" alignItems="center" justifyContent="center" spacing={5}>
            <PublishedWithChangesIcon sx={{ fontSize: 100 }} />
            <div>
              <Typography variant="h2">This account is {profileData.artist.status}</Typography>
              <Typography variant="body2">Contact to admin if you want know more details</Typography>
            </div>
          </Stack>
        </Paper>
      )}
    </Stack>
  );
};

const mapState = (state: IRootState) => ({
  artists: state.main.artists
});

export default connect(mapState)(ArtistDashboard);
