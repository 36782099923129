import { ACTIONS } from '../../enums/actions';
import { IInviteUserPayload } from '../../interfaces/requests/artist.interface';
import { IGetUserByIdPayload } from '../../interfaces/requests/user.interface';
import { IDispatchAction } from '../../interfaces/store/root.interface';
import { SHARED_ACTIONS } from '../../shared/constants/actions.const';
import { ISupporter } from '../../shared/interfaces/custom/supporter.interface';
import { IChatBotUser } from '../../shared/interfaces/models/chatbot-user.interface';
import { IUser } from '../../shared/interfaces/models/user.interface';

export const getMe = (onSuccess?: () => void, onFail?: () => void): IDispatchAction => ({
  type: ACTIONS.GET_ME,
  onSuccess,
  onFail
});

export const updateMe = (payload: IUser, onSuccess?: () => void, onFail?: () => void): IDispatchAction => ({
  payload,
  type: ACTIONS.UPDATE_ME,
  onSuccess,
  onFail
});

export const getUserById = (
  payload: IGetUserByIdPayload,
  onSuccess?: (payload: unknown) => void,
  onFail?: () => void
): IDispatchAction => ({
  type: ACTIONS.GET_USER_BY_ID,
  payload,
  onSuccess,
  onFail
});

// Admin only actions:
export const getUsers = (onSuccess?: () => void, onFail?: () => void): IDispatchAction => ({
  type: ACTIONS.GET_USERS,
  onSuccess,
  onFail
});

// export const inviteArtist = (
//   payload: IInviteArtistPayload,
//   onSuccess?: () => void,
//   onFail?: () => void
// ): IDispatchAction => ({
//   type: ACTIONS.INVITE_ARTIST,
//   payload,
//   onSuccess,
//   onFail
// });
//
// export const inviteCurator = (
//   payload: IInviteCuratorPayload,
//   onSuccess?: () => void,
//   onFail?: () => void
// ): IDispatchAction => ({
//   type: ACTIONS.INVITE_CURATOR,
//   payload,
//   onSuccess,
//   onFail
// });

export const updateUser = (payload: IUser, onSuccess?: () => void, onFail?: () => void): IDispatchAction => ({
  type: ACTIONS.UPDATE_USER,
  payload,
  onSuccess,
  onFail
});

export const removeUser = (payload: number, onSuccess?: () => void, onFail?: () => void): IDispatchAction => ({
  type: ACTIONS.REMOVE_USER,
  payload,
  onSuccess,
  onFail
});

export const makeTest = (payload?: any, onSuccess?: () => void, onFail?: () => void): IDispatchAction => ({
  type: SHARED_ACTIONS.TEST,
  payload,
  onSuccess,
  onFail
});

export const getChatBotUsers = (
  onSuccess?: (result: IChatBotUser[]) => void,
  onFail?: (result: IChatBotUser[]) => void
): IDispatchAction => ({
  type: SHARED_ACTIONS.GET_CHATBOT_USER_ID_LIST,
  onSuccess,
  onFail
});

export const inviteUser = (
  payload: IInviteUserPayload,
  onSuccess?: (data: unknown) => void,
  onFail?: (error: unknown) => void
): IDispatchAction => ({
  type: SHARED_ACTIONS.INVITE_USER,
  payload,
  onSuccess,
  onFail
});

export const getTopSupportersForArtist = (
  payload: { id: number },
  onSuccess?: (data: ISupporter[]) => void,
  onFail?: (error: unknown) => void
): IDispatchAction => ({
  type: SHARED_ACTIONS.GET_TOP_SUPPORTERS,
  payload,
  onSuccess,
  onFail
});

export const getTopSupportersCountForArtist = (
  payload: { id: number },
  onSuccess?: (data: number) => void,
  onFail?: (error: unknown) => void
): IDispatchAction => ({
  type: SHARED_ACTIONS.GET_TOP_SUPPORTERS_COUNT,
  payload,
  onSuccess,
  onFail
});
