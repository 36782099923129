import { AUDIO_EXTENSIONS, IMAGE_EXTENSIONS, VIDEO_EXTENSIONS } from '../shared/constants/file.const';

export const LIMITS = {
  IMAGES: {
    MAX_SIZE_MB: 10,
    MAX_RESOLUTION: {
      WIDTH: 1024,
      HEIGHT: 1024
    },
    EXTENSIONS_ALLOWED: IMAGE_EXTENSIONS
  },
  SONGS: {
    MAX_SIZE_MB: 20,
    EXTENSIONS_ALLOWED: AUDIO_EXTENSIONS
  },
  VIDEOS: {
    MAX_SIZE_MB: 120,
    MAX_RESOLUTION: {
      WIDTH: 1080,
      HEIGHT: 1920
    },
    EXTENSIONS_ALLOWED: VIDEO_EXTENSIONS
  }
};
