import React from 'react';
import { Route } from 'react-router-dom';
import ChatAndCurationComponent from '../../components/ChatAndCurationComponent/ChatAndCurationComponent';
import PlayerComponent from '../../components/RadioPlayerComponent/RadioPlayerComponent';
import routePaths from '../../lib/routePaths';
import { Grid } from '@mui/material';

const SplitViewTemplate: React.FC = () => {
  return (
    <Grid container>
      {/* Left Pane */}
      <Grid item xs={6}>
        <Route exact path={[routePaths.private.curation.top, routePaths.private.curation.new, routePaths.private.chat]}>
          <PlayerComponent />
        </Route>
      </Grid>
      {/* Right Pane */}
      <Grid item xs={6}>
        <Route exact path={[routePaths.private.curation.top, routePaths.private.curation.new, routePaths.private.chat]}>
          <ChatAndCurationComponent />
        </Route>
      </Grid>
    </Grid>
  );
};

export default SplitViewTemplate;
