/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Avatar,
  Box,
  CircularProgress,
  CircularProgressProps,
  Skeleton,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useIsOwner from '../../lib/hooks/useIsOwner';
import { RESOURCE_STATUS } from '../../shared/constants/resource.const';
import { APP_SECTION } from '../../shared/constants/upload.const';
import { IMAGE_SIZES } from '../../shared/interfaces/custom/image-preset-settings.interface';
import { IResource } from '../../shared/interfaces/models/resource.interface';
import { queue } from '../../store/actions/queueActions';
import { uploadResourceFile } from '../../store/actions/resourceActions';
import AvatarButton from '../AvatarButtonComponent/AvatarButton';
import ShowIfOwner from '../ShowIfOwner/ShowIfOwner';
import { IMAGE_EXTENSIONS } from '../../shared/constants/file.const';
import { LIMITS } from '../../enums/uploads';
import { mbToBytes } from '../../utils/general';
import { getFileExtension } from '../../shared/utils/file';

interface PropType {
  userId?: number;
  resources?: IResource[];
}

function CircularProgressWithLabel(props: CircularProgressProps & { value: number }) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const ProfileAvatarComponent: React.FC<PropType> = ({ userId, resources }) => {
  const [percent, setPercent] = useState(0);
  const isOwner = useIsOwner();
  const dispatch = useDispatch();
  const [profileImageResource, setProfileImageResource] = React.useState<IResource | undefined>();
  const [profileImageLoading, setProfileImageLoading] = React.useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const inputFileProfile = React.useRef<any>(null);

  const handleUploadSuccess = () => {
    // eslint-disable-next-line no-console
    console.log('handleUploadSuccess');
  };

  const handleUploadFail = () => {
    setError('Failed. Try Again.');
    // eslint-disable-next-line no-console
    console.log('handleUploadFail');
  };

  const uploadResource = (selectedFile: any, appSection: APP_SECTION) => {
    if (userId) {
      dispatch(
        queue(
          uploadResourceFile(
            {
              appSection,
              file: selectedFile,
              name: selectedFile.name,
              associatedId: userId
            },
            handleUploadSuccess,
            handleUploadFail,
            (progress) => setPercent(progress)
          )
        )
      );
    }
  };

  const handleFileProfile = async (fileInputEvent: any) => {
    const file = fileInputEvent.target.files[0];
    if (file) {
      console.log({ file });
      if (file.size > mbToBytes(LIMITS.IMAGES.MAX_SIZE_MB)) {
        alert(`File size cannot exceed more than ${LIMITS.IMAGES.MAX_SIZE_MB}MB`);
        return;
      }
      const extension = getFileExtension(file.name);
      if (!IMAGE_EXTENSIONS.includes(extension)) {
        alert(
          `File type "${extension}" is not supported. Format must be one of the following: ${IMAGE_EXTENSIONS.join(
            ', '
          )}`
        );
        return;
      }

      setError(undefined);
      setProfileImageResource(undefined);
      setProfileImageLoading(true);
      uploadResource(file, APP_SECTION.USER_PROFILE);
    }
  };

  useEffect(() => {
    if (resources) {
      const profileImageResource = resources.find(
        (r: IResource) => r.appSection === APP_SECTION.USER_PROFILE && r.data.size === IMAGE_SIZES.L
      );
      if (profileImageResource) {
        setProfileImageResource(profileImageResource);
        if (profileImageResource.status === RESOURCE_STATUS.AVAILABLE) {
          setProfileImageLoading(false);
          setPercent(0);
        }
      }
    }
  }, [resources]);

  if (!userId) {
    return <Skeleton variant="circular" sx={{ width: 140, height: 140 }}></Skeleton>;
  }

  if (percent === 0 && profileImageLoading) {
    return (
      <Avatar sx={{ width: 140, height: 140 }}>
        <Typography>Preparing...</Typography>
      </Avatar>
    );
  }

  if (percent !== 100 && profileImageLoading) {
    return (
      <Avatar sx={{ width: 140, height: 140 }}>
        <CircularProgressWithLabel value={percent} />
      </Avatar>
    );
  }

  if (profileImageLoading) {
    return (
      <Tooltip title="If you want, you can leave this window while we are processing your image.">
        <Avatar sx={{ width: 140, height: 140 }}>
          <Typography>Converting...</Typography>
        </Avatar>
      </Tooltip>
    );
  }

  return (
    <>
      <Stack alignItems="center" spacing={1}>
        {isOwner ? (
          <AvatarButton
            iconName="edit"
            onClick={() => inputFileProfile.current.click()}
            src={profileImageResource?.url}
            showUploadLimits
          />
        ) : (
          <Avatar sx={{ width: 140, height: 140 }} src={profileImageResource?.url} />
        )}
        {error && <Typography color="error">{error}</Typography>}
      </Stack>
      <ShowIfOwner>
        <input
          id="myInput"
          accept={IMAGE_EXTENSIONS.map((e) => `.${e}`).join(',')}
          type="file"
          ref={inputFileProfile}
          className="hidden"
          onChange={(e) => handleFileProfile(e)}
        />
      </ShowIfOwner>
    </>
  );
};

export default ProfileAvatarComponent;
