import React, { useEffect, useLayoutEffect, useState } from 'react';
import ReactDOM from 'react-dom';

const FloatingTemplate = ({
  anchorRef,
  children,
  yOffset = 0,
  xOffset = 0,
  inheritWidth = false,
  inheritHeight = false,
  yInherit = false,
  useAnchorAsContainer = false,
  sumAnchorHeightToYPosition = false,
  style = {}
}: {
  anchorRef?: React.RefObject<HTMLElement | undefined>;
  children: any;
  yOffset?: number;
  xOffset?: number;
  inheritWidth?: boolean;
  inheritHeight?: boolean;
  yInherit?: boolean;
  useAnchorAsContainer?: boolean;
  sumAnchorHeightToYPosition?: boolean;
  style?: any;
}) => {
  const [domRect, setDomRect] = useState<DOMRect>();

  useLayoutEffect(() => {
    if (anchorRef) {
      const resizeObserver = new ResizeObserver(() => {
        getButtonPosition();
      });
      // @ts-ignore
      resizeObserver.observe(anchorRef.current);
      return () => resizeObserver.disconnect();
    }
  }, [anchorRef]);

  const getButtonPosition = () => {
    if (anchorRef?.current) {
      const bcr = anchorRef.current.getBoundingClientRect();
      if (bcr) {
        setDomRect(bcr);
      }
    }
  };

  useEffect(() => {
    getButtonPosition();
  }, [anchorRef, anchorRef?.current]);

  useEffect(() => {
    window.addEventListener('resize', getButtonPosition);
    return () => window.removeEventListener('resize', getButtonPosition);
  }, []);

  return (
    <>
      {ReactDOM.createPortal(
        <>
          {domRect && (
            <div
              style={{
                position: 'absolute',
                top: useAnchorAsContainer
                  ? 0 + yOffset + (sumAnchorHeightToYPosition ? domRect.height : 0)
                  : `${(domRect?.bottom ?? 0) + yOffset - (yInherit ? domRect.height : 0)}px`,
                left: useAnchorAsContainer ? xOffset : `${(domRect?.left ?? 0) + xOffset}px`,
                width: inheritWidth ? domRect.width : undefined,
                height: inheritHeight ? domRect.height - (yInherit ? domRect.height * 0.04 : 0) : undefined,
                ...style
              }}
            >
              {children}
            </div>
          )}
        </>,
        useAnchorAsContainer && anchorRef?.current ? anchorRef.current : document.body
      )}
    </>
  );
};

export default FloatingTemplate;
