import AvatarButton from '../components/AvatarButtonComponent/AvatarButton';
import BottomBarPlayerComponent from '../components/BottomBarPlayerComponent/BottomBarPlayer';
import ChatAndCurationComponent from '../components/ChatAndCurationComponent/ChatAndCurationComponent';
import CommunitiesSectionComponent from '../components/CommunitiesSectionComponent/CommunitiesSectionComponent';
import CommunityComponent from '../components/CommunityComponent/CommunityComponent';
import ExclusiveEditionsCollectionComponent from '../components/ExclusiveEditionsCollectionComponent/ExclusiveEditionsCollectionComponent';
import RadioPlayerComponent from '../components/RadioPlayerComponent/RadioPlayerComponent';
import SectionTitleComponent from '../components/SectionTitleComponent/SectionTitleComponent';
import UserInfo from '../components/UserInfoComponent/UserInfo';
import ArtistDashboard from '../pages/ArtistDashboard/ArtistDashboard';
import CurationHistorySection from '../pages/CuratorProfilePage/CurationHistorySection';
import CuratorProfilePage from '../pages/CuratorProfilePage/CuratorProfilePage';
import JoinedCommunitiesPage from '../pages/JoinedCommunitiesPage/JoinedCommunitiesPage';
import { generateFakeCommunity } from '../utils/fakers';
import {
  avatarButtonArgs,
  communitiesSectionArgs,
  curationHistorySectionArgs,
  exclusiveEditionsCollectionArgs,
  userInfoArgs
} from './dummies-args/curator-profile-page';
import { ResultMessage } from '../components/SongUploadListProgressComponent/SongUploadProgressComponent';

export default {
  UploadSong: {
    ResultMessage: ResultMessage
  },
  CuratorProfilePage: {
    CuratorProfilePage: {
      component: CuratorProfilePage,
      args: {
        ...userInfoArgs,
        ...communitiesSectionArgs,
        ...exclusiveEditionsCollectionArgs
      }
    },
    CurationHistorySection: {
      component: CurationHistorySection,
      args: curationHistorySectionArgs
    },
    ExclusiveEditionsCollection: {
      component: ExclusiveEditionsCollectionComponent,
      args: exclusiveEditionsCollectionArgs
    },
    SectionTitle: {
      component: SectionTitleComponent,
      // eslint-disable-next-line no-console
      args: { title: 'Anything', onViewAll: () => console.log('view all') }
    },
    CommunitiesSectionComponent: {
      component: CommunitiesSectionComponent,
      args: communitiesSectionArgs
    },
    AvatarButton: {
      component: AvatarButton,
      args: avatarButtonArgs
    },
    UserInfo: {
      component: UserInfo,
      args: userInfoArgs
    }
  },
  JoinedCommunitiesPage: {
    JoinedCommunitiesPage: {
      component: JoinedCommunitiesPage,
      args: communitiesSectionArgs
    },
    CommunityComponent: {
      component: CommunityComponent,
      args: {
        community: generateFakeCommunity(123),
        // eslint-disable-next-line no-console
        onPlayCommunity: () => console.log('playing community')
      }
    }
  },
  Artist: {
    DashboardArtistComponent: {
      component: ArtistDashboard,
      args: {
        ...communitiesSectionArgs
      }
    }
  },
  General: {
    RadioPlayerComponent: RadioPlayerComponent,
    BottomBarPlayerComponent: BottomBarPlayerComponent
  },
  ExampleCategory: {
    ChatAndCurationComponent: ChatAndCurationComponent
  }
};
