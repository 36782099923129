import { ISong } from '../../../shared/interfaces/models/song.interface';
import { APP_SECTION } from '../../../shared/constants/upload.const';
import { RESOURCE_STATUS } from '../../../shared/constants/resource.const';
import { PLAYER_CONFIG } from '../../../enums/player';
import { IResource } from '../../../shared/interfaces/models/resource.interface';

export default function useAudioHelper() {
  const getUrlFromSong = (song: ISong): string | undefined => {
    let resource = song.resources?.find(
      (r) => r.appSection === APP_SECTION.ARTIST_SONG && r.status === RESOURCE_STATUS.AVAILABLE && r.extension === 'mp3'
    );
    if (!resource) {
      resource = song.resources?.find(
        (r) => r.appSection === APP_SECTION.ARTIST_SONG && r.status === RESOURCE_STATUS.AVAILABLE
      );
      if (!resource) {
        return undefined;
      }
    }
    return `${PLAYER_CONFIG.SPECIFIC_SONG_BASE_URL}/${resource.id}`;
  };

  const songHasErroredResources = (song?: ISong): boolean => {
    return (
      !song ||
      song.resources?.some((r: IResource) => r.status === RESOURCE_STATUS.ERROR) ||
      // !song.resources?.some((r: IResource) => r.appSection === APP_SECTION.SONG_VIDEO) ||
      !song.resources?.some((r: IResource) => r.appSection === APP_SECTION.ARTIST_SONG)
    );
  };

  return {
    getUrlFromSong,
    songHasErroredResources
  };
}
