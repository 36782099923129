import { ACTIONS } from '../../enums/actions';
import { IGetArtistPayload, IUpdateArtistPayload } from '../../interfaces/requests/artist.interface';
import { IDispatchAction } from '../../interfaces/store/root.interface';
import { IArtist } from '../../shared/interfaces/models/artist.interface';
import { SHARED_ACTIONS } from '../../shared/constants/actions.const';
import { DeleteSongPayloadInterface } from '../../shared/interfaces/custom/delete-song-payload.interface';

export const getArtist = (
  payload: IGetArtistPayload,
  onSuccess?: (result: IArtist) => void,
  onFail?: (result: IArtist) => void
): IDispatchAction => ({
  payload,
  type: ACTIONS.GET_ARTIST_BY_ID,
  onSuccess,
  onFail
});

export const updateArtist = (
  payload: IUpdateArtistPayload,
  onSuccess?: (result: IArtist[]) => void,
  onFail?: (result: IArtist[]) => void
): IDispatchAction => ({
  payload,
  type: ACTIONS.UPDATE_ARTIST,
  onSuccess,
  onFail
});

export const updateArtistBio = (
  payload: IUpdateArtistPayload,
  onSuccess?: (result: IArtist[]) => void,
  onFail?: (result: IArtist[]) => void
): IDispatchAction => ({
  payload,
  type: ACTIONS.UPDATE_ARTIST_BIO,
  onSuccess,
  onFail
});

export const createArtistRequest = (
  payload: any,
  onSuccess?: (result: boolean) => void,
  onFail?: () => void
): IDispatchAction => ({
  payload,
  type: SHARED_ACTIONS.CREATE_ARTIST_REQUEST,
  onSuccess,
  onFail
});

export const deleteSong = (
  payload: DeleteSongPayloadInterface,
  onSuccess?: (result: number) => void,
  onFail?: () => void
): IDispatchAction => ({
  type: SHARED_ACTIONS.DELETE_SONG,
  payload,
  onSuccess,
  onFail
});
