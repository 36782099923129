/* eslint-disable @typescript-eslint/no-empty-function  */
/* eslint-disable @typescript-eslint/no-unused-vars*/

import { useEffect, useState } from 'react';
import { RADIO_STATUS } from '../../../enums/player';
import {
  ICurrentData,
  IPlayerData,
  IPlayerDataSetters,
  IPlayerInfo,
  IPlayerInfoTriggers,
  IRadioData,
  ISongData
} from '../../../interfaces/echo-player/echo-player-info.interface';
import useRadioInfoTriggers from './useRadioInfoTriggers';
import useSongInfoTriggers from './useSongInfoTriggers';
import useCurrentInfoTriggers from './useCurrentInfoTriggers';
import { useDispatch } from 'react-redux';
import { setPlayerData } from '../../../store/actions/generalActions';

export const dataInitValues = {
  radio: {
    playing: false,
    loading: false,
    status: RADIO_STATUS.EMPTY
  },
  song: {
    playing: false,
    loading: false,
    audioContext: new window.AudioContext()
  },
  current: {
    playing: false,
    volume: 1,
    mute: false,
    loading: false
  }
};

export default function usePlayerInfo(): IPlayerInfo {
  const dispatch = useDispatch();
  const [data, setData] = useState<IPlayerData>(dataInitValues);

  useEffect(() => {
    dispatch(setPlayerData(data));
  }, [data]);

  const setRadioData = (newData: Partial<IRadioData>) => {
    setData((prevData) => ({
      ...prevData,
      radio: {
        ...prevData.radio,
        ...newData
      }
    }));
  };
  const setSongData = (newData: Partial<ISongData>) => {
    setData((prevData) => ({
      ...prevData,
      song: {
        ...prevData.song,
        ...newData
      }
    }));
  };
  const setCurrentData = (newData: Partial<ICurrentData>) => {
    setData((prevData) => ({
      ...prevData,
      current: {
        ...prevData.current,
        ...newData
      }
    }));
  };

  const setters: IPlayerDataSetters = { setData, setRadioData, setSongData, setCurrentData };

  const radio = useRadioInfoTriggers(data, setters);
  const song = useSongInfoTriggers(data, setters);
  const current = useCurrentInfoTriggers(data, setters);
  const triggers: IPlayerInfoTriggers = { radio, song, current };

  return { data, triggers };
}
