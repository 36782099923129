import { isNew } from '../../utils/general';
import { IUserCommunity } from '../../shared/interfaces/models/user-community.interface';
import { ICommunity } from '../../shared/interfaces/models/community.interface';

export interface ICommunityComponent {
  id?: number;
  name?: string;
  description?: string;
  playCount?: number;
  listeningHours?: number;
  createdAt?: Date;
  updatedAt?: Date;
  genre?: string;
  isNew?: boolean;
  level?: number;
  imageUrl?: string;
  coomingSoon?: boolean;
  private?: boolean;
}

export const createFromUserCommunity = (uc: IUserCommunity): ICommunityComponent => {
  let communityComponent = {
    level: uc.userLevel,
    isNew: uc.community?.createdAt ? isNew(new Date(uc.community.createdAt)) : false
  };
  if (uc.community) {
    communityComponent = {
      ...communityComponent,
      ...uc.community
    };
  }
  return communityComponent;
};

export const createFromCommunity = (community: ICommunity): ICommunityComponent => {
  let communityComponent = {
    level: undefined,
    isNew: community?.createdAt ? isNew(new Date(community.createdAt)) : false
  };
  if (community) {
    communityComponent = {
      ...communityComponent,
      ...community
    };
  }
  return communityComponent;
};
