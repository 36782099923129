/* eslint-disable @typescript-eslint/no-unused-vars */
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import FlareIcon from '@mui/icons-material/Flare';
import GroupsIcon from '@mui/icons-material/Groups';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  Chip,
  Icon,
  IconButton,
  Skeleton,
  Stack,
  SxProps,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import capitalize from 'lodash/capitalize';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { object, string } from 'yup';
import useAnalytics from '../../lib/hooks/useAnalytics';
import SupportersDialog from '../../pages/ArtistDashboard/SupportersDialog';
import { IArtist } from '../../shared/interfaces/models/artist.interface';
import { IUserCommunity } from '../../shared/interfaces/models/user-community.interface';
import { IUser } from '../../shared/interfaces/models/user.interface';
import { getUserCommunitiesByUserId } from '../../store/actions/communitiesActions';
import { getTopSupportersCountForArtist, updateMe } from '../../store/actions/userActions';
import { useAuth } from '../Authentication/ProvideAuth';
import ProfileAvatarComponent from '../ProfileAvatarComponent/ProfileAvatarComponent';
import ShowIfOwner from '../ShowIfOwner/ShowIfOwner';

interface PropType {
  title?: string;
  infoList?: {
    name: string;
    value: string;
  }[];
  buttons?: {
    text: string;
    onClick: () => void;
  }[];
  onAvatarClick?: () => void;
  onShareButtonClick?: () => void;
  sx?: SxProps;
  imageUrl?: string;
  imageLoading?: boolean;
  artist: IArtist;
  user?: IUser | null;
}

const ArtistInfo: React.FC<PropType> = ({ sx, artist, user, buttons, onShareButtonClick }) => {
  const { track } = useAnalytics();
  const dispatch = useDispatch();
  const theme = useTheme();
  const auth = useAuth();
  const [textFieldVisible, setTextFieldVisible] = useState(false);
  const [topLevelUc, setTopLevelUc] = useState<IUserCommunity>();
  const [topLevelUcLoading, setTopLevelUcLoading] = useState(false);
  const [username, setUsername] = useState(user?.username);
  const [errors, setErrors] = useState<{ username: string | undefined }>({ username: '' });
  const [loadings, setLoadings] = useState<{ username: boolean }>({ username: false });
  const [supportersCount, setSupportersCount] = useState<number>();
  const [showTopSupportersDialog, setShowTopSupportersDialog] = useState(false);

  const usernameValidationSchema = object().shape({
    username: string()
      .required()
      .min(4)
      .max(20)
      .matches(
        /^(?=[a-zA-Z0-9._]{4,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/,
        'Only letters, numbers, underscores and dots are allowed'
      )
  });

  useEffect(() => {
    setTopLevelUcLoading(true);
    dispatch(
      getUserCommunitiesByUserId({ id: artist.id }, (userCommunities) => {
        if (userCommunities.length > 0) {
          const topLevel = userCommunities.reduce(function (prev, current) {
            return (prev.points ?? 0) > (current.points ?? 0) ? prev : current;
          });
          setTopLevelUc(topLevel);
        }
        setTopLevelUcLoading(false);
      })
    );
  }, []);

  useEffect(() => {
    if (user && user.id) {
      dispatch(getTopSupportersCountForArtist({ id: user.id }, setSupportersCount));
    }
  }, [user]);

  useEffect(() => {
    if (user?.username) {
      setUsername(user?.username);
    }
  }, [user?.username]);

  const handleUpdateUsername = async () => {
    setErrors({ username: undefined });
    if (user && username) {
      setLoadings({ ...loadings, username: true });
      try {
        await usernameValidationSchema.validate({ username });
      } catch (e: any) {
        setLoadings({ ...loadings, username: false });
        setErrors({ ...errors, username: e.message });
        return;
      }

      const editedUser = { id: user.id, username } as IUser;
      console.log('updating user: ', editedUser);
      auth.checkIfUserExists(
        { username },
        (alreadyExists: boolean) => {
          if (alreadyExists) {
            setLoadings({ ...loadings, username: false });
            setErrors({ ...errors, username: `Username already in use.` });
          } else {
            setLoadings({ ...loadings, username: false });
            dispatch(
              updateMe(editedUser, () => {
                setTextFieldVisible(false);
                track('username_edited');
              })
            );
          }
        },
        () => {
          setLoadings({ ...loadings, username: false });
          dispatch(
            updateMe(editedUser, () => {
              setTextFieldVisible(false);
              track('username_edit_error');
            })
          );
        }
      );
    }
  };

  const handleCancel = () => {
    setErrors({ username: undefined });
    setUsername(user?.username);
    setTextFieldVisible((state) => !state);
  };

  return (
    <Stack sx={sx} direction="column" spacing={2} fontFamily="Open Sans, sans-serif">
      <Stack sx={{ pr: 1, py: 1 }} direction="row" spacing={4} alignItems="center">
        <ProfileAvatarComponent userId={artist.id} resources={artist.resources} />

        <Stack direction="column" spacing={1} sx={{ mb: 2 }}>
          {textFieldVisible ? (
            <>
              <TextField
                autoFocus
                error={!!errors.username}
                helperText={errors.username && errors.username}
                disabled={loadings.username}
                InputProps={{
                  style: { fontFamily: 'sans-serif', fontSize: 32, fontWeight: 600, lineHeight: '36px' }
                }}
                sx={{ width: 200 }}
                variant="standard"
                value={username}
                onKeyDown={(e) => e.key === 'Enter' && handleUpdateUsername()}
                onChange={(event) => setUsername(event.target.value)}
              />
            </>
          ) : (
            <Stack direction="row" spacing={3}>
              <Typography variant="h1" fontFamily={'sans-serif'}>
                {username}
              </Typography>
              <ShowIfOwner>
                <IconButton color="primary" onClick={() => setTextFieldVisible((state) => !state)}>
                  <DriveFileRenameOutlineIcon />
                </IconButton>
              </ShowIfOwner>
            </Stack>
          )}

          {textFieldVisible ? (
            <Stack direction="row" spacing={1} alignItems="flex-start">
              <LoadingButton
                loading={loadings.username}
                onClick={handleUpdateUsername}
                variant="contained"
                size="small"
              >
                Save
              </LoadingButton>
              <Button disabled={loadings.username} onClick={handleCancel} size="small">
                Cancel
              </Button>
            </Stack>
          ) : !topLevelUcLoading ? (
            <Stack direction="row" spacing={1} alignItems="center">
              <strong>Top Rank</strong>
              <Chip
                label={
                  topLevelUc ? `${capitalize(topLevelUc?.level?.name)} in ${topLevelUc?.community?.name}` : 'No Rank'
                }
                icon={<Icon>{topLevelUc?.level?.icon ?? 'diamond'}</Icon>}
              />
              <Chip
                label={`${topLevelUc?.points ?? 0} echo`}
                icon={<FlareIcon fontSize="small" sx={{ color: 'primary.main' }} />}
              />
              <Button
                size="small"
                onClick={() => setShowTopSupportersDialog(true)}
                variant="outlined"
                color="primary"
                startIcon={<GroupsIcon fontSize="small" />}
              >
                {supportersCount ?? 0} Supporters
              </Button>
            </Stack>
          ) : (
            <Stack direction="row" spacing={1}>
              <strong>Top Rank</strong>
              <Skeleton sx={{ width: 100 }} />
              <Skeleton sx={{ width: 100 }} />
            </Stack>
          )}
        </Stack>
      </Stack>

      {buttons && (
        <Stack sx={{ pr: 1, py: 1 }} direction="row" spacing={3} alignItems="center">
          {buttons.map((button) => (
            <Button key={button.text} onClick={button.onClick} variant="contained">
              {button.text}
            </Button>
          ))}
        </Stack>
      )}

      {user && <SupportersDialog user={user} open={showTopSupportersDialog} setOpen={setShowTopSupportersDialog} />}
    </Stack>
  );
};

export default ArtistInfo;
