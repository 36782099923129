export enum ACTIONS {
  // App state actions.
  INIT_SOCKET = 'INIT_SOCKET',
  DISCONNECT_SOCKET = 'DISCONNECT_SOCKET',
  ERROR = 'ERROR',
  TOASTER_MESSAGE = 'TOASTER_MESSAGE',
  LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',
  LOADING = 'LOADING',
  CLEAR_ERROR = 'CLEAR_ERROR',
  SOCKET_MESSAGE_ERROR = 'SOCKET_MESSAGE_ERROR',

  NORMALIZED = 'NORMALIZED',

  // Regular actions
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET',
  RESET_PASSWORD = 'RESET_PASSWORD',
  CHECK_AUTH = 'CHECK_AUTH',

  // General purpose actions.
  SET_GENERAL_VALUE = 'SET_GENERAL_VALUE',
  GET_INITIAL_DATA = 'GET_INITIAL_DATA',

  // Socket related actions.
  UPDATE_ME = 'UPDATE_ME',
  GET_ME = 'GET_ME',

  // Votes
  CAST_VOTE = 'CAST_VOTE',
  INIT_UPLOAD = 'INIT_UPLOAD',

  // Songs
  ADD_SONG_TO_UPLOAD_QUEUE = 'ADD_SONG_TO_UPLOAD_QUEUE',
  CREATE_SONG = 'CREATE_SONG',
  SET_UPLOAD_SONGS = 'SET_UPLOAD_SONGS',
  SET_UPLOAD_SONGS_SUCCESS = 'SET_UPLOAD_SONGS_SUCCESS',
  CREATE_SONG_SUCCESS = 'CREATE_SONG_SUCCESS',
  CREATE_SONGS_AND_GENERATE_PRESIGNED_UPLOAD_URLS = 'CREATE_SONGS_AND_GENERATE_PRESIGNED_UPLOAD_URLS',
  UPDATE_SONG = 'UPDATE_SONG',
  UPLOAD_FILE_SONG = 'UPLOAD_FILE_SONG',
  GET_SONGS_BY_ARTIST = 'GET_SONGS_BY_ARTIST',
  GET_SONGS_BY_ARTIST_SUCCESS = 'GET_SONGS_BY_ARTIST_SUCCESS',

  // AWS
  GET_PRESIGNED_UPLOAD_URLS = 'GET_PRESIGNED_UPLOAD_URLS',
  GET_PRESIGNED_UPLOAD_URLS_SUCCESS = 'GET_PRESIGNED_UPLOAD_URLS_SUCCESS',
  INIT_PRESIGNED_URL_MULTIPART_UPLOAD = 'INIT_PRESIGNED_URL_MULTIPART_UPLOAD',
  INIT_PRESIGNED_URL_MULTIPART_UPLOAD_SUCCESS = 'INIT_PRESIGNED_URL_MULTIPART_UPLOAD_SUCCESS',
  INIT_PRESIGNED_URL_SIMPLE_UPLOAD_SUCCESS = 'INIT_PRESIGNED_URL_SIMPLE_UPLOAD_SUCCESS',
  INIT_PRESIGNED_URL_SIMPLE_UPLOAD = 'INIT_PRESIGNED_URL_SIMPLE_UPLOAD',
  COMPLETE_MULTIPART_UPLOAD = 'COMPLETE_MULTIPART_UPLOAD',

  // Resources
  CREATE_UPLOAD_RESOURCE = 'CREATE_UPLOAD_RESOURCE',
  CREATE_UPLOAD_RESOURCE_SUCCESS = 'CREATE_UPLOAD_RESOURCE_SUCCESS',
  UPLOAD_RESOURCE_FILE = 'UPLOAD_RESOURCE_FILE',
  UPLOAD_RESOURCE_FILE_SUCCESS = 'UPLOAD_RESOURCE_FILE_SUCCESS',
  FILE_UPLOADED_SUCCESS = 'FILE_UPLOADED_SUCCESS',
  FILE_UPLOADED_ERROR = 'FILE_UPLOADED_ERROR',
  GET_USER_RESOURCES = 'GET_USER_RESOURCES',

  // Community
  GET_COMMUNITIES = 'GET_COMMUNITIES',
  GET_USER_COMMUNITIES_BY_USER_ID = 'GET_USER_COMMUNITIES_BY_USER_ID',
  GET_USER_COMMUNITIES_BY_USER_ID_SUCCESS = 'GET_USER_COMMUNITIES_BY_USER_ID_SUCCESS',
  GET_COMMUNITIES_SUCCESS = 'GET_COMMUNITIES_SUCCESS',
  UPDATE_COMMUNITY = 'UPDATE_COMMUNITY',
  UPDATE_COMMUNITY_SUCCESS = 'UPDATE_COMMUNITY_SUCCESS',
  // CREATE_COMMUNITY = 'CREATE_COMMUNITY',
  // CREATE_COMMUNITY_SUCCESS = 'CREATE_COMMUNITY_SUCCESS',
  // UPDATE_COMMUNITY = 'UPDATE_COMMUNITY',
  // UPDATE_COMMUNITY_SUCCESS = 'UPDATE_COMMUNITY_SUCCESS',
  // GET_COMMUNITY_BY_ID = 'GET_COMMUNITY_BY_ID',
  // GET_COMMUNITY_BY_ID_SUCCESS = 'GET_COMMUNITY_BY_ID_SUCCESS',

  // Users
  GET_USERS = 'GET_USERS',
  GET_USER_BY_ID = 'GET_USER_BY_ID',
  GET_USER_BY_ID_SUCCESS = 'GET_USER_BY_ID_SUCCESS',
  GET_USERS_SUCCESS = 'GET_USERS_SUCCESS',
  INVITE_ARTIST = 'INVITE_ARTIST',
  INVITE_ARTIST_SUCCESS = 'INVITE_ARTIST_SUCCESS',
  INVITE_CURATOR = 'INVITE_CURATOR',
  INVITE_CURATOR_SUCCESS = 'INVITE_CURATOR_SUCCESS',
  UPDATE_USER = 'UPDATE_USER',
  UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS',
  REMOVE_USER = 'REMOVE_USER',
  REMOVE_USER_SUCCESS = 'REMOVE_USER_SUCCESS',
  REGISTER_WITH_INVITATION = 'REGISTER_WITH_INVITATION',

  // Artist
  GET_ARTIST = 'GET_ARTIST',
  GET_ARTIST_SUCCESS = 'GET_ARTIST_SUCCESS',
  GET_ARTIST_BY_ID = 'GET_ARTIST_BY_ID',
  GET_ARTIST_BY_ID_SUCCESS = 'GET_ARTIST_BY_ID_SUCCESS',
  UPDATE_ARTIST = 'UPDATE_ARTIST',
  UPDATE_ARTIST_BIO = 'UPDATE_ARTIST_BIO',
  UPDATE_ARTIST_SUCCESS = 'UPDATE_ARTIST_SUCCESS',

  // Modal
  HANDLE_MODAL = 'HANDLE_MODAL',

  TEST = 'TEST',
  TEST_SUCCESS = 'TEST_SUCCESS',
  SET_PLAYER_DATA = 'SET_PLAYER_DATA',
  // SET_GLOBAL_PLAYER_INFO = 'SET_GLOBAL_PLAYER_INFO',
  // SET_RADIO_INFO = 'SET_RADIO_INFO',
  // SET_SPECIFIC_SONG_INFO = 'SET_SPECIFIC_SONG_INFO',
  // SET_PLAYER_CURRENT_INFO = 'SET_PLAYER_CURRENT_INFO',

  QUEUE = 'QUEUE',
  RUN_QUEUE = 'RUN_QUEUE',
  SET_MAIN_VALUE = 'SET_MAIN_VALUE'
}
