/* eslint-disable @typescript-eslint/no-unused-vars */
import LoadingButton from '@mui/lab/LoadingButton';
import { Link as MuiLink, Stack, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { object, string } from 'yup';
import { useAuth } from '../../components/Authentication/ProvideAuth';
import routePaths from '../../lib/routePaths';

const LoginForm: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const auth = useAuth();

  const loginValidationSchema = object().shape({
    email: string().email().required(),
    password: string().required()
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: loginValidationSchema,
    onSubmit: () => {
      setError(undefined);
      setLoading(true);
      auth.login(
        formik.values.email,
        formik.values.password,
        () => {
          setLoading(false);
        },
        (e: Error) => {
          setLoading(false);
          setError(e.message);
        }
      );
    }
  });

  const handleSubmit = () => {
    formik.handleSubmit();
  };

  return (
    <>
      <Stack sx={{ mt: 6 }} spacing={2}>
        <TextField
          onBlur={() => setError(undefined)}
          disabled={loading}
          onChange={formik.handleChange}
          error={!!formik.errors.email}
          helperText={formik.errors.email ? formik.errors.email : undefined}
          name="email"
          label="Email"
          variant="outlined"
          fullWidth
        />
        <TextField
          onBlur={() => setError(undefined)}
          disabled={loading}
          onChange={formik.handleChange}
          error={!!formik.errors.password}
          helperText={formik.errors.password ? formik.errors.password : undefined}
          name="password"
          type="password"
          label="Password"
          variant="outlined"
          fullWidth
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              handleSubmit();
            }
          }}
        />
        <Link component={MuiLink} to={routePaths.public.requestPasswordReset}>
          I forgot my password
        </Link>
      </Stack>

      <LoadingButton
        loading={loading}
        onClick={handleSubmit}
        variant="contained"
        color="primary"
        size="large"
        sx={{ mt: 4, width: '100%', borderRadius: 10 }}
      >
        SIGN IN
      </LoadingButton>
      {error && (
        <Typography variant="body2" sx={{ mt: 2, color: 'red' }}>
          {error}
        </Typography>
      )}
    </>
  );
};

export default LoginForm;
