/* eslint-disable @typescript-eslint/no-unused-vars */
import { IonSlide, IonSlides } from '@ionic/react';
import * as React from 'react';
import CommunityComponent from '../CommunityComponent/CommunityComponent';
import { ICommunityComponent } from '../../interfaces/ui-components/slider-community.interface';
import { Stack, ImageList, ImageListItem } from '@mui/material';

interface PropsType {
  communities: ICommunityComponent[];
  onPlayCommunity?: (communityId: number) => void;
}

const CommunitySlider: React.FC<PropsType> = ({ communities, onPlayCommunity }) => {
  return (
    <Stack sx={{ my: 2, mr: 2, maxWidth: 200 }} direction="row" spacing={2}>
      {communities.map((community) => (
        <CommunityComponent key={community.id} community={community} onPlayCommunity={onPlayCommunity} />
      ))}
    </Stack>
    // <ImageList
    //   sx={{
    //     gridAutoFlow: 'column',
    //     gridTemplateColumns: `repeat(auto-fill,minmax(140px, 140px)) !important`,
    //     gridAutoColumns: `minmax(50px, 140px)`
    //   }}
    // >
    //   {communities.map((community) => (
    //     <ImageListItem>
    //       <CommunityComponent key={community.id} community={community} onPlayCommunity={onPlayCommunity} />
    //     </ImageListItem>
    //   ))}
    //   {/*{images.map((image) => (*/}
    //   {/*  <ImageListItem>*/}
    //   {/*    <img src={image.thumbnail.uri} />*/}
    //   {/*    <ImageListItemBar title={image.thumbnail.name} />*/}
    //   {/*  </ImageListItem>*/}
    //   {/*))}*/}
    // </ImageList>
  );
};

export default CommunitySlider;
