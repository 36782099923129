/* eslint-disable */
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import React, { FormEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { APP_SECTION } from '../../shared/constants/upload.const';
import { uploadResourceFile } from '../../store/actions/resourceActions';
import { createSong } from '../../store/actions/songActions';
interface IUploadSongShowcase {}

const UploadSongShowcase: React.FC<IUploadSongShowcase> = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {}, []);
  const [selectedFile, setSelectedFile] = useState<File>();

  const callUploadSong = (selectedFile: any) => {
    dispatch(
      uploadResourceFile(
        {
          file: selectedFile,
          appSection: APP_SECTION.ARTIST_SONG,
          name: 'song',
          associatedId: 1
        },
        () => {
          console.log('file has been uploaded');
        },
        () => {
          console.log('file failed uploading');
        },
        (percent) => {
          console.log(`Upload has completed ${percent}%`);
        }
      )
    );
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (selectedFile) {
        callUploadSong(selectedFile);
        // dispatch(uploadSongFile({ file: selectedFile, name: 'song' }));
      }
    } catch (err) {
      console.log('error is', err);
    }
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Login page</IonTitle>
          </IonToolbar>
        </IonHeader>
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col items-center mt-2">
            <label
              htmlFor="picture"
              className="px-5 py-1 mt-6 bg-white border rounded-lg shadow cursor-pointer hover:bg-purple-600 hover:text-white"
            >
              <span className="mt-2 text-base leading-normal">{selectedFile?.name || 'File Input'}</span>
              <input
                type="file"
                accept="*"
                id="picture"
                name="picture"
                className="hidden"
                onChange={(e) => {
                  if (e.target.files && e.target.files[0]) {
                    setSelectedFile(e.target.files[0]);
                  }
                }}
              />
            </label>
            <button type="submit" className="px-1 py-2 my-6 border-2 border-green-400 rounded-md hover:bg-purple-200">
              Upload to s3
            </button>

            <button
              onClick={() => {
                dispatch(
                  createSong(
                    {
                      name: ''
                    },
                    () => {
                      console.log('success');
                    },
                    () => {
                      console.log('error');
                    }
                  )
                );
              }}
            >
              Create Song
            </button>
          </div>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default UploadSongShowcase;
