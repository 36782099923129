import { fork } from 'redux-saga/effects';
import songSaga from '../sagas/songSaga';

export default function* (socket: any) {
  yield fork(songSaga.createSong, socket);
  yield fork(songSaga.updateSong, socket);
  yield fork(songSaga.getSongsByArtist, socket);
  yield fork(songSaga.getSongCommunitiesByCommunity, socket);
  yield fork(songSaga.getSongCommunity, socket);
  yield fork(songSaga.updateUpvoteStatus, socket);
  yield fork(songSaga.getSongsByIdList, socket);
  yield fork(songSaga.getSongComments, socket);
  yield fork(songSaga.saveSongComment, socket);
  yield fork(songSaga.getSongReactions, socket);
  yield fork(songSaga.saveSongReaction, socket);
  yield fork(songSaga.getGenericVideos, socket);
}
