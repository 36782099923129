// Component used as a wrapper for pages such as login, request password, reset password and other pages that should go outside the main app
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import routePaths from '../../lib/routePaths';
import LoginPageOld from '../../pages/LoginPage/LoginPage';
import RegisterPage from '../../pages/RegisterPage/RegisterPage';
import RequestPasswordReset from '../../pages/RequestPasswordResetPage/RequestPasswordReset';
import ResetPasswordPage from '../../pages/ResetPasswordPage/ResetPasswordPage';
import UploadSongShowCase from '../../pages/UploadSongShowcase/UploadSongShowcase';

const ResponsiveTemplate: React.FC = () => {
  return (
    <div className="bg-black absolute w-full h-full">
      <Switch>
        <Route exact={true} path={routePaths.public.login}>
          <LoginPageOld />
        </Route>
        <Route exact={true} path={routePaths.public.register}>
          <RegisterPage />
        </Route>
        <Route exact={true} path={routePaths.public.invitationRegister}>
          <RegisterPage />
        </Route>
        <Route exact={false} path={routePaths.public.uploadSongShowCase}>
          <UploadSongShowCase />
        </Route>
        <Route exact path={routePaths.public.requestPasswordReset}>
          <RequestPasswordReset />
        </Route>
        <Route exact path={routePaths.public.resetPassword}>
          <ResetPasswordPage />
        </Route>
      </Switch>
    </div>
  );
};

export default ResponsiveTemplate;
