import React, { useEffect, useState } from 'react';
import { IRootState } from '../../interfaces/store/root.interface';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  createFromUserCommunity,
  ICommunityComponent
} from '../../interfaces/ui-components/slider-community.interface';
import { getUserCommunitiesByUserId } from '../../store/actions/communitiesActions';
import { IUserCommunity } from '../../shared/interfaces/models/user-community.interface';
import { PAGINATION_ITEMS_TO_SKIP, PAGINATION_ITEMS_TO_TAKE } from '../../shared/constants/pagination.const';
import useQuery from '../../lib/hooks/useQuery';
import ViewAllCommunitiesComponent from '../../components/ViewAllCommunitiesComponent/ViewAllCommunitiesComponent';
import { IPaginationInfo } from '../../shared/interfaces/custom/pagination-info.interface';

interface PropsType {
  onPlayCommunity?: (communityId: number) => void;
  pagination?: IPaginationInfo;
}

interface RouteParams {
  userId: string;
  skip?: string;
}

const JoinedCommunitiesPage: React.FC<PropsType> = ({ pagination, onPlayCommunity }) => {
  const dispatch = useDispatch();
  const queryParams = useQuery();
  const { userId } = useParams<RouteParams>();
  const [communityComponentList, setCommunityComponentList] = useState<ICommunityComponent[] | undefined>();

  useEffect(() => {
    const skip = queryParams.get('skip') || '0';
    fetchPage(parseInt(skip));
  }, []);

  const onReceiveResponse = (responseData: IUserCommunity[]) => {
    if (responseData) {
      const list: ICommunityComponent[] = Object.values(responseData).map(createFromUserCommunity);
      setCommunityComponentList(list);
    }
  };

  const fetchPage = (skip = PAGINATION_ITEMS_TO_SKIP, take = PAGINATION_ITEMS_TO_TAKE) => {
    dispatch(getUserCommunitiesByUserId({ id: userId, skip, take }, onReceiveResponse));
  };

  return (
    <ViewAllCommunitiesComponent
      title="Joined Communities"
      fetchPage={fetchPage}
      pagination={pagination}
      communityComponentList={communityComponentList}
      onPlayCommunity={onPlayCommunity}
    />
  );
};

const mapState = (state: IRootState) => ({
  pagination: state.general.pagination?.userCommunities
});

export default connect(mapState)(JoinedCommunitiesPage);
