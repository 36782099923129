/* eslint-disable no-console */
import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// import { generatePath, useHistory } from 'react-router-dom';
import CommunitiesSectionComponent from '../../components/CommunitiesSectionComponent/CommunitiesSectionComponent';
import { createFromCommunity, ICommunityComponent } from '../../interfaces/ui-components/slider-community.interface';
// import routePaths from '../../lib/routePaths';
import { getCommunities } from '../../store/actions/communitiesActions';
import { useSelector } from '../../store';
import { USER_ROLE } from '../../shared/constants/user.const';
import { Typography, Grid, Stack } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { updateMe } from '../../store/actions/userActions';
import { IUser } from '../../shared/interfaces/models/user.interface';

const CommunitiesPage: React.FC = () => {
  // const history = useHistory();
  const dispatch = useDispatch();
  // const { artistId } = useParams<RouteParams>();
  const { currentUser } = useSelector((state) => state.general);
  const [communityComponentList, setCommunityComponentList] = useState<ICommunityComponent[] | null>();
  const [qaList, setQaList] = useState<ICommunityComponent[] | null>();
  // const [privateList, setPrivateList] = useState<ICommunityComponent[] | null>();
  const [limitedTimeEventList, setLimitedTimeEventList] = useState<ICommunityComponent[] | null>();
  const [introVideoModalOpen, setIntroVideoModalOpen] = React.useState<boolean>();

  const coomingSoonCommunity = {
    id: 0,
    name: 'Coming Soon',
    description: 'Coming Soon',
    imageUrl: './assets/default-chat-icon.jpg',
    coomingSoon: true
  } as ICommunityComponent;

  useEffect(() => {
    document.title = `Echodrop | Home`;
  }, []);

  useEffect(() => {
    if (currentUser) {
      setIntroVideoModalOpen(!currentUser.introVideoSeen);
    } else {
      setIntroVideoModalOpen(false);
    }
  }, [currentUser]);

  useEffect(() => {
    if (introVideoModalOpen === false && currentUser && currentUser.introVideoSeen === false) {
      const editedUser = { id: currentUser.id, introVideoSeen: true } as IUser;
      dispatch(updateMe(editedUser));
    }
  }, [introVideoModalOpen]);

  useEffect(() => {
    dispatch(
      getCommunities((payload) => {
        if (payload) {
          let list: ICommunityComponent[] = Object.values(payload).map(createFromCommunity);
          const qaList = list.filter((community) => community.id === 8 || community.id === 3 || community.id === 12);
          // const privateListTmp = list.filter((community) => community.id === 11).map((c) => ({ ...c, private: true }));
          const limitedTimeListTmp = list.filter((community) => community.id === 13);
          setLimitedTimeEventList([...limitedTimeListTmp, coomingSoonCommunity]);
          list = list.filter(
            (community) => community.id !== 8 && community.id !== 3 && community.id !== 12 && community.id !== 13
          );
          setQaList(qaList);
          setCommunityComponentList([...list, coomingSoonCommunity]);
          // setPrivateList([...privateListTmp]);
        }
      })
    );
  }, []);

  const handleClose = () => {
    setIntroVideoModalOpen(false);
  };

  return (
    <Grid container>
      <Grid item xs={12} lg={9}>
        <Box sx={{ py: 3 }}>
          {limitedTimeEventList && (
            <CommunitiesSectionComponent title="Limited Time Events" communities={limitedTimeEventList} />
          )}
          {communityComponentList && (
            <CommunitiesSectionComponent
              sx={{ mt: 5 }}
              title="24/7 Streaming Playlists"
              communities={communityComponentList}
            />
          )}
          {/*{privateList && (*/}
          {/*  <CommunitiesSectionComponent sx={{ mt: 5 }} title="Private Communities" communities={privateList} />*/}
          {/*)}*/}
          {qaList && currentUser?.role === USER_ROLE.SYSADMIN && (
            <CommunitiesSectionComponent sx={{ mt: 5 }} title="Groups" communities={qaList} />
          )}

          <Dialog sx={{ p: 4 }} fullWidth open={!!introVideoModalOpen} onClose={handleClose}>
            <Typography sx={{ ml: 3, mt: 2 }} variant="h3">
              How Echo works in 25 secs!
            </Typography>

            <DialogContent>
              <video
                autoPlay
                controls
                style={{
                  width: 'inherit'
                }}
              >
                <source
                  src="https://echodrop-production.s3.us-west-2.amazonaws.com/public/echo-intro-video.mp4"
                  type="video/mp4"
                />
              </video>
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
              <Button variant="contained" onClick={() => setIntroVideoModalOpen(false)}>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Grid>
      <Grid item xs={12} lg={3} sx={{ mb: 3 }}>
        <Box sx={{ p: 3, mt: '68px', borderRadius: 2, backgroundColor: 'grey.100', width: '100%' }}>
          <Typography variant="h2" sx={{ mb: 3 }}>
            Alpha Release Notes
          </Typography>
          <Stack spacing={1}>
            <Typography variant="body2">Welcome to the Alpha!</Typography>
            <Typography variant="body2">
              If you are seeing this message you are part of a select group of early users. We want your feedback so
              please get in touch here if you have any thoughts!
            </Typography>
            <Typography variant="body2">Current Development Priorities</Typography>
            <Typography variant="body2">Testing leaderboards for top users in each community.</Typography>
            <Typography variant="body2">Testing leaderboards for top users in each artist.</Typography>
            <Typography variant="body2">Testing limited time beat battle event.</Typography>
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
};

export default CommunitiesPage;
