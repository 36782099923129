import React, { useEffect, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import CommunitiesSectionComponent from '../../components/CommunitiesSectionComponent/CommunitiesSectionComponent';
import { useParams } from 'react-router-dom';
import { getCommunitiesByArtist } from '../../store/actions/communitiesActions';
import { useDispatch } from 'react-redux';
import { createFromCommunity, ICommunityComponent } from '../../interfaces/ui-components/slider-community.interface';
import routePaths from '../../lib/routePaths';
import { SxProps } from '@mui/system';
import Box from '@mui/material/Box';

interface PropsType {
  sx?: SxProps;
}

interface RouteParams {
  artistId: string;
}

const CommunitiesFeaturingMusic: React.FC<PropsType> = ({ sx }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { artistId } = useParams<RouteParams>();
  const [communityComponentList, setCommunityComponentList] = useState<ICommunityComponent[] | null>();

  useEffect(() => {
    dispatch(
      getCommunitiesByArtist({ id: artistId, take: 5 }, (communities) => {
        const list: ICommunityComponent[] = communities.map(createFromCommunity);
        setCommunityComponentList(list);
      })
    );
  }, []);

  const handleViewAllCommunities = () => {
    history.push(generatePath(routePaths.private.communitiesFeaturing, { artistId }));
  };

  return (
    <>
      {communityComponentList && communityComponentList.length > 0 && (
        <Box sx={sx}>
          <CommunitiesSectionComponent
            onViewAll={handleViewAllCommunities}
            title="Communities featuring your music"
            communities={communityComponentList}
          />
        </Box>
      )}
    </>
  );
};

export default CommunitiesFeaturingMusic;
