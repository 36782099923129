export enum Z_INDEXES {
  VIDEO_SELECTOR = 1,
  AVATAR_BUTTON_COMPONENT = 1,

  BOTTOM_BAR_PLAYER = 9999,
  BOTTOM_BAR_PLAYER__CONTROL_SECTION__VOLUME_SLIDER = 1,

  GENERAL_BAR_COMPONENT_PROFILE_DROPDOWN = 0,

  RADIO_PLAYER__COMMUNITY_BAR = 999,
  RADIO_PLAYER__REACTION_BUTTON_COMPONENT = 999,
  RADIO_PLAYER__REACTION_BUTTON_COMPONENT_CONTAINER = 99,
  RADIO_PLAYER__COMMENT_BUTTON_COMPONENT = 999,
  RADIO_PLAYER__COMMENT_BUTTON_COMPONENT_CONTAINER = 99,
  RADIO_PLAYER__COMMENT_BOX = 20,
  RADIO_PLAYER__REACTION_BOX = 20,
  RADIO_PLAYER__COVER_SECTION = 998,
  RADIO_PLAYER__VOLUME_COMPONENT__SLIDER = 99999,

  LOGIN_PAGE__FOOTER_BAR = 1,
  LOGIN_PAGE__LOGO_ICON = 1,
  LOGIN_PAGE__LINES_DRAWN = 0,
  LOGIN_PAGE__RIGHT_SIDE = 1
}
