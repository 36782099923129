import React from 'react';
import { Stack, Typography, Button } from '@mui/material';
import { SxProps } from '@mui/system';

interface PropsType {
  title: string;
  onViewAll?: () => void;
  rightComponent?: JSX.Element;
  viewAllButtonText?: string;
  sx?: SxProps;
}

const SectionTitleComponent: React.FC<PropsType> = ({ title, onViewAll, rightComponent, viewAllButtonText, sx }) => {
  return (
    <Stack sx={sx} direction="row" alignItems="center" justifyContent="space-between">
      <Stack direction="row" alignItems="center" spacing={3}>
        <Typography variant="h2">{title}</Typography>
        {onViewAll && (
          <Button color="primary" onClick={onViewAll}>
            {viewAllButtonText ?? 'View all'}
          </Button>
        )}
      </Stack>
      {rightComponent && rightComponent}
    </Stack>
  );
};

export default SectionTitleComponent;
