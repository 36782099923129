/* eslint-disable @typescript-eslint/no-unused-vars */
import { useTheme } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import useWindowSize from '../../../lib/hooks/useWindowSize';
import { RESOURCE_STATUS } from '../../../shared/constants/resource.const';
import { APP_SECTION } from '../../../shared/constants/upload.const';
import { useSelector } from '../../../store';
import { setGeneralValue } from '../../../store/actions/generalActions';
import { getSongsByIdList } from '../../../store/actions/songActions';
import FloatingTemplate from '../../../templates/Desktop/FloatingTemplate';
import { useEchoPlayer } from '../../EchoPlayer/EchoPlayerProvider';
import SongDetailBodySection from './SongDetailBodySection/SongDetailBodySection';

interface RouteParams {
  songId: string;
  autoPlay?: string;
}

const SongDetailPlayerComponent: React.FC = () => {
  const dispatch = useDispatch();
  const player = useEchoPlayer();
  const size = useWindowSize();
  const { songId: songIdParam, autoPlay } = useParams<RouteParams>();
  const songId = parseInt(songIdParam);
  const theme = useTheme();
  const radioPlayerContainerBodyRef = useRef<any>();
  const videoRef = useRef<any>();
  const [videoUrl, setVideoUrl] = useState<string>();
  const { songDetail } = useSelector((state) => state.general);

  // const [loading, setLoading] = useState(true);
  // console.log('SongDetailPlayerComponent()');

  useEffect(() => {
    (async () => {
      if (player.data.radio.playing || player.data.radio.loading) {
        await player.controls.radio.stop();
      } else if ((player.data.song.playing || player.data.song.loading) && player.data.song.song?.id !== songId) {
        player.controls.song.pause();
      }
      if (songDetail?.id !== songId) {
        dispatch(setGeneralValue('songDetail', undefined));
        // setLoading(false);
        const idList = [songId];
        dispatch(
          getSongsByIdList({ idList }, (songs) => {
            if (songs && songs.length > 0) {
              dispatch(setGeneralValue('songDetail', songs[0]));
            }
          })
        );
      } else {
        // todo: play from the beginning
        autoPlay === 'autoPlay' && player.controls.song.play();
      }
    })().then();
  }, []);

  useEffect(() => {
    if (songDetail && songDetail.id === songId) {
      autoPlay === 'autoPlay' && player.controls.song.play(songDetail);
      const songResources = songDetail.resources ?? [];
      const videoResource = songResources.find(
        (r) => r.appSection === APP_SECTION.SONG_VIDEO && r.status === RESOURCE_STATUS.AVAILABLE
      );
      setVideoUrl(videoResource?.url);
    }
  }, [songDetail]);

  useEffect(() => {
    videoRef?.current?.load();
  }, [videoUrl]);

  // if (!loading) {
  //   return <Loading />;
  // }

  return (
    <div
      style={{
        height: size.containerHeight + 8 - 52,
        backgroundColor: theme.palette.grey[800],
        paddingBottom: theme.spacing(3)
      }}
    >
      <div
        ref={radioPlayerContainerBodyRef}
        style={{
          width: '100%',
          // filter: 'brightness(50%)',
          height: size.containerHeight - 44
        }}
      >
        {videoUrl && (
          <video
            ref={videoRef}
            className="videoTag"
            autoPlay
            loop
            muted
            style={{
              objectFit: 'cover',
              width: 'inherit',
              height: size.containerHeight - 44
            }}
          >
            <source src={videoUrl} type="video/mp4" />
          </video>
        )}
      </div>

      <FloatingTemplate yInherit inheritHeight inheritWidth anchorRef={radioPlayerContainerBodyRef}>
        <SongDetailBodySection song={songDetail} />
      </FloatingTemplate>
    </div>
  );
};

export default SongDetailPlayerComponent;
