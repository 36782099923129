import { IonContent, IonPage } from '@ionic/react';
import { Box } from '@mui/material';
import React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import BottomBarPlayerComponent from '../../components/BottomBarPlayerComponent/BottomBarPlayer';
import { useEchoPlayer } from '../../components/EchoPlayer/EchoPlayerProvider';
import GeneralBarComponent from '../../components/GeneralBarComponent/GeneralBarComponent';
import SongUploadListProgressComponent from '../../components/SongUploadListProgressComponent/SongUploadListProgressComponent';
import useWindowSize from '../../lib/hooks/useWindowSize';
import routePaths from '../../lib/routePaths';
import FullViewTemplate from './FullViewTemplate';
import SplitViewTemplate from './SplitViewTemplate';
import ChatProvider from '../../components/ChatAndCurationComponent/ChatComponent/ChatProvider';

const DesktopTemplate: React.FC = () => {
  const player = useEchoPlayer();
  const size = useWindowSize();
  const containerXPadding = `${size.containerXPadding}px`;
  const showBottomBarPlayer =
    !useRouteMatch([
      routePaths.private.curation.top,
      routePaths.private.curation.new,
      routePaths.private.radio,
      routePaths.private.chat,
      routePaths.private.songDetail
    ]) && player.data.current.sourceType !== undefined;
  return (
    <IonPage>
      <IonContent fullscreen>
        <ChatProvider>
          <SongUploadListProgressComponent />
          <GeneralBarComponent sx={{ px: containerXPadding }} />
          <Box
            style={showBottomBarPlayer ? { overflowY: 'scroll', height: window.innerHeight - 51 - 48 } : {}}
            sx={{ px: containerXPadding }}
          >
            <Route path={routePaths.private.app}>
              <FullViewTemplate />
            </Route>
            <Route
              path={[
                routePaths.private.curation.top,
                routePaths.private.curation.new,
                routePaths.private.chat,
                routePaths.private.songDetail
              ]}
            >
              <SplitViewTemplate />
            </Route>
          </Box>
          {showBottomBarPlayer && <BottomBarPlayerComponent sx={{ px: containerXPadding }} />}
        </ChatProvider>
      </IonContent>
    </IonPage>
  );
};

export default DesktopTemplate;
