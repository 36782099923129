/* eslint-disable no-param-reassign */
import omit from 'lodash/omit';
import { IMainState } from '../../interfaces/store/main.interface';
import { IReduceAction } from '../../interfaces/store/root.interface';
import { ACTIONS } from '../../enums/actions';

export const initialMainState: IMainState = {
  songs: {},
  songCommunities: {},
  roles: {},
  users: {},
  artists: {},
  communities: {},
  userCommunities: {},
  chatBotUsers: {},
  songUploadResults: {}
};

export default (state: IMainState = initialMainState, action: IReduceAction): IMainState => {
  if (action && (action.normalized || action.removeKeys)) {
    if (action.normalized) {
      Object.keys(action.normalized.entities).forEach((key) => {
        state[key] = {
          ...state[key],
          ...action?.normalized?.entities[key]
        };
      });
    }

    if (action.removeKeys) {
      for (let i = 0; i < action.removeKeys.length; i += 1) {
        state[action.removeKeys[i].entities] = omit(state[action.removeKeys[i].entities], action.removeKeys[i].ids);
      }
    }

    return { ...state };
  }

  switch (action.type) {
    case ACTIONS.LOGOUT_SUCCESS:
      return { ...state, initialMainState };
    case ACTIONS.SET_MAIN_VALUE:
      return {
        ...state,
        [(action as any).data.prop]: (action as any).data.value
      };

    default:
      return state;
  }
};
