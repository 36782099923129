import { statusErrors, stringErrors } from './errors';
import { PAGINATION_ITEMS_TO_TAKE } from '../shared/constants/pagination.const';
import { IPaginationInfo } from '../shared/interfaces/custom/pagination-info.interface';
import { APP_SECTION_IMAGE } from '../shared/constants/upload.const';
import { IMAGE_SIZES } from '../shared/interfaces/custom/image-preset-settings.interface';
import { IUser } from '../shared/interfaces/models/user.interface';
import { generatePath } from 'react-router-dom';
import routePaths from '../lib/routePaths';
import { IResource } from '../shared/interfaces/models/resource.interface';
import { RESOURCE_STATUS } from '../shared/constants/resource.const';
import { ISong } from '../shared/interfaces/models/song.interface';

export function GetFriendlyStatusError(error: number): string {
  if (statusErrors[error]) {
    return statusErrors[error];
  }
  return stringErrors.UNKNOWN_ERROR;
}

export function GetFriendlyError(error: string): string {
  if (stringErrors[error]) {
    return stringErrors[error];
  }
  return stringErrors.UNKNOWN_ERROR;
}

export function generateFakeSongList(songCount: number, imageSize = 40): any[] {
  let upvoteCount = songCount;
  return [...Array(songCount)].map((e, i) => {
    const id = i + 1;
    return {
      id,
      rank: id,
      imageUrl: `https://loremflickr.com/${imageSize}/${imageSize}?lock=${id}`,
      name: 'Night Cafe',
      artist: 'KLM',
      upvoteCount: upvoteCount-- * 12,
      streams: upvoteCount-- * 18,
      audioUrl: `http://example.echodrop.io/${id}`,
      soldNfts: i,
      totalNfts: i % 3 === 0 ? 0 : 100,
      isPlaying: i === 1
    };
  });
}

export const rankColors = ['warning.300', 'warning.900', 'error.300', 'error.900'];

export function isNew(createdAt?: Date, daysThreshold = 3): boolean {
  if (!createdAt) {
    return false;
  }
  const millisDiff = new Date().getTime() - createdAt.getTime();
  return millisDiff / 1000 / 60 / 60 / 24 < daysThreshold;
}

export function getPaginationSkip(pageNumber: number, pagination?: IPaginationInfo) {
  return (pageNumber - 1) * (pagination?.take || PAGINATION_ITEMS_TO_TAKE);
}

export function getRandomNumber(min = 0, max = 1000) {
  return Math.floor(Math.random() * (max - min)) + min;
}

export function fullTreeObjectToArray(obj: any, array?: any[]): any[] {
  array = array ?? [];
  for (const k in obj) {
    if (typeof obj[k] == 'object' && obj[k] !== null) {
      fullTreeObjectToArray(obj[k], array);
    } else {
      array.push(obj[k]);
    }
  }
  return array;
}

export function getProfileImage({
  user,
  size = IMAGE_SIZES.S
}: {
  user?: IUser;
  size?: IMAGE_SIZES;
}): string | undefined {
  return user?.resources?.find((r) => r.appSection === APP_SECTION_IMAGE.user_profile && r.data.size === size)?.url;
}

export function getProfileUrl(userId?: number): string {
  return userId ? generatePath(routePaths.private.artistDashboard.main, { artistId: userId }) : '#';
}

export function mbToBytes(bytes: number): number {
  return bytes * 1024 * 1024;
}

export function songHasAllResourcesAvailables(song?: ISong): boolean {
  if (!song || !song.resources || song.resources.length === 0) {
    // console.log('songHasAllResourcesAvailables: no song or no resources');
    return false;
  }
  // console.log('songHasAllResourcesAvailables: no song or no resources');
  return !song.resources.some((r: IResource) => r.status !== RESOURCE_STATUS.AVAILABLE) ?? false;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
// export function track(eventName: string, data?: any) {
//   const customProps: any = {};
//   if (data) {
//     data.songId !== undefined && (customProps['echo_user_id'] = data.userId);
//     data.songId !== undefined && (customProps['song_id'] = data.songId);
//     data.communityId !== undefined && (customProps['community_id'] = data.communityId);
//     data.boostWeight !== undefined && (customProps['boost_weight'] = data.boostWeight);
//     data.artistId !== undefined && (customProps['artist_Id'] = data.artistId);
//     data.songTime !== undefined && (customProps['song_time'] = data.songTime);
//   }
//   console.log({ customProps });
//   if (eventName) {
//     window.gtag('event', eventName, {
//       // eslint-disable-next-line camelcase
//       event_label: eventName.replaceAll('_', ' '),
//       ...customProps
//     });
//   }
// }

export function analyticsEvent(eventName: string, data?: { category?: string; label?: string; value?: number }) {
  if (eventName) {
    window.gtag('event', eventName, {
      // eslint-disable-next-line camelcase
      event_category: data?.category,
      // eslint-disable-next-line camelcase
      event_label: data?.label,
      value: data?.value
    });
  }
}
// export function analyticsPage(page: string, title: string, search?: string) {
//   search && ReactGA.set({ search });
//   ReactGA.send({ hitType: 'pageview', page, title });
// }
