import { Avatar, Box, CircularProgress, CircularProgressProps, Skeleton, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { APP_SECTION } from '../../shared/constants/upload.const';
import { IUploadSong } from '../../shared/interfaces/custom/upload-song.interface';
import { queue } from '../../store/actions/queueActions';
import { uploadResourceFile } from '../../store/actions/resourceActions';

interface PropsType {
  readyToUpload: boolean | undefined;
  uploadSong?: IUploadSong;
  onSuccess?: () => void;
  onFail?: () => void;
}

function CircularProgressWithLabel(props: CircularProgressProps & { value: number }) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const ImageFileUploadProgress: React.FC<PropsType> = ({ readyToUpload, uploadSong, onSuccess, onFail }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [imagePercents, setImagePercents] = useState(0);

  useEffect(() => {
    if (readyToUpload && uploadSong && uploadSong.song && uploadSong.song.id) {
      uploadImageFileAsResource(uploadSong);
    }
  }, [uploadSong, readyToUpload]);

  const uploadImageFileAsResource = (uploadSong: IUploadSong) => {
    if (uploadSong.imageFile) {
      dispatch(
        queue(
          uploadResourceFile(
            {
              file: uploadSong.imageFile,
              appSection: APP_SECTION.SONG_COVER,
              name: uploadSong.imageFile.name,
              associatedId: uploadSong.song?.id
            },
            onSuccess,
            onFail,
            (percent) => {
              setImagePercents(percent);
            }
          )
        )
      );
    } else {
      onSuccess && onSuccess();
    }
  };

  if (!uploadSong || !readyToUpload) {
    return <Skeleton variant="circular" width={40} height={40} />;
  }

  return (
    <>
      {imagePercents === 100 ? (
        <Avatar
          sx={{ width: 40, height: 40, border: `3px solid ${theme.palette.primary.main}`, mt: '-5px' }}
          src={uploadSong.imageUrl}
        />
      ) : (
        <CircularProgressWithLabel value={imagePercents} />
      )}
    </>
  );
};

export default ImageFileUploadProgress;
