import IosShareIcon from '@mui/icons-material/IosShare';
import { Button, IconButton, Stack, SxProps, Typography } from '@mui/material';
import React from 'react';
import { IUser } from '../../shared/interfaces/models/user.interface';
import ProfileAvatarComponent from '../ProfileAvatarComponent/ProfileAvatarComponent';

interface PropType {
  title?: string;
  subtitle?: string;
  user: IUser;
  infoList?: {
    name: string;
    value: string;
  }[];
  buttons?: {
    text: string;
    onClick: () => void;
  }[];
  onAvatarClick?: () => void;
  onShareButtonClick?: () => void;
  sx?: SxProps;
  imageUrl?: string;
  imageLoading?: boolean;
}

const UserInfo: React.FC<PropType> = ({ sx, title, subtitle, infoList, buttons, user, onShareButtonClick }) => {
  // const userImg = `https://loremflickr.com/128/128?lock=123`;
  return (
    <Stack sx={sx} direction="column" spacing={2}>
      <Stack sx={{ pr: 1, py: 1 }} direction="row" spacing={4} alignItems="center">
        <ProfileAvatarComponent userId={user.id} resources={user.resources} />
        <Stack direction="column" alignItems="flex-start" spacing={1}>
          <Typography variant="caption">{subtitle}</Typography>
          <Typography variant="h3">
            {title}
            {onShareButtonClick && (
              <IconButton sx={{ ml: 1 }} onClick={onShareButtonClick}>
                <IosShareIcon />
              </IconButton>
            )}
          </Typography>
          {infoList &&
            infoList.map((info) => (
              <Typography key={info.name} variant="body2">
                <strong>{info.name}:</strong> {info.value}
              </Typography>
            ))}
        </Stack>
      </Stack>
      {buttons && (
        <Stack sx={{ pr: 1, py: 1 }} direction="row" spacing={3} alignItems="center">
          {buttons.map((button) => (
            <Button key={button.text} onClick={button.onClick} variant="contained">
              {button.text}
            </Button>
          ))}
        </Stack>
      )}
    </Stack>
  );
};

export default UserInfo;
