import React from 'react';
import useIsOwner from '../../lib/hooks/useIsOwner';

interface PropType {
  children: any;
  userId?: number;
}

const ShowIfOwner: React.FC<PropType> = ({ children, userId }) => {
  const isOwner = useIsOwner(userId);

  if (isOwner) {
    return <>{children}</>;
  } else {
    return null;
  }
};

export default ShowIfOwner;
