const communitiesNames = [
  'Tapeland Chill',
  'Laaaarge name testing hehe mmmm',
  'Web3 Stars',
  'Imagero`s ho',
  'Deep house',
  'Study and Chill',
  'Instropection',
  'Whatever',
  'Anything',
  'Oh yeah',
  'One more time',
  'Success',
  'Failure'
];

export const generateFakeCommunity = (index = 0, name = 'Tapeland Chill', imageSize = 200) => {
  return {
    id: index + 1,
    name,
    rankLevel: index % 4,
    isNew: index % 4 === 0,
    imgUrl: `https://loremflickr.com/${imageSize}/${imageSize}?lock=${index}`
  };
};

export function generateFakeSongList(songCount: number, imageSize = 40): any[] {
  let upvoteCount = songCount;
  return [...Array(songCount)].map((e, i) => {
    const id = i + 1;
    return {
      id,
      rank: id,
      imageUrl: `https://loremflickr.com/${imageSize}/${imageSize}?lock=${id}`,
      name: 'Night Cafe',
      artist: 'KLM',
      upvoteCount: upvoteCount-- * 12,
      streams: upvoteCount-- * 18,
      audioUrl: `http://example.echodrop.io/${id}`,
      soldNfts: i,
      totalNfts: i % 3 === 0 ? 0 : 100,
      isPlaying: i === 1
    };
  });
}

export function generateFakeCommunities(imageSize = 200): any[] {
  return communitiesNames.map((name, i) => {
    return generateFakeCommunity(i, name, imageSize);
  });
}
