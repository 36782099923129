/* eslint-disable @typescript-eslint/no-unused-vars */
import LoadingButton from '@mui/lab/LoadingButton';
import { Stack, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { object, ref, string } from 'yup';
import routePaths from '../../lib/routePaths';
import { resetPassword } from '../../store/actions/authActions';
import { setToasterMessage } from '../../store/actions/generalActions';

const ResetPasswordForm: React.FC = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const { token } = useParams<{ token: string }>();
  const history = useHistory();

  const requestPasswordResetValidationSchema = object().shape({
    password: string().required().min(6).max(30),
    confirmPassword: string()
      .required()
      .oneOf([ref('password'), null], 'Passwords must match')
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: requestPasswordResetValidationSchema,
    onSubmit: () => {
      setError(undefined);
      setLoading(true);
      dispatch(
        resetPassword(
          { password: formik.values.password, token },
          () => {
            setLoading(false);
            history.replace(routePaths.public.login);
            dispatch(setToasterMessage('You password has been reset. Please login with your new password.'));
          },
          () => {
            history.replace(routePaths.public.login);
            setLoading(false);
          }
        )
      );
    }
  });

  const handleSubmit = () => {
    console.log('logging here');
    formik.handleSubmit();
  };

  return (
    <>
      <Stack sx={{ mt: 6 }} spacing={2}>
        <TextField
          onBlur={() => setError(undefined)}
          disabled={loading}
          onChange={formik.handleChange}
          error={!!formik.errors.password}
          helperText={formik.errors.password ? formik.errors.password : undefined}
          name="password"
          type="password"
          label="Password"
          variant="outlined"
          fullWidth
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              handleSubmit();
            }
          }}
        />
        <TextField
          onBlur={() => setError(undefined)}
          disabled={loading}
          onChange={formik.handleChange}
          error={!!formik.errors.confirmPassword}
          helperText={formik.errors.confirmPassword ? formik.errors.confirmPassword : undefined}
          name="confirmPassword"
          type="password"
          label="Confirm Password"
          variant="outlined"
          fullWidth
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              handleSubmit();
            }
          }}
        />
      </Stack>

      <LoadingButton
        loading={loading}
        onClick={handleSubmit}
        variant="contained"
        color="primary"
        size="large"
        sx={{ mt: 4, width: '100%', borderRadius: 10 }}
      >
        RESET
      </LoadingButton>
      {error && (
        <Typography variant="body2" sx={{ mt: 2, color: 'red' }}>
          {error}
        </Typography>
      )}
    </>
  );
};

export default ResetPasswordForm;
