import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/typography.css';
import { Provider } from 'react-redux';
import { CssBaseline } from '@mui/material';
import ColorModeContext from './theme/theme-context';
import AppContainer from './AppContainer';
import { ProvideAuth } from './components/Authentication/ProvideAuth';
import initializeReduxStorage from './store';
import './theme/tailwind.css';
/* Theme variables */
import './theme/variables.css';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useEffect, useMemo, useState } from 'react';
import generateTheme from './theme/mui-theme';
import { ThemeProvider } from '@mui/material/styles';
import { EchoPlayerProvider } from './components/EchoPlayer/EchoPlayerProvider';
import { gTagInitConfig } from './lib/hooks/useAnalytics';
// import ReactGA from 'react-ga4';
//
// // eslint-disable-next-line camelcase
// ReactGA.initialize('G-LECLSWH1PF', { gtagOptions: { debug_mode: true } });

setupIonicReact();
const store = initializeReduxStorage();

const App: React.FC = () => {
  gTagInitConfig();
  const [mode, setMode] = useState('light');

  // useEffect(() => {
  //
  // }, []);

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      }
    }),
    []
  );
  const theme = useMemo(() => generateTheme(mode), [mode]);
  return (
    <IonApp style={{ backgroundColor: 'white' }}>
      <Provider store={store}>
        <EchoPlayerProvider>
          <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <IonReactRouter>
                <IonRouterOutlet>
                  <ProvideAuth>
                    <AppContainer />
                  </ProvideAuth>
                </IonRouterOutlet>
              </IonReactRouter>
            </ThemeProvider>
          </ColorModeContext.Provider>
        </EchoPlayerProvider>
      </Provider>
    </IonApp>
  );
};

export default App;
