export const zeroPad = (num: any, places: any) => String(num).padStart(places, '0');

//'America/Chicago'
// export const getTimeZonedParts = (dt: Date, timezone: string) => {
// 	return {
// 		yyyy: Number(formatInTimeZone(dt, timezone, 'yyyy')),
// 		MM: Number(formatInTimeZone(dt, timezone, 'MM')),
// 		dd: Number(formatInTimeZone(dt, timezone, 'dd')),
// 		HH: Number(formatInTimeZone(dt, timezone, 'HH')),
// 		mm: Number(formatInTimeZone(dt, timezone, 'mm'))
// 	}
// }

export const getUTCParts = (dt: Date) => {
  return {
    yyyy: dt.getUTCFullYear(),
    m: dt.getUTCMonth(),
    dd: dt.getUTCDate(),
    hh: dt.getUTCHours(),
    mm: dt.getUTCMinutes()
  };
};

export const dateFormatted = (date: any, format: string, seperator = '/') => {
  try {
    const d = new Date(date),
      year = d.getFullYear(),
      hour = d.getHours(),
      minute = d.getMinutes(),
      second = d.getSeconds();

    let month = '' + (d.getMonth() + 1),
      day = '' + d.getDate();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    switch (format) {
      case 'yyyymmdd':
        return [year, month, day].join(seperator);
      case 'yyyymmddhh':
        return [year, month, day].join(seperator) + ' ' + zeroPad(hour, 2) + ':00:00';
      case 'yyyymmddhhmmss':
        return (
          [year, month, day].join(seperator) +
          ' ' +
          zeroPad(hour, 2) +
          ':' +
          zeroPad(minute, 2) +
          ':' +
          zeroPad(second, 2)
        );
      case 'mmddyyyy':
      default:
        return [month, day, year].join(seperator);
    }
  } catch (e) {
    return '';
  }
};

export const mmddyyyySort = (a: any, b: any) => {
  if (a && b) {
    const aDate = new Date(a);
    const bDate = new Date(b);
    return aDate.getTime() - bDate.getTime();
  } else {
    return 0;
  }
};

export const dateFmt = (dateStr: string | undefined) => {
  try {
    if (!dateStr) {
      return '';
    }
    const dt = new Date(dateStr);
    dt.setMilliseconds(0);
    return dt.toISOString().replace(/T/, ' ').replace(/.000Z/, '');
  } catch (e) {
    return '';
  }
};

export const isValidDate = (date: any) => {
  return new Date(date).toString() !== 'Invalid Date';
};

export function secondsToTimeFormat(seconds: number) {
  const hrs = ~~(seconds / 3600);
  const mins = ~~((seconds % 3600) / 60);
  const secs = ~~seconds % 60;

  let ret = '';

  if (hrs > 0) {
    ret += '' + hrs + ':' + (mins < 10 ? '0' : '');
  }

  ret += '' + mins + ':' + (secs < 10 ? '0' : '');
  ret += '' + secs;
  return ret;
}

// export function timeSince(fromDate?: Date | string) {
//   if (!fromDate) {
//     return '';
//   }
//   const date = new Date(fromDate);
//   const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);
//   let interval = seconds / 31536000;
//   if (interval > 1) {
//     return Math.floor(interval) + ' years';
//   }
//   interval = seconds / 2592000;
//   if (interval > 1) {
//     return Math.floor(interval) + ' months';
//   }
//   interval = seconds / 86400;
//   if (interval > 1) {
//     return Math.floor(interval) + ' days';
//   }
//   interval = seconds / 3600;
//   if (interval > 1) {
//     return Math.floor(interval) + ' hours';
//   }
//   interval = seconds / 60;
//   if (interval > 1) {
//     return Math.floor(interval) + ' minutes';
//   }
//   return Math.floor(seconds) + ' seconds';
// }

export function timeSince(time?: Date | number | string) {
  switch (typeof time) {
    case 'number':
      break;
    case 'string':
      time = +new Date(time);
      break;
    case 'object':
      if (time.constructor === Date) time = time.getTime();
      break;
    default:
      time = +new Date();
  }
  const millis = time as number;
  const timeFormats = [
    [60, 'seconds', 1], // 60
    [120, '1 minute ago', '1 minute from now'], // 60*2
    [3600, 'minutes', 60], // 60*60, 60
    [7200, '1 hour ago', '1 hour from now'], // 60*60*2
    [86400, 'hours', 3600], // 60*60*24, 60*60
    [172800, 'Yesterday', 'Tomorrow'], // 60*60*24*2
    [604800, 'days', 86400], // 60*60*24*7, 60*60*24
    [1209600, 'Last week', 'Next week'], // 60*60*24*7*4*2
    [2419200, 'weeks', 604800], // 60*60*24*7*4, 60*60*24*7
    [4838400, 'Last month', 'Next month'], // 60*60*24*7*4*2
    [29030400, 'months', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
    [58060800, 'Last year', 'Next year'], // 60*60*24*7*4*12*2
    [2903040000, 'years', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
    [5806080000, 'Last century', 'Next century'], // 60*60*24*7*4*12*100*2
    [58060800000, 'centuries', 2903040000] // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
  ];
  let seconds = (+new Date() - millis) / 1000;
  let token = 'ago';
  let listChoice = 1;

  if (seconds == 0) {
    return 'Just now';
  }
  if (seconds < 0) {
    seconds = Math.abs(seconds);
    token = 'from now';
    listChoice = 2;
  }
  for (const format of timeFormats) {
    if (seconds < format[0]) {
      if (typeof format[2] == 'string') return format[listChoice].toString();
      else return (Math.floor(seconds / format[2]) + ' ' + format[1] + ' ' + token).toString();
    }
  }
  return time.toString();
}
