import { normalize } from 'normalizr';
import { eventChannel } from 'redux-saga';
import { call, fork, put, take } from 'redux-saga/effects';
import { ACTIONS } from '../../enums/actions';
import { HttpResponse } from '../../interfaces/api/http.interface';
import { IDispatchAction } from '../../interfaces/store/root.interface';
import HttpClient from '../../lib/HttpClient';
import { SHARED_ACTIONS } from '../../shared/constants/actions.const';
import { APP_SECTION } from '../../shared/constants/upload.const';
import { DeleteSongPayloadInterface } from '../../shared/interfaces/custom/delete-song-payload.interface';
import { IArtist } from '../../shared/interfaces/models/artist.interface';
import { normalizedAction } from '../actions/generalActions';
import { artistSchema } from './schema';
import { responseHandler } from './socketSaga';

export function artistSubscribe(socket: any) {
  return eventChannel((emit) => {
    socket.on('artist/get-by-id-success', (data: IArtist) => {
      emit(normalizedAction(normalize(data, artistSchema)));
      emit({ type: ACTIONS.GET_ARTIST_BY_ID_SUCCESS, payload: data });
    });

    socket.on('artist/update-success', (data: { artist: IArtist; appSection: APP_SECTION }) => {
      emit(normalizedAction(normalize(data.artist, artistSchema)));
      emit({ type: SHARED_ACTIONS.ARTIST_UPDATED_SUCCESS, payload: data });
    });

    socket.on('artist/update-error', (data: { artist: IArtist; appSection: APP_SECTION }) => {
      emit(normalizedAction(normalize(data.artist, artistSchema)));
      emit({ type: SHARED_ACTIONS.ARTIST_UPDATED_ERROR, payload: data });
    });

    socket.on(SHARED_ACTIONS.CREATE_ARTIST_REQUEST_SUCCESS, (successResult: boolean) => {
      emit({ type: SHARED_ACTIONS.CREATE_ARTIST_REQUEST_SUCCESS, payload: successResult });
    });

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  });
}

const client = new HttpClient('artist');
async function deleteSongApi(data: DeleteSongPayloadInterface): Promise<HttpResponse<boolean>> {
  return await client.post('delete-song', data);
}

const artistSaga = {
  *getArtistById(emitSocketMessage: any): Generator {
    while (true) {
      const action = (yield take(ACTIONS.GET_ARTIST_BY_ID)) as IDispatchAction;
      emitSocketMessage('artist/get-by-id', action.payload);
      yield fork(responseHandler, ACTIONS.GET_ARTIST_BY_ID_SUCCESS, action.onSuccess, action.onFail);
    }
  },
  *updateArtist(emitSocketMessage: any): Generator {
    while (true) {
      const action = (yield take(ACTIONS.UPDATE_ARTIST)) as IDispatchAction<IArtist>;
      emitSocketMessage('artist/update', action.payload);
    }
  },
  *updateArtistBio(emitSocketMessage: any): Generator {
    while (true) {
      const action = (yield take(ACTIONS.UPDATE_ARTIST_BIO)) as IDispatchAction<IArtist>;
      emitSocketMessage('artist/update-bio', action.payload);
    }
  },

  *createArtistRequest(emitSocketMessage: any): Generator {
    while (true) {
      const action = (yield take(SHARED_ACTIONS.CREATE_ARTIST_REQUEST)) as IDispatchAction<IArtist>;
      emitSocketMessage(SHARED_ACTIONS.CREATE_ARTIST_REQUEST, action.payload);
      yield fork(responseHandler, SHARED_ACTIONS.CREATE_ARTIST_REQUEST_SUCCESS, action.onSuccess, action.onFail);
    }
  },

  *deleteSong(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as DeleteSongPayloadInterface;
      const response = (yield call(deleteSongApi, payload)) as HttpResponse<boolean>;
      if ((response.status === 200 || response.status === 201) && response.data) {
        if (action.onSuccess) {
          action.onSuccess(payload.songId);
          yield put({ type: SHARED_ACTIONS.DELETE_SONG_SUCCESS, data: payload.songId });
        }
      } else if (!response.data) {
        alert('This song could not be deleted. Please contact with an admin.');
        action.onFail && action.onFail('Error deleting song');
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }
};

export default artistSaga;
