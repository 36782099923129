import { all, put } from 'redux-saga/effects';
import { ACTIONS } from '../../enums/actions';

const generalSaga = {
  *getInitialData(): Generator {
    yield all([put({ type: ACTIONS.GET_ME }), put({ type: ACTIONS.GET_USER_RESOURCES })]);
  }
};

export default generalSaga;
