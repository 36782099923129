import { Box, Button, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { LIMITS } from '../../enums/uploads';
import { mbToBytes } from '../../utils/general';

interface IProps {
  onAudioFilesSelected: (audioFiles: File[]) => void;
  onCancel: () => void;
}

const DropZoneComponent: React.FC<IProps> = ({ onAudioFilesSelected, onCancel }) => {
  // TODO: Enable back multiple files support when uploading songs.
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
    accept: { 'audio/mp3': ['.mp3'] },
    multiple: false,
    maxSize: mbToBytes(LIMITS.SONGS.MAX_SIZE_MB)
  });
  const inputFileProfile = React.useRef<any>(null);
  const [dragOver, setDragOver] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | undefined>(undefined);

  useEffect(() => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      onAudioFilesSelected(acceptedFiles);
    }
  }, [acceptedFiles]);

  useEffect(() => {
    if (fileRejections && fileRejections.length > 0) {
      setError(`Only MP3 files are allowed. Max size is ${LIMITS.SONGS.MAX_SIZE_MB} MB.`);
    }
  }, [fileRejections]);

  const handleCancel = (e: any) => {
    e.stopPropagation();
    onCancel();
  };

  return (
    <Box
      {...getRootProps({
        className: 'dropzone',
        onDragOver: () => setDragOver(true),
        onDragLeave: () => setDragOver(false)
      })}
      sx={{
        height: '100%',
        borderRadius: '8px',
        border: dragOver ? '5px dashed #000000' : '3px dashed #D1D1D1',
        // background: dragOver ? '#c6c6c6' : '#fff',
        background: '#fff',
        margin: '0 auto',
        textAlign: 'center',
        m: 4,
        opacity: dragOver ? 0.5 : 1
      }}
    >
      <section className="container">
        <div>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center mt-12"
            sx={{ fontWeight: '500', fontSize: '16px', lineHeight: '16px', marginTop: '48px' }}
          >
            Drag and drop your song here
          </Typography>
        </div>
      </section>
      <input ref={inputFileProfile} className="hidden" {...getInputProps()} />
      <Button
        variant="contained"
        color="primary"
        sx={{ borderRadius: 4, py: 2, mt: 2, width: '317px', mx: 6 }}
        onClick={() => inputFileProfile?.current?.click()}
      >
        Or choose a file to upload
      </Button>

      <Typography sx={{ color: 'red', mt: 1 }}>{error}</Typography>

      <Typography variant="h6" sx={{ fontWeight: '400', fontSize: '12px', lineHeight: '16px', mt: 2 }}>
        Allowed format: {LIMITS.SONGS.EXTENSIONS_ALLOWED.join(', ').toUpperCase()}.
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: '400', fontSize: '12px', lineHeight: '16px', mt: 1 }}>
        Max file size is {LIMITS.SONGS.MAX_SIZE_MB} MB.
      </Typography>

      <Button
        sx={{ fontWeight: '700', fontSize: '12px', lineHeight: '16px', marginTop: '32px' }}
        onClick={handleCancel}
      >
        Cancel
      </Button>
    </Box>
  );
};

export default DropZoneComponent;
