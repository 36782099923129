import React from 'react';
import { Card, Typography, CardContent, Stack, Box } from '@mui/material';
import { useSelector } from '../../store';
import Loading from '../Loading/Loading';
import { timeSince } from '../../utils/date-utils';

const SongDetailStats: React.FC = () => {
  const { songDetail } = useSelector((state) => state.general);

  if (!songDetail) {
    return <Loading />;
  }

  return (
    <>
      <Box sx={{ mx: 2, height: 240, maxHeight: 240 }}>
        <Typography variant="h4" sx={{ color: 'black', my: 1 }}>
          <strong>Song Stats</strong>
        </Typography>

        <Stack spacing={2}>
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <Card sx={{ width: '100%', maxHeight: 87 }}>
              <CardContent>
                <Typography variant="h5">
                  <strong>Published</strong>
                </Typography>
                <Typography color="primary" variant="h6">
                  {timeSince(songDetail.createdAt)}
                </Typography>
              </CardContent>
            </Card>

            <Card sx={{ width: '100%', maxHeight: 87 }}>
              <CardContent>
                <Typography variant="h5">
                  <strong>Genre</strong>
                </Typography>
                <Typography color="primary" variant="h6">
                  {songDetail.genre}
                </Typography>
              </CardContent>
            </Card>
          </Stack>

          <Card sx={{ width: '100%', maxHeight: 87 }}>
            <CardContent>
              <Typography variant="h5">
                <strong>Description</strong>
              </Typography>
              <Typography noWrap color="primary" variant="body1">
                {songDetail.description}
              </Typography>
            </CardContent>
          </Card>
        </Stack>
      </Box>
      <Typography sx={{ ml: 2, mb: 1, color: 'black' }} variant="h4">
        <strong>Comments</strong>
      </Typography>
    </>
  );
};

export default SongDetailStats;
