import { Stack, Button, Paper, TextField, useMediaQuery, useTheme } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { saveSongComment } from '../../store/actions/songActions';
import FloatingTemplate from '../../templates/Desktop/FloatingTemplate';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { ISongComment } from '../../shared/interfaces/models/song-comment.interface';
import { Z_INDEXES } from '../../enums/zIndexes';
import { useEchoPlayer } from '../EchoPlayer/EchoPlayerProvider';
import OutsideAlerter from '../ClickOutsideHider/ClickOutsideHider';
import useAnalytics from '../../lib/hooks/useAnalytics';

interface PropType {
  communityId?: number;
}

const WriteCommentButtonComponent: React.FC<PropType> = ({ communityId }) => {
  const { track } = useAnalytics();
  const theme = useTheme();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const downLg = useMediaQuery(theme.breakpoints.down('lg'));
  const player = useEchoPlayer();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState('');
  const buttonRef = useRef<any>(null);
  const boxHeight = 112;
  const buttonWidth = 200;

  const handleSendMessage = () => {
    if (player.data.current.playing && message !== '') {
      const songComment = {
        message,
        songId: player.data.current.song?.id,
        communityId,
        songTime: player.controls.current.getCurrentTime()
      } as ISongComment;
      dispatch(
        saveSongComment(songComment, () => {
          track('song_comment', songComment);
          setMessage('');
          setShow(false);
        })
      );
    }
  };

  return (
    <div style={{ zIndex: Z_INDEXES.RADIO_PLAYER__COMMENT_BUTTON_COMPONENT }}>
      <Button
        startIcon={<ChatBubbleOutlineIcon />}
        sx={{
          zIndex: Z_INDEXES.RADIO_PLAYER__COMMENT_BUTTON_COMPONENT,
          color: show ? 'black' : 'white',
          width: buttonWidth
        }}
        color="cwhite"
        variant={show ? 'contained' : 'outlined'}
        ref={buttonRef}
        onClick={(e) => {
          if (e.pageX || e.pageY || e.screenX || e.screenY) {
            setShow((oldState) => (!oldState ? true : oldState));
          }
        }}
      >
        Write a comment
      </Button>

      {show && (
        <FloatingTemplate
          style={{ zIndex: Z_INDEXES.RADIO_PLAYER__COMMENT_BUTTON_COMPONENT_CONTAINER, cursor: 'default' }}
          anchorRef={buttonRef}
          yOffset={-boxHeight - 12}
          useAnchorAsContainer
        >
          <Stack direction="row" justifyContent="center" sx={{ width: downLg ? buttonWidth : buttonWidth * 2 + 8 }}>
            <OutsideAlerter
              onClickOutside={() => {
                setTimeout(() => setShow(false), 100);
              }}
            >
              <Paper
                sx={{
                  p: 1,
                  borderRadius: '8px',
                  backgroundColor: 'rgba(250, 250, 250, 0.7)',
                  width: 322,
                  height: boxHeight
                }}
              >
                <TextField
                  sx={{
                    mb: 1,
                    '& .MuiInputBase-root': {
                      backgroundColor: 'white'
                    }
                  }}
                  autoFocus
                  onChange={(e) => setMessage(e.target.value)}
                  value={message}
                  fullWidth
                  name="comment"
                  placeholder="Type a song comment..."
                  variant="outlined"
                  onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                      console.log('enter key pressed');
                      handleSendMessage();
                    }
                  }}
                />
                <Stack direction="row" justifyContent="flex-end">
                  <Button onClick={handleSendMessage} variant="contained" color="secondary">
                    Send
                  </Button>
                </Stack>
              </Paper>
            </OutsideAlerter>
          </Stack>
        </FloatingTemplate>
      )}
    </div>
  );
};

export default WriteCommentButtonComponent;
