import { useEffect, useState } from 'react';
import { useSelector } from '../../store';
import { useParams } from 'react-router-dom';

export default function useIsOwner(userId?: number): boolean | undefined {
  const { currentUser } = useSelector((state) => state.general);
  const [entityId, setEntityId] = useState<number | undefined>();

  const params = useParams<{ artistId: string | undefined; userId: string | undefined }>();
  useEffect(() => {
    let paramNumberId: number | undefined = parseInt(params.artistId ?? params.userId ?? '-1');
    paramNumberId = paramNumberId === -1 ? undefined : paramNumberId;
    setEntityId(userId ?? paramNumberId ?? undefined);
  }, [userId, params, currentUser]);

  if (!currentUser || !entityId) return undefined;
  return currentUser.id === entityId;
}
