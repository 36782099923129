import { LinearProgress, linearProgressClasses, Skeleton, Stack, styled, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { APP_SECTION } from '../../shared/constants/upload.const';
import { IUploadSong } from '../../shared/interfaces/custom/upload-song.interface';
import { queue } from '../../store/actions/queueActions';
import { uploadResourceFile } from '../../store/actions/resourceActions';

interface PropsType {
  readyToUpload: boolean | undefined;
  uploadSong?: IUploadSong;
  onSuccess?: () => void;
  onFail?: () => void;
}

const AudioFileUploadProgress: React.FC<PropsType> = ({ readyToUpload, uploadSong, onSuccess, onFail }) => {
  const dispatch = useDispatch();
  const [uploadPercent, setUploadPercent] = useState(0);

  useEffect(() => {
    if (readyToUpload && uploadSong && uploadSong.song && uploadSong.song.id) {
      uploadAudioFileAsResource(uploadSong);
    }
  }, [uploadSong, readyToUpload]);

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    width: '100%',
    height: 16,
    borderRadius: 18,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 18,
      backgroundColor: theme.palette.primary.main
    }
  }));

  const uploadAudioFileAsResource = (uploadSong: IUploadSong) => {
    dispatch(
      queue(
        uploadResourceFile(
          {
            file: uploadSong.audioFile,
            appSection: APP_SECTION.ARTIST_SONG,
            name: uploadSong.audioFile.name,
            associatedId: uploadSong.song?.id
          },
          onSuccess,
          onFail,
          (percent) => {
            setUploadPercent(percent);
          }
        )
      )
    );
  };

  if (!uploadSong || !readyToUpload) {
    return <Skeleton variant="text" />;
  }

  return (
    <Stack justifyContent="center">
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h6">{uploadSong.song?.name}</Typography>
        <Typography variant="caption">{uploadPercent}% completed</Typography>
      </Stack>
      <BorderLinearProgress variant="determinate" value={uploadPercent} />
    </Stack>
  );
};

export default AudioFileUploadProgress;
