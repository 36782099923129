import React from 'react';
import { Chip } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';

interface PropType {
  variant: any;
  message: any;
  severity?: any;
}

const Status: React.FC<PropType> = ({ variant, message, severity }) => {
  let icon = undefined;

  if (variant === 'screen') {
    if (severity === 'success') {
      icon = <CheckCircleIcon />;
    }
    if (severity === 'info') {
      icon = <InfoIcon />;
    }
    if (severity === 'error') {
      icon = <ErrorIcon />;
    }
    if (severity === 'warning') {
      icon = <WarningIcon />;
    }
  }

  return (
    <Chip
      size={variant === 'screen' ? 'small' : 'medium'}
      icon={icon}
      variant={variant}
      label={message}
      color={severity}
    />
  );
};

export default Status;
