/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  Link as MuiLink,
  Stack,
  Typography
} from '@mui/material';
import { Link } from 'react-router-dom';
import { Z_INDEXES } from '../../enums/zIndexes';
import useWindowSize from '../../lib/hooks/useWindowSize';
import routePaths from '../../lib/routePaths';
import FooterBar from './FooterBar';
import LeftSide from './LeftSide';
import LoginForm from './LoginForm';
import { useSelector } from '../../store';
import CloseIcon from '@mui/icons-material/Close';

const MailchimpForm: React.FC = () => {
  const [email, setEmail] = useState('');
  return (
    <div id="mc_embed_signup">
      <form
        action="https://xyz.us21.list-manage.com/subscribe/post?u=48cf888d1be6ffadaf962d44f&amp;id=7c96ba61d4&amp;f_id=008189e1f0"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        className="validate"
        target="_blank"
        noValidate
      >
        <div id="mc_embed_signup_scroll">
          <h2>Join the waitlist. We will email you when public registration is open again!</h2>
          <div className="indicates-required">
            <span className="asterisk">*</span> indicates required
          </div>
          <div className="mc-field-group">
            {/*@ts-ignore*/}
            <label for="mce-EMAIL">
              Email Address <span className="asterisk">*</span>
            </label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              name="EMAIL"
              className="required email"
              id="mce-EMAIL"
              required
            />
            <span id="mce-EMAIL-HELPERTEXT" className="helper_text"></span>
          </div>
          <div id="mce-responses" className="clear foot">
            <div className="response" id="mce-error-response" style={{ display: 'none' }}></div>
            <div className="response" id="mce-success-response" style={{ display: 'none' }}></div>
          </div>
          <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
            <input type="text" name="b_48cf888d1be6ffadaf962d44f_7c96ba61d4" tabIndex={-1} value="" />
          </div>
          <div className="optionalParent">
            <div className="clear foot">
              <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button" />
              <p className="brandingLogo">
                <a href="http://eepurl.com/imqrJU" title="Mailchimp - email marketing made easy and fun">
                  <img src="https://eep.io/mc-cdn-images/template_images/branding_logo_text_dark_dtp.svg" />
                </a>
              </p>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

const RegisterPage: React.FC = () => {
  const { appSettings } = useSelector((state) => state.general);
  const size = useWindowSize();
  const [showMailchimpForm, setShowMailchimpForm] = useState(false);

  useEffect(() => {
    document.title = `Echodrop | Login`;
  }, []);

  if (!size.height) {
    return <CircularProgress />;
  }

  // const alphaWarningText =
  //   'Echo Drop is in Alpha and currently not supported on mobile! ' +
  //   'We will be continuously fixing things and you will see new features ' +
  //   'popping up every day or two! Alpha release is limited to the first 1000 users who sign up.';

  const alphaWarningText =
    'ECHO drop is in Alpha and invites can be sent by existing members only! ' +
    'If you would like to be put on our waitlist for the next round of public invites sign up ';

  const handleOpenMailchimpForm = () => {
    setShowMailchimpForm(true);
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={6} sx={{ backgroundColor: 'black', width: '100%', height: size.height }}>
          <LeftSide />
        </Grid>
        <Grid
          item
          xs={6}
          sx={{ width: '100%', px: 10, py: 2, zIndex: Z_INDEXES.LOGIN_PAGE__RIGHT_SIDE, backgroundColor: 'white' }}
        >
          <Stack justifyContent="center" sx={{ height: '100%', marginTop: '-56px', maxWidth: '500px' }}>
            <Typography variant="h1">Sign in</Typography>
            <Typography sx={{ mt: 1 }} variant="body1">
              Enter your account
            </Typography>
            <LoginForm />
            <Stack direction="row" justifyContent="center">
              <Typography sx={{ mt: 4 }}>
                {appSettings?.registerEnabled && (
                  <>
                    {'No account yet? '}
                    <Link component={MuiLink} to={routePaths.public.register}>
                      Register
                    </Link>
                  </>
                )}
              </Typography>
            </Stack>
            {appSettings?.userInvitationEnabled && (
              <Typography align="justify" color="error" variant="caption" sx={{ mt: 2 }}>
                <>
                  {alphaWarningText}
                  <MuiLink sx={{ cursor: 'pointer' }} onClick={handleOpenMailchimpForm}>
                    here
                  </MuiLink>
                  .
                </>
              </Typography>
            )}
          </Stack>
        </Grid>
      </Grid>
      <FooterBar />
      <Dialog fullWidth onClose={() => setShowMailchimpForm(false)} open={showMailchimpForm}>
        <Box sx={{ p: 1, width: '100%' }}>
          <Stack direction="row" justifyContent="flex-end">
            <IconButton color="inherit" onClick={() => setShowMailchimpForm(false)}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <MailchimpForm />
        </Box>
      </Dialog>
    </div>
  );
};

export default RegisterPage;
