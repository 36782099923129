import React from 'react';
import { Stack } from '@mui/material';

import SongHorizontalList from './SongHorizontalList';
import SectionTitleComponent from '../SectionTitleComponent/SectionTitleComponent';

interface PropsType {
  title: string;
  songList?: any[];
  onPlaySong?: (songId: number) => void;
}

const ExclusiveEditionsCollectionComponent: React.FC<PropsType> = ({ title, songList, onPlaySong }) => {
  const handleViewAll = () => {
    // eslint-disable-next-line no-console
    console.log('viewAll implementation...');
  };
  return (
    <Stack spacing={3}>
      <SectionTitleComponent title={title} onViewAll={handleViewAll} />
      {songList && <SongHorizontalList songList={songList} onPlaySong={onPlaySong} />}
    </Stack>
  );
};

export default ExclusiveEditionsCollectionComponent;
