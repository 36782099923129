import { fork, takeLatest } from 'redux-saga/effects';
import artistSaga from '../sagas/artistSaga';
import { SHARED_ACTIONS } from '../../shared/constants/actions.const';

export default function* (socket: any) {
  yield fork(artistSaga.getArtistById, socket);
  yield fork(artistSaga.updateArtist, socket);
  yield fork(artistSaga.updateArtistBio, socket);
  yield fork(artistSaga.createArtistRequest, socket);
  yield takeLatest(SHARED_ACTIONS.DELETE_SONG, artistSaga.deleteSong);
}
