/* eslint-disable */
import { IonLoading } from '@ionic/react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from '../../store';
import { useAuth } from './ProvideAuth';

interface IPrivateRoute {
  children: any;
  [key: string]: any;
}

const PrivateRoute: React.FC<IPrivateRoute> = ({ children, ...rest }) => {
  const auth = useAuth();
  const { socketInitialized } = useSelector((state) => state.general);

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (auth.isAuthenticated === true) {
          if (socketInitialized) {
            return children;
          }
          return <IonLoading isOpen />;
        } else if (auth.isAuthenticated === false) {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location }
              }}
            />
          );
        }
      }}
    />
  );
};

export default PrivateRoute;
