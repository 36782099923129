import { fork } from 'redux-saga/effects';
import communitiesSaga from '../sagas/communitiesSaga';

export default function* (socket: any) {
  yield fork(communitiesSaga.getCommunities, socket);
  yield fork(communitiesSaga.getCommunityById, socket);
  yield fork(communitiesSaga.getUserCommunitiesByUserId, socket);
  yield fork(communitiesSaga.getCommunitiesByArtist, socket);
  yield fork(communitiesSaga.joinToCommunityEvents, socket);
  yield fork(communitiesSaga.leaveCommunityEvents, socket);
  yield fork(communitiesSaga.getLowestLevel, socket);
  yield fork(communitiesSaga.getTopUsersFromCommunity, socket);
}
