import React from 'react';
import SectionTitleComponent from '../../components/SectionTitleComponent/SectionTitleComponent';
import { Stack, Pagination } from '@mui/material';
import { useHistory } from 'react-router';
import { SxProps } from '@mui/system';
import SongBaseTable from '../SongBaseTableComponent/SongBaseTable';
import { ISongComponent } from '../../interfaces/ui-components/song.interface';
import { getPaginationSkip } from '../../utils/general';
import { IPaginationInfo } from '../../shared/interfaces/custom/pagination-info.interface';

interface PropsType {
  title?: string;
  songComponentList?: ISongComponent[];
  setSongComponentList: (songList: ISongComponent[]) => void;
  pagination?: IPaginationInfo;
  fetchPage?: (skip?: number, take?: number) => void;
  colsToShow?: string[];
  sx?: SxProps;
}

const ViewAllSongsComponent: React.FC<PropsType> = ({
  title = 'Songs',
  fetchPage,
  pagination,
  songComponentList,
  setSongComponentList,
  colsToShow,
  sx
}) => {
  const history = useHistory();

  const handlePageChange = (event: React.ChangeEvent<unknown>, pageNumber: number) => {
    if (fetchPage) {
      const skip = getPaginationSkip(pageNumber, pagination);
      fetchPage(skip);
      history.push({ pathname: `${history.location.pathname}`, search: `?skip=${skip}` });
    }
  };

  return (
    <Stack direction="column" sx={{ p: 5, ...sx }}>
      <SectionTitleComponent sx={{ mb: 5 }} title={title} />
      {songComponentList && (
        <SongBaseTable setSongList={setSongComponentList} songList={songComponentList} colsToShow={colsToShow} />
      )}
      {fetchPage && pagination && pagination.pages > 1 && (
        <Stack justifyContent="center" alignItems="center">
          <Pagination count={pagination.pages} page={pagination.pageNumber} onChange={handlePageChange} />
        </Stack>
      )}
    </Stack>
  );
};

export default ViewAllSongsComponent;
