/* eslint-disable @typescript-eslint/no-unused-vars */
import data from '@emoji-mart/data';
import CloseIcon from '@mui/icons-material/Close';
import {
  Avatar,
  Badge,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Link as MuiLink,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { init } from 'emoji-mart';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, generatePath } from 'react-router-dom';
import routePaths from '../../lib/routePaths';
import { IEmojiCount, ISupporter } from '../../shared/interfaces/custom/supporter.interface';
import { ILevel } from '../../shared/interfaces/models/level.interface';
import { IUser } from '../../shared/interfaces/models/user.interface';
import { getTopSupportersForArtist } from '../../store/actions/userActions';
import { getProfileImage } from '../../utils/general';

init({ data });

interface PropsType {
  user: IUser;
  open: boolean;
  setOpen: (open: boolean) => void;
}

const SupportersDialog: React.FC<PropsType> = ({ user, open, setOpen }) => {
  const dispatch = useDispatch();
  const [topSupporters, setTopSupporters] = React.useState<ISupporter[]>([]);
  const [defaultLevel, setDefaultLevel] = React.useState<ILevel>();

  useEffect(() => {
    if (user && user.id) {
      dispatch(getTopSupportersForArtist({ id: user.id }, setTopSupporters));
    }
  }, [user]);

  return (
    <Dialog maxWidth="lg" fullWidth onClose={() => setOpen(false)} open={open}>
      <DialogTitle>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Avatar sx={{ width: 24, height: 24 }} src={getProfileImage({ user: user })} />
          <Typography variant="h2">
            <strong>{user.username}</strong>
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ overflow: 'hidden', p: 4 }}>
        <Typography variant="h5" sx={{ mb: 1, mt: 2 }}>
          <strong>Top Supporters</strong>
        </Typography>
        <Paper sx={{ maxHeight: '300px' }}>
          <TableContainer sx={{ maxHeight: 'inherit', overflowY: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 700 }}>Rank</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>User</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>Reactions</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>Comments</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>Common Reactions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {topSupporters.map((supporter: ISupporter, i) => {
                  return supporter ? (
                    <TableRow key={supporter.id}>
                      <TableCell>#{i + 1}</TableCell>
                      <TableCell>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          {/* <Avatar src={getProfileImage({ user: supporter.user })} sx={{ width: 24, height: 24 }} /> */}
                          <MuiLink 
                            component={Link} 
                            to={generatePath(routePaths.private.artistDashboard.main, { artistId: supporter.id })}>
                            {supporter.username}
                          </MuiLink>
                        </Stack>
                      </TableCell>
                      <TableCell>{supporter.reactionsCount}</TableCell>
                      <TableCell>{supporter.commentsCount}</TableCell>
                      <TableCell>
                        <Stack direction="row" spacing={2}>
                          {supporter.topEmojis.map((emoji: IEmojiCount) => (
                            <Badge badgeContent={emoji.count} color="secondary">
                              {/* @ts-ignore */}
                              <em-emoji size={24} shortcodes={emoji.emojiColons}></em-emoji>
                            </Badge>
                          ))}
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ) : null;
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </DialogContent>
      <IconButton sx={{ position: 'absolute', right: 1, top: 1, m: 1 }} onClick={() => setOpen(false)}>
        <CloseIcon sx={{ color: 'black' }} />
        {/*<Avatar sx={{ bgcolor: 'white' }}>*/}
        {/*</Avatar>*/}
      </IconButton>
    </Dialog>
  );
};

export default SupportersDialog;
