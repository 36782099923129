import { normalize } from 'normalizr';
import { eventChannel } from 'redux-saga';
import { call, delay, put, race, take } from 'redux-saga/effects';
import { ACTIONS } from '../../enums/actions';
import { CompletedPart } from '../../interfaces/requests/aws.interface';
import { ICreateUploadResourcePayload, IResourceFileUploadPayload } from '../../interfaces/requests/resource.interface';
import { ICreateUploadResourceResponse } from '../../interfaces/responses/resource.interface';
import { IDispatchAction } from '../../interfaces/store/root.interface';
// import { APP_SECTION } from '../../shared/constants/upload.const';
import { IMAGE_SIZES } from '../../shared/interfaces/custom/image-preset-settings.interface';
import { IResource } from '../../shared/interfaces/models/resource.interface';
import { getFileExtension, getFileParts, getFileType } from '../../shared/utils/file';
import {
  completeMultipartUpload,
  completeSinglepartUpload,
  initPresignedUrlMultipartUpload,
  initPresignedUrlSimpleUpload
} from '../actions/awsActions';
import { normalizedAction } from '../actions/generalActions';
import { createUploadResource } from '../actions/resourceActions';
import awsSaga from './awsSaga';
import { resourceSchema } from './schema';
import { APP_SECTION } from '../../shared/constants/upload.const';

export function resourceSubscribe(socket: any) {
  return eventChannel((emit) => {
    socket.on('resource/create-upload-success', (data: ICreateUploadResourceResponse) => {
      emit({ type: ACTIONS.CREATE_UPLOAD_RESOURCE_SUCCESS, payload: data });
    });
    socket.on('resource/file-uploaded-success', (data: { resource: IResource }) => {
      emit(normalizedAction(normalize(data.resource, resourceSchema)));
    });
    socket.on('resource/file-uploaded-error', (data: { resource: IResource }) => {
      emit(normalizedAction(normalize(data.resource, resourceSchema)));
    });
    socket.on('resource/get-user-resources-success', (data: IResource[]) => {
      emit(normalizedAction(normalize(data, [resourceSchema])));
    });
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  });
}

const resourceSaga = {
  *getUserResources(emitSocketMessage: any): Generator {
    while (true) {
      yield take(ACTIONS.GET_USER_RESOURCES);
      emitSocketMessage('resource/get-user-resources');
    }
  },

  *createUploadResource(emitSocketMessage: any): Generator {
    while (true) {
      const action = (yield take(ACTIONS.CREATE_UPLOAD_RESOURCE)) as IDispatchAction<ICreateUploadResourcePayload>;
      emitSocketMessage('resource/create-upload', action.payload);

      // Here we wait until the one of the folloing things happen: successfull response, error or timeout
      const { timeout, error } = (yield race({
        error: take(ACTIONS.SOCKET_MESSAGE_ERROR),
        response: take(ACTIONS.CREATE_UPLOAD_RESOURCE_SUCCESS),
        timeout: delay(5000)
      })) as {
        response: IDispatchAction<ICreateUploadResourceResponse>;
        timeout: boolean;
        error: IDispatchAction<unknown>;
      };

      if (timeout) {
        if (action.onFail) {
          action.onFail();
        }
      } else if (error) {
        if (action.onFail) {
          action.onFail();
        }
        yield put({ type: ACTIONS.ERROR, data: error });
      } else {
        if (action.onSuccess) {
          action.onSuccess();
        }
      }
    }
  },
  *uploadResourceFile(action: IDispatchAction<IResourceFileUploadPayload>): Generator {
    if (!action.payload) {
      throw new Error('Payload cannot be empty');
    }

    const parts = getFileParts(action.payload.file);

    // Creating resource.
    yield put(
      createUploadResource({
        name: action.payload.name,
        appSection: action.payload.appSection,
        associatedId: action.payload.associatedId,
        extension: getFileExtension(action.payload.file.name),
        type: getFileType(action.payload.file.name),
        data: { size: IMAGE_SIZES.ORIGINAL },
        parts
      })
    );
    const responseAction = (yield take(
      ACTIONS.CREATE_UPLOAD_RESOURCE_SUCCESS
    )) as IDispatchAction<ICreateUploadResourceResponse>;
    const urls = responseAction.payload?.urls;
    const resources = responseAction.payload?.resources;

    // We can upload the file now.
    if (
      parts > 1 &&
      action.payload.appSection !== APP_SECTION.VIDEO_PROFILE &&
      action.payload.appSection !== APP_SECTION.SONG_VIDEO
    ) {
      const multiPartUploadResponse = yield call(
        awsSaga.initPresignedUrlMultipartUpload,
        initPresignedUrlMultipartUpload(
          { file: action.payload.file, urls: urls as string[] },
          action.onSuccess,
          action.onFail,
          action.onProgress
        )
      );

      yield put(
        completeMultipartUpload({
          key: responseAction.payload?.key ?? '',
          uploadId: responseAction.payload?.uploadId ?? '',
          parts: multiPartUploadResponse as CompletedPart[],
          resources: resources as IResource[]
        })
      );
    } else {
      yield call(
        awsSaga.initPresignedUrlSimpleUpload,
        initPresignedUrlSimpleUpload(
          { file: action.payload.file, url: urls as string },
          action.onSuccess,
          action.onFail,
          action.onProgress
        )
      );
      yield put(
        completeSinglepartUpload({
          resources: resources as IResource[]
        })
      );
    }
  }
};

export default resourceSaga;
