/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useRef } from 'react';

import { Stack } from '@mui/material';
import { Z_INDEXES } from '../../enums/zIndexes';

const LeftSide: React.FC = () => {
  const videoUrl = 'https://echodrop-production.s3.us-west-2.amazonaws.com/public/echo_icon-moshed-03-18-01-28-24.mp4';
  return (
    <>
      <Stack justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
        <video
          autoPlay
          loop
          muted
          style={{
            objectFit: 'cover',
            width: '50%',
            zIndex: Z_INDEXES.LOGIN_PAGE__LOGO_ICON
          }}
        >
          <source src={videoUrl} type="video/mp4" />
        </video>
      </Stack>
    </>
  );
};

export default LeftSide;
