import { Add } from '@mui/icons-material';
import { Button, Dialog, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import AddNewSongsComponent from '../../components/AddNewSongsComponent/AddNewSongsComponent';
import SectionTitleComponent from '../../components/SectionTitleComponent/SectionTitleComponent';
import ShowIfOwner from '../../components/ShowIfOwner/ShowIfOwner';
import SongBaseTable from '../../components/SongBaseTableComponent/SongBaseTable';
import { IRootState } from '../../interfaces/store/root.interface';
import { createFromISong, ISongComponent } from '../../interfaces/ui-components/song.interface';
import routePaths from '../../lib/routePaths';
import { ISong } from '../../shared/interfaces/models/song.interface';
import { getSongsByArtist } from '../../store/actions/songActions';
import { useSelector } from '../../store';
import useIsOwner from '../../lib/hooks/useIsOwner';
import { USER_ROLE } from '../../shared/constants/user.const';
import useAnalytics from '../../lib/hooks/useAnalytics';

interface PropsType {
  title: string;
  sx?: SxProps;
}

interface RouteParams {
  artistId: string;
}

const UploadedSongsSection: React.FC<PropsType> = ({ title, sx }) => {
  const { track } = useAnalytics();
  const history = useHistory();
  const dispatch = useDispatch();
  const { artistId } = useParams<RouteParams>();
  const [songList, setSongList] = useState<ISongComponent[] | null>();
  const { uploadModalState, pagination, currentUser } = useSelector((state: IRootState) => state.general);
  const [showUploadSongModal, setShowUploadSongModal] = useState(false);
  const isOwner = useIsOwner();

  const songDeleted = useSelector((state) => state.general.songDeleted);

  useEffect(() => {
    if (songList) {
      setSongList(
        songList.filter((song) => {
          if (song.id) {
            !songDeleted.includes(song.id);
          }
        })
      );
    }
  }, [songDeleted]);

  const handleViewAll = () => {
    history.push(generatePath(routePaths.private.uploadedMusic, { artistId }));
  };

  const colsToShow = useMemo(() => {
    const cols = ['song', 'listen'];
    (isOwner || currentUser?.role === USER_ROLE.SYSADMIN) && cols.push('delete');
    return cols;
  }, [isOwner, currentUser]);

  useEffect(() => {
    if (!uploadModalState) {
      dispatch(getSongsByArtist({ id: artistId }, onReceiveResponse));
    }
  }, [uploadModalState]);

  useEffect(() => {
    dispatch(getSongsByArtist({ id: artistId }, onReceiveResponse));
  }, [songDeleted]);

  const onReceiveResponse = (responseData: ISong[]) => {
    if (responseData) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const list: ISongComponent[] = responseData.map((song) => createFromISong({ song }));
      setSongList(list);
    }
  };

  const handleNewSongClick = () => {
    setShowUploadSongModal(true);
    track('new_song_button_click', {
      artistId
    });
  };

  const uploadSongButton = (
    <>
      <ShowIfOwner>
        <Button startIcon={<Add />} variant="contained" onClick={handleNewSongClick}>
          New Song
        </Button>
      </ShowIfOwner>
    </>
  );

  const colMapping = {
    song: { headerText: 'Title', songPropertyName: 'name' },
    listen: { headerText: 'Listen', songPropertyName: null },
    delete: { headerText: 'Delete', songPropertyName: null }
  };

  const showViewAllButton = songList && (pagination.songs?.total ?? 0) !== songList.length;

  return (
    <>
      {songList && (
        <Stack sx={sx} spacing={3}>
          <SectionTitleComponent
            title={title}
            onViewAll={showViewAllButton ? handleViewAll : undefined}
            rightComponent={uploadSongButton}
          />
          {songList.length > 0 ? (
            <SongBaseTable
              setSongList={setSongList}
              colMapping={colMapping}
              coloredHeaderBg
              songList={songList}
              colsToShow={colsToShow}
            />
          ) : (
            <Stack direction="row" justifyContent="center" sx={{ p: 5 }}>
              {currentUser && currentUser.id !== Number(artistId) ? (
                <Typography variant="h5">This artist doesn't have any songs yet</Typography>
              ) : (
                <Typography variant="h5">You haven't uploaded songs yet</Typography>
              )}
            </Stack>
          )}
        </Stack>
      )}
      <Dialog fullWidth onClose={() => setShowUploadSongModal(false)} open={showUploadSongModal}>
        <AddNewSongsComponent
          onFinish={() => {
            setShowUploadSongModal(false);
          }}
        />
      </Dialog>
    </>
  );
};

export default UploadedSongsSection;
