import { Box, Grid } from '@mui/material';
import React from 'react';
import SongDetailCommentSection from '../../components/SongDetail/SongDetailPlayerComponent/SongDetailCommentSection';
import SongDetailPlayerComponent from '../../components/SongDetail/SongDetailPlayerComponent/SongDetailPlayerComponent';
import SongDetailStats from '../../components/SongDetail/SongDetailStats';
import SongDetailTopbar from '../../components/SongDetail/SongDetailTopbar';

const SongDetailPage: React.FC = () => {
  return (
    <div>
      <SongDetailTopbar />
      <Grid container>
        <Grid item xs={6}>
          <SongDetailPlayerComponent />
        </Grid>
        <Grid item xs={6}>
          <SongDetailStats />
          <Box sx={{ mx: 2 }}>
            <SongDetailCommentSection />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default SongDetailPage;
