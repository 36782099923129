// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Link as MuiLink, List, ListItem, ListItemAvatar, Typography, Stack } from '@mui/material';
import { formatDistance } from 'date-fns';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import useWindowSize from '../../../lib/hooks/useWindowSize';
import { ISongComment } from '../../../shared/interfaces/models/song-comment.interface';
import { getSongComments } from '../../../store/actions/songActions';
import { useSelector } from '../../../store';
import { getProfileImage, getProfileUrl } from '../../../utils/general';
import AvatarButton from '../../AvatarButtonComponent/AvatarButton';
import { secondsToTimeFormat } from '../../../utils/date-utils';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';

const PAGE_SIZE = 10;
const PAGE_SIZE_INCREMENT = 10;

const SongDetailCommentSection: React.FC = () => {
  const params = useParams<{ songId: string }>();
  const history = useHistory();

  const { newRealTimeSongComment } = useSelector((state) => state.general);
  const [comments, _setComments] = useState<ISongComment[]>([]);
  const commentsRef = useRef<ISongComment[]>(comments);
  const setComments = (data: ISongComment[]) => {
    commentsRef.current = data;
    _setComments(data);
  };

  const [currentPageIncrement, _setCurrentPageIncrement] = useState(0);
  const currentPageIncrementRef = useRef(currentPageIncrement);
  const setCurrentPageIncrement = (data: number) => {
    currentPageIncrementRef.current = data;
    _setCurrentPageIncrement(data);
  };

  const [commentsHeight, setCommentsHeight] = useState(500);
  const dispatch = useDispatch();
  const size = useWindowSize();
  const listRef = useRef<HTMLDivElement>(null);

  // Dispatch action to get comments when scroll reaches bottom use callback
  const handleScroll = useCallback((e) => {
    if (e.target.scrollTop + e.target.clientHeight === e.target.scrollHeight) {
      dispatch(
        getSongComments(
          {
            songId: params.songId,
            take: PAGE_SIZE,
            skip: currentPageIncrementRef.current + PAGE_SIZE_INCREMENT,
            sort: { createdAt: 'DESC' }
          },
          (response) => {
            setCurrentPageIncrement(currentPageIncrementRef.current + PAGE_SIZE_INCREMENT);
            setComments([...(commentsRef.current ?? []), ...response]);
          }
        )
      );
    }
  }, []);

  useEffect(() => {
    if (newRealTimeSongComment) {
      setComments([newRealTimeSongComment, ...(commentsRef.current ?? [])]);
    }
  }, [newRealTimeSongComment]);

  useEffect(() => {
    // Call handleScroll when scroll reaches bottom
    listRef.current?.addEventListener('scroll', handleScroll);
    return () => {
      listRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    setCommentsHeight(size.containerHeight - 324.69);
  }, [size]);

  const getHumandReadableDate = (dateToFormat: Date) => {
    if (!dateToFormat) return null;
    const referenceDate = new Date();
    return formatDistance(dateToFormat, referenceDate, { addSuffix: true });
  };

  useEffect(() => {
    dispatch(
      getSongComments(
        { songId: params.songId, take: PAGE_SIZE, skip: currentPageIncrement, sort: { createdAt: 'DESC' } },
        (response) => {
          setComments(response);
        }
      )
    );
  }, []);

  return (
    <div ref={listRef} style={{ maxHeight: commentsHeight, overflowY: comments.length > 0 ? 'scroll' : 'inherit' }}>
      {comments?.length === 0 && <Typography variant="h6">No comments yet</Typography>}
      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {comments?.map((comment, index) => {
          return (
            <ListItem key={`comment-${index}`} alignItems="flex-start">
              <ListItemAvatar>
                <AvatarButton
                  sx={{ width: 32, height: 32 }}
                  iconProps={{ fontSize: 'small' }}
                  iconName="visibility"
                  onClick={() => {
                    history.push(getProfileUrl(comment?.user?.id));
                  }}
                  src={getProfileImage({ user: comment.user })}
                />
              </ListItemAvatar>

              <Stack spacing="6px">
                <div>
                  <MuiLink component={Link} to={getProfileUrl(comment?.user?.id)}>
                    {comment.user?.username}
                  </MuiLink>{' '}
                  <Typography component="span" variant="body1">
                    {comment.message}
                  </Typography>
                </div>
                <Stack direction="row" spacing={2}>
                  <Typography variant="caption">
                    <AudiotrackIcon fontSize="inherit" /> {secondsToTimeFormat(comment.songTime)}
                  </Typography>
                  <Typography variant="caption">
                    {getHumandReadableDate(new Date(comment.createdAt ?? new Date()))}
                  </Typography>
                </Stack>
              </Stack>
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};

export default SongDetailCommentSection;
