import React from 'react';

import { generatePath, useHistory } from 'react-router-dom';
import { ICommunityComponent } from '../../interfaces/ui-components/slider-community.interface';
import routePaths from '../../lib/routePaths';
import SectionTitleComponent from '../SectionTitleComponent/SectionTitleComponent';
import CommunitySlider from './CommunitySlider';
import { Box, SxProps } from '@mui/material';

interface PropsType {
  title: string;
  communities?: ICommunityComponent[];
  onPlayCommunity?: (communityId: number) => void;
  onViewAll?: () => void;
  sx?: SxProps;
}

const password = 'cow2023bell';

const CommunitiesSectionComponent: React.FC<PropsType> = ({ title, communities, onPlayCommunity, onViewAll, sx }) => {
  const history = useHistory();
  const handleViewAll = () => {
    if (onViewAll) onViewAll();
  };
  const handleClickCommunity = (communityId: number) => {
    if (onPlayCommunity) {
      onPlayCommunity(communityId);
    } else {
      const communityComponent = communities?.find((c) => c.id === communityId);
      if (communityComponent?.private) {
        const inputPassword = window.prompt('Password');
        if (password === inputPassword) {
          history.push(generatePath(routePaths.private.curation.top, { communityId }));
        } else {
          alert('Incorrect password!');
        }
      } else {
        history.push(generatePath(routePaths.private.curation.top, { communityId }));
      }
    }
  };
  return (
    <Box sx={sx}>
      <SectionTitleComponent title={title} onViewAll={onViewAll ? handleViewAll : undefined} />
      {communities && <CommunitySlider communities={communities} onPlayCommunity={handleClickCommunity} />}
    </Box>
  );
};

export default CommunitiesSectionComponent;
