/* eslint-disable @typescript-eslint/no-empty-function  */
/* eslint-disable @typescript-eslint/no-unused-vars*/

import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  ICurrentInfoTriggers,
  IPlayerData,
  IPlayerDataSetters
} from '../../../interfaces/echo-player/echo-player-info.interface';

export default function useCurrentInfoTriggers(data: IPlayerData, setters: IPlayerDataSetters): ICurrentInfoTriggers {
  const [lastVolume, setLastVolume] = useState(data.current.volume);

  useEffect(() => {
    setters.setCurrentData({ loading: data.radio.loading || data.song.loading });
  }, [data.radio.loading, data.song.loading]);

  const onVolumeChange = (volume: number) => {
    setters.setData((prevData) => ({
      ...prevData,
      current: { ...prevData.current, volume, mute: volume === 0 }
    }));
    setLastVolume(volume);
  };

  const onMuteChange = (mute: boolean) => {
    setters.setData((prevData) => ({
      ...prevData,
      current: {
        ...prevData.current,
        mute,
        volume: mute ? 0 : lastVolume > 0.05 ? lastVolume : 1
      }
    }));
  };

  return { onVolumeChange, onMuteChange };
}
