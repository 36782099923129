/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  InputLabel,
  Stack,
  TextareaAutosize,
  TextField,
  Typography
} from '@mui/material';
import { FormikErrors, useFormik } from 'formik';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { array, object, string } from 'yup';
import { IUploadSong } from '../../shared/interfaces/custom/upload-song.interface';
import { ICommunity } from '../../shared/interfaces/models/community.interface';
import { SxProps } from '@mui/system';
import './SongForm.scss';
import ShowIfOwner from '../ShowIfOwner/ShowIfOwner';
import VideocamIcon from '@mui/icons-material/Videocam';
import { getFileExtension } from '../../shared/utils/file';
import { IMAGE_EXTENSIONS, VIDEO_EXTENSIONS } from '../../shared/constants/file.const';
import AvatarButton from '../AvatarButtonComponent/AvatarButton';
import { LIMITS } from '../../enums/uploads';
import { mbToBytes } from '../../utils/general';
import { useSelector } from '../../store';
import { USER_ROLE } from '../../shared/constants/user.const';

interface IProps {
  uploadSong: IUploadSong;
  onCancel: () => void;
  onBack: () => void;
  onFormFilled: (uploadSong: IUploadSong) => void;
  communities: ICommunity[];
  isLastForm: boolean;
  isFirstForm: boolean;
  sx?: SxProps;
}

const songsValidationSchema = object().shape({
  name: string().required('Name is a required field'),
  genre: string().required('Genre is a required field'),
  imageUrl: string().required('Image is a required field'),
  description: string(),
  communityTags: array()
    .of(object())
    .min(1, 'You have to select at least one community')
    .required('Community tags is a required field'),
  videoUrl: string().required('Video is a required field')
});

const SongForm: React.FC<IProps> = ({
  uploadSong,
  onCancel,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onBack,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onFormFilled,
  communities,
  isLastForm,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isFirstForm,
  sx
}) => {
  const { currentUser } = useSelector((state) => state.general);
  const [errors, setErrors] = useState<FormikErrors<typeof initialValues>>({});
  const videoRef = useRef<any>(null);
  const [hasVideo, setHasVideo] = useState<boolean>(false);
  const inputFileVideo = useRef<any>(null);
  const [videoFile, setVideoFile] = useState<any>(null);
  const filteredCommunities = useMemo(() => {
    if (communities && currentUser) {
      return currentUser.role === USER_ROLE.SYSADMIN
        ? communities
        : communities.filter((community) => community.id !== 8 && community.id !== 3);
    }
    return [];
  }, [communities, currentUser]);

  useEffect(() => {
    if (hasVideo && videoFile) {
      videoRef.current.src = URL.createObjectURL(videoFile);
    }
  }, [hasVideo, videoFile]);

  useEffect(() => {
    formik.resetForm();
  }, [uploadSong]);

  const initialValues = {
    name: uploadSong.song?.name || '',
    genre: uploadSong.song?.genre || '',
    description: uploadSong.song?.description || '',
    // additionalTags: '',
    communityTags: (uploadSong.communityIds
      ? communities.filter((c) => uploadSong.communityIds.some((id) => id === c.id))
      : []) as ICommunity[],
    imageFile: uploadSong.imageFile,
    imageUrl: uploadSong.imageUrl,
    videoFile: uploadSong.videoFile,
    videoUrl: uploadSong.song?.videoUrl || ''
  };

  const inputFile = React.useRef<any>(null);

  const handleCompleted = async () => {
    const validationResult = await formik.validateForm();
    if (Object.values(validationResult).length > 0) {
      setErrors(validationResult);
      return;
    }
    setErrors({});
    uploadSong.song = {
      name: formik.values.name,
      genre: formik.values.genre,
      description: formik.values.description,
      videoUrl: formik.values.videoUrl
    };
    uploadSong.communityIds = formik.values.communityTags.map((c: ICommunity) => c.id);
    uploadSong.videoFile = formik.values.videoFile;
    uploadSong.imageUrl = formik.values.imageUrl;
    uploadSong.imageFile = formik.values.imageFile;
    formik.resetForm();
    onFormFilled(uploadSong);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: songsValidationSchema,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {}
  });

  const handleFileInputChange = (fileSelect: any) => {
    const file = fileSelect.target.files[0];
    if (file) {
      if (file.size > mbToBytes(LIMITS.IMAGES.MAX_SIZE_MB)) {
        alert(`File size cannot exceed more than ${LIMITS.IMAGES.MAX_SIZE_MB}MB`);
        return;
      }

      const extension = getFileExtension(file.name);
      if (!IMAGE_EXTENSIONS.includes(extension)) {
        alert(
          `File type "${extension}" is not supported. Format must be one of the following: ${IMAGE_EXTENSIONS.join(
            ', '
          )}`
        );
        return;
      }

      formik.setFieldValue('imageFile', file);
      formik.setFieldValue('imageUrl', URL.createObjectURL(file));
    }
  };

  const handleFileVideo = async (fileSelect: any) => {
    const file = fileSelect.target.files[0];

    if (file.size > mbToBytes(LIMITS.VIDEOS.MAX_SIZE_MB)) {
      alert(`Video size is too large. Please select a video under ${LIMITS.VIDEOS.MAX_SIZE_MB} MB.`);
      return;
    }

    const extension = getFileExtension(file.name);
    if (!VIDEO_EXTENSIONS.includes(extension)) {
      alert(
        `File type "${extension}" is not supported. Format must be one of the following: ${VIDEO_EXTENSIONS.join(', ')}`
      );
      return;
    }

    setVideoFile(file);
    const videoEl = document.createElement('video');
    videoEl.src = URL.createObjectURL(file);
    videoEl.onloadedmetadata = () => {
      window.URL.revokeObjectURL(videoEl.src);
      const { videoWidth, videoHeight } = videoEl;

      if (videoWidth > LIMITS.VIDEOS.MAX_RESOLUTION.WIDTH || videoHeight > LIMITS.VIDEOS.MAX_RESOLUTION.HEIGHT) {
        alert(
          `Max resolution allowed is: ${LIMITS.VIDEOS.MAX_RESOLUTION.WIDTH}x${LIMITS.VIDEOS.MAX_RESOLUTION.HEIGHT} (vertical video)`
        );
        return;
      }

      formik.setFieldValue('videoFile', file);
      formik.setFieldValue('videoUrl', URL.createObjectURL(file));

      if (videoRef.current) {
        videoRef.current.src = URL.createObjectURL(file);
      }
      setHasVideo(true);
    };
  };

  if (!formik.values) {
    return <CircularProgress />;
  }

  return (
    <Box sx={sx}>
      <Typography color="primary.main" variant="body1" sx={{ mb: 4 }}>
        Basic Info for {uploadSong.audioFile.name}
      </Typography>
      <form>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Stack spacing={2} alignItems="center">
              <div>
                <div>
                  {/*<img*/}
                  {/*  src={!formik.values.imageUrl ? './assets/upload-song.svg' : formik.values.imageUrl}*/}
                  {/*  className="dashboard-artist__profile__image"*/}
                  {/*  onClick={() => inputFile.current.click()}*/}
                  {/*/>*/}

                  <AvatarButton
                    iconName="edit"
                    onClick={() => inputFile.current.click()}
                    src={formik.values.imageUrl}
                    showUploadLimits
                    defaultImageUrl={'assets/default-chat-icon.jpg'}
                    // defaultImageUrl={'./assets/upload-song.svg'}
                  />

                  <input
                    type="file"
                    accept={IMAGE_EXTENSIONS.map((e) => `.${e}`).join(',')}
                    onChange={(e) => handleFileInputChange(e)}
                    ref={inputFile}
                    className="hidden"
                  />
                </div>
                {errors.imageUrl && (
                  <Typography align="left" variant="caption" sx={{ color: 'error.main' }}>
                    {errors.imageUrl}
                  </Typography>
                )}
              </div>

              <div>
                {hasVideo ? (
                  <Grid item marginRight="32px" marginLeft="40px">
                    <Stack component={Button} onClick={() => inputFileVideo.current.click()}>
                      <video ref={videoRef} style={{ minHeight: '311px', minWidth: '160px' }} autoPlay muted loop />
                    </Stack>
                    <ShowIfOwner>
                      <input
                        id="videoInput"
                        type="file"
                        accept={VIDEO_EXTENSIONS.map((e) => `.${e}`).join(',')}
                        ref={inputFileVideo}
                        className="hidden"
                        onChange={(e) => handleFileVideo(e)}
                      />
                    </ShowIfOwner>
                  </Grid>
                ) : (
                  <ShowIfOwner>
                    <Grid item marginRight="32px" marginLeft="40px">
                      <Stack
                        component={Button}
                        onClick={() => inputFileVideo.current.click()}
                        sx={{
                          backgroundColor: '#D9D9D9',
                          '&:hover': {
                            backgroundColor: '#E5E5EA'
                          },
                          borderRadius: '4px',
                          minHeight: '280px',
                          minWidth: '160px'
                        }}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <VideocamIcon />
                      </Stack>
                      <input
                        id="videoInput"
                        type="file"
                        accept={VIDEO_EXTENSIONS.map((e) => `.${e}`).join(',')}
                        ref={inputFileVideo}
                        className="hidden"
                        onChange={(e) => handleFileVideo(e)}
                      />
                    </Grid>
                  </ShowIfOwner>
                )}

                <Stack alignItems="center" justifyContent="center">
                  <Typography variant="caption">Formats: {VIDEO_EXTENSIONS.join(', ').toUpperCase()}</Typography>
                  <Typography variant="caption">
                    {LIMITS.VIDEOS.MAX_RESOLUTION.WIDTH}x{LIMITS.VIDEOS.MAX_RESOLUTION.HEIGHT} max resol.
                  </Typography>
                  <Typography variant="caption">{LIMITS.VIDEOS.MAX_SIZE_MB} MB max</Typography>
                </Stack>
              </div>
              {errors.videoUrl && (
                <Typography align="left" variant="caption" sx={{ color: 'error.main' }}>
                  {errors.videoUrl}
                </Typography>
              )}
            </Stack>
          </Grid>

          <Grid item xs={8}>
            <Stack spacing={1}>
              <div>
                <InputLabel htmlFor="standard-requeired" required sx={{ fontSize: '14px' }}>
                  Title
                </InputLabel>
                <TextField
                  error={!!errors.name}
                  helperText={errors.name}
                  variant="standard"
                  required
                  fullWidth
                  name="name"
                  value={formik.values.name}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                />
              </div>
              <Grid item xs={3} />
              {/* TODO: implement tags in future */}
              {/*<Grid item xs={3}>*/}
              {/*  <InputLabel htmlFor="standard-adornment-amount" sx={{ fontSize: '12px' }}>*/}
              {/*    Additional tags*/}
              {/*  </InputLabel>*/}
              {/*  <Input*/}
              {/*    id="standard-required"*/}
              {/*    multiline*/}
              {/*    maxRows={4}*/}
              {/*    fullWidth*/}
              {/*    name="additionalTags"*/}
              {/*    value={formik.values.additionalTags}*/}
              {/*    onChange={(e) => {*/}
              {/*      formik.handleChange(e);*/}
              {/*    }}*/}
              {/*  />*/}
              {/*</Grid>*/}

              <div>
                <InputLabel htmlFor="standard-requeired" required sx={{ fontSize: '14px' }}>
                  Genre
                </InputLabel>
                <TextField
                  required
                  variant="standard"
                  error={!!errors.genre}
                  helperText={errors.genre}
                  fullWidth
                  name="genre"
                  value={formik.values.genre}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                />
              </div>

              {communities && (
                <div>
                  <InputLabel id="demo-multiple-checkbox-label" required>
                    Communities
                  </InputLabel>
                  <Autocomplete
                    multiple
                    size="small"
                    options={filteredCommunities}
                    getOptionLabel={(option: any) => option.name}
                    defaultValue={[]}
                    value={formik.values.communityTags}
                    onChange={(e, value) => {
                      formik.setFieldValue('communityTags', value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        helperText={errors.communityTags}
                        error={!!errors.communityTags}
                        variant="standard"
                        label=""
                      />
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option: any, index) => (
                        <Chip
                          variant="outlined"
                          label={option.name}
                          size="small"
                          color="primary"
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                  />
                </div>
              )}
              <div>
                <InputLabel htmlFor="standard-adornment-amount" sx={{ marginBottom: '8px', fontSize: '14px' }}>
                  Description
                </InputLabel>
                <TextareaAutosize
                  aria-label="Describe your track"
                  minRows={3}
                  placeholder="Describe your track"
                  name="description"
                  value={formik.values.description}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  className="forms-songs-modal__textarea"
                />
              </div>
            </Stack>
          </Grid>
        </Grid>
      </form>
      <Stack direction="row" spacing={2} sx={{ justifyContent: 'flex-end', marginRight: '14px' }}>
        {/* TODO: enable back button preserving previous data */}
        {/*{!isFirstForm && (*/}
        {/*  <Button*/}
        {/*    onClick={onBack}*/}
        {/*    variant="contained"*/}
        {/*    color="primary"*/}
        {/*    sx={{ borderRadius: '16px', position: 'absolute', left: '25px' }}*/}
        {/*  >*/}
        {/*    Back*/}
        {/*  </Button>*/}
        {/*)}*/}
        <Button color="secondary" sx={{ color: 'violet' }} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={{ borderRadius: '16px' }}
          /*    disabled={!formik.isValid} */
          onClick={() => handleCompleted()}
        >
          {isLastForm ? 'Next' : 'Finish'}
        </Button>
      </Stack>
    </Box>
  );
};

export default SongForm;
