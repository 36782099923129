import {
  AUDIO_EXTENSIONS,
  FILE_CHUNK_SIZE,
  IMAGE_EXTENSIONS,
  VIDEO_EXTENSIONS,
} from "../constants/file.const";
import { RESOURCE_TYPE } from "../constants/resource.const";

export const getFileParts = (file: File): number => {
  const parts = Math.ceil(file.size / FILE_CHUNK_SIZE);
  return parts;
};

export const getFileExtension = (fileName: string): string => {
  const match = fileName.match(/[^\\]*\.(\w+)$/);
  if (!match || match.length < 2 || !match[1]) {
    throw new Error("Not a valid filename");
  }
  return match[1].toLowerCase();
};

export const getFileType = (fileName: string): RESOURCE_TYPE => {
  const fileExtension = getFileExtension(fileName);

  if (AUDIO_EXTENSIONS.indexOf(fileExtension) > -1) {
    return RESOURCE_TYPE.AUDIO;
  }

  if (IMAGE_EXTENSIONS.indexOf(fileExtension) > -1) {
    return RESOURCE_TYPE.IMAGE;
  }

  if (VIDEO_EXTENSIONS.indexOf(fileExtension) > -1) {
    return RESOURCE_TYPE.VIDEO;
  }

  throw new Error("Filetype is not supported");
};
