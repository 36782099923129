import { schema } from 'normalizr';
export const usersSchema = new schema.Entity('users');
export const songsSchema = new schema.Entity('songs');
export const communitiesSchema = new schema.Entity('communities');
export const songCommunitiesSchema = new schema.Entity('songCommunities');
export const songUploadResultsSchema = new schema.Entity('songUploadResults');
// export const songCommunitiesSchema = new schema.Entity('song-communities', {
//   user: usersSchema,
//   song: songsSchema
// });
export const userCommunitiesSchema = new schema.Entity('userCommunities');
export const communitiesPlaylist = new schema.Entity('communities-playlist');
export const resourceSchema = new schema.Entity('resources');
export const artistSchema = new schema.Entity('artists');
export const chatBotUsersSchema = new schema.Entity('chatBotUsers');
