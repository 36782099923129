/* eslint-disable @typescript-eslint/no-unused-vars */
import emojiData from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { Box, CircularProgress, useMediaQuery, useTheme } from '@mui/material';
import {
  Chat,
  isFilePayload,
  MessageInput,
  MessageList,
  MessageRendererProps,
  useChannelMembers
} from '@pubnub/react-chat-components';
// import { usePubNub } from 'pubnub-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { APP_SECTION } from '../../../shared/constants/upload.const';
import { IMAGE_SIZES } from '../../../shared/interfaces/custom/image-preset-settings.interface';
import { IChatBotUser } from '../../../shared/interfaces/models/chatbot-user.interface';
import { IUser } from '../../../shared/interfaces/models/user.interface';
import { useSelector } from '../../../store';
import { getChatBotUsers } from '../../../store/actions/userActions';
import './styles.css';

interface PropTypes {
  height: number;
  channel: string;
  user: IUser;
}

const ChatComponent: React.FC<PropTypes> = ({ height, channel, user }) => {
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down('sm'));
  const betweenSmAndMd = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const chatBotUsersNormalized = useSelector((state) => state.main.chatBotUsers);
  const dispatch = useDispatch();
  // const pubnub = usePubNub();
  const [chatBotUsers, setChatBotUsers] = useState<IChatBotUser[]>([]);
  // const [members, fetchPage, refetchChannelMembers, total] = useChannelMembers({ channel });

  const currentUserChatData = useMemo(() => {
    const profileImageResource = user.resources?.find(
      (r) => r.appSection === APP_SECTION.USER_PROFILE && r.data.size === IMAGE_SIZES.S
    );
    const defaultChatIconUrl = `${window.location.origin}/assets/default-chat-icon.jpg`;
    const profileUrl =
      !profileImageResource || !profileImageResource.url || profileImageResource?.url === ''
        ? defaultChatIconUrl
        : profileImageResource?.url;

    return {
      id: user.id?.toString() ?? '0',
      name: user.username ?? 'Unknown',
      profileUrl
    };
  }, [user]);

  // const getPubNubMetadata = async () => {
  //   if (pubnub && channel) {
  //     const response = await pubnub.objects.getMemberships();
  //     const channels = response.data.map((d) => d.channel.id);
  //     if (!channels.some((c) => c === channel)) {
  //       channels.push(channel);
  //       try {
  //         await pubnub.objects.setMemberships({ channels });
  //       } catch (e) {
  //         console.error('setMemberships() error. e:', e);
  //       }
  //     }
  //   }
  //
  //   if (pubnub && user) {
  //     try {
  //       await pubnub.objects.setUUIDMetadata({ data: currentUserChatData });
  //     } catch (status) {
  //       console.error('setUUIDMetadata() error. e:', status);
  //     }
  //   }
  // };

  useEffect(() => {
    // pubnub.subscribe({ channels: [channel] });
    // pubnub.addListener({
    //   objects: () => {
    //     refetchChannelMembers();
    //   }
    // });
    dispatch(getChatBotUsers());
    // getPubNubMetadata().then();
  }, []);

  useEffect(() => {
    if (chatBotUsersNormalized) {
      const newChatBotUsers = Object.values(chatBotUsersNormalized) as IChatBotUser[];
      setChatBotUsers(newChatBotUsers);
    }
  }, [chatBotUsersNormalized]);

  if (!chatBotUsers || chatBotUsers.length === 0) {
    return <CircularProgress />;
  }

  const isInChatBotList = (id: string | undefined) => {
    if (!id) {
      return false;
    }
    return chatBotUsers.some((c) => c.id === parseInt(id));
  };

  const getChatBotName = (id: string | undefined) => {
    if (!id) {
      return 'BOT';
    }
    return chatBotUsers.find((c) => c.id === parseInt(id))?.name ?? 'BOT';
  };

  const botBubbleStyle = {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  };

  const bubbleRenderer = (props: MessageRendererProps) => {
    // console.log('props:', props);
    const envelope = props.message;
    const message = isFilePayload(envelope.message) ? envelope.message.message : envelope.message;
    // @ts-ignore
    const isBotMessage = isInChatBotList(message?.sender?.id);
    return (
      // @ts-ignore
      <div className="pn-msg__bubble" style={isBotMessage ? botBubbleStyle : {}}>
        {props.editedText || (message === null || message === void 0 ? void 0 : message?.text)}
      </div>
    );
  };

  // todo: enable to upload to staging when production is ready
  const onError = (error: Error) => {
    // if (error.name === 'TypeError' && error.message === 'q.on is not a function') {
    //   alert('Invalid File. The max file size allowed is 5MB.');
    // }
  };

  const inputPlaceholder = betweenSmAndMd ? 'message' : downSm ? 'mes...' : 'Type a new message';

  return (
    <div style={{ height: 'inherit', maxHeight: 'inherit' }}>
      {/*@ts-ignore*/}
      <Chat {...{ currentChannel: channel, theme: 'light', users: [currentUserChatData], onError }}>
        <Box sx={{ height }}>
          <MessageList
            enableReactions
            fetchMessages={10}
            reactionsPicker={<Picker data={emojiData} showPreview={false} showSkinTones={false} />}
            bubbleRenderer={bubbleRenderer}
          />
          <MessageInput
            senderInfo
            placeholder={inputPlaceholder}
            emojiPicker={<Picker data={emojiData} showPreview={false} showSkinTones={false} />}
            fileUpload="all"
          />
        </Box>
      </Chat>
    </div>
  );
};

export default ChatComponent;
