const API_URL: string = process.env.REACT_APP_API_HOST || 'http://localhost:3001/api';
const RADIO_URL = process.env.REACT_APP_RADIO_BASE_URL || 'http://localhost:8080';

export enum RADIO_STATUS {
  CONNECTING = 'Connecting...',
  RECONNECTING = 'Reconnecting...',
  LOADING = 'Loading...',
  SWITCHING = 'Switching...',
  PLAYING = 'Playing',
  PLAYING_WITHOUT_METADATA = 'Playing without metadata',
  METADATA_RECEIVED = 'Metadata received',
  PAUSED = 'Paused',
  UNAVAILABLE = 'Unavailable',
  EMPTY = ''
}

export enum ICECAST_STATUS {
  PLAYING = 'playing'
}

export enum PLAYER_SOURCE {
  RADIO_STREAMING = 'RADIO_STREAMING',
  SPECIFIC_SONG = 'SPECIFIC_SONG'
}

export const PLAYER_CONFIG = {
  // @ts-ignore
  DEFAULT_BITRATE: 128,
  SPECIFIC_SONG_BASE_URL: `${API_URL}/songs/stream/resource-id`,
  RADIO_STREAMING_BASE_URL: `${RADIO_URL}/community`
  // RADIO_STREAMING_BASE_URL: 'https://echodrop-staging-radio.corbinbrooks.com/community'

  // SPECIFIC_SONG_BASE_URL: 'https://echodrop-staging-server.corbinbrooks.com/api/songs/stream/resource-id'
  // RADIO_STREAMING_BASE_URL: 'http://icecast.local/community',
  // SPECIFIC_SONG_BASE_URL: 'http://localhost:3001/api/songs/stream/resource-id'
};

export enum SONG_COMMENTS {
  SECONDS_TO_SHOW = 7
}

export enum SONG_REACTIONS {
  SECONDS_TO_SHOW = 7
}

export const WAVE_SIZE_PRESETS = {
  MEDIUM: {
    barHeight: 1,
    barWidth: 8,
    barGap: 6,
    barRadius: 4,
    height: 150,
    width: 680
  },
  SMALL: {
    barHeight: 1,
    barWidth: 3,
    barGap: 2,
    barRadius: 2,
    height: 30,
    width: 200
  }
};
