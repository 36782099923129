import { combineReducers } from 'redux';
import { IRootState } from '../../interfaces/store/root.interface';
import general from './generalReducer';
import main from './mainReducer';

const rootReducer = combineReducers<IRootState>({
  main,
  general
});

export default rootReducer;
