import { useSelector } from '../../../store';
import { setGeneralValue } from '../../../store/actions/generalActions';
import { getUserCommunitiesByUserId } from '../../../store/actions/communitiesActions';
import { useDispatch } from 'react-redux';
import { IUser } from '../../../shared/interfaces/models/user.interface';

export default function useCurrentUserCommunity(): { fetch: (communityId: number, currentUser: IUser) => void } {
  const dispatch = useDispatch();
  const { currentUserCommunityLoading } = useSelector((state) => state.general);

  const fetch = async (communityId: number, currentUser: IUser) => {
    if (currentUser && communityId && !currentUserCommunityLoading) {
      dispatch(setGeneralValue('currentUserCommunityLoading', true));
      dispatch(setGeneralValue('currentUserCommunity', undefined));
      dispatch(
        getUserCommunitiesByUserId(
          { id: currentUser.id },
          (userCommunities) => {
            const currentUserCommunity = userCommunities.find((uc) => uc.communityId === Number(communityId));
            dispatch(setGeneralValue('currentUserCommunity', currentUserCommunity));
            dispatch(setGeneralValue('currentUserCommunityLoading', false));
          },
          () => {
            dispatch(setGeneralValue('currentUserCommunityLoading', false));
          }
        )
      );
    }
  };
  return { fetch };
}
