import { Typography, Stack, Button, Dialog } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { IRootState } from '../../interfaces/store/root.interface';

import { IUploadSong } from '../../shared/interfaces/custom/upload-song.interface';
import { setGeneralValue } from '../../store/actions/generalActions';
import SongUploadProgressComponent from './SongUploadProgressComponent';
import ConfirmationDialogComponent from '../ConfirmationDialogComponent/ConfirmationDialogComponent';
import { deleteSong } from '../../store/actions/artistActions';
import { setMainValue } from '../../store/actions/mainActions';
import useAnalytics from '../../lib/hooks/useAnalytics';

interface PropsType {
  uploadSongs: IUploadSong[];
  uploadModalState: boolean;
}

const SongUploadListProgressComponent: React.FC<PropsType> = ({ uploadSongs, uploadModalState }) => {
  const { track } = useAnalytics();
  const dispatch = useDispatch();

  const [finished, setFinished] = useState(false);
  const [showConfirmationDialogForCancel, setShowConfirmationDialogForCancel] = useState(false);
  const [uploadSongsState, setUploadSongsState] = useState<IUploadSong[]>([]);
  const [finishedUploadSongs, setFinishedUploadSongs] = useState<IUploadSong[]>([]);

  useEffect(() => {
    dispatch(setMainValue('songUploadResults', {}));
    return () => {
      handleClose();
    };
  }, []);

  const handleClose = () => {
    dispatch(setMainValue('songUploadResults', {}));
    setFinished(false);
    setUploadSongsState([]);
    setFinishedUploadSongs([]);
    dispatch(setGeneralValue('uploadSongs', []));
    dispatch(setGeneralValue('uploadModalState', false));
  };

  const handleConfirmCancel = () => {
    track('song_upload_aborted', { songId: uploadSongs.length ? uploadSongs[0].song?.id : null });
    deleteUploadedSongs();
    handleClose();
  };

  const deleteUploadedSongs = () => {
    if (uploadSongs && uploadSongs.length > 0 && uploadSongs[0].song) {
      const songId = uploadSongs[0].song.id ?? 0;
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      dispatch(deleteSong({ songId }, () => {}));
    }
  };

  useEffect(() => {
    if (uploadSongs && uploadSongs.length > 0) {
      uploadSongs[0].readyToSaveAndUpload = true;
      setUploadSongsState(uploadSongs);
    }
  }, [uploadSongs]);

  useEffect(() => {
    if (
      finishedUploadSongs &&
      uploadSongs &&
      finishedUploadSongs.length > 0 &&
      finishedUploadSongs.length === uploadSongs.length
    ) {
      setFinished(true);
    }
  }, [finishedUploadSongs]);

  const handleCurrentUploadFinish = (alreadyUploadedSong: IUploadSong | undefined) => {
    if (alreadyUploadedSong) {
      setUploadSongsState((state) => {
        const uploadSongElement = state.find((song) => !song.readyToSaveAndUpload);
        if (uploadSongElement) {
          const index = state.indexOf(uploadSongElement);
          uploadSongElement.readyToSaveAndUpload = true;
          state[index] = uploadSongElement;
        }
        return state;
      });
      setFinishedUploadSongs((state) => [...state, alreadyUploadedSong]);
    }
  };

  const handleCancel = () => {
    setShowConfirmationDialogForCancel(true);
  };

  return (
    <Dialog
      onClose={() => finished && handleClose()}
      disableEscapeKeyDown={!finished}
      fullWidth
      open={uploadModalState}
    >
      <Stack direction="column" spacing={4} sx={{ borderRadius: 2, p: 3 }}>
        <Typography variant="h6" color="primary">
          {finished ? 'Uploaded songs' : 'Loading songs...'}
        </Typography>

        {uploadSongsState &&
          uploadSongsState.map((uploadSong) => (
            <SongUploadProgressComponent
              key={uploadSong.index}
              uploadSong={uploadSong}
              onFinished={handleCurrentUploadFinish}
              readyToUpload={uploadSong.readyToSaveAndUpload}
            />
          ))}

        {finished ? (
          <Stack direction="row" justifyContent="flex-end">
            <Button variant="contained" onClick={handleClose}>
              Close
            </Button>
          </Stack>
        ) : (
          <Stack direction="row" justifyContent="flex-end">
            <Stack direction="column" justifyContent="flex-end">
              <Typography variant="caption" textAlign="right">
                Please wait
              </Typography>
              <Button onClick={handleCancel}>Cancel</Button>
            </Stack>
          </Stack>
        )}
      </Stack>
      <ConfirmationDialogComponent
        text={'Songs are still being uploaded, are you sure you want to abort the upload?'}
        acceptText={'Abort upload'}
        cancelText={'Continue uploading songs'}
        showDialog={showConfirmationDialogForCancel}
        setShowDialog={setShowConfirmationDialogForCancel}
        onAccept={handleConfirmCancel}
        cancelIsActiveButton
      />
    </Dialog>
  );
};

const mapState = (state: IRootState) => ({
  uploadSongs: state.general.uploadSongs,
  uploadModalState: state.general.uploadModalState
});

export default connect(mapState)(SongUploadListProgressComponent);
