/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import ButtonBase from '@mui/material/ButtonBase';
import { ButtonBaseProps } from '@mui/material/ButtonBase/ButtonBase';
import MuiIcon from '@mui/material/Icon';
import { Z_INDEXES } from '../../enums/zIndexes';
import { Stack, Typography } from '@mui/material';
import { LIMITS } from '../../enums/uploads';

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  height: 140,
  width: 140,
  borderRadius: '50%',
  '& .MuiEditIcon-root': {
    opacity: 0,
    transition: theme.transitions.create('opacity')
  },
  '&:hover, &.Mui-focusVisible': {
    zIndex: Z_INDEXES.AVATAR_BUTTON_COMPONENT,
    '& .MuiImageBackdrop-root': {
      opacity: 0.3
    },
    '& .MuiEditIcon-root': {
      opacity: 1
    }
  }
}));

const ImageSrc = styled('span')({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: 'cover',
  backgroundPosition: 'center 40%',
  borderRadius: '50%'
});

const Icon = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.common.white
}));

const ImageBackdrop = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0,
  transition: theme.transitions.create('opacity'),
  borderRadius: '50%'
}));

interface PropType extends ButtonBaseProps {
  src?: string;
  iconProps?: any;
  iconName: string;
  defaultImageUrl?: string;
  showUploadLimits?: boolean;
}

const AvatarButton: React.FC<PropType> = ({
  src,
  iconProps,
  iconName,
  defaultImageUrl,
  showUploadLimits = false,
  ...rest
}) => {
  // const defaultImageUrl = 'https://t4.ftcdn.net/jpg/00/65/77/27/360_F_65772719_A1UV5kLi5nCEWI0BNLLiFaBPEkUbv5Fv.jpg';
  if (!defaultImageUrl) {
    defaultImageUrl = 'assets/person_FILL0_wght400_GRAD0_opsz48.svg';
  }
  return (
    <>
      <ImageButton focusRipple {...rest}>
        <ImageSrc style={{ backgroundImage: `url(${src ? src : defaultImageUrl})` }} />
        <ImageBackdrop className="MuiImageBackdrop-root" />
        <Icon>
          {showUploadLimits ? (
            <Stack alignItems="center" justifyContent="flex-start" spacing={1}>
              <MuiIcon {...iconProps} className="MuiEditIcon-root">
                {iconName}
              </MuiIcon>
              <Stack>
                <Typography
                  variant="caption"
                  className="MuiEditIcon-root"
                  sx={{ textShadow: '1px 1px 3px black, 0 0 8px black' }}
                >
                  {LIMITS.IMAGES.MAX_SIZE_MB} MB max
                </Typography>
                <Typography
                  variant="caption"
                  className="MuiEditIcon-root"
                  sx={{ textShadow: '1px 1px 3px black, 0 0 8px black' }}
                >
                  {LIMITS.IMAGES.MAX_RESOLUTION.WIDTH}x{LIMITS.IMAGES.MAX_RESOLUTION.HEIGHT} px max
                </Typography>
                <Typography
                  variant="caption"
                  className="MuiEditIcon-root"
                  sx={{ textShadow: '1px 1px 3px black, 0 0 8px black' }}
                >
                  {LIMITS.IMAGES.EXTENSIONS_ALLOWED.join(', ')}
                </Typography>
              </Stack>
              <div style={{ height: 20 }}></div>
            </Stack>
          ) : (
            <MuiIcon {...iconProps} className="MuiEditIcon-root">
              {iconName}
            </MuiIcon>
          )}
        </Icon>
      </ImageButton>
    </>
  );
};

export default AvatarButton;
