/* eslint-disable @typescript-eslint/no-unused-vars */
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Stack,
  TextField,
  Typography,
  Paper,
  Link as MuiLink
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { boolean, object, ref, string } from 'yup';
import { useAuth } from '../../components/Authentication/ProvideAuth';
import { IRegisterPayload } from '../../shared/interfaces/custom/register-payload.interface';
import { Link, useParams, useRouteMatch } from 'react-router-dom';
import routePaths from '../../lib/routePaths';
import { getInvitationByToken } from '../../store/actions/authActions';
import { IInvitation } from '../../shared/interfaces/models/invitation.interface';
import ErrorIcon from '@mui/icons-material/Error';
import Loading from '../../components/Loading/Loading';
import { useSelector } from '../../store';

const RegisterForm: React.FC = () => {
  const { appSettings } = useSelector((state) => state.general);
  const { token: tokenKey } = useParams<{ token: string | undefined }>();
  const [loading, setLoading] = useState(false);
  const auth = useAuth();
  const dispatch = useDispatch();
  const invitationRouteMatch = useRouteMatch(routePaths.public.invitationRegister);
  const isInvitation = useMemo(() => {
    console.log('invitationRouteMatch', invitationRouteMatch);
    return invitationRouteMatch === undefined ? undefined : !!invitationRouteMatch;
  }, [invitationRouteMatch]);
  const [invitationLoading, setInvitationLoading] = useState(true);
  const [invitationError, setInvitationError] = useState<string | undefined>();

  useEffect(() => {
    if (isInvitation) {
      setInvitationLoading(true);
      console.log('isInvitation', isInvitation);
      console.log('tokenKey', tokenKey);
      dispatch(
        getInvitationByToken(
          { tokenKey },
          (result: { invitation: IInvitation; validToken: boolean }) => {
            if (result.validToken) {
              formik.setFieldValue('email', result.invitation.email);
            } else {
              setInvitationError('Invitation has expired');
            }
            console.log('getInvitationByToken() success, result: ', result);
            setInvitationLoading(false);
          },
          (e) => {
            const errorMessage = JSON.parse(e.message)?.message;
            console.log('getInvitationByToken() errorMessage: ', errorMessage);
            setInvitationError(errorMessage);
            setInvitationLoading(false);
          }
        )
      );
    } else {
      setInvitationLoading(false);
    }
  }, [isInvitation]);

  const checkIfUserFieldAlreadyExists = (fieldName: string): Promise<boolean> => {
    return new Promise((resolve) => {
      // @ts-ignore
      const value = formik.values[fieldName];
      if (!value) {
        formik.setFieldError(fieldName, undefined);
        return resolve(false);
      }
      auth.checkIfUserExists(
        { [fieldName]: value },
        (alreadyExists: boolean) => {
          if (alreadyExists) {
            formik.setFieldError(fieldName, `${fieldName} already in use`);
          } else {
            formik.setFieldError(fieldName, undefined);
          }
          resolve(alreadyExists);
        },
        () => {
          formik.setFieldError(fieldName, undefined);
          resolve(false);
        }
      );
    });
  };

  const checkIfUserAlreadyExists = async (): Promise<boolean> => {
    const emailAlreadyExists = await checkIfUserFieldAlreadyExists('email');
    const usernameAlreadyExists = await checkIfUserFieldAlreadyExists('username');
    return emailAlreadyExists || usernameAlreadyExists;
  };

  const loginValidationSchema = object().shape({
    firstName: string().required('first name is a required field').min(2).max(256),
    lastName: string().required('last name is a required field').min(2).max(256),
    email: string().email().required().min(7).max(256),
    username: string()
      .required()
      .min(2)
      .max(20)
      .matches(
        /^(?=[a-zA-Z0-9._]{2,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/,
        'Only letters, numbers, underscores and dots are allowed'
      ),
    password: string().required().min(6).max(30),
    confirmPassword: string()
      .required()
      .oneOf([ref('password'), null], 'Passwords must match'),
    agree: boolean().isTrue('You must to agree to create an account'),
    artistName: string().max(256).when('artistRequest', {
      is: true,
      then: string().required()
    })
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      username: '',
      password: '',
      confirmPassword: '',
      artistName: '',
      agree: false,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      isInvitation: isInvitation !== undefined ? isInvitation : false,
      tokenKey: tokenKey ?? ''
    } as IRegisterPayload,
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: loginValidationSchema,
    onSubmit: async () => {
      const userAlreadyExists = await checkIfUserAlreadyExists();
      if (!userAlreadyExists) {
        setLoading(true);
        console.log('sending form:', formik.values);
        auth.register(
          formik.values,
          () => {
            window.gtag('event', 'sign_up');
            auth.login(formik.values.email, formik.values.password, () => {
              setLoading(false);
            });
          },
          () => {
            setLoading(false);
          }
        );
      }
    }
  });

  const handleSubmit = () => {
    console.log('submitting...');
    formik.handleSubmit();
  };

  if (appSettings === undefined || isInvitation === undefined || (isInvitation && invitationLoading)) {
    return (
      <Paper sx={{ mt: 4, p: 2, textAlign: 'center' }}>
        <Loading />
      </Paper>
    );
  }

  if (isInvitation && !appSettings.userInvitationEnabled) {
    return (
      <Paper sx={{ mt: 4, p: 2, textAlign: 'center' }}>
        <ErrorIcon color="error" sx={{ fontSize: 100 }} />
        <Typography variant="h2" color="error" sx={{ my: 1 }}>
          Invitations are disabled
        </Typography>
        {appSettings.registerEnabled && (
          <Typography variant="body1">
            But registrations now are open for everyone. Click{' '}
            <Link component={MuiLink} to="/register">
              here
            </Link>{' '}
            to register.
          </Typography>
        )}
      </Paper>
    );
  }

  if (!isInvitation && !appSettings.registerEnabled) {
    return (
      <Paper sx={{ mt: 4, p: 2, textAlign: 'center' }}>
        <ErrorIcon color="error" sx={{ fontSize: 100 }} />
        <Typography variant="h2" color="error" sx={{ my: 1 }}>
          Registrations are disabled
        </Typography>
        {appSettings.userInvitationEnabled && (
          <Typography variant="body1">
            Registration is only available by <strong>invitations</strong>. Request an invite from a friend who is
            already a member of Echodrop.
          </Typography>
        )}
      </Paper>
    );
  }

  if (isInvitation && invitationError) {
    return (
      <Paper sx={{ mt: 4, p: 2, textAlign: 'center' }}>
        <ErrorIcon color="error" sx={{ fontSize: 100 }} />
        <Typography variant="h2" color="error" sx={{ my: 1 }}>
          {invitationError}
        </Typography>
        <Typography variant="body1">Ask to your friend to send you a new one.</Typography>
      </Paper>
    );
  }

  return (
    <>
      <Stack sx={{ mt: 6 }} spacing={2}>
        <Stack direction="row" spacing={2}>
          <TextField
            disabled={loading}
            onChange={formik.handleChange}
            error={!!formik.errors.firstName}
            helperText={formik.errors.firstName ? formik.errors.firstName : undefined}
            size="small"
            name="firstName"
            label="First Name"
            variant="outlined"
            fullWidth
          />
          <TextField
            disabled={loading}
            onChange={formik.handleChange}
            error={!!formik.errors.lastName}
            helperText={formik.errors.lastName ? formik.errors.lastName : undefined}
            size="small"
            name="lastName"
            label="Last Name"
            variant="outlined"
            fullWidth
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextField
            disabled={loading || isInvitation}
            onBlur={() => checkIfUserFieldAlreadyExists('email')}
            onChange={formik.handleChange}
            error={!!formik.errors.email}
            helperText={formik.errors.email ? formik.errors.email : undefined}
            value={formik.values.email}
            size="small"
            name="email"
            label="Email"
            variant="outlined"
            fullWidth
          />
          <TextField
            disabled={loading}
            onBlur={() => checkIfUserFieldAlreadyExists('username')}
            onChange={formik.handleChange}
            error={!!formik.errors.username}
            helperText={formik.errors.username ? formik.errors.username : undefined}
            size="small"
            name="username"
            label="Username"
            variant="outlined"
            fullWidth
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextField
            disabled={loading}
            onChange={formik.handleChange}
            error={!!formik.errors.password}
            helperText={formik.errors.password ? formik.errors.password : undefined}
            size="small"
            name="password"
            type="password"
            label="Password"
            variant="outlined"
            fullWidth
          />
          <TextField
            disabled={loading}
            onChange={formik.handleChange}
            error={!!formik.errors.confirmPassword}
            helperText={formik.errors.confirmPassword ? formik.errors.confirmPassword : undefined}
            size="small"
            name="confirmPassword"
            type="password"
            label="Confirm password"
            variant="outlined"
            fullWidth
          />
        </Stack>
      </Stack>

      <FormControl style={{ margin: 0 }} error={!!formik.errors.agree} required sx={{ m: 3 }} variant="standard">
        <FormControlLabel
          disabled={loading}
          name="agree"
          onChange={formik.handleChange}
          control={<Checkbox checked={formik.values.agree} size="small" />}
          label="I agree to all the Terms and Privacy policy"
        />
        <FormHelperText>{formik.errors.agree ? formik.errors.agree : undefined}</FormHelperText>
      </FormControl>

      <Stack sx={{ mt: 2 }} direction="row" justifyContent="flex-start">
        <LoadingButton
          loading={loading}
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          sx={{ width: '100%', borderRadius: 10 }}
        >
          Create account
        </LoadingButton>
      </Stack>
    </>
  );
};

export default RegisterForm;
