import React, { useEffect, useMemo, useState } from 'react';
import { IRootState } from '../../interfaces/store/root.interface';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { PAGINATION_ITEMS_TO_SKIP, PAGINATION_ITEMS_TO_TAKE } from '../../shared/constants/pagination.const';
import useQuery from '../../lib/hooks/useQuery';
import ViewAllSongsComponent from '../../components/ViewAllSongsComponent/ViewAllSongsComponent';
import { createFromISong, ISongComponent } from '../../interfaces/ui-components/song.interface';
import { ISong } from '../../shared/interfaces/models/song.interface';
import { getSongsByArtist } from '../../store/actions/songActions';
import { IPaginationInfo } from '../../shared/interfaces/custom/pagination-info.interface';
import useIsOwner from '../../lib/hooks/useIsOwner';
import { useSelector } from '../../store';
import { USER_ROLE } from '../../shared/constants/user.const';

interface PropsType {
  pagination?: IPaginationInfo;
}

interface RouteParams {
  artistId: string;
  skip?: string;
}

const UploadedMusicPage: React.FC<PropsType> = ({ pagination }) => {
  const { currentUser } = useSelector((state) => state.general);
  const dispatch = useDispatch();
  const queryParams = useQuery();
  const { artistId } = useParams<RouteParams>();
  const [songComponentList, setSongComponentList] = useState<ISongComponent[] | undefined>();
  const isOwner = useIsOwner();

  const colsToShow = useMemo(() => {
    const cols = ['image', 'song', 'listen'];
    (isOwner || currentUser?.role === USER_ROLE.SYSADMIN) && cols.push('delete');
    return cols;
  }, [isOwner, currentUser]);

  useEffect(() => {
    const skip = queryParams.get('skip') || '0';
    fetchPage(parseInt(skip));
  }, []);

  const onReceiveResponse = (responseData: ISong[]) => {
    if (responseData) {
      const list: ISongComponent[] = responseData.map((song) => createFromISong({ song }));
      setSongComponentList(list);
    }
  };

  const fetchPage = (skip = PAGINATION_ITEMS_TO_SKIP, take = PAGINATION_ITEMS_TO_TAKE) => {
    dispatch(getSongsByArtist({ id: artistId, skip, take }, onReceiveResponse));
  };

  return (
    <ViewAllSongsComponent
      title="Uploaded Music"
      fetchPage={fetchPage}
      pagination={pagination}
      setSongComponentList={setSongComponentList}
      songComponentList={songComponentList}
      colsToShow={colsToShow}
    />
  );
};

const mapState = (state: IRootState) => ({
  pagination: state.general.pagination?.songs
});

export default connect(mapState)(UploadedMusicPage);
