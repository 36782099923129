import { IAppSettingsFields } from "../interfaces/custom/app-settings-fields.interface";
import { IAppSettings } from "../interfaces/models/app-settings.interface";
import { APP_SETTINGS } from "../constants/app-settings.const";

export const safeBool = (
  input: string | boolean,
  defaultValue = false
): boolean => {
  try {
    let newInput = input;
    if (typeof input === "string") {
      newInput = input.toLowerCase();
    }
    if (newInput === "true" || newInput === true) {
      return true;
    }
    if (newInput === "false" || newInput === false) {
      return false;
    }
    return defaultValue;
  } catch (e) {
    return defaultValue;
  }
};

export function getJsonFromUrl(url: any) {
  const query = url.substr(1);
  const result: Record<string, string> = {};
  query.split("&").forEach(function (part: string) {
    const item = part.split("=");
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
}

export function generateAppSettingsFieldObject(
  appSettingsArray: IAppSettings[]
): IAppSettingsFields {
  const registerEnabled: IAppSettings | undefined = appSettingsArray.find(
    (setting) => APP_SETTINGS.REGISTER_ENABLED === setting.name
  );
  const invitationEnabled: IAppSettings | undefined = appSettingsArray.find(
    (setting) => APP_SETTINGS.USER_INVITATION_ENABLED === setting.name
  );
  const boostLossEnabled: IAppSettings | undefined = appSettingsArray.find(
    (setting) => APP_SETTINGS.BOOST_LOSS_ENABLED === setting.name
  );
  const registerEnabledValue: string =
    registerEnabled !== undefined && registerEnabled.value !== undefined
      ? registerEnabled.value
      : "true";
  const invitationEnabledValue: string =
    invitationEnabled !== undefined && invitationEnabled.value !== undefined
      ? invitationEnabled.value
      : "true";
  const boostLossEnabledValue: string =
    boostLossEnabled !== undefined && boostLossEnabled.value !== undefined
      ? boostLossEnabled.value
      : "false";

  return {
    registerEnabled: safeBool(registerEnabledValue, true),
    userInvitationEnabled: safeBool(invitationEnabledValue, true),
    boostLossEnabled: safeBool(boostLossEnabledValue, false),
  };
}
