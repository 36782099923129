import { Box, CircularProgress, CircularProgressProps, Skeleton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { APP_SECTION } from '../../shared/constants/upload.const';
import { IUploadSong } from '../../shared/interfaces/custom/upload-song.interface';
import { queue } from '../../store/actions/queueActions';
import { uploadResourceFile } from '../../store/actions/resourceActions';

interface PropsType {
  readyToUpload: boolean | undefined;
  uploadSong?: IUploadSong;
  onSuccess?: () => void;
  onFail?: () => void;
}

function CircularProgressWithLabel(props: CircularProgressProps & { value: number }) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const VideoFileUploadProgress: React.FC<PropsType> = ({ readyToUpload, uploadSong, onSuccess, onFail }) => {
  const dispatch = useDispatch();
  const [videoPercents, setVideoPercents] = useState(0);

  useEffect(() => {
    if (readyToUpload && uploadSong && uploadSong.song && uploadSong.song.id) {
      uploadVideoFileAsResource(uploadSong);
    }
  }, [uploadSong, readyToUpload]);

  const uploadVideoFileAsResource = (uploadSong: IUploadSong) => {
    if (uploadSong.videoFile) {
      dispatch(
        queue(
          uploadResourceFile(
            {
              file: uploadSong.videoFile,
              appSection: APP_SECTION.SONG_VIDEO,
              name: uploadSong.videoFile.name,
              associatedId: uploadSong.song?.id
            },
            onSuccess,
            onFail,
            (percent) => {
              setVideoPercents(percent);
            }
          )
        )
      );
    } else {
      onSuccess && onSuccess();
    }
  };

  if (!uploadSong || !readyToUpload) {
    return <Skeleton variant="circular" width={40} height={40} />;
  }

  return (
    <div>
      <CircularProgressWithLabel value={videoPercents} />
    </div>
  );
};

export default VideoFileUploadProgress;
