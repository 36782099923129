/* eslint-disable @typescript-eslint/no-empty-function  */
/* eslint-disable @typescript-eslint/no-unused-vars*/

import { useState } from 'react';
import IcecastMetadataPlayer from 'icecast-metadata-player';
import { ISong } from '../../../shared/interfaces/models/song.interface';
import { IRadioControls, ISongControls } from '../../../interfaces/echo-player/echo-player-controls.interface';
import useCurrentControls from './useCurrentControls';
import useRadioControls from './useRadioControls';
import useSongControls from './useSongControls';
import { IPlayerInfo } from '../../../interfaces/echo-player/echo-player-info.interface';
import { RADIO_STATUS } from '../../../enums/player';

export const controlsInitValues = {
  radio: {
    icecast: undefined,
    audioElement: undefined,
    play: () => new Promise<void>(() => {}),
    stop: () => new Promise<void>(() => {}),
    togglePlay: () => new Promise<void>(() => {}),
    runBeforePlay: () => {},
    setOnStatusChange: () => {},
    stopWhileLoading: () => {}
  },
  song: {
    audioElement: undefined,
    play: () => new Promise<void>(() => {}),
    pause: () => {},
    togglePlay: () => new Promise<void>(() => {}),
    runBeforePlay: () => {}
  },
  current: {
    elapsed: undefined,
    togglePlay: () => new Promise<void>(() => {}),
    setVolume: () => {},
    setMute: () => {},
    getCurrentTime: () => undefined
  }
};

export default function useControls(playerInfo: IPlayerInfo) {
  const { data: playerData } = playerInfo;
  const [icecast, setIcecast] = useState<IcecastMetadataPlayer | undefined>(undefined);
  const [radioAudioElement, setRadioAudioElement] = useState<HTMLAudioElement>(new Audio());
  const [songAudioElement, setSongAudioElement] = useState<HTMLAudioElement>(new Audio());

  const radioControls = useRadioControls({
    icecast,
    setIcecast,
    audioElement: radioAudioElement,
    setAudioElement: setRadioAudioElement,
    playerInfo
  });
  const songControls = useSongControls({
    audioElement: songAudioElement,
    setAudioElement: setSongAudioElement,
    playerInfo
  });

  const songControlsWrapper: ISongControls = {
    ...songControls,
    play: async (song?: ISong, communityId?: number) => {
      if (playerData.radio.playing || playerData.radio.loading) {
        await radioControls.stop();
      }
      await songControls.play(song, communityId);
    },
    togglePlay: async (song?: ISong, communityId?: number) => {
      if (playerData.radio.playing || playerData.radio.loading) {
        await radioControls.stop();
      }
      await songControls.togglePlay(song, communityId);
    }
  };

  const radioControlsWrapper: IRadioControls = {
    ...radioControls,
    play: async (communityId?: number) => {
      if (playerData.song.playing || playerData.song.loading) {
        songControls.pause();
      }
      await radioControls.play(communityId);
    },
    togglePlay: async (communityId?: number) => {
      if (playerData.song.playing || playerData.song.loading) {
        songControls.pause();
      }
      await radioControls.togglePlay(communityId);
    }
  };

  const currentControls = useCurrentControls({
    songControls: songControlsWrapper,
    radioControls: radioControlsWrapper,
    playerInfo
  });

  return {
    song: songControlsWrapper,
    radio: radioControlsWrapper,
    current: currentControls
  };
}
