/* eslint-disable @typescript-eslint/no-unused-vars */
import { Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import UserInfo from '../../components/UserInfoComponent/UserInfo';
import { INormalized } from '../../interfaces/store/main.interface';
import { IRootState } from '../../interfaces/store/root.interface';
import {
  createFromUserCommunity,
  ICommunityComponent
} from '../../interfaces/ui-components/slider-community.interface';
import useIsOwner from '../../lib/hooks/useIsOwner';
import routePaths from '../../lib/routePaths';
import { IUserCommunity } from '../../shared/interfaces/models/user-community.interface';
import { IUser } from '../../shared/interfaces/models/user.interface';
import { getUserCommunitiesByUserId } from '../../store/actions/communitiesActions';
import { generateFakeSongList } from '../../utils/general';
import ArtistRequest from './ArtistRequest';

interface PropsType {
  user: IUser | null;
  userCommunities: INormalized<IUserCommunity>;
}

interface RouteParams {
  userId: string;
}

const CuratorProfilePage: React.FC<PropsType> = ({ user, userCommunities }) => {
  const history = useHistory();
  const isOwner = useIsOwner();
  const songList = generateFakeSongList(4, 500);
  const dispatch = useDispatch();
  const { userId } = useParams<RouteParams>();
  const [communityComponentList, setCommunityComponentList] = useState<ICommunityComponent[] | null>();
  const [profileButtons, setProfileButtons] = useState<any[]>([]);
  const [showArtistRequestModal, setShowArtistRequestModal] = useState(false);

  const artistProfileButton = (artistId: number) => ({
    text: 'Artist Profile',
    onClick: () => {
      history.push(generatePath(routePaths.private.artistDashboard.main, { artistId }));
    }
  });
  const artistRequestButton = {
    text: 'Request to be an Artist',
    onClick: () => setShowArtistRequestModal(true)
  };

  useEffect(() => {
    dispatch(getUserCommunitiesByUserId({ id: userId, take: 5 }));
  }, []);

  useEffect(() => {
    if (user?.artist?.id) {
      setProfileButtons([artistProfileButton(user.artist.id)]);
    } else {
      setProfileButtons([artistRequestButton]);
    }
  }, [user?.artist]);

  useEffect(() => {
    if (userCommunities) {
      const list: ICommunityComponent[] = Object.values(userCommunities).map(createFromUserCommunity);
      setCommunityComponentList(list);
    }
  }, [userCommunities]);

  const handleViewAllCommunities = () => {
    history.push(generatePath(routePaths.private.joinedCommunities, { userId }));
  };

  const styles = {
    bannerWidth: 1440,
    bannerHeight: 176
  };
  const bannerImgUrl = `https://loremflickr.com/${styles.bannerWidth}/${styles.bannerHeight}?lock=125`;
  return (
    <>
      <Stack sx={{ p: 1 }} spacing={3}>
        <img style={{ width: '100%' }} src={bannerImgUrl} alt="banner image" />
        {user && (
          <UserInfo
            sx={{ mb: 2 }}
            title={`${user.firstName} ${user.lastName}`}
            subtitle={user.username}
            buttons={profileButtons}
            user={user}
            // eslint-disable-next-line no-console
            onAvatarClick={isOwner ? () => console.log('edit user action') : undefined}
          />
        )}
        {/*{communityComponentList && (*/}
        {/*  <CommunitiesSectionComponent*/}
        {/*    onViewAll={handleViewAllCommunities}*/}
        {/*    title="Joined Communities"*/}
        {/*    communities={communityComponentList}*/}
        {/*  />*/}
        {/*)}*/}
        {/*<ExclusiveEditionsCollectionComponent title="Exclusive Editions Collection" songList={songList} />*/}
        {/*<CurationHistorySection title="Curation History" songList={songList} />*/}
      </Stack>
      <ArtistRequest modalOpen={showArtistRequestModal} setModalOpen={setShowArtistRequestModal} />
    </>
  );
};

const mapState = (state: IRootState) => ({
  user: state.general.currentUser,
  userCommunities: state.main.userCommunities
});

export default connect(mapState)(CuratorProfilePage);
