/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';

import { CircularProgress, Grid, Link as MuiLink, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { Z_INDEXES } from '../../enums/zIndexes';
import useWindowSize from '../../lib/hooks/useWindowSize';
import routePaths from '../../lib/routePaths';
import FooterBar from './FooterBar';
import LeftSide from './LeftSide';
import RegisterForm from './RegisterForm';

const RegisterPage: React.FC = () => {
  const size = useWindowSize();

  useEffect(() => {
    document.title = `Echodrop | Register`;
  }, []);

  if (!size.height) {
    return <CircularProgress />;
  }

  const alphaWarningText =
    'Echo Drop is in Alpha and currently not supported on mobile! ' +
    'We will be continuously fixing things and you will see new features ' +
    'popping up every day or two! Alpha release is limited to the first 1000 users who sign up.';

  return (
    <div>
      <Grid container>
        <Grid item xs={6} sx={{ backgroundColor: 'black', width: '100%', height: size.height }}>
          <LeftSide />
        </Grid>
        <Grid
          item
          xs={6}
          sx={{ width: '100%', px: 10, py: 2, zIndex: Z_INDEXES.LOGIN_PAGE__RIGHT_SIDE, backgroundColor: 'white' }}
        >
          <Stack justifyContent="center" sx={{ height: '100%', marginTop: '-56px', maxWidth: '500px' }}>
            <Typography variant="h1">Create account</Typography>
            <Typography sx={{ mt: 1 }} variant="body1">
              Be a curator or share your music as an artist!
            </Typography>
            <RegisterForm />
            <Stack direction="row" justifyContent="center">
              <Typography sx={{ mt: 4 }}>
                Already have an account?{' '}
                <Link component={MuiLink} to={routePaths.public.login}>
                  Log in
                </Link>
              </Typography>
            </Stack>
            <Typography align="justify" color="error" variant="caption" sx={{ mt: 2 }}>
              {alphaWarningText}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <FooterBar />
    </div>
  );
};

export default RegisterPage;
