/* eslint-disable @typescript-eslint/no-empty-function  */
/* eslint-disable @typescript-eslint/no-unused-vars*/

import { Dispatch, SetStateAction } from 'react';
import { PLAYER_SOURCE } from '../../../enums/player';
import {
  IPlayerData,
  IPlayerDataSetters,
  ISongInfoTriggers
} from '../../../interfaces/echo-player/echo-player-info.interface';
import useAudioHelper from '../helpers/useAudioHelper';
import { ISong } from '../../../shared/interfaces/models/song.interface';

export default function useSongInfoTriggers(data: IPlayerData, setters: IPlayerDataSetters): ISongInfoTriggers {
  const { getUrlFromSong } = useAudioHelper();

  const onAudioContextChange = (audioContext: AudioContext) => {
    setters.setSongData({ audioContext });
  };

  const beforePlay = (song: ISong, sourceUrl: string, songStartedAt: Date, communityId?: number) => {
    // console.log('SONG beforePlay()');
    setters.setSongData({ loading: true, song, nextSong: undefined, sourceUrl });
  };

  const beforePause = () => {
    // console.log('SONG beforePause()');
    setters.setSongData({ loading: true });
  };

  const afterPlay = (song: ISong, sourceUrl: string, songStartedAt: Date, communityId?: number) => {
    // console.log('SONG afterPlay()');
    setters.setData((prevData) => ({
      ...prevData,
      radio: {
        ...prevData.radio,
        playing: false
      },
      song: {
        ...prevData.song,
        song,
        nextSong: undefined,
        sourceUrl,
        playing: true,
        loading: false,
        songStartedAt
      },
      current: {
        ...prevData.current,
        communityId: communityId,
        song,
        nextSong: undefined,
        sourceType: PLAYER_SOURCE.SPECIFIC_SONG,
        playing: true,
        songStartedAt,
        songResourceUrl: getUrlFromSong(song)
      }
    }));
  };

  const afterPause = () => {
    // console.log('SONG afterPause()');
    setters.setData((prevData) => ({
      ...prevData,
      song: {
        ...prevData.song,
        playing: false,
        loading: false
      },
      current: {
        ...prevData.current,
        playing: prevData.current.sourceType === PLAYER_SOURCE.SPECIFIC_SONG ? false : prevData.current.playing
      }
    }));
  };

  return { afterPause, afterPlay, beforePlay, beforePause, onAudioContextChange };
}
