import React, { useCallback, useEffect, useState } from 'react';
import { ISongReaction } from '../../../../shared/interfaces/models/song-reaction.interface';
// import { Emoji } from 'emoji-mart';
import data from '@emoji-mart/data';
import { init } from 'emoji-mart';
import { motion } from 'framer-motion';
import { SONG_REACTIONS } from '../../../../enums/player';
import { Z_INDEXES } from '../../../../enums/zIndexes';
import useWindowSize from '../../../../lib/hooks/useWindowSize';
import { getRandomNumber } from '../../../../utils/general';
import { useElapsedTime } from '../../../EchoPlayer/ElapsedTimeProvider';

interface PropType {
  songReaction: ISongReaction;
  onAnimationComplete?: () => void;
  live?: boolean;
}

interface ReactionsBoxEmojiProps {
  songReaction: ISongReaction;
  marginLeft: number | undefined;
  handleAnimationComplete: any;
}

const ReactionsBoxEmoji: React.FC<ReactionsBoxEmojiProps> = ({ songReaction, marginLeft, handleAnimationComplete }) => {
  return (
    <motion.div
      key={songReaction.id}
      style={{
        zIndex: Z_INDEXES.RADIO_PLAYER__REACTION_BOX,
        position: 'absolute',
        marginLeft
      }}
      hidden={songReaction.showed}
      onAnimationComplete={handleAnimationComplete}
      initial={{ opacity: 0 }}
      animate={{ opacity: [0.13, 1, 0], y: -600, scale: [1, 1, 1.5, 1, 1] }}
      transition={{ duration: SONG_REACTIONS.SECONDS_TO_SHOW }}
    >
      {/*@ts-ignore*/}
      <em-emoji size={64} shortcodes={songReaction.emojiColons}></em-emoji>
      {/*<Emoji size={64} emoji={songReaction.emojiColons} />*/}
    </motion.div>
  );
};

const MemoizedReactionsBoxEmoji = React.memo(ReactionsBoxEmoji);

const ReactionsBox: React.FC<PropType> = ({ songReaction, onAnimationComplete, live }) => {
  const [marginLeft, setMarginLeft] = useState<number>();
  const [shouldRender, setShouldRender] = useState(false);
  const size = useWindowSize();
  const elapsed = useElapsedTime();

  init({ data });

  useEffect(() => {
    if (marginLeft === undefined && size && size.width) {
      setMarginLeft(getRandomNumber(10, size.containerWidth / 2 - 100));
    }
  }, [marginLeft, size.width]);

  const handleAnimationComplete = useCallback(() => {
    songReaction.showed = true;
    onAnimationComplete && onAnimationComplete();
  }, [onAnimationComplete]);

  if (elapsed === songReaction.songTime && !shouldRender && !live) {
    setShouldRender(true);
  }

  if (shouldRender || live) {
    return (
      <MemoizedReactionsBoxEmoji
        songReaction={songReaction}
        marginLeft={marginLeft}
        handleAnimationComplete={handleAnimationComplete}
      />
    );
  }

  return null;
};

export default ReactionsBox;
