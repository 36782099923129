/* eslint-disable @typescript-eslint/no-unused-vars */
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { Avatar, CircularProgress, Grid, IconButton, Slider } from '@mui/material';
import React, { useState } from 'react';
import { Z_INDEXES } from '../../enums/zIndexes';
import { useEchoPlayer } from '../EchoPlayer/EchoPlayerProvider';
// import VolumeComponent from '../RadioPlayerComponent/CoverSection/VolumeComponent';

interface PropType {
  playing: boolean;
  togglePlaying: () => void;
  loadingPlay: boolean;
  mute: boolean;
  setMute: (mute: boolean) => void;
  volume: number;
  setVolume: (volume: number) => void;
}

const ControlsSection: React.FC<PropType> = ({
  playing,
  togglePlaying,
  loadingPlay,
  mute,
  setMute,
  volume,
  setVolume
}) => {
  const player = useEchoPlayer();
  const [showVolumeSlider] = useState(true);

  const handleSliderValueChange = (_event: Event, newValue: number | number[]) => {
    newValue < 5 ? setVolume(0) : setVolume((newValue as number) / 100);
  };

  return (
    <>
      {/*<VolumeComponent iconSize="small" />*/}
      <Grid style={{ display: 'flex' }}>
        {showVolumeSlider ? (
          <Grid style={{ width: '8rem', marginRight: '0.8rem' }}>
            <Slider
              aria-label="Volume"
              orientation="horizontal"
              value={volume * 100}
              onChange={handleSliderValueChange}
              sx={{
                color: 'white',
                zIndex: Z_INDEXES.BOTTOM_BAR_PLAYER__CONTROL_SECTION__VOLUME_SLIDER,
                '& .MuiSlider-track': {
                  border: 'none'
                },
                '& .MuiSlider-thumb': {
                  width: 13,
                  height: 13,
                  backgroundColor: '#fff',
                  '&:before': {
                    boxShadow: '0 4px 8px rgba(0,0,0,0.4)'
                  },
                  '&:hover, &.Mui-focusVisible, &.Mui-active': {
                    boxShadow: 'none'
                  }
                }
              }}
            />
          </Grid>
        ) : (
          <Grid style={{ width: '8rem', marginRight: '0.8rem' }} />
        )}
        <IconButton size="small" onClick={() => setMute(!mute)}>
          <Avatar sx={{ bgcolor: 'white', width: 24, height: 24 }}>
            {mute ? (
              <VolumeOffIcon sx={{ fontSize: 16, color: 'black' }} />
            ) : (
              <VolumeUpIcon sx={{ fontSize: 16, color: 'black' }} />
            )}
          </Avatar>
        </IconButton>
      </Grid>

      <IconButton disabled={loadingPlay} size="small" onClick={() => togglePlaying()}>
        <Avatar sx={{ bgcolor: player.data.current.loading ? 'grey.500' : 'white', width: 24, height: 24 }}>
          {loadingPlay ? (
            <CircularProgress sx={{ color: 'white' }} size={10} />
          ) : (
            <>
              {playing ? (
                <PauseIcon sx={{ fontSize: 16, color: 'black' }} />
              ) : (
                <PlayArrowIcon sx={{ fontSize: 16, color: 'black' }} />
              )}
            </>
          )}
        </Avatar>
      </IconButton>
    </>
  );
};

export default ControlsSection;
