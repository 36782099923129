import React from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { PauseCircle } from '@mui/icons-material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { Avatar, CircularProgress, IconButton, Link as MuiLink, Typography } from '@mui/material';
import { generatePath, Link, useHistory } from 'react-router-dom';
import { ISongComponent } from '../../interfaces/ui-components/song.interface';
import routePaths from '../../lib/routePaths';
import { ReactComponent as AudioTrackIcon } from '../../theme/custom-icons/audiotrack_black_24dp.svg';
import AvatarButton from '../AvatarButtonComponent/AvatarButton';
import { useEchoPlayer } from '../EchoPlayer/EchoPlayerProvider';
import useAudioHelper from '../EchoPlayer/helpers/useAudioHelper';
import UpvoteButtonComponent from '../UpvoteButtonComponent/UpvoteButtonComponent';

interface BaseCellPropsType {
  song: ISongComponent;
  colKey: string;
  colMapping: any;
  handleDeleteButtonClick: (songId: number) => void;
}

const SongBaseCell: React.FC<BaseCellPropsType> = ({ song, colKey, colMapping, handleDeleteButtonClick }) => {
  const { songHasErroredResources } = useAudioHelper();
  const history = useHistory();
  const { getUrlFromSong } = useAudioHelper();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const player = useEchoPlayer();

  const button = (IconComponent: any, onClick?: () => void, loading = false, disabled = false) => (
    <IconButton disabled={loading || disabled} color="secondary" size="small" onClick={onClick ?? onClick}>
      {loading ? <CircularProgress size={20} /> : <IconComponent />}
    </IconButton>
  );

  const isSongAvailable = (songComponent: ISongComponent) => {
    return songComponent.song && getUrlFromSong(songComponent.song) !== undefined;
  };

  // const togglePlaySong = (songComponent: ISongComponent) => {
  //   const isSameSongPlaying = isSongPlayingIndividually(songComponent);
  //   if (isSameSongPlaying) {
  //     playerControls.song.togglePlay(songComponent.song, songComponent.communityId);
  //   } else {
  //     playerControls.song.play(songComponent.song, songComponent.communityId);
  //   }
  // };

  const isSameSongOnEchoPlayerAsIndividually = (songComponent: ISongComponent) => {
    const { song } = songComponent;
    if (!song || !player.data.song.song) {
      return false;
    }
    return player.data.song.song?.id === song?.id;
  };

  const isSounding = () => {
    // const { song } = songComponent;
    if (!song.song || !player.data.current.song) {
      return false;
    }
    return player.data.current.playing && player.data.current.song?.id === song.song?.id;
  };

  const isSongLoading = (songComponent: ISongComponent) => {
    return player.data.song.loading && isSameSongOnEchoPlayerAsIndividually(songComponent);
  };

  const isSongPlayingIndividually = (songComponent: ISongComponent) => {
    return player.data.song.playing && isSameSongOnEchoPlayerAsIndividually(songComponent);
  };

  const getListenIcon = (songComponent: ISongComponent) => {
    if (isSongPlayingIndividually(songComponent)) {
      return PauseCircle;
    }
    return PlayCircleIcon;
  };

  const showUpvoteButton =
    song.currentUserBoostCount !== undefined &&
    song.availableBoosts !== undefined &&
    song.id &&
    song.communityId &&
    song.votes !== undefined;

  const artistProfileLink = song.artistId
    ? generatePath(routePaths.private.artistDashboard.main, { artistId: song.artistId })
    : undefined;
  const songLink = generatePath(routePaths.private.songDetail, { songId: song.id, autoPlay: 'noAutoPlay' });
  const songLinkWithAutoPlay = generatePath(routePaths.private.songDetail, { songId: song.id, autoPlay: 'autoPlay' });
  // const erroredSongMessage = 'This song upload was interrumped and will be removed soon';

  switch (colKey) {
    case 'song':
      return !songHasErroredResources(song.song) ? (
        <MuiLink component={Link} to={songLinkWithAutoPlay}>
          {song.name}
        </MuiLink>
      ) : (
        <Typography>{song.name}</Typography>
      );
    case 'exclusive':
      return (
        <Typography>
          {song.totalNfts && song.totalNfts > 0 ? `${song.soldNfts}/${song.totalNfts}` : 'Not available'}
        </Typography>
      );
    case 'image':
      if (!songHasErroredResources(song.song)) {
        return (
          <div style={{ position: 'relative', width: 40, height: 40 }}>
            <AvatarButton
              sx={{
                opacity: isSounding() ? 0.6 : 1,
                filter: isSounding() ? 'brightness(1.5)' : undefined,
                position: 'absolute',
                width: 40,
                height: 40
              }}
              iconProps={{ fontSize: 'small' }}
              iconName="visibility_icon"
              onClick={() => history.push(songLink)}
              defaultImageUrl="assets/audiotrack_black_24dp.svg"
              // @ts-ignore
              src={song[colMapping.image.songPropertyName]}
            />
            {isSounding() && (
              <img
                style={{ paddingLeft: 5, paddingTop: 5, width: 35, height: 35 }}
                src="assets/icons8-audio-wave.gif"
              />
            )}
          </div>
        );
      } else {
        return (
          // @ts-ignore
          <Avatar src={song[colMapping.image.songPropertyName]}>
            <AudioTrackIcon />
          </Avatar>
        );
      }
    case 'artist':
      return artistProfileLink ? (
        <MuiLink component={Link} to={artistProfileLink}>
          {song.artist}
        </MuiLink>
      ) : (
        <Typography>{song.artist}</Typography>
      );
    case 'upvote':
      return <>{showUpvoteButton && <UpvoteButtonComponent songComponent={song} iconSize="small" />}</>;
    case 'listen':
      return button(
        getListenIcon(song),
        () => history.push(songLinkWithAutoPlay),
        isSongLoading(song),
        player.data.current.loading || !isSongAvailable(song)
      );
    case 'edit':
      // eslint-disable-next-line no-console
      return button(EditIcon, () => console.log(`edit song id ${song.id}`));
    case 'delete':
      return button(DeleteOutlineIcon, () => handleDeleteButtonClick(song.id ?? 0), song.deleting);
    default:
      // @ts-ignore
      return <Typography>{song[colMapping[colKey]?.songPropertyName]}</Typography>;
  }
};

export default SongBaseCell;
