import React, { useEffect, useState } from 'react';
import { Typography, Paper } from '@mui/material';
import { motion } from 'framer-motion';
import { SONG_COMMENTS } from '../../../../enums/player';
import { ISongComment } from '../../../../shared/interfaces/models/song-comment.interface';
import { secondsToTimeFormat } from '../../../../utils/date-utils';
import { getRandomNumber } from '../../../../utils/general';
import useWindowSize from '../../../../lib/hooks/useWindowSize';
import { Z_INDEXES } from '../../../../enums/zIndexes';

interface PropType {
  songComment: ISongComment;
  animationDistance?: number;
  style?: any;
}

const CommentsBox: React.FC<PropType> = ({ songComment, animationDistance = 600, style = {} }) => {
  const [marginLeft, setMarginLeft] = useState<number>();
  const size = useWindowSize();
  const onCommentHiden = () => {
    songComment.showed = true;
  };

  useEffect(() => {
    if (!marginLeft && size && size.width) {
      setMarginLeft(getRandomNumber(20, size.containerWidth / 2 - 220));
    }
  }, [marginLeft, size.width]);

  return (
    <motion.div
      style={{
        zIndex: Z_INDEXES.RADIO_PLAYER__COMMENT_BOX,
        width: 200,
        maxWidth: 200,
        marginLeft,
        ...style
      }}
      hidden={songComment.showed}
      onAnimationComplete={onCommentHiden}
      initial={{ opacity: 0 }}
      animate={{ opacity: [0, 1, 0], y: -animationDistance }}
      transition={{ duration: SONG_COMMENTS.SECONDS_TO_SHOW }}
    >
      <Paper sx={{ p: 1 }}>
        <Typography variant="caption" sx={{ textAling: 'left' }}>
          {songComment.user?.username} - at <strong>{secondsToTimeFormat(songComment.songTime)}</strong>
        </Typography>

        <Typography
          variant="body1"
          color="primary"
          sx={{
            textAling: 'left',
            fontSize: 14,
            fontWeight: 600
          }}
        >
          {songComment.message}
        </Typography>
      </Paper>
    </motion.div>
  );
};

export default CommentsBox;
