import axios from 'axios';
import { eventChannel } from 'redux-saga';
import { call, put, take } from 'redux-saga/effects';
import { ACTIONS } from '../../enums/actions';
import { HttpResponse } from '../../interfaces/api/http.interface';
import {
  ICompleteMultipartUploadPayload,
  IInitPresignedUrlMultipartUploadPayload,
  IInitPresignedUrlSimpleUploadPayload
} from '../../interfaces/requests/aws.interface';
import { IDispatchAction } from '../../interfaces/store/root.interface';
import { FILE_CHUNK_SIZE } from '../../shared/constants/file.const';

export function awsSubscribe(socket: any) {
  return eventChannel((emit) => {
    socket.on('aws/get-presigned-upload-urls-success', (data: unknown) => {
      emit({ type: ACTIONS.GET_PRESIGNED_UPLOAD_URLS_SUCCESS, payload: data });
    });
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  });
}

async function uploadMultipartFile(
  file: File,
  urls: string | string[],
  setProgress: (percent: number) => void = () => null
): Promise<unknown> {
  const httpClient = axios.create();
  delete httpClient.defaults.headers.put['Content-Type'];

  const fileSize = file.size;

  const resParts = [];
  let partNumber = 0;

  for (let start = 0; start < fileSize; start += FILE_CHUNK_SIZE) {
    let end = start + FILE_CHUNK_SIZE;
    if (end > fileSize) {
      end = fileSize;
    }
    const blob = file.slice(start, end);

    const part = await httpClient.put(urls[partNumber], blob, {
      onUploadProgress: (progressEvent) => {
        const { loaded } = progressEvent;
        const totalLoaded = loaded + FILE_CHUNK_SIZE * partNumber;
        const percent = Math.floor((totalLoaded / fileSize) * 100);
        if (setProgress) {
          setProgress(percent);
        }
      }
    });

    partNumber += 1;

    resParts.push({
      ETag: (part as any).headers.etag,
      PartNumber: partNumber
    });
  }

  return resParts;
}

async function uploadSimpleFile(
  file: File,
  url: string,
  setProgress: (percent: number) => void = () => null
): Promise<unknown> {
  return axios.put(url, file, {
    headers: {
      'Content-Type': 'application/octet-stream'
    },
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      const percentage = Math.floor((loaded * 100) / total);
      if (setProgress) {
        setProgress(percentage);
      }
    }
  });
}

const awsSaga = {
  *initPresignedUrlMultipartUpload(action: IDispatchAction): Generator {
    // TODO: Error handling.
    const payload = action.payload as IInitPresignedUrlMultipartUploadPayload;
    const response = yield call(uploadMultipartFile, payload.file, payload.urls, action.onProgress);

    yield put({ type: ACTIONS.INIT_PRESIGNED_URL_MULTIPART_UPLOAD_SUCCESS, payload: response });

    if (action.onSuccess) {
      action.onSuccess();
    }

    return response;
  },
  *initPresignedUrlSimpleUpload(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as IInitPresignedUrlSimpleUploadPayload;
      const response = (yield call(
        uploadSimpleFile,
        payload.file,
        payload.url,
        action.onProgress
      )) as HttpResponse<unknown>;
      if (response.status === 200 || response.status === 201) {
        yield put({ type: ACTIONS.INIT_PRESIGNED_URL_SIMPLE_UPLOAD_SUCCESS, payload: response });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        // eslint-disable-next-line no-console
        console.error('ERROR. response:', response);
        if (action.onFail) {
          action.onFail();
        }
      }
      return response;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('ERROR: ', e);
    }
    return null;
  },
  *completeMultiPartUpload(emitSocketMessage: any): Generator {
    while (true) {
      const action = (yield take(
        ACTIONS.COMPLETE_MULTIPART_UPLOAD
      )) as IDispatchAction<ICompleteMultipartUploadPayload>;
      emitSocketMessage('aws/complete-multipart-upload', action.payload);
    }
  }
};

export default awsSaga;
