// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { CircularProgress, IconButton, Stack, Badge, Tooltip, Avatar, Skeleton } from '@mui/material';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { getSongCommunity, updateUpvoteStatus } from '../../store/actions/songActions';
import { ISongVote } from '../../shared/interfaces/models/song-vote.interface';
import sparklingOutlinedIcon from '../../theme/custom-icons/sparkling-outlined-100.png';
import sparklingFilledIcon from '../../theme/custom-icons/sparkling-filled-96.png';
import { motion } from 'framer-motion';
import { ISongComponent } from '../../interfaces/ui-components/song.interface';
import { useSelector } from '../../store';
import { ILevelPermission } from '../../shared/interfaces/models/level-permission.interface';
import { DEFAULT_BOOST_WEIGHT } from '../../shared/constants/boost.const';
import useAnalytics from '../../lib/hooks/useAnalytics';
// import { useEchoPlayer } from '../EchoPlayer/EchoPlayerProvider';

interface PropsType {
  loading?: boolean;
  hideVotes?: boolean;
  songComponent?: ISongComponent;
  songId?: number;
  communityId?: number;
  showBadge?: boolean;
  iconSize?: 'small' | 'medium' | 'large';
  useWhiteCircleBg?: boolean;
}

interface IState {
  userBoost: number | undefined;
  votes: number | undefined;
  availableBoosts: number | undefined;
  songId: number | undefined;
  communityId: number | undefined;
}

const UpvoteButtonComponent: React.FC<PropsType> = ({
  loading,
  hideVotes,
  songComponent,
  songId,
  communityId,
  showBadge = true,
  iconSize = 'medium',
  useWhiteCircleBg
}) => {
  const { track } = useAnalytics();
  const { currentUserCommunity } = useSelector((state) => state.general);
  const boostWeight: number = useMemo(
    () =>
      currentUserCommunity?.level?.permissions?.find(
        (p: ILevelPermission) => p.communityId === currentUserCommunity?.communityId
      )?.boostWeight ?? DEFAULT_BOOST_WEIGHT,
    [currentUserCommunity]
  );
  const dispatch = useDispatch();
  // const player = useEchoPlayer();
  const [values, setValues] = useState<IState>({
    userBoost: undefined,
    votes: undefined,
    availableBoosts: undefined,
    songId: undefined,
    communityId: undefined
  });

  useEffect(() => {
    if (songComponent) {
      setValues({
        ...values,
        userBoost: songComponent.currentUserBoostCount ?? 0,
        votes: songComponent.votes ?? 0,
        availableBoosts: songComponent.availableBoosts ?? 0,
        songId: songComponent.song?.id,
        communityId: songComponent.communityId
      });
    } else if (songId && communityId) {
      const payload = { songId, communityId };
      // console.log('UpvoteButtonComponent payload: ', payload);
      dispatch(
        getSongCommunity(payload, (sc) => {
          if (sc) {
            setValues({
              ...values,
              userBoost: sc.currentUserBoostCount ?? 0,
              votes: sc.votes ?? 0,
              availableBoosts: sc.availableBoosts ?? 0,
              songId,
              communityId
            });
          }
        })
      );
    }
  }, [songId, communityId, songComponent]);

  const onReceiveResponse = (responseData: ISongVote) => {
    if (responseData) {
      setValues((oldValues) => ({
        ...oldValues,
        userBoost: responseData.boostCount,
        votes: (oldValues.votes ?? 0) + boostWeight,
        availableBoosts: (oldValues.availableBoosts ?? 0) - 1
      }));
    }
  };

  const handleUpdateUpvoteStatus = () => {
    if ((values.availableBoosts ?? 0) > 0) {
      const payload = { songId: values.songId, communityId: values.communityId };
      track('song_boost', {
        songId: values.songId,
        communityId: values.communityId,
        boostWeight
      });
      dispatch(updateUpvoteStatus(payload, onReceiveResponse));
    }
  };

  const _loading =
    loading || values.userBoost === undefined || values.availableBoosts === undefined || values.votes === undefined;

  const ButtonContainer: React.FC = ({ children }: any) => {
    return (
      <IconButton disabled={loading} size={iconSize} onClick={() => !_loading && handleUpdateUpvoteStatus()}>
        {useWhiteCircleBg ? <Avatar sx={{ bgcolor: loading ? 'grey.500' : 'white' }}>{children}</Avatar> : children}
      </IconButton>
    );
  };

  if (_loading) {
    return (
      <ButtonContainer>
        <CircularProgress size={20} />
      </ButtonContainer>
    );
  }

  const noAvailableBoostMessage = 'You can only boost this song once per day. Boost your favorite songs daily!';

  const upvoteButton = (
    <ButtonContainer>
      <Badge
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={showBadge ? values.userBoost : 0}
        color="primary"
      >
        {(values.availableBoosts ?? 0) > 0 ? (
          <img style={{ marginLeft: 2 }} width={24} height={24} src={sparklingOutlinedIcon} alt="boost icon" />
        ) : (
          <motion.div initial={{ scale: 1.0 }} animate={{ scale: [1.0, 1.5, 1.0] }} transition={{ duration: 0.4 }}>
            <img style={{ marginLeft: 2 }} width={24} height={24} src={sparklingFilledIcon} alt="boost icon" />
          </motion.div>
        )}
      </Badge>
    </ButtonContainer>
  );

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="right">
        {!hideVotes && values.votes}
        {(values.availableBoosts ?? 0) > 0 ? (
          upvoteButton
        ) : (
          <Tooltip title={noAvailableBoostMessage}>{upvoteButton}</Tooltip>
        )}
      </Stack>
    </>
  );
};

export default UpvoteButtonComponent;
