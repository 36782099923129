import { IonLoading } from '@ionic/react';
import { useEffect, useState } from 'react';
// import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import AdminRoute from './components/Authentication/AdminRoute';
import PrivateRoute from './components/Authentication/PrivateRoute';
import routePaths from './lib/routePaths';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import { useSelector } from './store';
// import { clearError } from './store/actions/generalActions';
import CustomStoryRenderer from './stories/CustomStoryRenderer';
import AdminTemplate from './templates/Admin/AdminTemplate';
import AppTemplate from './templates/AppTemplate';
import ResponsiveTemplate from './templates/Responsive/ResponsiveTemplate';
import { fullTreeObjectToArray } from './utils/general';
import { useDispatch } from 'react-redux';
import { getAppSettings } from './store/actions/authActions';

const AppContainer: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { loading, errorMessage, currentUser } = useSelector((state) => state.general);
  // const [present, dismiss] = useIonToast();
  // const dispatch = useDispatch();
  const allRoutes = fullTreeObjectToArray(routePaths);
  const routeMatch = useRouteMatch({ path: allRoutes, exact: true });
  const [pageNotFound] = useState(!routeMatch);

  useEffect(() => {
    dispatch(getAppSettings());
  }, []);

  useEffect(() => {
    if (pageNotFound) {
      history.replace(routePaths.public.notFound);
    }
  }, [pageNotFound]);

  useEffect(() => {
    history.listen((location) => {
      const customProps: any = {};
      !!currentUser && (customProps['echo_user_id'] = currentUser?.id);

      window.gtag('event', 'page_view', {
        // eslint-disable-next-line camelcase
        page_path: location.pathname + location.search + location.hash,
        // eslint-disable-next-line camelcase
        page_search: location.search,
        // eslint-disable-next-line camelcase
        page_hash: location.hash,
        ...customProps
      });
    });
  }, [currentUser]);

  useEffect(() => {
    if (errorMessage) {
      console.log('Error from backend: ', errorMessage);
      // present({
      //   position: 'top',
      //   buttons: [
      //     {
      //       text: 'hide',
      //       handler: () => {
      //         dispatch(clearError());
      //         dismiss();
      //       }
      //     }
      //   ],
      //   message: errorMessage
      // });
    }
  }, [errorMessage]);

  return (
    <>
      <Switch>
        <Redirect exact from={routePaths.public.landing} to={routePaths.private.app} />

        <PrivateRoute exact={true} path={routePaths.private.stories}>
          <CustomStoryRenderer />
        </PrivateRoute>
        {/* authenticated user routes */}
        <PrivateRoute path={routePaths.private.app}>
          <AppTemplate />
        </PrivateRoute>

        {/* admin routes */}
        <AdminRoute path={routePaths.admin.admin}>
          <AdminTemplate />
        </AdminRoute>

        <Route path={routePaths.public.notFound}>
          <NotFoundPage />
        </Route>

        {/* public routes */}
        <Route path="/">
          <ResponsiveTemplate />
        </Route>

        {loading && <IonLoading isOpen />}
      </Switch>
    </>
  );
};

export default AppContainer;
