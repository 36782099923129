/* eslint-disable */
import { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { IAuthContext } from '../../interfaces/general/auth-context.interface';
import { useSelector } from '../../store';
import {
  checkAuth,
  checkIfUserExists,
  getAppSettings,
  login,
  logout,
  register,
  registerWithInvitation
} from '../../store/actions/authActions';
import { getInitialData, setGeneralValue } from '../../store/actions/generalActions';
import { disconnectSocket, initSocket } from '../../store/actions/socketActions';
import { IRegisterPayload } from '../../shared/interfaces/custom/register-payload.interface';
import { ICheckIfUserExistsPayload } from '../../shared/interfaces/custom/check-if-user-exists-payload.interface';

const authContext = createContext<IAuthContext>({
  isAuthenticated: false,
  login: () => {},
  register: () => {},
  checkIfUserExists: () => {},
  logout: () => {},
  registerWithInvitation: () => {}
});

export const ProvideAuth: React.FC<{ children: unknown }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { currentUser } = useSelector((state) => state.general);

  const onAuthenticated = (hasAuthentication: boolean) => {
    if (hasAuthentication) {
      dispatch(
        initSocket(() => {
          dispatch(setGeneralValue('loading', false));
          dispatch(getInitialData());
          dispatch(getAppSettings());
          dispatch(setGeneralValue('socketInitialized', true));
          setIsAuthenticated(hasAuthentication);
        })
      );
    } else {
      setIsAuthenticated(hasAuthentication);
    }
  };

  useEffect(() => {
    dispatch(
      checkAuth(
        () => onAuthenticated(true),
        () => onAuthenticated(false)
      )
    );
  }, []);

  useEffect(() => {
    if (currentUser && !isAuthenticated) {
      setIsAuthenticated(true);
    } else if (!currentUser && isAuthenticated) {
      setIsAuthenticated(false);
    }
  }, [currentUser]);

  const onSuccessLogin = (cb: unknown) => {
    dispatch(
      initSocket(() => {
        dispatch(setGeneralValue('loading', false));
        dispatch(getInitialData());
        dispatch(getAppSettings());
        dispatch(setGeneralValue('socketInitialized', true));
        window.gtag('event', 'login');
        setIsAuthenticated(true);
        history.push('/app');
        if (cb) {
          (cb as () => void)();
        }
      })
    );
  };

  const onSuccessRegister = () => {
    // dispatch(
    //   initSocket(() => {
    //     dispatch(setGeneralValue('loading', false));
    //     dispatch(getInitialData());
    //     dispatch(setGeneralValue('socketInitialized', true));
    //     setIsAuthenticated(true);
    //     history.push('/app');
    //   })
    // );
  };

  const onSocketDisconnected = () => {
    dispatch(
      logout(() => {
        history.push('/app');
        dispatch(getAppSettings());
      })
    );
    setIsAuthenticated(false);
  };

  const onFailLogin = (error: any, cb: unknown) => {
    dispatch(setGeneralValue('loading', false));
    if (cb) {
      (cb as (error: any) => void)(error);
    }
  };

  const onSuccessRegisterWithInvitation = () => {
    dispatch(setGeneralValue('loading', false));
  };

  const onFailRegisterWithInvitation = () => {
    dispatch(setGeneralValue('loading', false));
  };

  const auth = {
    isAuthenticated,
    login: (email: string, password: string, onSuccess: unknown, onFail: unknown) =>
      dispatch(
        login(
          { email, password },
          () => onSuccessLogin(onSuccess),
          (error: any) => onFailLogin(error, onFail)
        )
      ),
    logout: () => {
      dispatch(disconnectSocket(onSocketDisconnected));
    },
    register: (payload: IRegisterPayload, onSuccessRegister: any, onFail: any) => {
      dispatch(register(payload, onSuccessRegister, onFail));
    },
    checkIfUserExists: (payload: ICheckIfUserExistsPayload, onSuccess: any, onFail: any) => {
      dispatch(checkIfUserExists(payload, onSuccess, onFail));
    },
    registerWithInvitation: (invitationId: number, firstName: string, lastName: string, password: string) =>
      dispatch(
        registerWithInvitation(
          { invitationId, firstName, lastName, password },
          () => onSuccessRegisterWithInvitation,
          onFailRegisterWithInvitation
        )
      )
  };

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export function useAuth() {
  return useContext(authContext);
}
