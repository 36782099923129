import React from 'react';
import { Stack } from '@mui/material';
import SectionTitleComponent from '../../components/SectionTitleComponent/SectionTitleComponent';
import SongBaseTable from '../../components/SongBaseTableComponent/SongBaseTable';

interface PropsType {
  title: string;
  songList?: any[];
}

const CurationHistorySection: React.FC<PropsType> = ({ title, songList }) => {
  const handleViewAll = () => {
    // eslint-disable-next-line no-console
    console.log('viewAll implementation...');
  };

  const colsToShow = ['image', 'song', 'artist', 'rank', 'exclusive', 'listen'];

  return (
    <Stack spacing={3}>
      <SectionTitleComponent title={title} onViewAll={handleViewAll} />
      {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
      {songList && <SongBaseTable setSongList={() => {}} songList={songList} colsToShow={colsToShow} />}
    </Stack>
  );
};

export default CurationHistorySection;
