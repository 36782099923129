// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Avatar, IconButton, Skeleton, Stack, Typography, CircularProgress } from '@mui/material';
import React, { useMemo, useRef } from 'react';
import { RESOURCE_STATUS } from '../../../../../shared/constants/resource.const';
import { APP_SECTION } from '../../../../../shared/constants/upload.const';
import { IMAGE_SIZES } from '../../../../../shared/interfaces/custom/image-preset-settings.interface';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { Z_INDEXES } from '../../../../../enums/zIndexes';
import { useEchoPlayer } from '../../../../EchoPlayer/EchoPlayerProvider';
import useAudioHelper from '../../../../EchoPlayer/helpers/useAudioHelper';
import { ISong } from '../../../../../shared/interfaces/models/song.interface';
import VolumeComponent from '../../../../SharedPlayerSections/VolumeComponent';
import TimeSection from '../../../../SharedPlayerSections/TimeSection';
import { ElapsedTimeProvider } from '../../../../EchoPlayer/ElapsedTimeProvider';
import { generatePath, useHistory } from 'react-router-dom';
import routePaths from '../../../../../lib/routePaths';

interface PropType {
  song?: ISong;
}

const CoverSection: React.FC<PropType> = ({ song }) => {
  const history = useHistory();
  const { getUrlFromSong } = useAudioHelper();
  const { controls: playerControls, data: playerData } = useEchoPlayer();
  const reactionAnchorRef = useRef<any>();

  const coverImageResource = song?.resources?.find(
    (r) =>
      r.appSection === APP_SECTION.SONG_COVER && r.status === RESOURCE_STATUS.AVAILABLE && r.data.size === IMAGE_SIZES.L
  );

  // const artistProfileLink = song?.artistId
  //   ? generatePath(routePaths.private.artistDashboard.main, { artistId: song.artistId })
  //   : undefined;

  const artistProfileLink = useMemo(() => {
    if (!song) return undefined;
    return generatePath(routePaths.private.artistDashboard.main, { artistId: song.artistId });
  }, [song]);

  const songLinkWithAutoPlay = useMemo(() => {
    if (!song) return undefined;
    return generatePath(routePaths.private.songDetail, { songId: song?.id, autoPlay: 'autoPlay' });
  }, [song]);

  const handleSongTitleClick = () => {
    songLinkWithAutoPlay && history.push(songLinkWithAutoPlay);
  };

  const handleSongArtistClick = () => {
    artistProfileLink && history.push(artistProfileLink);
  };

  return (
    <Stack direction="column" justifyContent="flex-start" alignItems="center" spacing={3} sx={{ py: 3 }}>
      <Stack
        sx={{ zIndex: Z_INDEXES.RADIO_PLAYER__COVER_SECTION }}
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <VolumeComponent buttonColor="white" iconColor="black" openDirection="bottom" />
        <div>
          {playerData ? (
            <Avatar
              ref={reactionAnchorRef}
              alt={song?.name}
              src={coverImageResource?.url}
              sx={{ width: 85, height: 85 }}
            />
          ) : (
            <Skeleton variant="circular" width={85} height={85} />
          )}
        </div>

        {/*todo: check if togglePlay needs params*/}
        <IconButton disabled={playerData.current.loading} onClick={() => playerControls.song.togglePlay(song)}>
          <Avatar sx={{ bgcolor: playerData.current.loading ? 'grey.500' : 'white' }}>
            {playerData.song.loading ? (
              <CircularProgress size={20} />
            ) : (
              <>
                {playerData.song.playing ? (
                  <PauseIcon sx={{ color: 'black' }} />
                ) : (
                  <PlayArrowIcon sx={{ color: 'black' }} />
                )}
              </>
            )}
          </Avatar>
        </IconButton>
      </Stack>

      {/* eslint-disable-next-line no-inline-styles/no-inline-styles */}
      <div style={{ textAlign: 'center' }}>
        {song && getUrlFromSong(song) === undefined && (
          <>
            <Typography variant="h5" sx={{ fontWeight: 700, fontSize: 20 }}>
              This song is unavailable for the moment :(
            </Typography>
            <Typography variant="caption">We'll be back soon</Typography>
          </>
        )}
        <Typography
          onClick={handleSongTitleClick}
          variant="h5"
          sx={{ fontWeight: 700, fontSize: 20, cursor: songLinkWithAutoPlay ? 'pointer' : 'default' }}
        >
          {song ? song?.name : <Skeleton width={200} />}
        </Typography>
        <Typography
          onClick={handleSongArtistClick}
          variant="caption"
          sx={{ fontSize: 16, cursor: artistProfileLink ? 'pointer' : 'default' }}
        >
          {song ? song?.user?.username : <Skeleton width={200} />}
        </Typography>{' '}
        <br />
        <ElapsedTimeProvider>
          <TimeSection />
        </ElapsedTimeProvider>
      </div>
    </Stack>
  );
};

export default CoverSection;
