// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Avatar, MenuItem, Skeleton, Typography, Stack, Icon, LinearProgress } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import { SxProps } from '@mui/system';
import * as React from 'react';
import { useEffect } from 'react';
import { generatePath, Link, useHistory, useRouteMatch } from 'react-router-dom';
import { Z_INDEXES } from '../../enums/zIndexes';
import routePaths from '../../lib/routePaths';
import { APP_SECTION_IMAGE } from '../../shared/constants/upload.const';
import { IMAGE_SIZES } from '../../shared/interfaces/custom/image-preset-settings.interface';
import { useSelector } from '../../store';
import { useAuth } from '../Authentication/ProvideAuth';
import './GeneralBar.scss';
import FlareIcon from '@mui/icons-material/Flare';
// import ChildCareIcon from '@mui/icons-material/ChildCare';
import capitalize from 'lodash/capitalize';
import { motion } from 'framer-motion';
import { ILevel } from '../../shared/interfaces/models/level.interface';
import { useDispatch } from 'react-redux';
import { getLowestLevel } from '../../store/actions/communitiesActions';
import InviteUserDialog from '../UserInfoComponent/InviteUserDialog';
// import { useDispatch } from 'react-redux';

interface PropsType {
  sx?: SxProps;
}

const GeneralBarComponent: React.FC<PropsType> = ({ sx }) => {
  const auth = useAuth();
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    currentUser,
    newRealTimeUserPoint,
    newRealTimeUserLevel,
    currentUserCommunity,
    currentUserCommunityLoading,
    appSettings
  } = useSelector((state) => state.general);
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [userMenuOptions, setUserMenuOptions] = React.useState<any[]>();
  const [avatarUrl, setAvatarUrl] = React.useState<string | undefined>();
  const [showNewPointsAnimation, setShowNewPointsAnimation] = React.useState(false);
  const [showNewLevelAnimation, setShowNewLevelAnimation] = React.useState(false);
  const [defaultLevel, setDefaultLevel] = React.useState<ILevel>();
  const [showInviteUserDialog, setShowInviteUserDialog] = React.useState(false);

  const open = Boolean(anchorEl);
  const communityRouteMatch = useRouteMatch([
    routePaths.private.curation.top,
    routePaths.private.curation.new,
    routePaths.private.radio,
    routePaths.private.chat
  ]);

  useEffect(() => {
    if (!currentUserCommunity && !currentUserCommunityLoading) {
      dispatch(
        getLowestLevel((level) => {
          setDefaultLevel(level);
        })
      );
    }
  }, [currentUserCommunity, currentUserCommunityLoading]);

  useEffect(() => {
    if (newRealTimeUserPoint !== undefined) {
      setShowNewPointsAnimation(true);
      setTimeout(() => {
        setShowNewPointsAnimation(false);
      }, 500);
    }
  }, [newRealTimeUserPoint]);

  useEffect(() => {
    if (newRealTimeUserLevel !== undefined) {
      setShowNewLevelAnimation(true);
      setTimeout(() => {
        setShowNewLevelAnimation(false);
      }, 500);
    }
  }, [newRealTimeUserLevel]);

  useEffect(() => {
    if (currentUser && appSettings) {
      const newSettings = [];
      newSettings.push({
        text: 'Profile',
        url: generatePath(routePaths.private.artistDashboard.main, { artistId: currentUser.id })
      });

      appSettings.userInvitationEnabled &&
        newSettings.push({ text: 'Invite user', action: () => setShowInviteUserDialog(true) });

      newSettings.push(
        // { text: 'Profile', url: generatePath(routePaths.private.curatorProfile, { userId: currentUser.id }) },
        { text: 'Logout', action: auth.logout }
      );

      const userProfileImageXs = currentUser.resources?.find(
        (r) => r.appSection === APP_SECTION_IMAGE.user_profile && r.data.size === IMAGE_SIZES.S
      );

      if (userProfileImageXs) {
        setAvatarUrl(userProfileImageXs?.url);
      }
      setUserMenuOptions(newSettings);
    }
  }, [currentUser, appSettings]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseNavMenu = (item: any) => {
    item.url && history.push(item.url);
    item.action && item.action();
  };

  // const levelPercent = useMemo(() => {
  //   return currentUserCommunity
  //     ? ((currentUserCommunity.points ?? 0) * 100) / (currentUserCommunity.level?.maxPoints ?? 1)
  //     : 0;
  // }, [currentUserCommunity]);

  return (
    <AppBar sx={{ ...sx, bgcolor: '#333333' }} position="sticky">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ height: '48px', minHeight: '48px' }}
      >
        <Link to={generatePath(routePaths.private.app)}>
          <img src="./assets/register/echo_icon.png" alt="logo" className="mr-10 logo" />
        </Link>

        {currentUser ? (
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
            {!currentUserCommunityLoading && communityRouteMatch ? (
              <Stack spacing="4px">
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Stack direction="row" justifyContent="flex-end" alignItems="center">
                    {showNewPointsAnimation ? (
                      <motion.div
                        initial={{ scale: 1.0 }}
                        animate={{ scale: [1.0, 1.5, 1.0] }}
                        transition={{ duration: 0.5 }}
                      >
                        <FlareIcon fontSize="small" sx={{ mr: 1 }} />
                      </motion.div>
                    ) : (
                      <FlareIcon fontSize="small" sx={{ mr: 1 }} />
                    )}
                    <Typography variant="caption">{`${currentUserCommunity?.points ?? 0} / ${
                      currentUserCommunity?.level?.maxPoints ?? defaultLevel?.maxPoints ?? 100
                    }`}</Typography>
                  </Stack>

                  <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                    {showNewLevelAnimation ? (
                      <>
                        <motion.div
                          initial={{ scale: 1.0 }}
                          animate={{ scale: [1.0, 1.5, 1.0] }}
                          transition={{ duration: 0.5 }}
                        >
                          <Icon fontSize="small">{currentUserCommunity?.level?.icon ?? 'diamond'}</Icon>
                        </motion.div>
                        <Typography variant="caption">
                          {capitalize(currentUserCommunity?.level?.name ?? defaultLevel?.name ?? 'No rank')}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Icon fontSize="small">{currentUserCommunity?.level?.icon ?? 'diamond'}</Icon>
                        <Typography variant="caption">
                          {capitalize(currentUserCommunity?.level?.name ?? defaultLevel?.name ?? 'No rank')}
                        </Typography>
                      </>
                    )}
                  </Stack>
                </Stack>
                {/*<LinearProgress variant="determinate" color="inherit" value={levelPercent} />*/}
              </Stack>
            ) : (
              currentUserCommunityLoading && <Skeleton sx={{ width: 200 }} />
            )}

            <div className="dropdown">
              <input type="checkbox" id="dropdown" />

              <label className="dropdown__face cursor-pointer" htmlFor="dropdown" onClick={handleClick}>
                <div className=" flex items-center">
                  <IconButton
                    sx={{ pr: 1 }}
                    size="small"
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                  >
                    <Avatar alt="" className="avatar-profile" src={avatarUrl} />
                  </IconButton>
                  <Typography
                    variant="caption"
                    className="dropdown__text text-center text-white font-bold truncate"
                    sx={{ fontSize: '12px', paddingTop: '6px', paddingBottom: '6px', paddingLeft: '6px' }}
                  >
                    {currentUser.username}
                  </Typography>
                </div>

                <div className="dropdown__arrow"></div>
              </label>

              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    bgcolor: 'rgba(0, 0, 0, 0.4);',
                    color: 'white',
                    fontWeight: 'bold',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'rgba(0, 0, 0, 0.4)',
                      color: 'white',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: Z_INDEXES.GENERAL_BAR_COMPONENT_PROFILE_DROPDOWN
                    }
                  }
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                {userMenuOptions?.map((item: any) => (
                  <MenuItem onClick={() => handleCloseNavMenu(item)} className="text-center" key={item.text}>
                    <Typography variant="caption" className="dropdown__text text-center text-white px-4 font-bold">
                      {item.text}
                    </Typography>
                  </MenuItem>
                ))}
                {/*<MenuItem sx={{ '&:hover': { backgroundColor: 'transparent' } }}>*/}
                {/*  <Typography variant="caption" color="darkgray" className="dropdown__text text-center pl-10 font-bold">*/}
                {/*    Connect Wallet*/}
                {/*  </Typography>*/}
                {/*</MenuItem>*/}
              </Menu>
            </div>
          </Stack>
        ) : (
          <Skeleton
            variant="rectangular"
            sx={{ width: 150, height: 20, bgcolor: 'grey.700', borderRadius: 4 }}
          ></Skeleton>
        )}
      </Stack>
      <InviteUserDialog showDialog={showInviteUserDialog} setShowDialog={setShowInviteUserDialog} />
    </AppBar>
  );
};

export default GeneralBarComponent;
