import { useEffect, useRef } from 'react';

function useOnClickOutside(onClickOutside: () => void) {
  const ref = useRef<any>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target)) {
      onClickOutside();
    }
  };

  useEffect(() => {
    document.addEventListener('mouseup', handleClickOutside, true);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside, true);
    };
  }, []);

  return { ref };
}

const OutsideAlerter = ({ onClickOutside, children }: { onClickOutside: () => void; children: any }) => {
  const { ref } = useOnClickOutside(onClickOutside);
  return <div ref={ref}>{children}</div>;
};

export default OutsideAlerter;
