/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Box, Dialog, DialogTitle, TextField, Typography, DialogContent, DialogActions, Stack } from '@mui/material';
import SectionTitleComponent from '../../components/SectionTitleComponent/SectionTitleComponent';
import SongBaseTable from '../../components/SongBaseTableComponent/SongBaseTable';
import { createArtistRequest } from '../../store/actions/artistActions';
import { getMe } from '../../store/actions/userActions';
import { useSelector } from '../../store';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { LoadingButton } from '@mui/lab';

interface PropsType {
  modalOpen: boolean;
  setModalOpen: (modalOpen: boolean) => void;
}

const ArtistRequest: React.FC<PropsType> = ({ modalOpen = true, setModalOpen }) => {
  const dispatch = useDispatch();

  const { currentUser } = useSelector((state) => state.general);
  // const [_modalOpen, _setModalOpen] = useState(true);
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   if (modalOpen !== undefined) {
  //     _setModalOpen(modalOpen);
  //   }
  // }, [modalOpen]);

  const formik = useFormik({
    initialValues: {
      artistRequest: ''
    },
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: object().shape({
      artistRequest: string().required().min(2).max(255)
    }),
    onSubmit: () => {
      setLoading(true);
      dispatch(
        createArtistRequest({ artistName: formik.values.artistRequest }, (successResult) => {
          if (successResult) {
            dispatch(
              getMe(() => {
                setLoading(false);
                setModalOpen(false);
              })
            );
          } else {
            setLoading(false);
            formik.setFieldError('artistRequest', 'Something wrong happened, try again.');
          }
        })
      );
    }
  });

  const handleCloseModal = () => {
    !loading && setModalOpen(false);
  };

  const colsToShow = ['image', 'song', 'artist', 'rank', 'exclusive', 'listen'];

  return (
    <Dialog fullWidth onClose={handleCloseModal} disableEscapeKeyDown={loading} open={modalOpen}>
      <Stack sx={{ p: 2 }} spacing={2}>
        <DialogTitle>
          <Typography variant="h2">Request to be an artist</Typography>
        </DialogTitle>

        <Box sx={{ px: 3 }}>
          <TextField
            onBlur={() => formik.setFieldError('artistRequest', undefined)}
            disabled={loading}
            onChange={formik.handleChange}
            error={!!formik.errors.artistRequest}
            helperText={formik.errors.artistRequest ? formik.errors.artistRequest : undefined}
            name="artistRequest"
            label="Insert your artist name"
            variant="outlined"
            fullWidth
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                formik.handleSubmit();
              }
            }}
          />
        </Box>
        <DialogActions>
          <LoadingButton loading={loading} onClick={() => setModalOpen(false)}>
            Cancel
          </LoadingButton>
          <LoadingButton loading={loading} onClick={() => formik.handleSubmit()} variant="contained" autoFocus>
            Send
          </LoadingButton>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};

export default ArtistRequest;
