/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import {
  Button,
  Divider,
  Skeleton,
  Stack,
  TableBody,
  TableCell,
  TableFooter,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import SongBaseCell from './SongBaseCell';
import { ISongComponent } from '../../interfaces/ui-components/song.interface';
import useAudioHelper from '../EchoPlayer/helpers/useAudioHelper';
import { useDispatch } from 'react-redux';
import { deleteSong } from '../../store/actions/artistActions';
import ConfirmationDialogComponent from '../ConfirmationDialogComponent/ConfirmationDialogComponent';
import { useSelector } from '../../store';
import { USER_ROLE } from '../../shared/constants/user.const';
import useAnalytics from '../../lib/hooks/useAnalytics';

interface BaseTableBodyPropsType {
  songList: ISongComponent[];
  colMapping: any;
  colsToShow: string[];
  skeletonRows?: number;
  separateTop?: number;
  hasMore?: boolean;
  loadMore?: () => void;
}

const SongBaseTableBody: React.FC<BaseTableBodyPropsType> = ({
  songList,
  colMapping,
  colsToShow,
  skeletonRows,
  separateTop,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  hasMore = false,
  loadMore
}) => {
  const { track } = useAnalytics();
  const { currentUser } = useSelector((state) => state.general);
  const { songHasErroredResources } = useAudioHelper();
  const erroredSongMessage =
    'The upload of this song has been interrupted and has had errors. Please upload it again. (Only sys-admin users can see this song).';
  const styles = {
    rowCell: { fontSize: 16, fontWeight: 600, borderBottom: 'none' }
  };

  const dispatch = useDispatch();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [songIdToDelete, setSongIdToDelete] = useState<number | undefined>(undefined);

  const handleDeleteButtonClick = (songId: number) => {
    setSongIdToDelete(songId);
    setShowDeleteConfirmation(true);
  };

  const handleConfirmClick = () => {
    handleDeleteSong();
    setShowDeleteConfirmation(false);
  };

  const handleDeleteSong = () => {
    if (songIdToDelete) {
      const songIndexToDelete = songList.findIndex((song) => song.id === songIdToDelete);
      songList[songIndexToDelete].deleting = true;
      dispatch(
        deleteSong(
          {
            songId: songIdToDelete
          },
          () => {
            track('song_deleted', { songId: songIdToDelete });
            songList[songIndexToDelete].deleting = false;
            setSongIdToDelete(undefined);
          }
        )
      );
    }
  };

  const renderTableRow = (song: ISongComponent) => {
    return (
      <TableRow key={song.id} hover>
        {colsToShow.map((colToShow) => (
          <TableCell key={colToShow} sx={styles.rowCell} align={colToShow === 'upvote' ? 'right' : 'left'}>
            <SongBaseCell
              song={song}
              colKey={colToShow}
              colMapping={colMapping}
              handleDeleteButtonClick={handleDeleteButtonClick}
            />
          </TableCell>
        ))}
      </TableRow>
    );
  };

  return (
    <>
      <TableBody>
        {songList.map((song, index) => (
          <React.Fragment key={index}>
            {separateTop && separateTop === index && (
              <TableRow key={`warning-text-${index}`}>
                <TableCell sx={styles.rowCell} colSpan={colsToShow.length}>
                  <Typography variant="caption">The next songs aren't included in the radio playlist</Typography>
                  <Divider />
                </TableCell>
              </TableRow>
            )}
            {songHasErroredResources(song.song) && currentUser?.role === USER_ROLE.SYSADMIN ? (
              <Tooltip title={erroredSongMessage}>{renderTableRow(song)}</Tooltip>
            ) : (
              !songHasErroredResources(song.song) && renderTableRow(song)
            )}
          </React.Fragment>
        ))}
        {skeletonRows &&
          [...Array(skeletonRows)].map((_, i) => (
            <TableRow key={`skel-${i}`}>
              {colsToShow.map((colToShow) => (
                <TableCell key={colToShow} sx={styles.rowCell} align={colToShow === 'upvote' ? 'right' : 'left'}>
                  <Typography variant="body1">
                    <Skeleton variant="rectangular" width="100%" sx={{ ml: 1, mt: 1 }} />
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          ))}
      </TableBody>
      {hasMore && loadMore && (
        <TableFooter>
          <TableRow>
            <TableCell sx={styles.rowCell} colSpan={colsToShow.length}>
              <Stack alignItems="center">
                <Button onClick={loadMore}>Load more</Button>
              </Stack>
            </TableCell>
          </TableRow>
        </TableFooter>
      )}

      <ConfirmationDialogComponent
        text={'Are you sure you want to delete this song?'}
        acceptText={'Delete song'}
        cancelText={'No'}
        showDialog={showDeleteConfirmation}
        setShowDialog={setShowDeleteConfirmation}
        onAccept={handleConfirmClick}
      />
    </>
  );
};

export default SongBaseTableBody;
