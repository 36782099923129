/* eslint-disable @typescript-eslint/no-unused-vars */
import LoadingButton from '@mui/lab/LoadingButton';
import { Chip, Stack, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { object, string } from 'yup';
import routePaths from '../../lib/routePaths';
import { requestPasswordReset } from '../../store/actions/authActions';
import { setToasterMessage } from '../../store/actions/generalActions';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const RequestPasswordResetForm: React.FC = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [success, setSuccess] = useState<boolean | undefined>();
  const history = useHistory();
  // const auth = useAuth();

  const requestPasswordResetValidationSchema = object().shape({
    email: string().email().required()
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: requestPasswordResetValidationSchema,
    onSubmit: () => {
      setError(undefined);
      setLoading(true);
      dispatch(
        requestPasswordReset(
          { email: formik.values.email },
          () => {
            setLoading(false);
            setSuccess(true);
            // history.replace(routePaths.public.login);
            dispatch(setToasterMessage("You'll receive an email shortly."));
          },
          () => {
            setError('Email not found');
            setLoading(false);
          }
        )
      );
    }
  });

  const handleSubmit = () => {
    formik.handleSubmit();
  };

  return (
    <>
      {!success ? (
        <>
          <Stack sx={{ mt: 6 }} spacing={2}>
            <TextField
              onBlur={() => setError(undefined)}
              disabled={loading}
              onChange={formik.handleChange}
              error={!!formik.errors.email}
              helperText={formik.errors.email ? formik.errors.email : undefined}
              name="email"
              label="Email"
              variant="outlined"
              fullWidth
            />
          </Stack>

          <LoadingButton
            loading={loading}
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            size="large"
            sx={{ mt: 4, width: '100%', borderRadius: 10 }}
          >
            REQUEST PASSWORD RESET
          </LoadingButton>
          {error && (
            <Typography variant="body2" sx={{ mt: 2, color: 'red' }}>
              {error}
            </Typography>
          )}
        </>
      ) : (
        <Stack direction="row" spacing={1} sx={{ mt: 4 }} alignItems="center">
          <CheckCircleIcon sx={{ color: 'success.main' }} />
          <Typography color="success.main" variant="h3">
            Check your inbox, you'll receive an email shortly.
          </Typography>
        </Stack>
      )}
    </>
  );
};

export default RequestPasswordResetForm;
