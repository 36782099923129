import { dataInitValues } from '../../components/EchoPlayer/info/usePlayerInfo';
import { ACTIONS } from '../../enums/actions';
import { IGeneral } from '../../interfaces/store/general.interface';
import { IReduceActionGeneral } from '../../interfaces/store/root.interface';
import { SHARED_ACTIONS } from '../../shared/constants/actions.const';
import { IUser } from '../../shared/interfaces/models/user.interface';
// import { IResource } from '../../shared/interfaces/models/resource.interface';
// import { ISong } from '../../shared/interfaces/models/song.interface';
// import { songHasAllResourcesAvailables } from '../../utils/general';
// import { ISong } from '../../shared/interfaces/models/song.interface';
// import { APP_SECTION } from '../../shared/constants/upload.const';

export const initialGeneralState: IGeneral = {
  currentUser: null,
  errorMessage: '',
  loading: false,
  shouldSocketReconnect: false,
  socketInitialized: false,
  uploads: [],
  uploadSongs: [],
  uploadModalState: false,
  pagination: {},
  newRealTimeSongComment: undefined,
  newRealTimeSongReaction: undefined,
  newRealTimeUserPoint: undefined,
  newRealTimeUserLevel: undefined,
  currentUserCommunity: undefined,
  currentUserCommunityLoading: false,
  radioStreamingInfo: undefined,
  tasks: [],
  playerData: dataInitValues,
  songDetail: undefined,
  songDeleted: [],
  appSettings: undefined
};

export default (state: IGeneral = initialGeneralState, action: IReduceActionGeneral): IGeneral => {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return { ...state, currentUser: action.data as IUser };
    case ACTIONS.LOGOUT_SUCCESS:
      return { ...state, ...initialGeneralState };
    case ACTIONS.TOASTER_MESSAGE:
    case ACTIONS.ERROR:
      return { ...state, errorMessage: action.data as string };
    case ACTIONS.CLEAR_ERROR:
      return { ...state, errorMessage: '' };
    case ACTIONS.SET_GENERAL_VALUE:
      return {
        ...state,
        [(action as any).data.prop]: (action as any).data.value
      };
    case SHARED_ACTIONS.SET_UPDATE_ME_DATA:
      return {
        ...state,
        currentUser: { ...state.currentUser, ...(action.data as IUser) }
      };
    case ACTIONS.SET_UPLOAD_SONGS_SUCCESS:
      return { ...state, uploads: [action.data as any] };
    case ACTIONS.SET_PLAYER_DATA:
      return { ...state, playerData: action.data as any };
    case SHARED_ACTIONS.DELETE_SONG_SUCCESS: {
      return { ...state, songDeleted: [action.data as number] };
    }
    // case SHARED_ACTIONS.SONG_UPLOAD_FINISHED: {
    //   console.log('[SONG_UPLOAD_FINISHED] action: ', action);
    //   const result = action.data as { song?: ISong; resource: IResource };
    //   console.log('[SONG_UPLOAD_FINISHED] result:', result);
    //   const songId = result?.song?.id ?? result?.resource?.associatedId;
    //   if (!songId) {
    //     console.log('[SONG_UPLOAD_FINISHED] songId not found, data:', result);
    //     return state;
    //   }
    //   const success = songHasAllResourcesAvailables(result.song);
    //   // const tmpUploadSongList = state.uploadSongs;
    //   // console.log({ tmpUploadSongList });
    //   // const uploadSongObj = tmpUploadSongList.find((us) => us.song?.id === songId);
    //   // if (uploadSongObj) {
    //   //   const success = songHasAllResourcesAvailables(result.song);
    //   //   tmpUploadSongList[uploadSongObj.index].success = success;
    //   //   console.log('[SONG_UPLOAD_FINISHED] success', success);
    //   //   console.log(
    //   //     '[SONG_UPLOAD_FINISHED] tmpUploadSongList[uploadSongObj.index]: ',
    //   //     tmpUploadSongList[uploadSongObj.index]
    //   //   );
    //   // } else {
    //   //   console.log('[SONG_UPLOAD_FINISHED] song not found, songId:', songId);
    //   // }
    //   return { ...state, currentUploadSongSuccess: success };
    // }
    case SHARED_ACTIONS.SONG_UPLOAD_ERROR: {
      return { ...state, songDeleted: [action.data as number] };
    }
    default:
      return state;
  }
};
