import { ACTIONS } from '../../enums/actions';
import { IDispatchAction } from '../../interfaces/store/root.interface';
import { ISongWithParts } from '../../shared/interfaces/custom/song-with-parts.interface';
import { Paginated } from '../../shared/interfaces/responses/paginated.interface';
import { getPaginationInfo } from '../../shared/utils/pagination';
import { IUser } from '../../shared/interfaces/models/user.interface';
import { SHARED_ACTIONS } from '../../shared/constants/actions.const';

export const normalizedAction = (normalized: unknown, type = 'NORMALIZED') => ({
  type,
  normalized
});

export const clearError = (): IDispatchAction => ({
  type: ACTIONS.CLEAR_ERROR
});

export const setToasterMessage = (message: string) => ({
  type: ACTIONS.TOASTER_MESSAGE,
  data: message
});

export const setGeneralValue = (prop: string, value: any) => ({
  data: { prop, value },
  type: ACTIONS.SET_GENERAL_VALUE
});

export const setUpdateMeData = (value: IUser) => ({
  data: value,
  type: SHARED_ACTIONS.SET_UPDATE_ME_DATA
});

export const setPlayerData = (data: any) => ({
  type: ACTIONS.SET_PLAYER_DATA,
  data
});

export const addSongToUploadQueue = (payload: ISongWithParts) => ({
  type: ACTIONS.ADD_SONG_TO_UPLOAD_QUEUE,
  payload
});

export const getInitialData = () => ({
  type: ACTIONS.GET_INITIAL_DATA
});

export const setPaginationValue = (entityName: string, page: Paginated<unknown>) => {
  const value = {
    [entityName]: getPaginationInfo(page)
  };
  return {
    data: { prop: 'pagination', value },
    type: ACTIONS.SET_GENERAL_VALUE
  };
};
