import { createContext } from 'react';
// import { ColorPartial } from '@mui/material/styles/createPalette';

// declare module '@mui/material/styles/createPalette' {
//   interface PaletteColor extends ColorPartial {}
// }

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    hero: true;
    corben: true;
    display1: true;
    display2: true;
    userid: true;
    badge: true;
    bottomnav: true;
    'h2-medium': true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    text: true;
    tag: true;
    badge: true;
    screen: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    cwhite: true;
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    hero: React.CSSProperties;
    corben: React.CSSProperties;
    display1: React.CSSProperties;
    display2: React.CSSProperties;
    userid: React.CSSProperties;
    badge: React.CSSProperties;
    bottomnav: React.CSSProperties;
    'h2-medium': React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    hero?: React.CSSProperties;
    corben?: React.CSSProperties;
    display1?: React.CSSProperties;
    display2?: React.CSSProperties;
    userid?: React.CSSProperties;
    badge?: React.CSSProperties;
    bottomnav?: React.CSSProperties;
    'h2-medium'?: React.CSSProperties;
  }
}

export default createContext({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleColorMode: () => {}
});
