import React from 'react';
import SectionTitleComponent from '../../components/SectionTitleComponent/SectionTitleComponent';
import CommunityComponent from '../../components/CommunityComponent/CommunityComponent';
import { Stack, Grid, Pagination } from '@mui/material';
import { ICommunityComponent } from '../../interfaces/ui-components/slider-community.interface';
import { PAGINATION_ITEMS_TO_TAKE } from '../../shared/constants/pagination.const';
import { useHistory } from 'react-router';
import { SxProps } from '@mui/system';
import { IPaginationInfo } from '../../shared/interfaces/custom/pagination-info.interface';

interface PropsType {
  title?: string;
  communityComponentList?: ICommunityComponent[];
  onPlayCommunity?: (communityId: number) => void;
  pagination?: IPaginationInfo;
  fetchPage: (skip?: number, take?: number) => void;
  sx?: SxProps;
}

const ViewAllCommunitiesComponent: React.FC<PropsType> = ({
  title = 'Communities',
  fetchPage,
  pagination,
  communityComponentList,
  onPlayCommunity,
  sx
}) => {
  const history = useHistory();

  const handlePageChange = (event: React.ChangeEvent<unknown>, pageNumber: number) => {
    const skip = (pageNumber - 1) * (pagination?.take || PAGINATION_ITEMS_TO_TAKE);
    fetchPage(skip);
    history.push({ pathname: `${history.location.pathname}`, search: `?skip=${skip}` });
  };

  return (
    <Stack direction="column" sx={{ p: 5, ...sx }}>
      <SectionTitleComponent sx={{ mb: 5 }} title={title} />
      {communityComponentList && (
        <Grid sx={{ mb: 5 }} container direction="row" spacing={3} justifyContent="center">
          {communityComponentList.map((community) => (
            <Grid item key={community.id}>
              <CommunityComponent community={community} onPlayCommunity={onPlayCommunity} />
            </Grid>
          ))}
        </Grid>
      )}
      {pagination && pagination.pages > 1 && (
        <Stack justifyContent="center" alignItems="center">
          <Pagination count={pagination.pages} page={pagination.pageNumber} onChange={handlePageChange} />
        </Stack>
      )}
    </Stack>
  );
};

export default ViewAllCommunitiesComponent;
