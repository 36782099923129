/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import { ICECAST_STATUS, PLAYER_SOURCE, RADIO_STATUS, WAVE_SIZE_PRESETS } from '../../enums/player';
// import ReactWaves from '@dschoon/react-waves';
import { CircularProgress, Stack, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
// import { useEchoPlayer } from '../EchoPlayer/EchoPlayerProvider';

interface PropTypes {
  showRadioStatusLabel?: boolean;
  sx?: SxProps;
  centerContent?: boolean;
  sizePreset: typeof WAVE_SIZE_PRESETS.SMALL;
  style?: any;
}

const AudioVisualizer = ({
  centerContent = false,
  showRadioStatusLabel = false,
  sx,
  sizePreset = WAVE_SIZE_PRESETS.SMALL,
  style = {}
}: PropTypes) => {
  // const theme = useTheme();
  // const player = useEchoPlayer();
  // const { icecast } = player.controls.radio;
  // // todo: change to false
  // const [waveformLoaded, setWaveformLoaded] = useState(true);
  //
  // const handlePosChanged = () => {
  //   if (!waveformLoaded) {
  //     setWaveformLoaded(true);
  //   }
  // };
  // const elapsed = player.controls.current.getCurrentTime();
  //
  // const wavesContainer = (
  //   <>
  //     <div
  //       style={{
  //         marginLeft: centerContent ? 70 : 0,
  //         width: '100%',
  //         marginTop: -40,
  //         display: waveformLoaded ? 'block' : 'none',
  //         ...style
  //       }}
  //     >
  //       <ReactWaves
  //         onPosChange={handlePosChanged}
  //         pos={elapsed}
  //         audioFile={player.data.current.songResourceUrl}
  //         options={{
  //           ...sizePreset,
  //           interact: false,
  //           // maxCanvasWidth: sizePreset.width,
  //           autoCenter: true,
  //           hideScrollbar: true,
  //           cursorWidth: 0,
  //           // @ts-ignore
  //           progressColor: theme.palette.grey[400],
  //           waveColor: theme.palette.common.white,
  //           scrollParent: true
  //         }}
  //         volume={0}
  //         playing={true}
  //         zoom={10}
  //       />
  //     </div>
  //   </>
  // );

  return (
    // <></>
    <Stack
      sx={{ ...sx, width: sizePreset.width, height: sizePreset.height, minWidth: sizePreset.width }}
      direction="row"
      justifyContent={centerContent ? 'center' : 'flex-start'}
    >
      {/*{player.data.current.sourceType === PLAYER_SOURCE.RADIO_STREAMING ? (*/}
      {/*  <>*/}
      {/*    {player.data.radio.status === RADIO_STATUS.PLAYING ? (*/}
      {/*      <>*/}
      {/*        {(!waveformLoaded || elapsed === undefined) && <CircularProgress sx={{ color: 'white' }} size={25} />}*/}
      {/*        {icecast &&*/}
      {/*          elapsed !== undefined &&*/}
      {/*          icecast.state === ICECAST_STATUS.PLAYING &&*/}
      {/*          player.data.current.songResourceUrl !== undefined &&*/}
      {/*          wavesContainer}*/}
      {/*      </>*/}
      {/*    ) : (*/}
      {/*      <>*/}
      {/*        {showRadioStatusLabel && (*/}
      {/*          <Typography sx={{ color: 'white' }} variant="caption">*/}
      {/*            {player.data.radio.status}*/}
      {/*          </Typography>*/}
      {/*        )}*/}
      {/*      </>*/}
      {/*    )}*/}
      {/*  </>*/}
      {/*) : (*/}
      {/*  <>*/}
      {/*    {player.data.current.playing && (*/}
      {/*      <>*/}
      {/*        {player.data.current.songResourceUrl === undefined ? (*/}
      {/*          <CircularProgress sx={{ color: 'white' }} size={25} />*/}
      {/*        ) : (*/}
      {/*          wavesContainer*/}
      {/*        )}*/}
      {/*      </>*/}
      {/*    )}*/}
      {/*  </>*/}
      {/*)}*/}
    </Stack>
  );
};

export default AudioVisualizer;
