import { Paginated } from "../interfaces/responses/paginated.interface";
import { IPaginationInfo } from "../interfaces/custom/pagination-info.interface";

export function getPaginationInfo(page: Paginated<unknown>): IPaginationInfo {
  const elementLeft = page.total - page.skip - page.data.length;
  return {
    take: page.take,
    skip: page.skip,
    total: page.total,
    pages: Math.ceil(page.total / page.take),
    pageNumber: Math.ceil(page.skip / page.take) + 1,
    elementLeft,
    nextPageElementCount: elementLeft >= page.take ? page.take : elementLeft,
    hasMore: page.skip + page.take < page.total,
  };
}
