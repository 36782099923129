import { call, put, select, take } from 'redux-saga/effects';
import { ACTIONS } from '../../enums/actions';
import { IResourceFileUploadPayload } from '../../interfaces/requests/resource.interface';
import { IGeneral, ISagaTask } from '../../interfaces/store/general.interface';
import { IDispatchAction, IRootState } from '../../interfaces/store/root.interface';
import { setGeneralValue } from '../actions/generalActions';
import resourceSaga from './resourceSaga';

const queueSaga = {
  *queue(action: IDispatchAction): Generator {
    const tasks = (yield select((state: { general: IGeneral }) => state.general.tasks)) as IDispatchAction[];
    yield put(setGeneralValue('tasks', [...tasks, { running: false, action: action.payload }]));
    yield put({ type: ACTIONS.RUN_QUEUE });
  },

  *worker(): Generator {
    while (true) {
      const tasks = (yield select((state: IRootState) => state.general.tasks) as any) as ISagaTask[];
      const tasksRunning = tasks.filter((t) => t.running);

      if (tasksRunning.length === 0 && tasks.length > 0) {
        const task = tasks.shift();
        yield put(setGeneralValue('tasks', tasks));
        switch (task?.action.type) {
          case ACTIONS.UPLOAD_RESOURCE_FILE:
            yield call(resourceSaga.uploadResourceFile, task?.action as IDispatchAction<IResourceFileUploadPayload>);
            break;
        }
      } else {
        yield take(ACTIONS.RUN_QUEUE);
      }
    }
  }
};

export default queueSaga;
