export enum RESOURCE_TYPE {
  IMAGE = "image",
  VIDEO = "video",
  AUDIO = "audio",
}

export enum RESOURCE_STATUS {
  UNAVAILABLE = "unavailable",
  AVAILABLE = "available",
  DELETED = "deleted",
  ERROR = "error",
}
