/* eslint-disable @typescript-eslint/no-unused-vars */

import React from 'react';

import { Avatar, IconButton, Stack, Typography } from '@mui/material';
import { ReactComponent as DotSeparator } from './ellipse.svg';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Z_INDEXES } from '../../enums/zIndexes';

const FooterBar: React.FC = () => {
  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-around"
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          height: 56,
          backgroundColor: '#333333',
          zIndex: Z_INDEXES.LOGIN_PAGE__FOOTER_BAR
        }}
      >
        <Typography color="white" sx={{ fontWeight: 700 }}>
          E C H O <span style={{ marginLeft: 10 }}>D R O P</span>
        </Typography>

        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography color="white">2022 All Rights Reserved</Typography>
          <DotSeparator />
          <Typography color="white">Privacy Policy</Typography>
          <DotSeparator />
          <Typography color="white">Terms & Conditions</Typography>
        </Stack>
        <Stack direction="row">
          <a target="_blank" href="https://twitter.com/EchoDropXYZ">
            <Avatar sx={{ bgcolor: '#1B342E', width: 24, height: 24 }}>
              <TwitterIcon sx={{ fontSize: 16 }} />
            </Avatar>
          </a>
          {/*<IconButton>*/}
          {/*  <Avatar sx={{ bgcolor: '#844CA5', width: 24, height: 24 }}>*/}
          {/*    <FacebookIcon sx={{ fontSize: 16 }} />*/}
          {/*  </Avatar>*/}
          {/*</IconButton>*/}
          {/*<IconButton>*/}
          {/*  <Avatar sx={{ bgcolor: '#DB9A9E', width: 24, height: 24 }}>*/}
          {/*    <InstagramIcon sx={{ fontSize: 16 }} />*/}
          {/*  </Avatar>*/}
          {/*</IconButton>*/}
        </Stack>
      </Stack>
    </>
  );
};

export default FooterBar;
