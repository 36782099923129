import React from 'react';
import { CircularProgress, Stack } from '@mui/material';

function Loading() {
  return (
    <Stack justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
      <CircularProgress disableShrink />
    </Stack>
  );
}

export default Loading;
