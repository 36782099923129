/* eslint-disable no-console */
import { Button, Typography } from '@mui/material';
import React from 'react';
import GeneralBarComponent from '../../components/GeneralBarComponent/GeneralBarComponent';
import useWindowSize from "../../lib/hooks/useWindowSize";
import {useHistory} from "react-router";

const NotFoundPage: React.FC = () => {
  const history = useHistory();
  const size = useWindowSize();
  const containerXPadding = `${size.containerXPadding}px`;
  return (
    <>
      <GeneralBarComponent sx={{ px: containerXPadding }} />
      <div className="container p-4 mx-auto">
        <div className="grid grid-cols-2 items-center pt-40">
          <div className="col-span-1 text-left">
            <Typography variant="display2">
              <b>
                {' '}
                We cannot find what you are <br /> looking for.
              </b>
            </Typography>
            <Typography variant="h5" className="pt-4">
              Perhaps what you are looking for has changed places, but <br /> there is still much to explore on Echo
              Drop!
            </Typography>

            <Button variant="contained" className="mt-8" sx={{ textTransform: 'none', borderRadius: '1rem' } } onClick={() => history.push('/')}>
              <Typography variant="h5" className="px-5 py-2">
                Go back home
              </Typography>
            </Button>
          </div>
          <div className="col-span-1">
            <img src="./assets/404.png" alt="404" className="mx-auto block" />
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFoundPage;
