/* eslint-disable @typescript-eslint/no-unused-vars */
import { normalize } from 'normalizr';
import { eventChannel } from 'redux-saga';
import { call, fork, put, take } from 'redux-saga/effects';
import { ACTIONS } from '../../enums/actions';
import { HttpResponse } from '../../interfaces/api/http.interface';
import { IInviteUserPayload } from '../../interfaces/requests/artist.interface';
import { IGetUserByIdPayload, IUpdateUserMePayload } from '../../interfaces/requests/user.interface';
import { IGetUsersResponse } from '../../interfaces/responses/user.interface';
import { IDispatchAction } from '../../interfaces/store/root.interface';
import HttpClient, { HttpError } from '../../lib/HttpClient';
import { gTagInitConfig } from '../../lib/hooks/useAnalytics';
import { SHARED_ACTIONS } from '../../shared/constants/actions.const';
import { ISupporter } from '../../shared/interfaces/custom/supporter.interface';
import { IChatBotUser } from '../../shared/interfaces/models/chatbot-user.interface';
import { IUserCommunity } from '../../shared/interfaces/models/user-community.interface';
import { IUser } from '../../shared/interfaces/models/user.interface';
import { GetFriendlyError, GetFriendlyStatusError } from '../../utils/general';
import { logout } from '../actions/authActions';
import { normalizedAction, setGeneralValue, setUpdateMeData } from '../actions/generalActions';
import { disconnectSocket } from '../actions/socketActions';
import { chatBotUsersSchema, usersSchema } from './schema';
import { responseHandler } from './socketSaga';

const apiClient = new HttpClient('user');

async function getTopSupportersForArtistAPI(data: { id: number }): Promise<HttpResponse<ISupporter[]>> {
  return apiClient.post('get-top-supporters', data);
}

async function getTopSupportersCountForArtistAPI(data: { id: number }): Promise<HttpResponse<number>> {
  return apiClient.post('get-top-supporters-count', data);
}

export function userSubscribe(socket: any) {
  return eventChannel((emit) => {
    socket.on('user/get-me-success', (data: IUser) => {
      if (data) {
        window.analytics?.identify(data.id, {
          email: data.email,
          username: data.username,
          name: data.firstName + ' ' + data.lastName,
          id: data.id,
          introVideoSeen: data.introVideoSeen,
          role: data.role
        });
        // eslint-disable-next-line camelcase
        gTagInitConfig(data.id);
        // window.gtag('config', process.env.REACT_APP_GA_MEASUREMENT_ID, {
        //   // eslint-disable-next-line camelcase
        //   send_page_view: false,
        //   // eslint-disable-next-line camelcase
        //   user_id: `${data.id}`,
        //   // eslint-disable-next-line camelcase
        //   custom_map: {
        //     dimension1: 'song_id',
        //     dimension2: 'community_id',
        //     dimension3: 'boost_weight',
        //     dimension4: 'artist_id',
        //     dimension5: 'echo_user_id',
        //     metric1: 'song_time'
        //   }
        // });
        emit(setGeneralValue('currentUser', data));
        emit({ type: ACTIONS.GET_USERS_SUCCESS, payload: data });
      } else {
        emit(setGeneralValue('currentUser', undefined));
        emit(setGeneralValue('currentUser', null));
        emit(
          disconnectSocket(() => {
            emit(logout());
          })
        );
      }
    });
    socket.on('user/get-user-by-id-success', (data: IUser) => {
      emit({ type: ACTIONS.GET_USER_BY_ID_SUCCESS, payload: data });
    });
    socket.on('user/get-users-success', (data: IGetUsersResponse) => {
      emit(normalizedAction(normalize(data.users, [usersSchema])));
    });
    socket.on(SHARED_ACTIONS.INVITE_USER_SUCCESS, (data: any) => {
      emit({ type: SHARED_ACTIONS.INVITE_USER_SUCCESS, payload: data });
    });
    socket.on('user/update-success', (data: { user: IUser }) => {
      emit(normalizedAction(normalize(data.user, usersSchema)));
    });
    socket.on('user/update-me-success', (data: { user: IUser }) => {
      emit(setGeneralValue('currentUser', data.user));
      emit(setUpdateMeData(data.user));
    });
    socket.on(SHARED_ACTIONS.TEST_SUCCESS, (data: unknown) => {
      // eslint-disable-next-line no-console
      console.log('[test-success] data: ', data);
    });

    socket.on(SHARED_ACTIONS.NEW_USER_POINTS, (userCommunity: IUserCommunity) => {
      // console.log('NEW_USER_POINTS userCommunity: ', userCommunity);
      // emit(setGeneralValue('currentUser', userCommunity));
      emit(setGeneralValue('newRealTimeUserPoint', userCommunity));
      emit(setGeneralValue('currentUserCommunity', userCommunity));
    });
    socket.on(SHARED_ACTIONS.NEW_USER_LEVEL, (userCommunity: IUserCommunity) => {
      // console.log('NEW_USER_LEVEL userCommunity: ', userCommunity);
      // emit(setGeneralValue('currentUser', userCommunity));
      emit(setGeneralValue('newRealTimeUserLevel', userCommunity));
      emit(setGeneralValue('currentUserCommunity', userCommunity));
    });
    socket.on(SHARED_ACTIONS.GET_CHATBOT_USER_ID_LIST_SUCCESS, (chatBotUsers: IChatBotUser[]) => {
      emit(normalizedAction(normalize(chatBotUsers, [chatBotUsersSchema])));
      emit({ type: SHARED_ACTIONS.GET_CHATBOT_USER_ID_LIST_SUCCESS, payload: chatBotUsers });
    });
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  });
}

const userSaga = {
  *getMe(emitSocketMessage: any): Generator {
    while (true) {
      const action = (yield take(ACTIONS.GET_ME)) as IDispatchAction;
      emitSocketMessage('user/get-me');
      yield fork(responseHandler, ACTIONS.GET_USERS_SUCCESS, action.onSuccess, action.onFail);
    }
  },
  *updateMe(emitSocketMessage: any): Generator {
    while (true) {
      const action = (yield take(ACTIONS.UPDATE_ME)) as IDispatchAction<IUser>;
      emitSocketMessage('user/update-me', action.payload);
    }
  },
  *getUserById(emitSocketMessage: any): Generator {
    while (true) {
      const action = (yield take(ACTIONS.GET_USER_BY_ID)) as IDispatchAction<IGetUserByIdPayload>;
      emitSocketMessage('user/get-user-by-id', action.payload);
      yield fork(responseHandler, ACTIONS.GET_USER_BY_ID_SUCCESS, action.onSuccess, action.onFail);
    }
  },
  *getUsers(emitSocketMessage: any): Generator {
    while (true) {
      const action = (yield take(ACTIONS.GET_USERS)) as IDispatchAction;
      emitSocketMessage('user/get-users', action.payload);
      yield fork(responseHandler, ACTIONS.GET_USERS_SUCCESS, action.onSuccess, action.onFail);
    }
  },
  *updateUser(emitSocketMessage: any) {
    while (true) {
      const action = (yield take(ACTIONS.UPDATE_USER)) as IDispatchAction<IUser>;
      emitSocketMessage('user/update-user', { id: action.payload?.id, user: action.payload });
      yield fork(responseHandler, ACTIONS.UPDATE_USER_SUCCESS, action.onSuccess, action.onFail);
    }
  },
  *removeUser(emitSocketMessage: any) {
    while (true) {
      const action = (yield take(ACTIONS.REMOVE_USER)) as IDispatchAction<number>;
      emitSocketMessage('user/update-user', action.payload);
      yield fork(responseHandler, ACTIONS.REMOVE_USER_SUCCESS, action.onSuccess, action.onFail);
    }
  },
  *inviteUser(emitSocketMessage: any) {
    while (true) {
      const action = (yield take(SHARED_ACTIONS.INVITE_USER)) as IDispatchAction<IInviteUserPayload>;
      emitSocketMessage(SHARED_ACTIONS.INVITE_USER, action.payload);
      yield fork(responseHandler, SHARED_ACTIONS.INVITE_USER_SUCCESS, action.onSuccess, action.onFail);
    }
  },
  *makeTest(emitSocketMessage: any) {
    while (true) {
      const action = (yield take(SHARED_ACTIONS.TEST)) as IDispatchAction<IUpdateUserMePayload>;
      emitSocketMessage(SHARED_ACTIONS.TEST, action.payload);
      yield fork(responseHandler, SHARED_ACTIONS.TEST_SUCCESS, action.onSuccess, action.onFail);
    }
  },
  *getChatBotUsers(emitSocketMessage: any) {
    while (true) {
      const action = (yield take(SHARED_ACTIONS.GET_CHATBOT_USER_ID_LIST)) as IDispatchAction;
      emitSocketMessage(SHARED_ACTIONS.GET_CHATBOT_USER_ID_LIST);
      yield fork(responseHandler, SHARED_ACTIONS.GET_CHATBOT_USER_ID_LIST_SUCCESS, action.onSuccess, action.onFail);
    }
  },
  *getTopSupportersForArtist(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as { id: number };
      const response = (yield call(getTopSupportersForArtistAPI, payload)) as HttpResponse<ISupporter[]>;
      if (response.status === 200 || response.status === 201) {
        if (action.onSuccess) {
          action.onSuccess(response.data as ISupporter[]);
        }
        yield put({ type: SHARED_ACTIONS.GET_TOP_SUPPORTERS_SUCCESS, data: response.data });
      } else {
        action.onFail && action.onFail(response);
        yield put({ type: SHARED_ACTIONS.GET_TOP_SUPPORTERS_SUCCESS, data: response.data });
      }
    } catch (e) {
      action.onFail && action.onFail(e);
      let friendlyError;
      if (e instanceof HttpError) {
        const error = e as HttpError;
        const parsedErrorMessage = JSON.parse(error.message);
        if (parsedErrorMessage.code === 400) {
          friendlyError = GetFriendlyError(parsedErrorMessage.message);
        } else {
          friendlyError = GetFriendlyStatusError(parsedErrorMessage.code);
        }
        yield put({ type: ACTIONS.ERROR, data: friendlyError });
      } else {
        const error = e as Error;
        friendlyError = GetFriendlyError(error.message);
      }
      yield put({ type: ACTIONS.ERROR, data: friendlyError });
    }
  },

  *getTopSupportersCountForArtist(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as { id: number };
      const response = (yield call(getTopSupportersCountForArtistAPI, payload)) as HttpResponse<number>;
      if (response.status === 200 || response.status === 201) {
        if (action.onSuccess) {
          action.onSuccess(response.data as number);
        }
        yield put({ type: SHARED_ACTIONS.GET_TOP_SUPPORTERS_COUNT_SUCCESS, data: response.data });
      } else {
        action.onFail && action.onFail(response);
        yield put({ type: SHARED_ACTIONS.GET_TOP_SUPPORTERS_COUNT_SUCCESS, data: response.data });
      }
    } catch (e) {
      action.onFail && action.onFail(e);
      let friendlyError;
      if (e instanceof HttpError) {
        const error = e as HttpError;
        const parsedErrorMessage = JSON.parse(error.message);
        if (parsedErrorMessage.code === 400) {
          friendlyError = GetFriendlyError(parsedErrorMessage.message);
        } else {
          friendlyError = GetFriendlyStatusError(parsedErrorMessage.code);
        }
        yield put({ type: ACTIONS.ERROR, data: friendlyError });
      } else {
        const error = e as Error;
        friendlyError = GetFriendlyError(error.message);
      }
      yield put({ type: ACTIONS.ERROR, data: friendlyError });
    }
  }
};

export default userSaga;
