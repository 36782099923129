import { useTheme } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import useWindowSize from '../../lib/hooks/useWindowSize';
import { RESOURCE_STATUS } from '../../shared/constants/resource.const';
import { APP_SECTION } from '../../shared/constants/upload.const';
import FloatingTemplate from '../../templates/Desktop/FloatingTemplate';
import { useEchoPlayer } from '../EchoPlayer/EchoPlayerProvider';
import CommunityBar from './CommunityBar';
import RadioBodySection from './RadioBodySection/RadioBodySection';

interface RouteParams {
  communityId: string;
}

const RadioPlayerComponent: React.FC = () => {
  const player = useEchoPlayer();
  const size = useWindowSize();
  const communityId = parseInt(useParams<RouteParams>().communityId);
  const theme = useTheme();
  const radioPlayerContainerBodyRef = useRef<any>();
  const videoRef = useRef<any>();
  const [videoUrl, setVideoUrl] = useState<string>();

  useEffect(() => {
    player.controls.radio.play(communityId);
  }, []);

  useEffect(() => {
    const songResources = player.data.current.song?.resources ?? [];
    const videoResource = songResources.find(
      (r) => r.appSection === APP_SECTION.SONG_VIDEO && r.status === RESOURCE_STATUS.AVAILABLE
    );
    setVideoUrl(videoResource?.url);
  }, [player.data.current.song, player.data.current.song?.resources]);

  useEffect(() => {
    videoRef?.current?.load();
  }, [videoUrl]);

  return (
    <div
      style={{
        height: size.containerHeight + 8,
        backgroundColor: theme.palette.grey[800],
        paddingBottom: theme.spacing(3)
      }}
    >
      <CommunityBar playerData={player.data} />

      <div
        ref={radioPlayerContainerBodyRef}
        style={{
          width: '100%',
          // filter: 'brightness(50%)',
          height: size.containerHeight - 44
        }}
      >
        {videoUrl && (
          <video
            ref={videoRef}
            className="videoTag"
            autoPlay
            loop
            muted
            style={{
              objectFit: 'cover',
              width: 'inherit',
              height: size.containerHeight - 44
            }}
          >
            <source src={videoUrl} type="video/mp4" />
          </video>
        )}
      </div>

      <FloatingTemplate yInherit inheritHeight inheritWidth anchorRef={radioPlayerContainerBodyRef}>
        <RadioBodySection />
      </FloatingTemplate>
    </div>
  );
};

export default RadioPlayerComponent;
