import { normalize } from 'normalizr';
import { eventChannel } from 'redux-saga';
import { fork, take } from 'redux-saga/effects';
import { ACTIONS } from '../../enums/actions';
import { IDispatchAction } from '../../interfaces/store/root.interface';
import { SHARED_ACTIONS } from '../../shared/constants/actions.const';
import { ICommunity } from '../../shared/interfaces/models/community.interface';
import { IUserCommunity } from '../../shared/interfaces/models/user-community.interface';
import { Paginated } from '../../shared/interfaces/responses/paginated.interface';
import { normalizedAction, setGeneralValue, setPaginationValue } from '../actions/generalActions';
import { communitiesSchema, userCommunitiesSchema } from './schema';
import { responseHandler } from './socketSaga';
import { ISongComment } from '../../shared/interfaces/models/song-comment.interface';
import { ISongReaction } from '../../shared/interfaces/models/song-reaction.interface';
import { ILevel } from '../../shared/interfaces/models/level.interface';

export function communitiesSubscribe(socket: any) {
  return eventChannel((emit) => {
    socket.on('communities/get-success', (data: ICommunity[]) => {
      emit(normalizedAction(normalize(data, [communitiesSchema])));
      emit({ type: ACTIONS.GET_COMMUNITIES_SUCCESS, payload: data });
    });
    socket.on(SHARED_ACTIONS.GET_COMMUNITY_BY_ID_SUCCESS, (data: ICommunity) => {
      emit(normalizedAction(normalize([data], [communitiesSchema])));
      emit({ type: SHARED_ACTIONS.GET_COMMUNITY_BY_ID_SUCCESS, payload: data });
    });
    socket.on(SHARED_ACTIONS.GET_USER_COMMUNITY_BY_USER_SUCCESS, (page: Paginated<IUserCommunity>) => {
      emit(normalizedAction(normalize(page.data, [userCommunitiesSchema])));
      emit(setPaginationValue('userCommunities', page));
      emit({ type: ACTIONS.GET_USER_COMMUNITIES_BY_USER_ID_SUCCESS, payload: page.data });
    });
    socket.on(SHARED_ACTIONS.GET_LOWEST_LEVEL_SUCCESS, (level: ILevel) => {
      emit({ type: SHARED_ACTIONS.GET_LOWEST_LEVEL_SUCCESS, payload: level });
    });
    socket.on(SHARED_ACTIONS.GET_COMMUNITIES_BY_ARTIST_SUCCESS, (page: Paginated<ICommunity>) => {
      emit(normalizedAction(normalize(page.data, [communitiesSchema])));
      emit(setPaginationValue('communities', page));
      emit({ type: SHARED_ACTIONS.GET_COMMUNITIES_BY_ARTIST_SUCCESS, payload: page.data });
    });
    socket.on('communities/update-success', (data: ICommunity[]) => {
      emit(normalizedAction(normalize(data, [communitiesSchema])));
    });
    socket.on(SHARED_ACTIONS.NEW_SONG_COMMENT_EVENT, (songComment: ISongComment) => {
      emit(setGeneralValue('newRealTimeSongComment', songComment));
    });
    socket.on(SHARED_ACTIONS.NEW_SONG_REACTION_EVENT, (songReaction: ISongReaction) => {
      emit(setGeneralValue('newRealTimeSongReaction', songReaction));
    });
    socket.on(SHARED_ACTIONS.GET_TOP_USERS_FROM_COMMUNITY_SUCCESS, (users: IUserCommunity[]) => {
      emit({ type: SHARED_ACTIONS.GET_TOP_USERS_FROM_COMMUNITY_SUCCESS, payload: users });
    });

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  });
}

const communitiesSaga = {
  *getCommunities(emitSocketMessage: any): Generator {
    while (true) {
      const action = (yield take(ACTIONS.GET_COMMUNITIES)) as IDispatchAction;
      emitSocketMessage('communities/get');
      yield fork(responseHandler, ACTIONS.GET_COMMUNITIES_SUCCESS, action.onSuccess, action.onFail);
    }
  },
  *getCommunityById(emitSocketMessage: any): Generator {
    while (true) {
      const action = (yield take(SHARED_ACTIONS.GET_COMMUNITY_BY_ID)) as IDispatchAction;
      emitSocketMessage(SHARED_ACTIONS.GET_COMMUNITY_BY_ID, action.payload);
      yield fork(responseHandler, SHARED_ACTIONS.GET_COMMUNITY_BY_ID_SUCCESS, action.onSuccess, action.onFail);
    }
  },
  *getUserCommunitiesByUserId(emitSocketMessage: any): Generator {
    while (true) {
      const action = (yield take(ACTIONS.GET_USER_COMMUNITIES_BY_USER_ID)) as IDispatchAction;
      emitSocketMessage(SHARED_ACTIONS.GET_USER_COMMUNITY_BY_USER, action.payload);
      yield fork(responseHandler, ACTIONS.GET_USER_COMMUNITIES_BY_USER_ID_SUCCESS, action.onSuccess, action.onFail);
    }
  },
  *getLowestLevel(emitSocketMessage: any): Generator {
    while (true) {
      const action = (yield take(SHARED_ACTIONS.GET_LOWEST_LEVEL)) as IDispatchAction;
      emitSocketMessage(SHARED_ACTIONS.GET_LOWEST_LEVEL, action.payload);
      yield fork(responseHandler, SHARED_ACTIONS.GET_LOWEST_LEVEL_SUCCESS, action.onSuccess, action.onFail);
    }
  },
  *getCommunitiesByArtist(emitSocketMessage: any): Generator {
    while (true) {
      const action = (yield take(SHARED_ACTIONS.GET_COMMUNITIES_BY_ARTIST)) as IDispatchAction;
      emitSocketMessage(SHARED_ACTIONS.GET_COMMUNITIES_BY_ARTIST, action.payload);
      yield fork(responseHandler, SHARED_ACTIONS.GET_COMMUNITIES_BY_ARTIST_SUCCESS, action.onSuccess, action.onFail);
    }
  },
  *joinToCommunityEvents(emitSocketMessage: any): Generator {
    while (true) {
      const action = (yield take(SHARED_ACTIONS.JOIN_TO_COMMUNITY_EVENTS)) as IDispatchAction;
      emitSocketMessage(SHARED_ACTIONS.JOIN_TO_COMMUNITY_EVENTS, action.payload);
      yield fork(responseHandler, SHARED_ACTIONS.JOIN_TO_COMMUNITY_EVENTS_SUCCESS, action.onSuccess, action.onFail);
    }
  },
  *leaveCommunityEvents(emitSocketMessage: any): Generator {
    while (true) {
      const action = (yield take(SHARED_ACTIONS.LEAVE_COMMUNITY_EVENTS)) as IDispatchAction;
      emitSocketMessage(SHARED_ACTIONS.LEAVE_COMMUNITY_EVENTS, action.payload);
      yield fork(responseHandler, SHARED_ACTIONS.LEAVE_COMMUNITY_EVENTS_SUCCESS, action.onSuccess, action.onFail);
    }
  },
  *getTopUsersFromCommunity(emitSocketMessage: any): Generator {
    while (true) {
      const action = (yield take(SHARED_ACTIONS.GET_TOP_USERS_FROM_COMMUNITY)) as IDispatchAction;
      emitSocketMessage(SHARED_ACTIONS.GET_TOP_USERS_FROM_COMMUNITY, action.payload);
      yield fork(responseHandler, SHARED_ACTIONS.GET_TOP_USERS_FROM_COMMUNITY_SUCCESS, action.onSuccess, action.onFail);
    }
  }
};

export default communitiesSaga;
