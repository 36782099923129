import React from 'react';
import { useElapsedTime } from '../EchoPlayer/ElapsedTimeProvider';
import { Typography } from '@mui/material';
import { secondsToTimeFormat } from '../../utils/date-utils';

const TimeSection: React.FC = () => {
  const elapsed = useElapsedTime();
  if (!elapsed) {
    return null;
  }
  return (
    <Typography sx={{ fontSize: 16 }} variant="caption" color="white">
      {secondsToTimeFormat(elapsed)}
    </Typography>
  );
};

export default TimeSection;
