import { normalize } from 'normalizr';
import { eventChannel } from 'redux-saga';
import { delay, fork, put, race, take } from 'redux-saga/effects';
import { ACTIONS } from '../../enums/actions';
import { IGetMeResponse } from '../../interfaces/responses/user.interface';
import { IDispatchAction } from '../../interfaces/store/root.interface';
import { IUser } from '../../shared/interfaces/models/user.interface';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { normalizedAction, setGeneralValue, setPaginationValue } from '../actions/generalActions';
import { ISong } from '../../shared/interfaces/models/song.interface';
import { songCommunitiesSchema, songsSchema, songUploadResultsSchema } from './schema';
import { SHARED_ACTIONS } from '../../shared/constants/actions.const';
import { responseHandler } from './socketSaga';
import { Paginated } from '../../shared/interfaces/responses/paginated.interface';
import { ISongCommunity } from '../../shared/interfaces/models/song-community.interface';
import { ISongVote } from '../../shared/interfaces/models/song-vote.interface';
import { ISongComment } from '../../shared/interfaces/models/song-comment.interface';
import { ISongReaction } from '../../shared/interfaces/models/song-reaction.interface';
import { IPexelsVideo } from '../../shared/interfaces/custom/pexels-video.interface';
import { IResource } from '../../shared/interfaces/models/resource.interface';
import { songHasAllResourcesAvailables } from '../../utils/general';
import { ISongUploadResult } from '../../shared/interfaces/custom/upload.interface';

export function songSubscribe(socket: any) {
  return eventChannel((emit) => {
    socket.on('song/create-song-success', (data: IGetMeResponse) => {
      emit({ type: ACTIONS.CREATE_SONG_SUCCESS, payload: data });
    });
    socket.on(SHARED_ACTIONS.GET_SONGS_BY_ARTIST_SUCCESS, (page: Paginated<ISong>) => {
      emit(setPaginationValue('songs', page));
      emit(normalizedAction(normalize(page.data, [songsSchema])));
      emit({ type: SHARED_ACTIONS.GET_SONGS_BY_ARTIST_SUCCESS, payload: page.data });
    });
    socket.on(SHARED_ACTIONS.GET_SONGS_BY_COMMUNITY_SUCCESS, (page: Paginated<ISongCommunity>) => {
      emit(setPaginationValue('songCommunities', page));
      emit(normalizedAction(normalize(page.data, [songCommunitiesSchema])));
      emit({ type: SHARED_ACTIONS.GET_SONGS_BY_COMMUNITY_SUCCESS, payload: page });
    });
    socket.on(SHARED_ACTIONS.GET_SONG_COMMUNITY_SUCCESS, (songCommunity: ISongCommunity) => {
      emit({ type: SHARED_ACTIONS.GET_SONG_COMMUNITY_SUCCESS, payload: songCommunity });
    });
    socket.on(SHARED_ACTIONS.UPDATE_UPVOTE_STATUS_SUCCESS, (songVote: ISongVote) => {
      emit({ type: SHARED_ACTIONS.UPDATE_UPVOTE_STATUS_SUCCESS, payload: songVote });
    });
    socket.on('song/update-success', (data: { song: ISong }) => {
      emit(normalizedAction(normalize(data.song, songsSchema)));
    });
    socket.on(SHARED_ACTIONS.SONG_UPLOAD_FINISHED, (data: { song?: ISong; resource: IResource; success: boolean }) => {
      const resourcesAvailable = songHasAllResourcesAvailables(data.song);
      if (data.success && !resourcesAvailable) {
        return;
      }
      const finalSuccess = data.success && resourcesAvailable;
      const songId = data?.song?.id ?? data?.resource?.associatedId;
      // console.log('[SONG_UPLOAD_FINISHED]', {
      //   finalSuccess,
      //   resourceId: data.resource.id,
      //   section: data.resource?.appSection,
      //   songId: data.song?.id,
      //   size: data.resource?.data?.size,
      //   type: data.resource?.type,
      //   ...data
      // });

      if (songId) {
        const songUploadResult = { id: songId, success: finalSuccess } as ISongUploadResult;
        emit(normalizedAction(normalize([songUploadResult], [songUploadResultsSchema])));
      }
    });
    socket.on(SHARED_ACTIONS.GET_SONGS_BY_ID_LIST_SUCCESS, (songs: ISong[]) => {
      emit({ type: SHARED_ACTIONS.GET_SONGS_BY_ID_LIST_SUCCESS, payload: songs });
    });
    socket.on(SHARED_ACTIONS.GET_SONG_COMMENTS_SUCCESS, (page: Paginated<ISongComment>) => {
      emit({ type: SHARED_ACTIONS.GET_SONG_COMMENTS_SUCCESS, payload: page.data });
    });
    socket.on(SHARED_ACTIONS.SAVE_SONG_COMMENT_SUCCESS, (songComment: ISongComment) => {
      emit({ type: SHARED_ACTIONS.SAVE_SONG_COMMENT_SUCCESS, payload: songComment });
    });
    socket.on(SHARED_ACTIONS.GET_SONG_REACTIONS_SUCCESS, (songReaction: ISongReaction) => {
      emit({ type: SHARED_ACTIONS.GET_SONG_REACTIONS_SUCCESS, payload: songReaction });
    });
    socket.on(SHARED_ACTIONS.SAVE_SONG_REACTION_SUCCESS, (songReaction: ISongReaction) => {
      emit({ type: SHARED_ACTIONS.SAVE_SONG_REACTION_SUCCESS, payload: songReaction });
    });
    socket.on(SHARED_ACTIONS.GET_GENERIC_VIDEOS_SUCCESS, (payload: Paginated<IPexelsVideo>) => {
      emit({ type: SHARED_ACTIONS.GET_GENERIC_VIDEOS_SUCCESS, payload });
    });
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  });
}

const songSaga = {
  *createSong(emitSocketMessage: any): Generator {
    while (true) {
      const action = (yield take(ACTIONS.CREATE_SONG)) as IDispatchAction<ISong>;
      emitSocketMessage('song/create-song', action.payload);
      const { response, timeout, error } = (yield race({
        error: take(ACTIONS.SOCKET_MESSAGE_ERROR),
        response: take(ACTIONS.CREATE_SONG_SUCCESS),
        timeout: delay(5000)
      })) as { response: IDispatchAction<ISong>; timeout: boolean; error: IDispatchAction<unknown> };

      if (timeout) {
        if (action.onFail) {
          action.onFail();
        }
      } else if (error) {
        if (action.onFail) {
          action.onFail();
        }
        yield put({ type: ACTIONS.ERROR, data: error });
      } else {
        yield put(normalizedAction(normalize(response, songsSchema)));
        if (action.onSuccess) {
          action.onSuccess(response.payload);
        }
      }
    }
  },

  *setUploadSong(action: IDispatchAction): Generator {
    yield put({
      type: ACTIONS.SET_UPLOAD_SONGS_SUCCESS,
      data: action.payload
    });
  },
  *updateSong(emitSocketMessage: any): Generator {
    while (true) {
      const { payload } = (yield take(ACTIONS.UPDATE_ME)) as IDispatchAction<IUser>;
      emitSocketMessage('user/update-me', payload);
    }
  },
  *getSongsByArtist(emitSocketMessage: any): Generator {
    while (true) {
      const action = (yield take(SHARED_ACTIONS.GET_SONGS_BY_ARTIST)) as IDispatchAction;
      emitSocketMessage(SHARED_ACTIONS.GET_SONGS_BY_ARTIST, action.payload);
      yield fork(responseHandler, SHARED_ACTIONS.GET_SONGS_BY_ARTIST_SUCCESS, action.onSuccess, action.onFail);
    }
  },
  *getSongCommunitiesByCommunity(emitSocketMessage: any): Generator {
    while (true) {
      const action = (yield take(SHARED_ACTIONS.GET_SONGS_BY_COMMUNITY)) as IDispatchAction;
      emitSocketMessage(SHARED_ACTIONS.GET_SONGS_BY_COMMUNITY, action.payload);
      yield fork(responseHandler, SHARED_ACTIONS.GET_SONGS_BY_COMMUNITY_SUCCESS, action.onSuccess, action.onFail);
    }
  },
  *getSongCommunity(emitSocketMessage: any): Generator {
    while (true) {
      const action = (yield take(SHARED_ACTIONS.GET_SONG_COMMUNITY)) as IDispatchAction;
      emitSocketMessage(SHARED_ACTIONS.GET_SONG_COMMUNITY, action.payload);
      yield fork(responseHandler, SHARED_ACTIONS.GET_SONG_COMMUNITY_SUCCESS, action.onSuccess, action.onFail);
    }
  },
  *updateUpvoteStatus(emitSocketMessage: any): Generator {
    while (true) {
      const action = (yield take(SHARED_ACTIONS.UPDATE_UPVOTE_STATUS)) as IDispatchAction;
      emitSocketMessage(SHARED_ACTIONS.UPDATE_UPVOTE_STATUS, action.payload);
      yield fork(responseHandler, SHARED_ACTIONS.UPDATE_UPVOTE_STATUS_SUCCESS, action.onSuccess, action.onFail);
    }
  },
  *getSongsByIdList(emitSocketMessage: any): Generator {
    while (true) {
      const action = (yield take(SHARED_ACTIONS.GET_SONGS_BY_ID_LIST)) as IDispatchAction;
      emitSocketMessage(SHARED_ACTIONS.GET_SONGS_BY_ID_LIST, action.payload);
      yield fork(responseHandler, SHARED_ACTIONS.GET_SONGS_BY_ID_LIST_SUCCESS, action.onSuccess, action.onFail);
    }
  },
  *getSongComments(emitSocketMessage: any): Generator {
    while (true) {
      const action = (yield take(SHARED_ACTIONS.GET_SONG_COMMENTS)) as IDispatchAction;
      emitSocketMessage(SHARED_ACTIONS.GET_SONG_COMMENTS, action.payload);
      yield fork(responseHandler, SHARED_ACTIONS.GET_SONG_COMMENTS_SUCCESS, action.onSuccess, action.onFail);
    }
  },
  *saveSongComment(emitSocketMessage: any): Generator {
    while (true) {
      const action = (yield take(SHARED_ACTIONS.SAVE_SONG_COMMENT)) as IDispatchAction;
      // console.log('[songSata.ts] saveSongComment() action.payload:', action.payload);
      emitSocketMessage(SHARED_ACTIONS.SAVE_SONG_COMMENT, action.payload);
      yield fork(responseHandler, SHARED_ACTIONS.SAVE_SONG_COMMENT_SUCCESS, action.onSuccess, action.onFail);
    }
  },
  *getSongReactions(emitSocketMessage: any): Generator {
    while (true) {
      const action = (yield take(SHARED_ACTIONS.GET_SONG_REACTIONS)) as IDispatchAction;
      emitSocketMessage(SHARED_ACTIONS.GET_SONG_REACTIONS, action.payload);
      yield fork(responseHandler, SHARED_ACTIONS.GET_SONG_REACTIONS_SUCCESS, action.onSuccess, action.onFail);
    }
  },
  *saveSongReaction(emitSocketMessage: any): Generator {
    while (true) {
      const action = (yield take(SHARED_ACTIONS.SAVE_SONG_REACTION)) as IDispatchAction;
      // console.log('[songSata.ts] saveSongComment() action.payload:', action.payload);
      emitSocketMessage(SHARED_ACTIONS.SAVE_SONG_REACTION, action.payload);
      yield fork(responseHandler, SHARED_ACTIONS.SAVE_SONG_REACTION_SUCCESS, action.onSuccess, action.onFail);
    }
  },
  *getGenericVideos(emitSocketMessage: any): Generator {
    while (true) {
      const action = (yield take(SHARED_ACTIONS.GET_GENERIC_VIDEOS)) as IDispatchAction;
      emitSocketMessage(SHARED_ACTIONS.GET_GENERIC_VIDEOS, action.payload);
      yield fork(responseHandler, SHARED_ACTIONS.GET_GENERIC_VIDEOS_SUCCESS, action.onSuccess, action.onFail);
    }
  }
};

export default songSaga;
