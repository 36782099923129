import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { Avatar, IconButton, Slider, Box } from '@mui/material';
import React, { useRef, useState } from 'react';
import { OverridableStringUnion } from '@mui/types';
import { IconButtonPropsSizeOverrides } from '@mui/material/IconButton/IconButton';
import { useEchoPlayer } from '../EchoPlayer/EchoPlayerProvider';
import FloatingTemplate from '../../templates/Desktop/FloatingTemplate';
import { Z_INDEXES } from '../../enums/zIndexes';

interface PropType {
  iconSize?: OverridableStringUnion<'small' | 'medium' | 'large', IconButtonPropsSizeOverrides>;
  buttonColor?: string;
  iconColor?: string;
  openDirection?: 'top' | 'bottom';
}

const VolumeComponent: React.FC<PropType> = ({
  iconSize,
  iconColor = 'white',
  buttonColor = 'primary.main',
  openDirection = 'top'
}) => {
  const containerRef = useRef<any>();
  const sliderHeight = 100;
  const player = useEchoPlayer();

  const [showVolumeSlider, setShowVolumeSlider] = useState(false);

  const handleSliderValueChange = (_event: Event, newValue: number | number[]) => {
    newValue < 5 ? saveVolume(0) : saveVolume((newValue as number) / 100);
  };

  const saveVolume = (volume: number) => {
    player.controls.current.setVolume(volume);
  };

  return (
    <Box onMouseEnter={() => setShowVolumeSlider(true)} onMouseLeave={() => setShowVolumeSlider(false)}>
      <IconButton
        ref={containerRef}
        size={iconSize}
        onClick={() => player.controls.current.setMute(!player.data.current.mute)}
      >
        <Avatar
          sx={{
            bgcolor: buttonColor,
            width: iconSize === 'small' ? 24 : undefined,
            height: iconSize === 'small' ? 24 : undefined
          }}
        >
          {player.data.current.volume > 0 ? (
            <VolumeUpIcon sx={{ color: iconColor, fontSize: iconSize === 'small' ? 16 : undefined }} />
          ) : (
            <VolumeOffIcon sx={{ color: iconColor, fontSize: iconSize === 'small' ? 16 : undefined }} />
          )}
        </Avatar>
      </IconButton>

      {showVolumeSlider && (
        <FloatingTemplate
          sumAnchorHeightToYPosition={openDirection === 'bottom'}
          yOffset={openDirection === 'bottom' ? 0 : -sliderHeight}
          inheritWidth
          useAnchorAsContainer
          anchorRef={containerRef}
          style={{ paddingTop: openDirection === 'bottom' ? 5 : undefined }}
        >
          <Slider
            disableSwap
            aria-label="Volume"
            orientation="vertical"
            value={player.data.current.volume * 100}
            // onChangeCommitted={handleSliderChangeCommitted}
            onChange={handleSliderValueChange}
            sx={{
              height: sliderHeight,
              color: 'white',
              zIndex: Z_INDEXES.RADIO_PLAYER__VOLUME_COMPONENT__SLIDER,
              '& .MuiSlider-thumb': {
                width: 13,
                height: 13,
                backgroundColor: '#fff',
                '&:before': {
                  boxShadow: '0 4px 8px rgba(0,0,0,0.4)'
                },
                '&:hover, &.Mui-focusVisible, &.Mui-active': {
                  boxShadow: 'none'
                }
              }
            }}
          ></Slider>
        </FloatingTemplate>
      )}
    </Box>
  );
};

export default VolumeComponent;
