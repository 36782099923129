import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CurationTable from './Curation/CurationTable';
import TopBar from './TopBar';
import { Box, CircularProgress } from '@mui/material';
import { useSelector } from '../../store';
import useWindowSize from '../../lib/hooks/useWindowSize';
import { CURATION_TABLE_TYPE } from '../../enums/curation';
import ChatContainer from './ChatComponent/ChatContainer';
import useCurrentUserCommunity from './hooks/useFetchCurrentUserCommunity';

interface RouteParams {
  communityId: string;
}

const ChatAndCurationComponent: React.FC = () => {
  const { currentUser } = useSelector((state) => state.general);
  const { communityId } = useParams<RouteParams>();
  const [selectedPage, setSelectedPage] = useState(0);
  const size = useWindowSize();
  const currentUserCommunityHook = useCurrentUserCommunity();

  useEffect(() => {
    if (communityId && currentUser) {
      currentUserCommunityHook.fetch(Number(communityId), currentUser);
    }
  }, [communityId, currentUser]);

  const topbarHeight = 52;
  const user = useSelector((state) => state.general.currentUser);
  if (!user) {
    return <CircularProgress />;
  }

  const height = size.containerHeight - topbarHeight + 5 - (selectedPage !== 0 ? 37 : 0);

  return (
    <div>
      <TopBar
        height={topbarHeight}
        onPageChange={setSelectedPage}
        selectedPage={selectedPage}
        communityId={parseInt(communityId)}
      />
      <Box sx={{ height, maxHeight: height }}>
        {selectedPage === 0 && <ChatContainer height={height - 60} />}
        {selectedPage === 1 && <CurationTable type={CURATION_TABLE_TYPE.TOP} />}
        {selectedPage === 2 && <CurationTable type={CURATION_TABLE_TYPE.NEW} />}
      </Box>
    </div>
  );
};

export default ChatAndCurationComponent;
