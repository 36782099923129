/* eslint-disable @typescript-eslint/no-empty-function  */
/* eslint-disable @typescript-eslint/no-unused-vars*/

import { ISong } from '../../../shared/interfaces/models/song.interface';
import useAudioHelper from '../helpers/useAudioHelper';
import { useDispatch } from 'react-redux';
import { ISongControls } from '../../../interfaces/echo-player/echo-player-controls.interface';
import { IPlayerInfo } from '../../../interfaces/echo-player/echo-player-info.interface';
import { useCallback, useEffect, useState } from 'react';

interface IProps {
  audioElement: HTMLAudioElement;
  setAudioElement: (audioElement: HTMLAudioElement) => void;
  playerInfo: IPlayerInfo;
}

export default function useSongControls({ audioElement, setAudioElement, playerInfo }: IProps): ISongControls {
  const [audioSource, setAudioSource] = useState<MediaElementAudioSourceNode>();

  const {
    data: playerData,
    triggers: {
      song: { afterPlay, afterPause, beforePlay, beforePause }
    }
  } = playerInfo;
  const { getUrlFromSong } = useAudioHelper();
  const dispatch = useDispatch();

  useEffect(() => {
    audioElement.onended = () => {
      afterPause();
    };
    linkAudioContext(audioElement);
  }, []);

  const linkAudioContext = useCallback(
    (audioElement: HTMLAudioElement) => {
      const { audioContext } = playerInfo.data.song;
      if (audioElement && audioContext) {
        audioElement.onplay = () => audioContext.resume();
        try {
          const newAudioSource = audioContext.createMediaElementSource(audioElement);
          newAudioSource.connect(audioContext.destination);
        } catch (e) {
          console.error(e);
        }
      }
    },
    [audioElement]
  );

  const pause = () => {
    beforePause();
    audioElement.pause();
    afterPause();
  };

  const togglePlay = async (song?: ISong, communityId?: number) => {
    if (!playerData.song.playing) {
      await play(song, communityId);
    } else {
      pause();
    }
  };

  const play = async (song?: ISong, communityId?: number) => {
    if (playerData.song.playing && ((song && song.id === playerData.song.song?.id) || !song)) {
      return;
    }
    const sourceUrl = song ? getUrlFromSong(song) : playerData.song.sourceUrl;
    const songStartedAt = new Date();
    song = song ?? playerData.song.song;
    if (!sourceUrl || !song) {
      console.error('no resource found');
      return;
    }
    beforePlay(song, sourceUrl, songStartedAt, communityId);

    if (audioElement.src !== sourceUrl) {
      audioElement.src = sourceUrl;
      audioElement.crossOrigin = 'anonymous';
      audioElement.load();
    }
    await audioElement.play();
    // linkAudioContext(audioElement);

    afterPlay(song, sourceUrl, songStartedAt, communityId);
  };

  return {
    audioElement,
    play,
    togglePlay,
    pause
  };
}
