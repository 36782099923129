import { fork } from 'redux-saga/effects';
import userSaga from '../sagas/userSaga';

export default function* (socket: any) {
  yield fork(userSaga.getMe, socket);
  yield fork(userSaga.updateMe, socket);
  yield fork(userSaga.getUsers, socket);
  yield fork(userSaga.makeTest, socket);
  yield fork(userSaga.inviteUser, socket);
  yield fork(userSaga.getChatBotUsers, socket);
  yield fork(userSaga.getUserById, socket);
  yield fork(userSaga.updateUser, socket);
  yield fork(userSaga.getTopSupportersForArtist, socket);
  yield fork(userSaga.getTopSupportersCountForArtist, socket);
}
