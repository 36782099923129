import React, { FunctionComponent, useState } from 'react';
import { Grid, Paper } from '@mui/material';
import componentMapping from './story-mapping';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import useWindowSize from '../lib/hooks/useWindowSize';

interface PropType {
  storyInfo: {
    kind: string;
    name: string;
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Story: React.FC<PropType> = (props) => {
  const { storyInfo, ...rest } = props;
  // @ts-ignore
  const story = componentMapping[storyInfo.kind][storyInfo.name];
  if ((story as FunctionComponent).name) {
    const Component = story;
    return <Component {...rest} />;
  } else {
    const Component = story.component;
    const { args } = story;
    return <Component {...rest} {...args} />;
  }
};

const CustomStoryRenderer: React.FC = () => {
  const firstKindStory = Object.keys(componentMapping)[0];
  const firstStoryName = Object.keys(Object.values(componentMapping)[0])[0];
  const size = useWindowSize();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [storyInfo, setStoryInfo] = useState({
    kind: firstKindStory,
    name: firstStoryName
  });
  const handleNodeSelection = (e: any, nodeId: any) => {
    const [kind, name] = nodeId.split('-');
    if (kind && name) {
      setStoryInfo({ kind, name });
    }
  };

  const colStyle = {
    overflowY: 'scroll',
    height: size.height ?? '600px'
  };

  return (
    <Grid container direction="row" justifyContent="flex-start">
      <Grid item xs={3}>
        <Paper sx={{ py: 2, width: '100%', ...colStyle }}>
          <TreeView
            aria-label="file system navigator"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            defaultExpanded={[firstKindStory]}
            defaultSelected={`${firstKindStory}-${firstStoryName}`}
            onNodeSelect={handleNodeSelection}
          >
            {/*eslint-disable-next-line @typescript-eslint/no-unused-vars*/}
            {Object.entries(componentMapping).map(([storyKind, stories]) => (
              <TreeItem key={storyKind} nodeId={storyKind} label={storyKind.toString()}>
                {Object.entries(stories).map(([storyName]) => (
                  <TreeItem key={storyName} nodeId={`${storyKind}-${storyName}`} label={storyName} />
                ))}
              </TreeItem>
            ))}
          </TreeView>
        </Paper>
      </Grid>

      <Grid item xs={9}>
        <Paper sx={{ p: 2, ...colStyle }}>
          <Story storyInfo={storyInfo} />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default CustomStoryRenderer;
