import React from 'react';
import { Typography, Stack, Button } from '@mui/material';
import { useSelector } from '../../store';
import Loading from '../Loading/Loading';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useHistory } from 'react-router-dom';

const SongDetailTopbar: React.FC = () => {
  const { songDetail } = useSelector((state) => state.general);
  const history = useHistory();

  if (!songDetail) {
    return <Loading />;
  }

  return (
    <>
      <Stack
        sx={{ width: '100%', height: 52, bgcolor: 'grey.100', px: 1 }}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Button onClick={() => history.goBack()} color="inherit" startIcon={<ArrowBackIosNewIcon />}>
          Back
        </Button>
        <Stack alignItems="center" justifyContent="center">
          <Typography sx={{ fontSize: 14, fontWeight: 700 }} variant="h4">
            {songDetail.name}
          </Typography>
          <Typography sx={{ fontSize: 12, fontWeight: 300, color: '#5B5B5B' }} variant="h6">
            {songDetail.user?.username}
          </Typography>
        </Stack>
        <div style={{ width: 83 }}></div>
      </Stack>
    </>
  );
};

export default SongDetailTopbar;
