import { generateFakeSongList } from '../../utils/general';
import { generateFakeCommunities } from '../../utils/fakers';

export const userInfoArgs = {
  user: {
    imgUrl: 'https://loremflickr.com/128/128/cat?lock=7',
    role: 'Curator / Collector',
    fullName: 'Cody Fisher',
    communitiesCount: 11,
    userSince: 'Jan 2022',
    radishBalance: 13785
  },
  buttons: [
    {
      text: 'Manage Wallet',
      // eslint-disable-next-line no-console
      onClick: () => console.log('Manage wallet click')
    },
    {
      text: 'Invite a friend',
      // eslint-disable-next-line no-console
      onClick: () => console.log('Invite a friend click')
    }
  ],
  // eslint-disable-next-line no-console
  onAvatarClick: () => console.log('Avatar click'),
  // eslint-disable-next-line no-console
  onShareButtonClick: () => console.log('Share button click')
};

export const avatarButtonArgs = {
  src: 'https://loremflickr.com/200/200/cat?lock=7',
  // eslint-disable-next-line no-console
  onClick: () => console.log('click on avatar button'),
  sx: { width: 200, height: 200 },
  iconName: 'add'
};

export const communitiesSectionArgs = {
  title: 'Top communities this month',
  communities: generateFakeCommunities(),
  // eslint-disable-next-line no-console
  onPlayCommunity: (communityId: number) => console.log(`playing community id ${communityId}`)
};

export const exclusiveEditionsCollectionArgs = {
  title: 'Exclusive Editions Collection',
  songList: generateFakeSongList(3, 500),
  // eslint-disable-next-line no-console
  onPlaySong: (communityId: number) => console.log(`playing song id ${communityId}`)
};

export const curationHistorySectionArgs = {
  title: 'Curation History',
  songList: generateFakeSongList(4),
  // eslint-disable-next-line no-console
  onPlaySong: (communityId: number) => console.log(`playing song id ${communityId}`)
};
