import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SONG_REACTIONS } from '../../../../enums/player';
import { ISongReaction } from '../../../../shared/interfaces/models/song-reaction.interface';
import { useSelector } from '../../../../store';
import { getSongReactions } from '../../../../store/actions/songActions';
import { useEchoPlayer } from '../../../EchoPlayer/EchoPlayerProvider';
import { useElapsedTime } from '../../../EchoPlayer/ElapsedTimeProvider';
import ReactionBox from './ReactionBox';

interface IProps {
  communityId?: number;
}

const ReactionSection: React.FC<IProps> = ({ communityId }) => {
  const dispatch = useDispatch();
  const player = useEchoPlayer();
  const elapsed = useElapsedTime();
  const { newRealTimeSongReaction } = useSelector((state) => state.general);

  const [fullReactionsList, _setFullReactionsList] = useState<ISongReaction[]>([]);
  const fullReactionsListRef = useRef<ISongReaction[]>(fullReactionsList);
  const setFullReactionsList = (data: ISongReaction[]) => {
    fullReactionsListRef.current = data;
    _setFullReactionsList(data);
  };

  const [showReactionsQueue, _setShowReactionsQueue] = useState<ISongReaction[]>([]);
  const showReactionsQueueRef = useRef<ISongReaction[]>(showReactionsQueue);
  const setShowReactionsQueue = (data: ISongReaction[]) => {
    showReactionsQueueRef.current = data;
    _setShowReactionsQueue(data);
  };

  const [liveReactionsQueue, _setLiveReactionsQueue] = useState<ISongReaction[]>([]);
  const liveReactionsQueueRef = useRef<ISongReaction[]>(liveReactionsQueue);
  const setLiveReactionsQueue = (data: ISongReaction[]) => {
    liveReactionsQueueRef.current = data;
    _setLiveReactionsQueue(data);
  };

  useEffect(() => {
    if (newRealTimeSongReaction) {
      let currentReactions = liveReactionsQueueRef.current;
      if (elapsed) {
        currentReactions = liveReactionsQueueRef.current.filter(
          (r) => r.songTime < elapsed + SONG_REACTIONS.SECONDS_TO_SHOW + 1
        );
      }
      setLiveReactionsQueue([...currentReactions, newRealTimeSongReaction]);
    }
  }, [newRealTimeSongReaction]);

  useEffect(() => {
    const songId = player.data.current.song?.id;
    const take = 1000;
    if (songId) {
      dispatch(
        getSongReactions({ songId, communityId, take }, (songReactionList) => {
          setFullReactionsList(songReactionList.data);
        })
      );
    }
  }, [player.data.current.song, communityId]);

  useEffect(() => {
    if (
      player.data.current.playing &&
      elapsed !== undefined &&
      fullReactionsList.length > 0 &&
      elapsed % SONG_REACTIONS.SECONDS_TO_SHOW === 0
    ) {
      const tmpQueue = fullReactionsListRef.current.filter(
        (comment) =>
          comment.songTime >= elapsed - SONG_REACTIONS.SECONDS_TO_SHOW &&
          comment.songTime <= elapsed + SONG_REACTIONS.SECONDS_TO_SHOW
      );

      if (tmpQueue.length > 0) {
        setShowReactionsQueue(tmpQueue);
      }
    }
  }, [player.data.current.playing, elapsed]);

  if (player.data.current.playing && elapsed) {
    return (
      <div style={{ width: '100%', bottom: 0, position: 'absolute', left: 0 }}>
        {showReactionsQueueRef.current.map((reaction) => (
          <ReactionBox key={reaction.id} songReaction={reaction} />
        ))}
        {liveReactionsQueueRef.current.map((reaction) => (
          <ReactionBox key={reaction.id} songReaction={reaction} live={true} />
        ))}
      </div>
    );
  }

  return null;
};

export default ReactionSection;
