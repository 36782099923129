import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import CommentBox from './CommentBox';
import { useSelector } from '../../../../store';
import { SONG_COMMENTS } from '../../../../enums/player';
import { useDispatch } from 'react-redux';
import { getSongComments } from '../../../../store/actions/songActions';
import { ISongComment } from '../../../../shared/interfaces/models/song-comment.interface';
import { useEchoPlayer } from '../../../EchoPlayer/EchoPlayerProvider';
import { useElapsedTime } from '../../../EchoPlayer/ElapsedTimeProvider';

interface IProps {
  communityId?: number;
}

const CommentsSection: React.FC<IProps> = ({ communityId }) => {
  const dispatch = useDispatch();
  const player = useEchoPlayer();
  const elapsed = useElapsedTime();
  const { newRealTimeSongComment } = useSelector((state) => state.general);
  const [fullCommentsList, setFullCommentsList] = useState<ISongComment[]>([]);
  const [showCommentsQueue, setShowCommentsQueue] = useState<ISongComment[]>([]);

  useEffect(() => {
    if (newRealTimeSongComment) {
      showCommentsQueue.push(newRealTimeSongComment);
    }
  }, [newRealTimeSongComment]);

  useEffect(() => {
    const songId = player.data.current.song?.id;
    const take = 1000;
    if (songId) {
      dispatch(
        getSongComments({ songId, communityId, take }, (songCommentList) => {
          setFullCommentsList(songCommentList);
        })
      );
    }
  }, [player.data.current.song, communityId]);

  useEffect(() => {
    if (
      player.data.current.playing &&
      elapsed !== undefined &&
      elapsed % SONG_COMMENTS.SECONDS_TO_SHOW === 0 &&
      fullCommentsList.length > 0
    ) {
      const minElapsed = Math.floor(SONG_COMMENTS.SECONDS_TO_SHOW / 2);
      const maxElapsed = SONG_COMMENTS.SECONDS_TO_SHOW % 2 === 0 ? minElapsed - 1 : minElapsed;
      const tmpQueue = fullCommentsList.filter(
        (comment) => comment.songTime - minElapsed <= elapsed && elapsed <= comment.songTime + maxElapsed
      );
      if (tmpQueue.length > 0) {
        setShowCommentsQueue(tmpQueue);
      }
    }
  }, [player.data.current.playing, elapsed]);

  return (
    <>
      <Grid container direction="row" alignItems="flex-end" sx={{ height: '100%' }}>
        <Grid item xs={12}>
          {showCommentsQueue.map((songComment) => (
            <CommentBox key={songComment.id} songComment={songComment} />
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default CommentsSection;
