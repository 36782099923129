import { useSelector } from '../../store';
import { useCallback } from 'react';

export function gTagInitConfig(userId?: number) {
  const MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;
  const optProps: any = {};
  userId && (optProps['user_id'] = userId);
  if (!MEASUREMENT_ID) {
    // eslint-disable-next-line no-console
    console.warn('REACT_APP_GA_MEASUREMENT_ID is not defined');
    return;
  }
  gtag('config', MEASUREMENT_ID, {
    ...optProps,
    // eslint-disable-next-line camelcase
    send_page_view: false,
    // eslint-disable-next-line camelcase
    custom_map: {
      dimension1: 'song_id',
      dimension2: 'community_id',
      dimension3: 'boost_weight',
      dimension4: 'artist_id',
      dimension5: 'echo_user_id',
      metric1: 'song_time'
    }
  });
}

export default function useAnalytics(): { track: (eventName: string, data?: any) => void } {
  const { currentUser } = useSelector((state) => state.general);

  const track = useCallback(
    (eventName: string, data?: any) => {
      if (!window.gtag) {
        // eslint-disable-next-line no-console
        console.warn('window.gtag is not defined');
        return;
      }

      const customProps: any = {};
      currentUser !== undefined && (customProps['echo_user_id'] = currentUser?.id);
      if (data) {
        data.songId !== undefined && (customProps['song_id'] = data.songId);
        data.communityId !== undefined && (customProps['community_id'] = data.communityId);
        data.boostWeight !== undefined && (customProps['boost_weight'] = data.boostWeight);
        data.artistId !== undefined && (customProps['artist_Id'] = data.artistId);
        data.songTime !== undefined && (customProps['song_time'] = data.songTime);
      }
      if (eventName) {
        window.gtag('event', eventName, {
          // eslint-disable-next-line camelcase
          event_label: eventName.replaceAll('_', ' '),
          ...customProps
        });
      }
    },
    [currentUser]
  );

  return { track };
}
