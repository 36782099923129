const routePaths = {
  public: {
    landing: '/',
    login: '/login',
    register: '/register',
    invitationRegister: '/invitation-register/:token',
    requestPasswordReset: '/request-password-reset',
    resetPassword: '/reset-password/:token',
    uploadSongShowCase: '/upload-song-show-case',
    dashboard: '/dashboard',
    notFound: '/not-found'
  },
  private: {
    app: '/app',
    stories: '/stories',
    curation: {
      top: '/app/curation/top/:communityId',
      new: '/app/curation/new/:communityId'
    },
    chat: '/app/chat/:communityId',
    radio: '/app/radio/:communityId',
    profile: '/app/profile',
    curatorProfile: '/app/curator-profile/:userId',
    joinedCommunities: '/app/joined-communities/:userId',
    communitiesFeaturing: '/app/communities-featuring/:artistId',
    uploadedMusic: '/app/uploaded-music/:artistId',
    showcase: '/app/showcase',
    songDetail: '/app/song-detail/:songId/:autoPlay',
    artistDashboard: {
      main: '/app/artist-dashboard/:artistId',
      songEdit: '/app/artist-dashboard/song-edit/:songId'
    }
  },
  admin: {
    admin: '/admin',
    users: '/admin/users'
  }
};

export default routePaths;
