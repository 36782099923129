import { CircularProgress, Grid, Stack, Typography, Link as MuiLink } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { IUploadSong } from '../../shared/interfaces/custom/upload-song.interface';
import { createSong } from '../../store/actions/songActions';
import ImageFileUploadProgress from './ImageFileUploadProgress';
import AudioFileUploadProgress from './AudioFileUploadProgress';
import VideoFileUploadProgress from './VideoFileUploadProgress';
import { SxProps } from '@mui/system';
import { useSelector } from '../../store';
import { IRootState } from '../../interfaces/store/root.interface';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { generatePath, Link } from 'react-router-dom';
import routePaths from '../../lib/routePaths';
import { setGeneralValue } from '../../store/actions/generalActions';
import { setMainValue } from '../../store/actions/mainActions';
import useAnalytics from '../../lib/hooks/useAnalytics';

interface PropsType {
  uploadSong: IUploadSong;
  onFinished?: (uploadSong: IUploadSong | undefined) => void;
  onSuccess?: (uploadSong: IUploadSong | undefined) => void;
  onFail?: (uploadSong: IUploadSong | undefined) => void;
  readyToUpload: boolean | undefined;
  sx?: SxProps;
}

interface IResultMessageProps {
  success: boolean | undefined;
  savedUploadSong: any | undefined;
}

export const ResultMessage: React.FC<IResultMessageProps> = ({ success, savedUploadSong }) => {
  const { track } = useAnalytics();
  const dispatch = useDispatch();
  let songLink: string | undefined;

  useEffect(() => {
    if (success !== undefined) {
      track(success ? 'song_upload_success' : 'song_upload_error');
    }
  }, [success]);

  if (savedUploadSong && savedUploadSong.song && savedUploadSong.song.id) {
    songLink = generatePath(routePaths.private.songDetail, {
      songId: savedUploadSong?.song?.id,
      autoPlay: 'autoPlay'
    });
  }
  const handleViewNewSong = () => {
    dispatch(setMainValue('songUploadResults', {}));
    dispatch(setGeneralValue('currentUploadSongSuccess', undefined));
    dispatch(setGeneralValue('uploadSongs', []));
    dispatch(setGeneralValue('uploadModalState', false));
  };

  return (
    <Stack spacing={1}>
      <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
        {success === undefined ? (
          <>
            <CircularProgress />
            <Typography>Processing files...</Typography>
          </>
        ) : success ? (
          <>
            <CheckCircleIcon color="success" />
            <Typography color="success.main">Upload successful!</Typography>
          </>
        ) : (
          <>
            <ErrorIcon color="error" />
            <Typography color="error.main">Something went wrong with the upload. Please try again.</Typography>
          </>
        )}
      </Stack>
      <Stack alignItems="center">
        {/* @ts-ignore */}
        {success === true && songLink && (
          <MuiLink component={Link} to={songLink} onClick={handleViewNewSong}>
            Go to your track
          </MuiLink>
        )}
      </Stack>
    </Stack>
  );
};

const SongUploadProgressComponent: React.FC<PropsType> = ({
  sx,
  uploadSong,
  onSuccess,
  onFail,
  onFinished,
  readyToUpload
}) => {
  const dispatch = useDispatch();
  const { songUploadResults } = useSelector((state: IRootState) => state.main);

  const [savedUploadSong, setSavedUploadSong] = useState<IUploadSong | undefined>(undefined);
  const [imageUploadSuccess, setImageUploadSuccess] = useState<boolean | undefined>(undefined);
  const [audioUploadSuccess, setAudioUploadSuccess] = useState<boolean | undefined>(undefined);
  const [videoUploadSuccess, setVideoUploadSuccess] = useState<boolean | undefined>(undefined);
  const [processingSuccess, setProcessingSuccess] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (
      savedUploadSong?.song?.id &&
      songUploadResults &&
      songUploadResults[savedUploadSong.song.id] !== undefined &&
      songUploadResults[savedUploadSong.song.id].success !== undefined
    ) {
      setProcessingSuccess(songUploadResults[savedUploadSong.song.id]?.success);
    }
  }, [songUploadResults]);

  useEffect(() => {
    if (uploadSong && readyToUpload) {
      const saveAndUpload = async () => {
        const savedUploadSong = await saveNewSong(uploadSong);
        setSavedUploadSong(savedUploadSong);
      };
      // eslint-disable-next-line no-console
      saveAndUpload().catch((e: any) => console.log(e));
    }
  }, [uploadSong, readyToUpload]);

  useEffect(() => {
    if (
      imageUploadSuccess !== undefined &&
      audioUploadSuccess !== undefined &&
      videoUploadSuccess !== undefined &&
      processingSuccess !== undefined
    ) {
      onFinished && onFinished(savedUploadSong);
      if (imageUploadSuccess && audioUploadSuccess && videoUploadSuccess && processingSuccess) {
        onSuccess && onSuccess(savedUploadSong);
      } else {
        onFail && onFail(savedUploadSong);
      }
    }
  }, [imageUploadSuccess, audioUploadSuccess, videoUploadSuccess, processingSuccess]);

  const saveNewSong = (uploadSong: IUploadSong): Promise<IUploadSong> => {
    return new Promise((resolve, reject) =>
      dispatch(
        createSong(
          {
            name: uploadSong.song?.name,
            genre: uploadSong.song?.genre,
            description: uploadSong.song?.description,
            communityIds: uploadSong.communityIds,
            videoUrl: uploadSong.song?.videoUrl
          },
          (song) => {
            uploadSong.song = song;
            resolve(uploadSong);
          },
          () => {
            reject('error trying to save song to db');
          }
        )
      )
    );
  };

  if (!uploadSong) {
    return <CircularProgress />;
  }

  return (
    <>
      {!imageUploadSuccess || !audioUploadSuccess || !videoUploadSuccess ? (
        <Grid container key={uploadSong.index} sx={sx} direction="row" alignItems="center">
          <Grid xs={1} item sx={{ mr: 1 }}>
            <ImageFileUploadProgress
              readyToUpload={readyToUpload}
              uploadSong={savedUploadSong}
              onSuccess={() => setImageUploadSuccess(true)}
            />
          </Grid>
          <Grid xs={1} item sx={{ mr: 1 }}>
            <VideoFileUploadProgress
              readyToUpload={readyToUpload && imageUploadSuccess}
              uploadSong={savedUploadSong}
              onSuccess={() => setVideoUploadSuccess(true)}
            />
          </Grid>
          <Grid xs={7} item sx={{ mr: 1 }}>
            <AudioFileUploadProgress
              // Image, Audio and Video cannot be uploaded simultaneously, because this causes error
              readyToUpload={readyToUpload && imageUploadSuccess && videoUploadSuccess}
              uploadSong={savedUploadSong}
              onSuccess={() => setAudioUploadSuccess(true)}
            />
          </Grid>
        </Grid>
      ) : (
        <ResultMessage success={processingSuccess} savedUploadSong={savedUploadSong} />
      )}
    </>
  );
};

export default SongUploadProgressComponent;
