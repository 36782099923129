import React from 'react';
import AdminTable from '../../components/AdminTable/AdminTable';

const AdminUsers = () => {
  return (
    <>
      <AdminTable />
    </>
  );
};

export default AdminUsers;
