import React from 'react';
import { Route, Switch } from 'react-router-dom';
import routePaths from '../../lib/routePaths';
import ArtistDashboard from '../../pages/ArtistDashboard/ArtistDashboard';
import SongEdit from '../../pages/ArtistDashboard/Song/SongEdit';
import CommunitiesPage from '../../pages/CommunitiesPage/CommunitiesPage';
import Profile from '../../pages/ProfilePage/Profile';
import Showcase from '../../pages/ShowcasePage/Showcase';
import JoinedCommunitiesPage from '../../pages/JoinedCommunitiesPage/JoinedCommunitiesPage';
// import TemporaryHomeNavButtons from '../../components/TemporaryHomeNavButtons/TemporaryHomeNavButtons';
import CuratorProfilePage from '../../pages/CuratorProfilePage/CuratorProfilePage';
import CommunitiesFeaturingPage from '../../pages/CommunitiesFeaturingPage/CommunitiesFeaturingPage';
import UploadedMusicPage from '../../pages/UploadedMusicPage/UploadedMusicPage';
import SongDetailPage from '../../pages/SongDetailPage/SongDetailPage';

const FullViewTemplate: React.FC = () => {
  return (
    <Switch>
      <Route exact path={routePaths.private.app}>
        {/*<TemporaryHomeNavButtons />*/}
        <CommunitiesPage />
      </Route>
      <Route exact path={routePaths.private.profile}>
        <Profile />
      </Route>
      <Route exact path={routePaths.private.joinedCommunities}>
        <JoinedCommunitiesPage />
      </Route>
      <Route exact path={routePaths.private.communitiesFeaturing}>
        <CommunitiesFeaturingPage />
      </Route>
      <Route exact path={routePaths.private.uploadedMusic}>
        <UploadedMusicPage />
      </Route>
      <Route exact path={routePaths.private.showcase}>
        <Showcase />
      </Route>
      <Route exact path={routePaths.private.artistDashboard.main}>
        <ArtistDashboard />
      </Route>
      {/*<Route exact path={routePaths.private.artistDashboard.songDetail}>*/}
      {/*  <SongDetail />*/}
      {/*</Route>*/}
      <Route exact path={routePaths.private.artistDashboard.songEdit}>
        <SongEdit />
      </Route>
      <Route exact path={routePaths.private.curatorProfile}>
        <CuratorProfilePage />
      </Route>
      <Route exact path={[routePaths.private.songDetail]}>
        <SongDetailPage />
      </Route>
    </Switch>
  );
};

export default FullViewTemplate;
