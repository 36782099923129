import { createContext, useContext, useEffect } from 'react';
import { IEchoPlayerContext } from '../../interfaces/echo-player/echo-player-context.interface';
import useControls, { controlsInitValues } from './controls/useControls';
import usePlayerInfo, { dataInitValues } from './info/usePlayerInfo';
import { leaveCommunityEvents } from '../../store/actions/communitiesActions';
import { useDispatch } from 'react-redux';

const initValues = {
  data: dataInitValues,
  controls: controlsInitValues
};

const echoPlayerContext = createContext<IEchoPlayerContext>(initValues);

export const EchoPlayerProvider: React.FC<{ children: unknown }> = ({ children }) => {
  const dispatch = useDispatch();
  const playerInfo = usePlayerInfo();
  const controls = useControls(playerInfo);

  const handleTabClose = () => {
    if (playerInfo.data.radio.playing) {
      dispatch(leaveCommunityEvents({ id: playerInfo.data.radio.communityId }));
    }
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleTabClose);
    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, []);

  const contextValue = {
    data: playerInfo.data,
    controls
  };

  return <echoPlayerContext.Provider value={contextValue}>{children}</echoPlayerContext.Provider>;
};

export function useEchoPlayer() {
  return useContext(echoPlayerContext);
}
