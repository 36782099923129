import { ACTIONS } from '../../enums/actions';
import {
  ICompleteMultipartUploadPayload,
  IInitPresignedUrlMultipartUploadPayload,
  IInitPresignedUrlSimpleUploadPayload
} from '../../interfaces/requests/aws.interface';
import { IDispatchAction } from '../../interfaces/store/root.interface';
import { SHARED_ACTIONS } from '../../shared/constants/actions.const';

export const initPresignedUrlMultipartUpload = (
  payload: IInitPresignedUrlMultipartUploadPayload,
  onSuccess?: () => void,
  onFail?: () => void,
  onProgress?: (percent: number) => void
): IDispatchAction<IInitPresignedUrlMultipartUploadPayload> => ({
  type: ACTIONS.INIT_PRESIGNED_URL_MULTIPART_UPLOAD,
  payload,
  onSuccess,
  onFail,
  onProgress
});

export const initPresignedUrlSimpleUpload = (
  payload: IInitPresignedUrlSimpleUploadPayload,
  onSuccess?: () => void,
  onFail?: () => void,
  onProgress?: (percent: number) => void
): IDispatchAction<IInitPresignedUrlSimpleUploadPayload> => ({
  type: ACTIONS.INIT_PRESIGNED_URL_SIMPLE_UPLOAD,
  payload,
  onSuccess,
  onFail,
  onProgress
});

export const completeMultipartUpload = (
  payload: ICompleteMultipartUploadPayload,
  onSuccess?: () => void,
  onFail?: () => void,
  onProgress?: (percent: number) => void
): IDispatchAction<ICompleteMultipartUploadPayload> => ({
  type: ACTIONS.COMPLETE_MULTIPART_UPLOAD,
  payload,
  onSuccess,
  onFail,
  onProgress
});

export const completeSinglepartUpload = (
  payload: any,
  onSuccess?: () => void,
  onFail?: () => void,
  onProgress?: (percent: number) => void
): IDispatchAction<any> => ({
  type: SHARED_ACTIONS.COMPLETE_SINGLEPART_UPLOAD,
  payload,
  onSuccess,
  onFail,
  onProgress
});
