import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useEchoPlayer } from './EchoPlayerProvider';

const ElapsedTimeContext = createContext<number | undefined>(undefined);

export const ElapsedTimeProvider: React.FC<{ children: unknown }> = ({ children }) => {
  const [clockTick, setClockTick] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const [elapsed, _setElapsed] = useState<number | undefined>(0);
  const elapsedTimeRef = useRef<number | undefined>(elapsed);
  const setElapsed = (data: number | undefined) => {
    elapsedTimeRef.current = data;
    _setElapsed(data);
  };

  // const [elapsed, setElapsed] = useState<number>();
  const player = useEchoPlayer();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setClockTick((state) => !state);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (player.data.radio.playing) {
      const currentMillis = new Date().getTime();
      let elapsedTime: number | undefined =
        currentMillis - (player.data.radio.songStartedAt?.getTime() ?? currentMillis);
      elapsedTime = Math.floor(elapsedTime / 1000);
      elapsedTime -= 36;
      player.data.radio.songDuration && elapsedTime < player.data.radio.songDuration ? elapsedTime : undefined;
      setElapsed(elapsedTime);
    } else if (player.data.song.playing) {
      const time = player.controls.song.audioElement?.currentTime;
      setElapsed(time ? Math.floor(time) : undefined);
    } else if (elapsed !== undefined) {
      setElapsed(elapsed);
    }
  }, [clockTick]);

  return <ElapsedTimeContext.Provider value={elapsed}>{children}</ElapsedTimeContext.Provider>;
};

export function useElapsedTime() {
  return useContext(ElapsedTimeContext);
}
