import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Divider,
  IconButton,
  Link,
  Paper,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import React, { useState } from 'react';
import Status from '../../theme/components/Status';

interface PropType {
  colorName: string;
  colorVariant: string;
  colorCode: string;
}

const ColorBox: React.FC<PropType> = ({ colorName, colorVariant, colorCode }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: 96,
        height: 96,
        backgroundColor: `${colorName}.${colorVariant}`,
        textAlign: 'center',
        padding: 1
      }}
    >
      <Typography variant="body1" color={theme.palette.getContrastText(colorCode)}>
        {colorCode}
      </Typography>
      <Typography variant="caption" color={theme.palette.getContrastText(colorCode)}>
        {colorName}.{colorVariant}
      </Typography>
    </Box>
  );
};

const Showcase: React.FC = () => {
  const [showAlert, setShowAlert] = useState(true);
  const theme = useTheme();
  // console.log('theme.palette', theme.palette);

  return (
    <div className="p-5">
      <Typography color="primary" variant="corben">
        Echodrop <span style={{ color: theme.palette.secondary.main }}>Showcase</span>
      </Typography>
      <Divider />

      <Typography color="primary" variant="display1" sx={{ mt: 7, textAlign: 'center' }}>
        Colors
      </Typography>
      <Paper sx={{ m: 3, p: 5, bgcolor: 'rgba(196,196,196,0.09)' }}>
        <Typography color="primary" variant="h2" sx={{ mb: 2, textAlign: 'center' }}>
          Material UI styles
        </Typography>
        <Stack justifyContent="center" alignItems="flex-start" direction="row" spacing={1}>
          {/*{Object.keys(theme.palette).map((colorName, i) => (*/}
          {['primary', 'secondary', 'success', 'warning', 'error', 'info', 'grey', 'text'].map((colorName, i) => (
            <div key={i}>
              <Typography
                sx={{
                  textAlign: 'center',
                  fontWeight: 800,
                  mb: 1,
                  color: `${colorName}.main`
                }}
                variant="h5"
              >
                {colorName}
              </Typography>
              <Stack key={i} justifyContent="center" alignItems="center" direction="column" spacing={1}>
                {
                  // @ts-ignore
                  Object.keys(theme.palette[colorName]).map((colorVariant) => (
                    <ColorBox
                      key={colorVariant}
                      colorName={colorName}
                      colorVariant={colorVariant}
                      // @ts-ignore
                      colorCode={theme.palette[colorName][colorVariant]}
                    />
                  ))
                }
              </Stack>
            </div>
          ))}
        </Stack>

        {/* suspended for now... */}

        {/* TailwindCSS doesn't support dynamic variables on class name */}
        {/*  <Typography color="primary" variant="h2" sx={{ mt: 7, mb: 2, textAlign: 'center' }}>*/}
        {/*    TailwindCSS styles*/}
        {/*  </Typography>*/}
        {/*  <Stack justifyContent="center" alignItems="center" direction="row" spacing={1}>*/}
        {/*    <Box className="bg-primary-main w-24 h-24 p-2 text-center">*/}
        {/*      <Typography variant="body1" className="text-white">*/}
        {/*        {theme.palette.primary.main}*/}
        {/*      </Typography>*/}
        {/*      <Typography variant="caption" className="text-white">*/}
        {/*        success*/}
        {/*      </Typography>*/}
        {/*    </Box>*/}
        {/*    <Box className="bg-secondary-main w-24 h-24 p-2 text-center">*/}
        {/*      <Typography variant="body1" className="text-white">*/}
        {/*        {theme.palette.secondary.main}*/}
        {/*      </Typography>*/}
        {/*      <Typography variant="caption" className="text-white">*/}
        {/*        success*/}
        {/*      </Typography>*/}
        {/*    </Box>*/}
        {/*    <Box className="bg-success-main w-24 h-24 p-2 text-center">*/}
        {/*      <Typography variant="body1" className="text-white">*/}
        {/*        {theme.palette.success.main}*/}
        {/*      </Typography>*/}
        {/*      <Typography variant="caption" className="text-white">*/}
        {/*        success*/}
        {/*      </Typography>*/}
        {/*    </Box>*/}
        {/*    <Box className="bg-warning-main w-24 h-24 p-2 text-center">*/}
        {/*      <Typography variant="body1" className="text-white">*/}
        {/*        {theme.palette.warning.main}*/}
        {/*      </Typography>*/}
        {/*      <Typography variant="caption" className="text-white">*/}
        {/*        success*/}
        {/*      </Typography>*/}
        {/*    </Box>*/}
        {/*    <Box className="bg-error-main w-24 h-24 p-2 text-center">*/}
        {/*      <Typography variant="body1" className="text-white">*/}
        {/*        {theme.palette.error.main}*/}
        {/*      </Typography>*/}
        {/*      <Typography variant="caption" className="text-white">*/}
        {/*        success*/}
        {/*      </Typography>*/}
        {/*    </Box>*/}
        {/*    <Box className="bg-info-main w-24 h-24 p-2 text-center">*/}
        {/*      <Typography variant="body1" className="text-white">*/}
        {/*        {theme.palette.info.main}*/}
        {/*      </Typography>*/}
        {/*      <Typography variant="caption" className="text-white">*/}
        {/*        success*/}
        {/*      </Typography>*/}
        {/*    </Box>*/}
        {/*  </Stack>*/}
      </Paper>

      <Typography color="primary" variant="display1" sx={{ mt: 7, textAlign: 'center' }}>
        Fonts
      </Typography>
      <Paper sx={{ m: 3, p: 5, bgcolor: 'rgba(196,196,196,0.09)' }}>
        <Typography variant="h1">H1</Typography>
        <Typography variant="h2">H2</Typography>
        <Typography variant="h2-medium">H2-medium</Typography>
        <Typography variant="h3">H3</Typography>
        <Typography variant="h4">H4</Typography>
        <Typography variant="h5">H5</Typography>
        <Typography variant="h6">H6</Typography>
        <Typography variant="subtitle1">subtitle1</Typography>
        <Typography variant="subtitle2">subtitle2</Typography>
        <Typography variant="body1">body1</Typography>
        <Typography variant="body2">body2</Typography>
        <Typography variant="button">button</Typography>
        <div>
          <Typography variant="caption">caption</Typography>
        </div>
        <Typography variant="overline">overline</Typography>
        <Typography variant="corben">corben</Typography>
        <Typography variant="hero">hero</Typography>
        <Typography variant="display1">display1</Typography>
        <Typography variant="display2">display2</Typography>
        <Typography variant="userid">userid</Typography>
        <Typography variant="badge">badge</Typography>
        <Typography variant="bottomnav">bottomnav</Typography>
      </Paper>

      <Typography color="primary" variant="display1" sx={{ mt: 7, textAlign: 'center' }}>
        Buttons
      </Typography>
      <Paper sx={{ m: 3, p: 5, bgcolor: 'rgba(196,196,196,0.09)' }}>
        <div className="mb-5">
          <Button variant="contained">Contained Button</Button>
          <Button variant="outlined">Outlined Button</Button>
          <Button>Normal Button</Button>
        </div>
        <div className="mb-5">
          <Button disabled variant="contained">
            Contained Button
          </Button>
          <Button disabled variant="outlined">
            Outlined Button
          </Button>
          <Button disabled>Normal Button</Button>
        </div>
        <div className="mb-5">
          <Button variant="contained" color="primary">
            Primary
          </Button>
          <Button variant="contained" color="secondary">
            Secondary
          </Button>
          <Button variant="contained" color="warning">
            Warning
          </Button>
          <Button variant="contained" color="error">
            Error
          </Button>
          <Button variant="contained" color="success">
            Success
          </Button>
          <Button variant="contained" color="info">
            Info
          </Button>
        </div>
        <div className="mb-5">
          <Button variant="outlined" color="primary">
            Primary
          </Button>
          <Button variant="outlined" color="secondary">
            Secondary
          </Button>
          <Button variant="outlined" color="warning">
            Warning
          </Button>
          <Button variant="outlined" color="error">
            Error
          </Button>
          <Button variant="outlined" color="success">
            Success
          </Button>
          <Button variant="outlined" color="info">
            Info
          </Button>
        </div>

        <div className="mb-5">
          <LoadingButton loading variant="contained" color="primary">
            Contained Button
          </LoadingButton>
          <LoadingButton loading variant="outlined" color="primary">
            Outlined Button
          </LoadingButton>
          <LoadingButton loading color="primary">
            Normal Button
          </LoadingButton>
        </div>
        <div className="mb-5">
          <Link href="#">link</Link>
        </div>
        <div>
          <Button startIcon={<ImageOutlinedIcon />}>Icon Button</Button>
          <Button disabled startIcon={<ImageOutlinedIcon />}>
            Icon Button
          </Button>
        </div>

        <div>
          <Button endIcon={<ImageOutlinedIcon />}>Icon Button</Button>
          <Button disabled endIcon={<ImageOutlinedIcon />}>
            Icon Button
          </Button>
        </div>
        <div>
          <IconButton color="primary">
            <ImageOutlinedIcon />
          </IconButton>
        </div>
      </Paper>

      <Typography color="primary" variant="display1" sx={{ mt: 7, textAlign: 'center' }}>
        Status
      </Typography>
      <Paper sx={{ m: 3, p: 5, bgcolor: 'rgba(196,196,196,0.09)' }}>
        <Typography color="primary" variant="h2" sx={{ mt: 0, mb: 2 }}>
          Text
        </Typography>
        <Stack direction="row" spacing={1}>
          <Status variant="text" message="Message" severity="success" />
          <Status variant="text" message="Message" severity="info" />
          <Status variant="text" message="Message" severity="error" />
          <Status variant="text" message="Message" severity="warning" />
          <Status variant="text" message="Message" />
        </Stack>

        <Typography color="primary" variant="h2" sx={{ mt: 7, mb: 2 }}>
          Tag
        </Typography>
        <Stack direction="row" spacing={1}>
          <Status variant="tag" message="Message" severity="success" />
          <Status variant="tag" message="Message" severity="info" />
          <Status variant="tag" message="Message" severity="error" />
          <Status variant="tag" message="Message" severity="warning" />
          <Status variant="tag" message="Message" />
        </Stack>

        <Typography color="primary" variant="h2" sx={{ mt: 7, mb: 2 }}>
          Badge
        </Typography>
        <Stack direction="row" spacing={1}>
          <Status variant="badge" message="Message" severity="success" />
          <Status variant="badge" message="Message" severity="info" />
          <Status variant="badge" message="Message" severity="error" />
          <Status variant="badge" message="Message" severity="warning" />
          <Status variant="badge" message="Message" />
        </Stack>

        <Typography color="primary" variant="h2" sx={{ mt: 7, mb: 2 }}>
          Screen
        </Typography>
        <Stack direction="row" spacing={1}>
          <Status variant="screen" message="Message" severity="success" />
          <Status variant="screen" message="Message" severity="info" />
          <Status variant="screen" message="Message" severity="error" />
          <Status variant="screen" message="Message" severity="warning" />
          <Status variant="screen" message="Message" />
        </Stack>
      </Paper>

      <Typography color="primary" variant="display1" sx={{ mt: 7, textAlign: 'center' }}>
        Alert message
      </Typography>
      <Paper sx={{ m: 3, p: 5, bgcolor: 'rgba(196,196,196,0.09)' }}>
        <Typography color="primary" variant="h2" sx={{ mt: 0, mb: 2, textAlign: 'center' }}>
          Message only
        </Typography>
        <Stack spacing={2}>
          {showAlert && <Alert onClose={() => setShowAlert(false)}>Alert with close button</Alert>}
          <Alert severity="error">This is an error alert — check it out!</Alert>
          <Alert severity="warning">This is a warning alert — check it out!</Alert>
          <Alert severity="info">This is an info alert — check it out!</Alert>
          <Alert severity="success">This is a success alert — check it out!</Alert>
          {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
        </Stack>

        <Typography color="primary" variant="h2" sx={{ mt: 7, mb: 2, textAlign: 'center' }}>
          With title
        </Typography>

        <Stack sx={{ width: '100%' }} spacing={2}>
          <Alert severity="error">
            <AlertTitle>
              <strong>Error</strong>
            </AlertTitle>
            This is an error alert
          </Alert>
          <Alert severity="warning">
            <AlertTitle>
              <strong>Warning</strong>
            </AlertTitle>
            This is a warning alert
          </Alert>
          <Alert severity="info">
            <AlertTitle>
              <strong>Info</strong>
            </AlertTitle>
            This is an info alert
          </Alert>
          <Alert severity="success">
            <AlertTitle>
              <strong>Success</strong>
            </AlertTitle>
            This is a success alert
          </Alert>
        </Stack>
      </Paper>
    </div>
  );
};

export default Showcase;
