/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  TextField,
  Typography,
  useTheme,
  DialogTitle,
  DialogContent
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { object, string } from 'yup';
import { useAuth } from '../Authentication/ProvideAuth';
import LoadingButton from '@mui/lab/LoadingButton';
import useAnalytics from '../../lib/hooks/useAnalytics';
import { useFormik } from 'formik';
import { inviteUser } from '../../store/actions/userActions';
import { IInviteUserPayload } from '../../interfaces/requests/artist.interface';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

interface PropType {
  showDialog: boolean;
  setShowDialog: (value: boolean) => void;
}

const InviteUserDialog: React.FC<PropType> = ({ showDialog = false, setShowDialog }) => {
  const { track } = useAnalytics();
  const dispatch = useDispatch();
  const theme = useTheme();
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const [userInvitedSuccess, setUserInvitedSuccess] = useState<boolean | undefined>();
  const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    if (showDialog) {
      setLoading(false);
      setUserInvitedSuccess(undefined);
      setError(undefined);
      formik.resetForm();
    }
  }, [showDialog]);

  const validationSchema = object().shape({
    email: string().email().required().min(7).max(256)
  });

  const checkIfUserFieldAlreadyExists = (fieldName: string): Promise<boolean> => {
    return new Promise((resolve) => {
      // @ts-ignore
      const value = formik.values[fieldName];
      if (!value) {
        formik.setFieldError(fieldName, undefined);
        return resolve(false);
      }
      auth.checkIfUserExists(
        { [fieldName]: value },
        (alreadyExists: boolean) => {
          if (alreadyExists) {
            formik.setFieldError(fieldName, `${formik.values.email} is already a registered user`);
          } else {
            formik.setFieldError(fieldName, undefined);
          }
          resolve(alreadyExists);
        },
        () => {
          formik.setFieldError(fieldName, undefined);
          resolve(false);
        }
      );
    });
  };

  const formik = useFormik({
    initialValues: {
      email: ''
    } as IInviteUserPayload,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validationSchema,
    onSubmit: async () => {
      const userAlreadyExists = await checkIfUserFieldAlreadyExists('email');
      if (!userAlreadyExists) {
        setLoading(true);
        dispatch(
          inviteUser(
            formik.values,
            (result: any) => {
              if (result.error) {
                setError(result.error.response.error);
                track('user_invited_error');
              } else {
                track('user_invited_success');
              }
              setUserInvitedSuccess(!result.error);
              setLoading(false);
            },
            (e: any) => {
              track('user_invited_error');
              setUserInvitedSuccess(false);
              setError('Something went wrong. Please try again later');
              setLoading(false);
            }
          )
        );
      }
    }
  });

  return (
    <>
      <Dialog fullWidth onClose={() => setShowDialog(false)} open={showDialog}>
        <Box sx={{ p: 2 }}>
          <DialogTitle>
            <Typography variant="h2">Invite user</Typography>
          </DialogTitle>
          <DialogContent>
            {userInvitedSuccess === undefined && (
              <TextField
                sx={{ my: 1 }}
                disabled={loading}
                onChange={formik.handleChange}
                error={!!formik.errors.email}
                helperText={formik.errors.email ? formik.errors.email : undefined}
                name="email"
                label="Email"
                variant="outlined"
                fullWidth
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    formik.handleSubmit();
                  }
                }}
              />
            )}

            {userInvitedSuccess === true && (
              <Box sx={{ textAlign: 'center' }}>
                <CheckCircleIcon sx={{ fontSize: 100, color: theme.palette.primary.main }} />
                <Typography variant="h4" sx={{ mt: 2 }}>
                  <strong>{formik.values.email}</strong> has been invited!
                </Typography>
                <Typography variant="body1" sx={{ mt: 1 }}>
                  We have sent an email to {formik.values.email} with instructions on how to join.
                </Typography>
              </Box>
            )}

            {userInvitedSuccess === false && (
              <Box sx={{ textAlign: 'center' }}>
                <ErrorIcon sx={{ fontSize: 100, color: theme.palette.error.main }} />
                <Typography color={'error.main'} variant="h4" sx={{ mt: 2 }}>
                  <strong>{formik.values.email}</strong> could not be invited!
                </Typography>
                <Typography color={'error.main'} variant="body1" sx={{ mt: 1 }}>
                  {error}
                </Typography>
              </Box>
            )}
          </DialogContent>
          <DialogActions sx={{ pr: 3 }}>
            {userInvitedSuccess !== undefined ? (
              <Button onClick={() => setShowDialog(false)} variant="contained" color="primary">
                Close
              </Button>
            ) : (
              <>
                <Button disabled={loading} onClick={() => setShowDialog(false)} color="primary">
                  Cancel
                </Button>
                <LoadingButton
                  loading={loading}
                  onClick={() => formik.handleSubmit()}
                  variant="contained"
                  color="primary"
                >
                  Invite user
                </LoadingButton>
              </>
            )}
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default InviteUserDialog;
