import RefreshIcon from '@mui/icons-material/Refresh';
import { Stack, Button } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CURATION_TABLE_TYPE } from '../../../enums/curation';
import { createFromSongCommunity, ISongComponent } from '../../../interfaces/ui-components/song.interface';
import useQuery from '../../../lib/hooks/useQuery';
import { PAGINATION_ITEMS_TO_SKIP, PAGINATION_ITEMS_TO_TAKE } from '../../../shared/constants/pagination.const';
import { IPaginationInfo } from '../../../shared/interfaces/custom/pagination-info.interface';
import { ISongCommunity } from '../../../shared/interfaces/models/song-community.interface';
import { Paginated } from '../../../shared/interfaces/responses/paginated.interface';
import { getPaginationInfo } from '../../../shared/utils/pagination';
import { useSelector } from '../../../store';
import { getSongCommunitiesByCommunity } from '../../../store/actions/songActions';
import Loading from '../../Loading/Loading';
import SongBaseTable from '../../SongBaseTableComponent/SongBaseTable';
import { getUserCommunitiesByUserId } from '../../../store/actions/communitiesActions';
import { MAX_TOP_SONGS_IN_COMMUNITY } from '../../../shared/constants/curation';
import { USER_ROLE } from '../../../shared/constants/user.const';

interface PropsType {
  type?: CURATION_TABLE_TYPE;
}

interface RouteParams {
  communityId: string;
  skip?: string;
}

const PAGE_SIZE = 50;

const CurationTable: React.FC<PropsType> = ({ type = CURATION_TABLE_TYPE.TOP }) => {
  const { currentUser, currentUserCommunity, currentUserCommunityLoading } = useSelector((state) => state.general);
  const dispatch = useDispatch();
  const queryParams = useQuery();
  const { communityId } = useParams<RouteParams>();
  const [songComponentList, setSongComponentList] = useState<ISongComponent[]>([]);
  const [currentPaginationInfo, setCurrentPaginationInfo] = useState<IPaginationInfo | undefined>();
  const [loading, setLoading] = useState(false);
  const [isModerator, setIsModerator] = useState<boolean>();

  const colsToShow = useMemo(() => {
    const cols = ['image', 'song', 'artist', 'upvote', 'addedToCommunityAt', 'listen'];
    type === CURATION_TABLE_TYPE.TOP && cols.unshift('rank');
    (isModerator || currentUser?.role === USER_ROLE.SYSADMIN) && cols.push('delete');
    return cols;
  }, [isModerator, currentUser]);

  useEffect(() => {
    const skip = queryParams.get('skip') || '0';
    fetchPage(parseInt(skip), PAGE_SIZE);
  }, []);

  useEffect(() => {
    if (currentUser) {
      // const skip = queryParams.get('skip') || '0';
      // fetchPage(parseInt(skip), PAGE_SIZE);
      if (isModerator === undefined) {
        dispatch(
          getUserCommunitiesByUserId({ id: currentUser.id }, (userCommunities) => {
            const isModeratorValue = userCommunities.some(
              (uc) => uc.communityId === Number(communityId) && uc.isModerator
            );
            setIsModerator(isModeratorValue);
          })
        );
      }
    }
  }, [currentUser]);

  useEffect(() => {
    setLoading(false);
  }, [songComponentList]);

  const onReceiveResponse = (page: Paginated<ISongCommunity>) => {
    if (page) {
      setSongComponentList((oldState) => {
        setCurrentPaginationInfo(getPaginationInfo(page));
        const newList: ISongComponent[] = page.data.map((songCommunity, index) => {
          const newSong = createFromSongCommunity(songCommunity);
          newSong.index = oldState.length + index + 1;
          newSong.rank = newSong.index.toString();
          return newSong;
        });
        return [...oldState, ...newList];
      });
    }
  };

  const onReceiveResponseReloadTable = (page: Paginated<ISongCommunity>) => {
    if (page) {
      setSongComponentList(() => {
        setCurrentPaginationInfo(getPaginationInfo(page));
        return page.data.map((songCommunity, index) => {
          const newSong = createFromSongCommunity(songCommunity);
          newSong.index = index + 1;
          newSong.rank = newSong.index.toString();
          return newSong;
        });
      });
    }
  };

  const handleLoadMore = () => {
    if (currentPaginationInfo && currentPaginationInfo.hasMore && !loading) {
      fetchPage(currentPaginationInfo?.skip + PAGE_SIZE, PAGE_SIZE);
    }
  };

  const fetchPage = useCallback(
    (skip = PAGINATION_ITEMS_TO_SKIP, take = PAGINATION_ITEMS_TO_TAKE, reloadTable?: boolean) => {
      setLoading(true);
      let sort: any;
      if (type === CURATION_TABLE_TYPE.TOP) {
        sort = { votes: 'DESC', createdAt: 'DESC' };
      } else if (type === CURATION_TABLE_TYPE.NEW) {
        sort = { createdAt: 'DESC' };
      }
      if (reloadTable)
        dispatch(getSongCommunitiesByCommunity({ id: communityId, skip, take, sort }, onReceiveResponseReloadTable));
      else dispatch(getSongCommunitiesByCommunity({ id: communityId, skip, take, sort }, onReceiveResponse));
    },
    [currentPaginationInfo, type]
  );

  if ((songComponentList.length === 0 && loading) || currentUserCommunityLoading) {
    return <Loading />;
  }

  return (
    <>
      <Stack direction="row" sx={{ mb: 1 }} justifyContent="flex-end">
        <Button startIcon={<RefreshIcon />} onClick={() => fetchPage(0, PAGE_SIZE, true)}>
          Refresh
        </Button>
      </Stack>
      <SongBaseTable
        setSongList={setSongComponentList}
        songList={songComponentList}
        colsToShow={colsToShow}
        onScrollReachBottom={handleLoadMore}
        loadMore={handleLoadMore}
        skeletonRows={loading ? 5 : undefined}
        separateTop={
          type === CURATION_TABLE_TYPE.TOP
            ? currentUserCommunity?.community?.streamingPlaylistSize ?? MAX_TOP_SONGS_IN_COMMUNITY
            : undefined
        }
        hasMore={currentPaginationInfo?.hasMore}
      />
    </>
  );
};
export default CurationTable;
