import DiamondOutlinedIcon from '@mui/icons-material/DiamondOutlined';
import { Avatar, Badge, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { ICommunityComponent } from '../../interfaces/ui-components/slider-community.interface';
import { rankColors } from '../../utils/general';
import AvatarButton from '../AvatarButtonComponent/AvatarButton';

interface PropsType {
  community: ICommunityComponent;
  onPlayCommunity?: (communityId: number) => void;
}

const CommunityComponent: React.FC<PropsType> = ({ community, onPlayCommunity }) => {
  const styles = {
    newLabel: {
      p: '4px',
      borderRadius: 1,
      fontWeight: 700,
      color: 'primary.main',
      bgcolor: 'primary.50'
    },
    smallAvatar: {
      width: 28,
      height: 28,
      bgcolor: 'primary.50',
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.12)'
    }
  };

  return (
    <Stack sx={{ width: 140 }} justifyContent="center" alignItems="center">
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={
          community.isNew ? (
            <Typography variant="caption" sx={styles.newLabel}>
              New
            </Typography>
          ) : (
            <>
              {community.level ? (
                <Avatar sx={styles.smallAvatar}>
                  <DiamondOutlinedIcon
                    sx={{
                      color: rankColors[community.level]
                    }}
                  />
                </Avatar>
              ) : null}
            </>
          )
        }
      >
        {onPlayCommunity && !community.coomingSoon ? (
          <AvatarButton
            defaultImageUrl={'assets/diversity_3_FILL0_wght400_GRAD0_opsz48.svg'}
            sx={{ width: 140, height: 140 }}
            iconProps={{ fontSize: 'large' }}
            iconName="play_circle_outline"
            onClick={() => {
              community.id && onPlayCommunity(community.id);
            }}
            src={community.imageUrl}
          />
        ) : (
          <Avatar src={community.imageUrl} sx={{ width: 140, height: 140 }} />
        )}
      </Badge>
      <Typography variant="body2" sx={{ pt: 2, textAlign: 'center' }}>
        {community.name}
      </Typography>
    </Stack>
  );
};

export default CommunityComponent;
