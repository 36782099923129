/* eslint-disable */
import { Redirect, Route } from 'react-router-dom';
import { USER_ROLE } from '../../shared/constants/user.const';
import { useSelector } from '../../store';

interface IAdminRoute {
  children: any;
  [key: string]: any;
}

const AdminRoute: React.FC<IAdminRoute> = ({ children, ...rest }) => {
  const user = useSelector((state) => state.general.currentUser);
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (user?.role === USER_ROLE.ADMIN || user?.role === USER_ROLE.SYSADMIN) {
          return children;
        } else {
          return (
            <Redirect
              to={{
                pathname: '/',
                state: { from: location }
              }}
            />
          );
        }
      }}
    />
  );
};

export default AdminRoute;
