import React from 'react';
import { useParams } from 'react-router-dom';
import ChatComponent from './ChatComponent';
import { CircularProgress } from '@mui/material';
import { useSelector } from '../../../store';

interface RouteParams {
  communityId?: string;
  songId?: string;
}

interface IProps {
  height: number;
}

const ChatContainer: React.FC<IProps> = ({ height }) => {
  const { communityId, songId } = useParams<RouteParams>();
  const user = useSelector((state) => state.general.currentUser);
  if (!user) {
    return <CircularProgress />;
  }
  const channel = communityId ? `community-${communityId}` : `song-${songId}`;

  return <ChatComponent height={height} channel={channel} user={user} />;
};

export default ChatContainer;
