import React, { useEffect } from 'react';
import { PubNubProvider } from 'pubnub-react';
import PubNub from 'pubnub';
import { useSelector } from '../../../store';
import Loading from '../../Loading/Loading';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { USER_ROLE } from '../../../shared/constants/user.const';

const PUBNUB_PUBLISH_KEY: string =
  process.env.REACT_APP_PUBNUB_PUBLISH_KEY || 'pub-c-d12071d1-d5c8-423f-bdf6-9bc8796ace5a';
const PUBNUB_SUBSCRIBE_KEY: string =
  process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY || 'sub-c-095d822b-1dc6-4cc2-9925-b5014ed4551e';

interface IProps {
  children: any;
}

const ChatProvider: React.FC<IProps> = ({ children }) => {
  const [pubnub, setPubnub] = React.useState<PubNub>();
  const user = useSelector((state) => state.general.currentUser);

  useEffect(() => {
    if (user && user.id && !pubnub) {
      const pubnubInstance = new PubNub({
        publishKey: PUBNUB_PUBLISH_KEY,
        subscribeKey: PUBNUB_SUBSCRIBE_KEY,
        uuid: `${user.id}`,
        logVerbosity: false
      });
      setPubnub(pubnubInstance);
    }
  }, [user, pubnub]);

  if (!pubnub || !user) {
    return <Loading />;
  }

  return <PubNubProvider client={pubnub}>{children}</PubNubProvider>;
};

export default ChatProvider;
