/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import {
  Dialog,
  Stack,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Grid,
  DialogTitle,
  DialogContent,
  Avatar,
  IconButton
} from '@mui/material';
import { ILevelPermission } from '../../shared/interfaces/models/level-permission.interface';
import capitalize from 'lodash/capitalize';
import { ICommunity } from '../../shared/interfaces/models/community.interface';
import { useDispatch } from 'react-redux';
import { getLowestLevel, getTopUsersFromCommunity } from '../../store/actions/communitiesActions';
import { IUserCommunity } from '../../shared/interfaces/models/user-community.interface';
import { getProfileImage } from '../../utils/general';
import { ILevel } from '../../shared/interfaces/models/level.interface';
import CloseIcon from '@mui/icons-material/Close';

interface PropsType {
  community: ICommunity;
  open: boolean;
  setOpen: (open: boolean) => void;
}

const CommunityInfoDialog: React.FC<PropsType> = ({ community, open, setOpen }) => {
  const dispatch = useDispatch();
  const [topUserCommunities, setTopUserCommunities] = React.useState<IUserCommunity[]>([]);
  const [defaultLevel, setDefaultLevel] = React.useState<ILevel>();

  useEffect(() => {
    dispatch(
      getLowestLevel((level) => {
        setDefaultLevel(level);
      })
    );
  }, []);

  useEffect(() => {
    if (community) {
      dispatch(
        getTopUsersFromCommunity({ id: community.id }, (usersCommunities) => {
          console.log('usersCommunities: ', usersCommunities);
          setTopUserCommunities(usersCommunities);
        })
      );
    }
  }, [community]);

  return (
    <Dialog maxWidth="lg" fullWidth onClose={() => setOpen(false)} open={open}>
      <DialogTitle>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Avatar sx={{ width: 24, height: 24 }} src={community?.imageUrl} />
          <Typography variant="h2">
            <strong>{community?.name}</strong>
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ overflow: 'hidden', p: 4 }}>
        <Grid container alignItems="stretch" direction="row" spacing={2}>
          {community?.description && community?.description.length > 0 && (
            <Grid item xs={9}>
              <Typography variant="h5" sx={{ mb: 1 }}>
                <strong>Description</strong>
              </Typography>
              <Paper sx={{ p: 1, height: '86px', overflowY: 'auto' }}>
                <Typography>{community?.description}</Typography>
              </Paper>
            </Grid>
          )}
          <Grid item xs={3}>
            <Typography variant="h5" sx={{ mb: 1 }}>
              <strong>Boost Weights</strong>
            </Typography>
            <Paper sx={{ p: 1, height: '86px', overflowY: 'auto' }}>
              <Stack direction="row" justifyContent="space-around">
                <Stack spacing={'4px'}>
                  {community?.levelPermissions?.map((levelPermission: ILevelPermission) => (
                    <Typography key={levelPermission.id}>{capitalize(levelPermission.level?.name)}</Typography>
                  ))}
                </Stack>
                <Stack spacing={'4px'}>
                  {community?.levelPermissions?.map((levelPermission: ILevelPermission) => (
                    <Typography key={levelPermission.id}>{levelPermission.boostWeight}X</Typography>
                  ))}
                </Stack>
              </Stack>
            </Paper>
          </Grid>
        </Grid>
        <Typography variant="h5" sx={{ mb: 1, mt: 2 }}>
          <strong>Top Users</strong>
        </Typography>
        <Paper sx={{ maxHeight: '300px' }}>
          <TableContainer sx={{ maxHeight: 'inherit', overflowY: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 700 }}>Rank</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>User</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>Level</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>Points</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {topUserCommunities.map((uc, i) => {
                  return uc.user ? (
                    <TableRow key={uc.id}>
                      <TableCell>#{i + 1}</TableCell>
                      <TableCell>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Avatar src={getProfileImage({ user: uc.user })} sx={{ width: 24, height: 24 }} />
                          <Typography>{uc.user?.username}</Typography>
                        </Stack>
                      </TableCell>
                      <TableCell>{capitalize(uc.level?.name ?? defaultLevel?.name ?? 'No level')}</TableCell>
                      <TableCell>{uc.points} echo</TableCell>
                    </TableRow>
                  ) : null;
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </DialogContent>
      <IconButton sx={{ position: 'absolute', right: 1, top: 1, m: 1 }} onClick={() => setOpen(false)}>
        <CloseIcon sx={{ color: 'black' }} />
        {/*<Avatar sx={{ bgcolor: 'white' }}>*/}
        {/*</Avatar>*/}
      </IconButton>
    </Dialog>
  );
};

export default CommunityInfoDialog;
