/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';

import { CircularProgress, Grid, Link as MuiLink, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { Z_INDEXES } from '../../enums/zIndexes';
import useWindowSize from '../../lib/hooks/useWindowSize';
import routePaths from '../../lib/routePaths';
import FooterBar from '../LoginPage/FooterBar';
import LeftSide from '../LoginPage/LeftSide';
import ResetPasswordForm from './ResetPasswordForm';

const ResetPasswordPage: React.FC = () => {
  const size = useWindowSize();

  useEffect(() => {
    document.title = `Echodrop | Reset Password`;
  }, []);

  if (!size.height) {
    return <CircularProgress />;
  }

  return (
    <div>
      <Grid container>
        <Grid item xs={6} sx={{ backgroundColor: 'black', width: '100%', height: size.height }}>
          <LeftSide />
        </Grid>
        <Grid
          item
          xs={6}
          sx={{ width: '100%', px: 10, py: 2, zIndex: Z_INDEXES.LOGIN_PAGE__RIGHT_SIDE, backgroundColor: 'white' }}
        >
          <Stack justifyContent="center" sx={{ height: '100%', marginTop: '-56px', maxWidth: '500px' }}>
            <Typography variant="h1">RESET MY PASSWORD</Typography>
            <ResetPasswordForm />
            <Stack direction="row" justifyContent="center">
              <Typography sx={{ mt: 4 }}>
                Do not you have an account yet?{' '}
                <Link component={MuiLink} to={routePaths.public.register}>
                  Register
                </Link>
              </Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      <FooterBar />
    </div>
  );
};

export default ResetPasswordPage;
