// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Grid, Stack, useMediaQuery, useTheme } from '@mui/material';
import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { WAVE_SIZE_PRESETS } from '../../../enums/player';
import useWindowSize from '../../../lib/hooks/useWindowSize';
import FloatingTemplate from '../../../templates/Desktop/FloatingTemplate';
import AudioVisualizer from '../../AudioVisualizer/AudioVisualizer';
import CommentsSection from './CommentsSection/CommentsSection';
import CoverSection from './CoverSection/CoverSection';
import ReactionSection from './ReactionsSection/ReactionSection';
import ReactionButtonComponent from '../../SharedPlayerSections/ReactionButtonComponent';
import WriteCommentButtonComponent from '../../SharedPlayerSections/WriteCommentButtonComponent';
import { useEchoPlayer } from '../../EchoPlayer/EchoPlayerProvider';
import { ElapsedTimeProvider } from '../../EchoPlayer/ElapsedTimeProvider';

interface RouteParams {
  communityId: string;
}

const RadioBodySection: React.FC = () => {
  const theme = useTheme();
  const downLg = useMediaQuery(theme.breakpoints.down('lg'));
  const upMd = useMediaQuery(theme.breakpoints.up('md'));
  const player = useEchoPlayer();
  const size = useWindowSize();
  const communityId = parseInt(useParams<RouteParams>().communityId);
  const audioVisualizerRef = useRef<any>();
  const containerRef = useRef<any>();

  const waveSizePreset = {
    ...WAVE_SIZE_PRESETS.MEDIUM,
    width: size.containerWidth / 2,
    height: size.containerHeight >= 650 ? WAVE_SIZE_PRESETS.MEDIUM.height : WAVE_SIZE_PRESETS.SMALL.height
  };

  return (
    <>
      <div ref={containerRef} style={{ color: '#fafafa', height: '100%' }}>
        <CoverSection communityId={communityId} />

        <div ref={audioVisualizerRef} style={{ position: 'absolute', bottom: 0, right: 0 }}>
          {player.data.current.playing && (
            <Stack direction="row" justifyContent="center" sx={{ mt: 5 }}>
              <AudioVisualizer showRadioStatusLabel centerContent sizePreset={waveSizePreset} />
            </Stack>
          )}

          {player.data.radio.playing && upMd && (
            <Stack direction={downLg ? 'column' : 'row'} justifyContent="center" alignItems="center" spacing={1}>
              <WriteCommentButtonComponent communityId={communityId} />
              <ReactionButtonComponent communityId={communityId} />
            </Stack>
          )}
        </div>
      </div>
      {upMd && (
        <ElapsedTimeProvider>
          <FloatingTemplate useAnchorAsContainer inheritHeight inheritWidth yInherit anchorRef={audioVisualizerRef}>
            <ReactionSection communityId={communityId} />
          </FloatingTemplate>

          <FloatingTemplate useAnchorAsContainer inheritWidth inheritHeight anchorRef={audioVisualizerRef} yInherit>
            <CommentsSection communityId={communityId} />
          </FloatingTemplate>
        </ElapsedTimeProvider>
      )}
    </>
  );
};

export default RadioBodySection;
